import React, { useEffect, useState } from "react";
import MyHelmetOutside from "../../components/helmet/MyHelmetOutside";
import { useTranslation } from "react-i18next";
import PricingCardsSection from "./PricingCardsSection";
import PricingDetailsSection from "./PricingDetailsSection";
import ServiceActions from "../../actions/ServiceActions";
import UtilNotify from "../../utils/UtilNotify";
import "./Pricing.scss";

const PricingPage = () => {
  const { t } = useTranslation();

  const [loading, setloading] = useState(true);
  const [list, setList] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState("");

  useEffect(() => {
    const fill = async () => {
      setloading(true);
      const result = await ServiceActions.fillList();
      if (result.success) {
        setList(result.data);
        setSelectedPlan(result?.data[0]?.serviceName);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setloading(false);
    };
    fill();
  }, []);

  return (
    <>
      <MyHelmetOutside title={t("WORD_PRICING")} />
      <PricingCardsSection loading={loading} list={list} />
      <PricingDetailsSection
        loading={loading}
        list={list}
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
      />
    </>
  );
};

export default PricingPage;
