import React, { useCallback, useEffect, useMemo, useState } from "react";
import MySpace from "../../../components/mySpace/MySpace";
import MyPageHeader from "../../../components/myPageHeader/MyPageHeader";
import { useTranslation } from "react-i18next";
import MyTable from "../../../components/myTable/MyTable";
import { useSelector } from "react-redux";
import ApiStorage from "../../../redux/ApiStorage";
import ProductQuantitiesFilterDrawer from "./ProductQuantitiesFilterDrawer";
import UtilNotify from "../../../utils/UtilNotify";
import ProductQuantityActions from "../../../actions/ProductQuantityActions";
import UtilDate from "../../../utils/UtilDate";
import { useParams } from "react-router-dom";
import MyLink from "../../../components/myLink/MyLink";
import StatisticCard from "../../../components/statisticCard/StatisticCard";
import useNavigateApi from "../../../appRoute/useNavigateApi";

const ProductQuantities = () => {
  const { _idProduct } = useParams();

  const { t } = useTranslation();
  const navigateApi = useNavigateApi();

  const [pagination, setpagination] = useState({ page: 0, perPage: 50 });
  const [loading, setLoading] = useState(false);
  const [totalRows, settotalRows] = useState(0);
  const [totals, settotals] = useState({});
  const [data, setData] = useState([]);
  const [sort, setsort] = useState("");

  const productQuantitiesFilters = useSelector((state) => {
    return state.productQuantitiesFilters;
  });

  const setpage = (page) => {
    setpagination((ov) => ({ page: page, perPage: ov.perPage }));
  };

  const setFilter = useCallback(
    (fil) => {
      ApiStorage.setProductQuantitiesFilters(
        Object.assign({}, productQuantitiesFilters, fil)
      );
      setpage(0);
    },
    [productQuantitiesFilters]
  );

  const setFilterClear = () => {
    ApiStorage.setProductQuantitiesFilters({});
    setpage(0);
  };

  useEffect(() => {
    const fill = async () => {
      setLoading(true);
      const fetchParams = {
        page: pagination.page,
        perPage: pagination.perPage,
        sort: sort,
        filter: { ...productQuantitiesFilters, idProduct: _idProduct },
      };
      const result = await ProductQuantityActions.fillPagination(fetchParams);
      if (result.success) {
        // if (page !== 0) {
        //   setData((ov) => [...ov, ...(result.data ?? [])]);
        // } else {
        setData(result.data);
        // }
        settotalRows(result.totalRows);
        settotals(result.totals);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setLoading(false);
    };
    fill();
  }, [pagination, productQuantitiesFilters, _idProduct, sort]);

  const columns = useMemo(() => {
    let col = [
      {
        title: t("WORD_PRODUCT_NAME"),
        dataIndex: "productName",
        key: "productName",
        //    width: "160px",
        render: (value, { idProduct }) => {
          return (
            <MyLink
              className="product-link"
              path={`/product/edit/${idProduct}`}
            >
              {value}
            </MyLink>
          );
        },
      },
      {
        title: t("WORD_QUANTITY"),
        dataIndex: "quantity",
        key: "quantity",
        width: 200,
        sorter: true,
      },
      {
        title: t("WORD_TYPE"),
        dataIndex: "type",
        key: "type",
        width: 160,
        sorter: true,
        render: (value) => {
          let type = ProductQuantityActions.getType(value);
          return <div>{type.label}</div>;
        },
      },
      {
        title: t("WORD_NAME"),
        dataIndex: "nameUser",
        key: "nameUser",
        sorter: true,
        width: 200,
      },
      {
        title: t("WORD_CREATE_DATE"),
        dataIndex: "createDate",
        key: "createDate",
        sorter: true,
        width: 200,
        render: (value) => <div>{UtilDate.formatDateTime(value)}</div>,
      },
    ];
    return col;
  }, [t]);

  const onChangeTable = (_, __, sorter) => {
    let so = "";
    if (sorter.order) {
      so = `${sorter.columnKey} ${sorter.order === "ascend" ? "ASC" : "DESC"}`;
    }
    setsort(so);
  };

  return (
    <>
      <MySpace direction="vertical" size={"middle"} fullWidth>
        <div style={{ marginBottom: 10 }}>
          <MyPageHeader
            extraRight={
              <MySpace>
                <ProductQuantitiesFilterDrawer
                  onChange={setFilter}
                  onReset={setFilterClear}
                  filter={productQuantitiesFilters}
                />
              </MySpace>
            }
            title={t("WORD_PRODUCT_QUANTITIES")}
            onBack={() => navigateApi(`/product/list`)}
          />
        </div>
        <MySpace className="stat-card-row">
          <StatisticCard
            title={t("WORD_TOTAL_QUANTITY")}
            loading={loading}
            amount={Math.round(totals?.totalQuantity)}
            withoutCurrency={true}
            // icon={<ShoppingCartPurpleCircleSVG />}
            // secondTitle={t("WORD_IN")}
            // count={totals?.totalCountOrder}
          />
        </MySpace>
        <MyTable
          rowKey="idProductQuantity"
          // height={"75vh"}
          columns={columns}
          dataSource={data}
          onChangePage={(e, ee) => {
            setpage(e);
          }}
          loading={loading}
          total={totalRows}
          onChange={onChangeTable}
          page={pagination.page}
          pageSize={pagination.perPage}
        />
      </MySpace>
    </>
  );
};

export default ProductQuantities;
