import React, { memo } from "react";
import { Table } from "antd";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";
import MyPagination from "../../components/myPagination/MyPagination";

const MyDraggableTable = ({
  dataSource,
  columns,
  rowKey,
  rowSelection,
  onChange,
  onChangePage,
  footer,
  noFooter,
  total,
  onShowSizeChange,
  pageSize,
  page,
  loading,
  onChangeTable,
  onRow,
  onRowClick,
  onRowDoubleClick,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 1 } })
  );

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      const activeIndex = dataSource.findIndex(
        (record) => record[rowKey] === active?.id
      );
      const overIndex = dataSource.findIndex(
        (record) => record[rowKey] === over?.id
      );
      const list = arrayMove(dataSource, activeIndex, overIndex);
      onChange &&
        onChange(
          list.map((it) => it[rowKey]),
          list
        );
    }
  };

  const Row = ({ ...props }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props["data-row-key"],
    });

    const style = {
      ...props.style,
      transform: isTableEmpty ? undefined : CSS.Translate.toString(transform),
      transition: isDragging ? "none" : transition,
      cursor: isTableEmpty ? "default" : "grab",
      ...(isDragging
        ? {
            position: "relative",
            zIndex: 9999,
            background: isTableEmpty ? "" : "#f0f0f0",
          }
        : {}),
    };

    return (
      <tr
        {...props}
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
      />
    );
  };

  const MemoizedRow = memo(Row);

  let isTableEmpty = dataSource.length === 0;

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={onDragEnd}
    >
      <SortableContext
        items={dataSource.map((i) => i[rowKey])}
        strategy={verticalListSortingStrategy}
      >
        <Table
          rowSelection={rowSelection}
          onChange={onChangeTable}
          onRow={
            onRow ||
            ((record, rowIndex) => {
              return {
                onClick: onRowClick, // click row
                onDoubleClick: onRowDoubleClick, // double click row
              };
            })
          }
          loading={loading}
          className="draggable-table"
          showSorterTooltip={false}
          footer={
            noFooter
              ? false
              : () => (
                  <>
                    {footer}
                    <MyPagination
                      total={total}
                      onShowSizeChange={onShowSizeChange}
                      pageSize={pageSize}
                      onChange={onChangePage}
                      current={page}
                      showQuickJumper={false}
                    />
                  </>
                )
          }
          columns={columns}
          dataSource={dataSource}
          size="small"
          pagination={false}
          rowKey={rowKey}
          components={{
            body: {
              row: MemoizedRow,
            },
          }}
        />
      </SortableContext>
    </DndContext>
  );
};

export default MyDraggableTable;
