import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UtilNotify from "../../utils/UtilNotify";
import ProductUnitActions from "../../actions/ProductUnitActions";
import MySelectFormItem from "../mySelectFormItem/MySelectFormItem";

const SelectUnit = ({ idUnit, ...otherProps }) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    const fill = async () => {
      const result = await ProductUnitActions.fillListOptions(idUnit);
      if (result.success) {
        const translatedUnits = result.data.map((unit) => ({
          // ...unit,
          value: unit.value,
          label: t(unit.label),
        }));
        setlist(translatedUnits);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    };
    fill();
  }, [t, idUnit]);

  return <MySelectFormItem options={list} {...otherProps} />;
};

export default SelectUnit;
