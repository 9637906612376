import React, { useEffect, useRef, useState } from "react";
import ChangePackageCard from "./ChangePackageCard";
import ChangePackageCardEnterprise from "./ChangePackageCardEnterprise";
import ServiceActions from "../../../actions/ServiceActions";
import UtilNotify from "../../../utils/UtilNotify";
import { useTranslation } from "react-i18next";
import ChangePackageModalPay from "./ChangePackageModalPay";
import MyHelmet from "../../../components/helmet/MyHelmet";
import LoadingIconPage from "../../../components/loadingIconApp/LoadingIconPage";
import useWindowSize from "../../../utils/useWindowSize";
import PricingLeftSVG from "../../../icons/PricingLeftSVG";
import UtilNumber from "../../../utils/UtilNumber";
import "./Package.scss";

const ChangePackagePage = () => {
  const { t } = useTranslation();
  const refModal = useRef();
  const { isMobile } = useWindowSize();

  const [list, setList] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    const fill = async () => {
      setloading(true);
      const result = await ServiceActions.fillList();
      if (result.success) {
        setList(result.data);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setloading(false);
    };
    fill();
  }, []);

  if (loading) {
    return <LoadingIconPage />;
  }

  return (
    <>
      <MyHelmet title={t("WORD_PACKAGES")} />
      <div className="pricing-card-wrapper-package">
        {isMobile ? null : (
          <div className="left-image">
            <PricingLeftSVG />
          </div>
        )}
        <div className="package-card-wrapper">
          {list.map((oo) => {
            return (
              <ChangePackageCard
                key={oo.idService}
                packageName={t(oo.serviceName)}
                packagePrice={`${UtilNumber.formatDouble(oo.priceMontly)}${
                  oo.currrencySymbol
                }`}
                packagePerMonth={`${t(
                  "per month or"
                )} ${UtilNumber.formatDouble(oo.priceYearly)}${
                  oo.currrencySymbol
                } ${t("billed annually")}`}
                packageDeviceCount={oo.deviceCount}
                packageBranchCount={oo.adminCount}
                packageDataStorage={oo.storageSize}
                packageGPSTracking={oo.tracking}
                isPopular={false}
                onClick={() => {
                  refModal?.current?.viewPay(oo);
                }}
              />
            );
          })}
          <ChangePackageCardEnterprise packageName="Enterprise" />
        </div>
      </div>
      <ChangePackageModalPay ref={refModal} />
    </>
  );
};

export default ChangePackagePage;
