import React, { useState } from "react";
import GpsYandex from "./GpsYandex";
import GpsGoogle from "./GpsGoogle";
import MyHelmet from "../../components/helmet/MyHelmet";
import { useTranslation } from "react-i18next";

const Gps = () => {
  const { t } = useTranslation();

  const [mapType] = useState("yandex");

  return (
    <>
      <MyHelmet title={t("WORD_GPS")} />
      {mapType === "google" ? <GpsGoogle /> : <GpsYandex />}
    </>
  );
};

export default Gps;
