import React, { memo } from "react";
import MyIcon from "./MyIcon";

export const SVGIcon = ({ style, size }) => {
  return (
    <svg
      width={size || 48}
      height={size || 48}
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M26.7337 4.32935C26.6391 4.81535 26.592 5.30937 26.593 5.80447C26.593 10.2465 30.1992 13.8474 34.6477 13.8474C35.1375 13.845 35.6261 13.798 36.1074 13.7069V29.6523C36.1074 36.3606 32.1504 40.3293 25.4323 40.3293H10.8001C4.0644 40.3293 0.107391 36.3606 0.107391 29.6523V15.0415C0.107391 8.33325 4.0644 4.32935 10.8001 4.32935H26.7337ZM26.9754 16.3179L26.7407 16.3325C26.3496 16.3888 25.9981 16.601 25.7664 16.9206L20.9476 23.1196L15.4606 18.7996C15.1473 18.5555 14.7488 18.4478 14.355 18.5006C13.9612 18.5535 13.6054 18.7626 13.3678 19.0806L7.45864 26.702C7.25093 26.9609 7.13911 27.2835 7.14208 27.6152L7.14727 27.8359C7.20455 28.4159 7.60236 28.9171 8.17215 29.097C8.81315 29.2993 9.51015 29.044 9.86802 28.4757L14.8099 22.0835L20.2969 26.3859C20.609 26.6375 21.0102 26.7516 21.4082 26.7019C21.8062 26.6522 22.1669 26.4431 22.4073 26.1225L28.123 18.7469V18.7118C28.6123 18.0543 28.4875 17.1271 27.8416 16.622C27.529 16.3807 27.1318 16.2763 26.7407 16.3325L26.9754 16.3179Z"
        fill="white"
      ></path>
      <path
        opacity="0.4"
        d="M35.1074 10.3293C37.8688 10.3293 40.1074 8.09077 40.1074 5.32935C40.1074 2.56792 37.8688 0.329346 35.1074 0.329346C32.346 0.329346 30.1074 2.56792 30.1074 5.32935C30.1074 8.09077 32.346 10.3293 35.1074 10.3293Z"
        fill="white"
      ></path>
    </svg>
  );
};

const DebtSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(DebtSVG);
