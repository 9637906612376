import React from "react";
import MyCard from "../../../components/myCard/MyCard";
import { useTranslation } from "react-i18next";
import MyButtonMain from "../../../components/myButton/MyButtonMain";
import MySpace from "../../../components/mySpace/MySpace";
import MyTextSecondary from "../../../components/myText/MyTextSecondary";
import MyLinkA from "../../../components/myLinkA/MyLinkA";
import "./Package.scss";

const ChangePackageCardEnterprise = ({ isPopular }) => {
  const { t } = useTranslation();

  const handleClick = () => {
    window.location.href = "tel:+37412800700";
  };

  return (
    <MyCard className="package-card package-card-enterprise">
      <MySpace
        direction="vertical"
        fullWidth
        style={{ justifyContent: "space-between" }}
      >
        <div>
          <h4
            style={{ marginTop: isPopular ? 35 : 35 }}
            className="package-name"
          >
            {t("WORD_ENTERPRISE")}
          </h4>
          <MyTextSecondary className="package-price-enterprise" bold size={20}>
            {t("WORD_NEED_CUSTOM_SOLUTION")}
          </MyTextSecondary>
        </div>
        <div className="enterprise-text">{t("WORD_TALK_ABOUT_ORDERSBOOK")}</div>
        <MySpace direction="vertical" fullWidth>
          <MyButtonMain
            className="package-btn"
            label={"+374-12-800700"}
            onClick={handleClick}
          />
          <MyLinkA
            label={
              <MyButtonMain
                className="package-demo-btn"
                changePassword
                label={t("WORD_REQUEST_DEMO")}
              />
            }
            blank
            href="https://calendly.com/ubicross/ordersbook-demo"
            style={{ width: "100%", height: "100%", display: "block" }}
          />
        </MySpace>
      </MySpace>
    </MyCard>
  );
};

export default ChangePackageCardEnterprise;
