import React, { useEffect, useMemo, useState, useCallback } from "react";
import MySpace from "../../../components/mySpace/MySpace";
import MyPageHeader from "../../../components/myPageHeader/MyPageHeader";
import UtilNotify from "../../../utils/UtilNotify";
import { useTranslation } from "react-i18next";
import UserActions from "../../../actions/UserActions";
import ApiStorage from "../../../redux/ApiStorage";
import { useSelector } from "react-redux";
import MyTable from "../../../components/myTable/MyTable";
import { faArrowsUpDown, faPen } from "@fortawesome/free-solid-svg-icons";
import MyHelmet from "../../../components/helmet/MyHelmet";
import MyTooltip from "../../../components/myTooltip/MyTooltip";
import PartnersSVG from "../../../icons/PartnersSVG";
import useNavigateApi from "../../../appRoute/useNavigateApi";
import TeamNewUserPage from "./TeamNewUserPage";
import MyButtonSuccess from "../../../components/myButton/MyButtonSuccess";
import FormApp from "../../../components/formApp/FormApp";
import SelectOrderBranch from "../../../components/selects/SelectOrderBranch";
import SelectManagerStatus from "../../../components/selects/SelectManagerStatus";
import { useForm } from "antd/es/form/Form";
import MyButtonText from "../../../components/myButton/MyButtonText";
import SearchInput from "../../../components/myInputForm/SearchInput";
import MyButtonTableAction from "../../../components/myButton/MyButtonTableAction";
import { Flex } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import MyTag from "../../../components/tag/MyTag";

const formName = "formUserListFilter";

const UserList = () => {
  const { t } = useTranslation();
  const navigateApi = useNavigateApi();
  const [form] = useForm();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setpagination] = useState({ page: 0, perPage: 50 });
  const [totalRows, settotalRows] = useState(0);
  const [search, setSearch] = useState("");
  const [sort, setsort] = useState("");

  const userPageFilters = useSelector((state) => {
    return state.userPageFilters;
  });

  const stepsDone = useSelector((state) => {
    return state.currentUser?.stepsDone;
  });

  const isUser1C = useSelector((state) => {
    return state.currentUser?.is1c;
  });

  const onFinish = useCallback(
    (fil) => {
      ApiStorage.setUserPageFilters(Object.assign({}, userPageFilters, fil));
      setpage(0);
    },
    [userPageFilters]
  );

  const setFilterClear = () => {
    form?.resetFields();
    ApiStorage.setUserPageFilters({});
    setpage(0);
  };

  useEffect(() => {
    const fill = async () => {
      setLoading(true);
      const fetchParams = {
        page: pagination.page,
        perPage: pagination.perPage,
        search: search,
        sort: sort,
        filter: { ...userPageFilters, role: UserActions.ROLE.MANAGER },
      };
      const result = await UserActions.fillPagination(fetchParams);
      if (result.success) {
        if (pagination.page !== 0) {
          setData((ov) => [...ov, ...(result.data ?? [])]);
        } else {
          setData(result.data);
        }
        settotalRows(result.totalRows);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setLoading(false);
    };

    fill();
  }, [pagination, search, userPageFilters, sort]);

  useEffect(() => {
    if (userPageFilters) {
      form?.setFieldsValue(userPageFilters);
    }
  }, [userPageFilters, form]);

  const columns = useMemo(() => {
    let col = [
      {
        title: t("WORD_USERNAME"),
        dataIndex: "username",
        key: "username",
        width: 160,
        sorter: true,
        render: (value, { idUser }) => {
          return <MyTooltip title={`#${idUser}`}>{value}</MyTooltip>;
        },
      },
      {
        title: t("WORD_FIRST_NAME"),
        dataIndex: "fName",
        key: "fName",
        sorter: true,
      },
      {
        title: t("WORD_STATUS"),
        dataIndex: "status",
        key: "status",
        width: 50,
        align: "right",
        render: (value, { role, toUpdate }) => {
          const status = UserActions.getStatusLabel(value);
          return (
            <MySpace>
              <MyTag label={t(status.label)} color={status.color} />
              {role === UserActions.ROLE.MANAGER ? (
                <div>
                  <SyncOutlined
                    style={{
                      color: Number(toUpdate) ? "silver" : "green",
                      fontSize: 15,
                    }}
                  />
                </div>
              ) : null}
            </MySpace>
          );
        },
      },
      {
        title: t("WORD_BRANCH"),
        dataIndex: "nameBranch",
        key: "nameBranch",
        width: 160,
      },
      {
        title: t("WORD_ACTIONS"),
        dataIndex: "actions",
        width: 120,
        key: "actions",
        render: (_, { idUser, username }) => (
          <MySpace>
            <MyButtonTableAction
              title={t("WORD_EDIT")}
              icon={faPen}
              onClick={() => navigateApi(`/user/edit/${idUser}`)}
            />
            <MyButtonTableAction
              antdIcon
              title={t("WORD_CUSTOMERS")}
              icon={<PartnersSVG size={13} />}
              onClick={() => navigateApi(`/user/route/${idUser}`)}
            />
            {isUser1C ? null : (
              <MyButtonTableAction
                title={t("WORD_QUANTITY")}
                icon={faArrowsUpDown}
                onClick={() =>
                  navigateApi(`/user/quantities/${idUser}`, {
                    state: { username: username },
                  })
                }
              />
            )}
          </MySpace>
        ),
      },
    ];
    return col;
  }, [t, navigateApi, isUser1C]);

  const setpage = (page) => {
    setpagination((ov) => ({ page: page, perPage: ov.perPage }));
  };

  const onChangeTable = (_, __, sorter) => {
    let so = "";
    if (sorter.order) {
      so = `${sorter.columnKey} ${sorter.order === "ascend" ? "ASC" : "DESC"}`;
    }
    setsort(so);
  };

  return (
    <>
      <MyHelmet title={t("WORD_MANAGER")} />
      <Flex vertical gap={10}>
        <MyPageHeader
          inPage
          extraRight={
            <MySpace>
              <MyButtonSuccess
                withIcon
                label={t("WORD_ADD")}
                onClick={() => navigateApi(`/user/create`)}
              />
            </MySpace>
          }
          title={t("WORD_SALES_TEAM")}
        />
        <MySpace style={{ marginLeft: 8 }}>
          <SearchInput
            onClear={() => {
              setSearch("");
              setpage(0);
            }}
            onPressEnter={(value) => {
              setSearch(value);
              setpage(0);
            }}
          />
          <FormApp onFinish={onFinish} name={formName} form={form}>
            <MySpace>
              <SelectOrderBranch
                name="idBranch"
                placeholder={t("WORD_BRANCH")}
                onChange={() => form.submit()}
                width={140}
              />
              <SelectManagerStatus
                name="status"
                placeholder={t("WORD_STATUS")}
                onChange={() => form.submit()}
                width={160}
              />
              <MyButtonText
                label={t("WORD_RESET_FILTERS")}
                onClick={setFilterClear}
              />
            </MySpace>
          </FormApp>
        </MySpace>
        {!stepsDone && !loading && data.length === 0 ? (
          <TeamNewUserPage />
        ) : (
          <MyTable
            rowKey="idUser"
            height={"75vh"}
            columns={columns}
            dataSource={data}
            onChange={onChangeTable}
            loading={loading}
            total={totalRows}
            onChangePage={(e, ee) => {
              setpagination({ page: e, perPage: ee });
            }}
            page={pagination.page}
            pageSize={pagination.perPage}
            hideOnSinglePage={false}
            // noFooter
            // corners
          />
        )}
      </Flex>
    </>
  );
};

export default UserList;
