import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import MyModalForm from "../../../components/myModal/MyModalForm";
import { useTranslation } from "react-i18next";
import InputTextFormItem from "../../../components/myInputForm/InputTextFormItem";
import FormApp from "../../../components/formApp/FormApp";
import { useForm } from "antd/es/form/Form";
import { Tree } from "antd";
import UtilNotify from "../../../utils/UtilNotify";
import ProductActions from "../../../actions/ProductActions";
import MyDivider from "../../../components/myDivider/MyDivider";
import { getBaseURLShowImage } from "../../../utils/UrlHelper";
import MyImageSmallPreview from "../../../components/myImage/MyImageSmallPreview";

const formName = "formFolderMove";

const { DirectoryTree } = Tree;

const FolderMoveModal = forwardRef(
  ({ selectedRowKeys, onChange, idParent }, ref) => {
    const refModal = useRef();
    const { t } = useTranslation();
    const [form] = useForm();

    const [loading, setLoading] = useState(true);
    const [list, setlist] = useState([]);


    const fill = useCallback(async () => {
      setLoading(true);
      const result = await ProductActions.getAllForTree();
      if (result.success) {
        const fixedData = result.data
          .filter((oo) => {
            const productId = Number(oo.idProduct);
            const parentId = Number(idParent);
            return (
              !selectedRowKeys.includes(productId) && productId !== parentId
            );
          })
          .map((dt) => ({
            ...dt,
            icon: dt.icon ? (
              <MyImageSmallPreview
                icon={getBaseURLShowImage(dt.icon)}
                width={25}
                height={30}
              />
            ) : null,
            key: dt.idProduct,
          }));
        if (idParent) {
          fixedData.unshift({
            idParent: 0,
            icon: null,
            key: 0,
            title: "Root folder",
          });
        }
        setlist(fixedData);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setLoading(false);
    }, [selectedRowKeys, idParent])



    useImperativeHandle(ref, () => ({
      viewFolders: async () => {
        refModal.current.open();
        await fill();
      },
    }));

    const onFinish = async (values) => {
      values.productList = selectedRowKeys;
      const result = await ProductActions.moveMultiple(values);
      if (result.success) {
        refModal.current.close();
        onChange && onChange();
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    };

    const getTitle = () => {
      return `${t("WORD_MOVE")} ${selectedRowKeys?.length} ${selectedRowKeys?.length > 1 ? t("WORD_ITEMS") : t("WORD_ITEM")
        }`;
    };

    const onSelect = (selectedKeys) => {
      if (selectedKeys?.length > 0) {
        form.setFieldsValue({ idParent: selectedKeys[0] });
      }
    };

    return (
      <MyModalForm
        ref={refModal}
        loading={loading}
        title={getTitle()}
        okText={t("WORD_MOVE")}
        //  saving={saving}
        formName={formName}
        width={600}
      >
        <MyDivider />
        <div style={{ height: 300, overflowY: "auto", padding: 5 }}>
          <FormApp onFinish={onFinish} name={formName} form={form}>
            <InputTextFormItem name="idParent" hidden />
            <DirectoryTree
              treeData={list}
              expandAction={false}
              onSelect={onSelect}
              defaultExpandParent={false}
            />
          </FormApp>
        </div>
        <MyDivider />
      </MyModalForm>
    );
  }
);

export default FolderMoveModal;
