import React, { forwardRef, useMemo } from "react";
import MySpace from "../../../components/mySpace/MySpace";
import { useTranslation } from "react-i18next";
import UtilDate from "../../../utils/UtilDate";
import MyTable from "../../../components/myTable/MyTable";
import LogoLightSVG from "../../../icons/LogoLightSVG";
import MyLinkA from "../../../components/myLinkA/MyLinkA";
import MyText from "../../../components/myText/MyText";
import { Table } from "antd";
import InvoiceActions from "../../../actions/InvoiceActions";
import { useFormatCurrency } from "../../../utils/useFormatWithCurrency";

const InvoicePrint = forwardRef(({ data }, ref) => {
  const { t } = useTranslation();
  const { formatDoubleWithCurrency } = useFormatCurrency();

  const columns = useMemo(() => {
    let col = [
      // {
      //   title: t("WORD_N"),
      //   dataIndex: "n",
      //   key: "n",
      //   width: 30,
      //   align: "center",
      //   render: (_, __, index) => <div>{index + 1}</div>,
      // },
      {
        title: t("WORD_DESCRIPTION"),
        dataIndex: "serviceName",
        key: "serviceName",
        render: (value) => <div>{value + " package"}</div>,
      },
      {
        title: t("WORD_MONTH"),
        dataIndex: "quantity",
        key: "quantity",
        width: 30,
        align: "center",
      },
      {
        title: t("WORD_PRICE"),
        dataIndex: "price",
        key: "price",
        width: 170,
        render: (value) => <div>{formatDoubleWithCurrency(value)}</div>,
      },
      {
        title: t("WORD_AMOUNT"),
        dataIndex: "totalAmount",
        key: "totalAmount",
        width: 150,
        render: (value) => <div>{formatDoubleWithCurrency(value)}</div>,
      },
    ];
    return col;
  }, [t, formatDoubleWithCurrency]);

  const statusLabel = InvoiceActions.getStatus(data?.status).label;

  return (
    <div ref={ref} style={{ padding: 35 }}>
      <div className="ordersbook-logo-wrapper">
        <LogoLightSVG />
      </div>
      <MySpace fullWidth direction="vertical" size="middle">
        {/* <MyTextTitle size={24} bold style={{ textTransform: "uppercase" }}>
          Ubicross LLC
        </MyTextTitle> */}
        <MySpace
          fullWidth
          spaceBetween
          align="top"
          style={{ marginBottom: 10 }}
        >
          <MySpace direction="vertical" style={{ gap: 0 }}>
            <MyLinkA
              href="https://app.ordersbook.com/"
              label={"ordersbook.com"}
              blank
              color="blue"
            />
            <MyText>{"Vagharshyan 12 area 1"}</MyText>
            <MyText>{"Yerevan, Armenia"}</MyText>
            <MyText>{"Zip code: 0012"}</MyText>
          </MySpace>
          <MySpace direction="vertical" style={{ gap: 0 }}>
            <MyText>{`${t("WORD_INVOICE_ID")} # ${data?.idInvoice}`}</MyText>
            <MyText>
              {`${t("WORD_DATE")}: ${UtilDate.formatDateTime(
                data?.createDate
              )}`}
            </MyText>
            <MyText>
              {`${t("WORD_PAYING_FOR")}: ${UtilDate.formatDate(
                data?.invoiceDateFrom
              )}
                 -
                ${UtilDate.formatDate(data?.invoiceDateTo)}`}
            </MyText>
            <MyText>{"Status: " + statusLabel}</MyText>
          </MySpace>
        </MySpace>
        <MyTable
          rowKey="idInvoice"
          columns={columns}
          dataSource={data?.invoiceItems}
          page={0}
          totalRows={1}
          noFooter
          summary={() => {
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell />
                  <Table.Summary.Cell />
                  <Table.Summary.Cell />
                  <Table.Summary.Cell index={0}>
                    <strong>{t("WORD_TOTAL_AMOUNT")}:</strong>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <strong style={{ textWrap: "nowrap" }}>
                      {formatDoubleWithCurrency(data?.totalAmount)}
                    </strong>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </MySpace>
    </div>
  );
});

export default InvoicePrint;
