import React from "react";
import MySpace from "../../../components/mySpace/MySpace";
import ShoppingCartPurpleCircleSVG from "../../../icons/ShoppingCartPurpleCircleSVG";
import MyTextSecondary from "../../../components/myText/MyTextSecondary";
import { useTranslation } from "react-i18next";
import WalletSVG from "../../../icons/WalletSVG";
import RefundsYellowCircleSVG from "../../../icons/RefundsYellowCircleSVG";
import LocationSVG from "../../../icons/GpsSVG";
import MyDivider from "../../../components/myDivider/MyDivider";
import { Flex } from "antd";
import { useFormatCurrency } from "../../../utils/useFormatWithCurrency";
import UtilNumber from "../../../utils/UtilNumber";

const VisitViewRightCardStats = () => {
  const { t } = useTranslation();
  const { formatDoubleWithCurrency } = useFormatCurrency();

  const VisitBlockRender = ({
    label,
    data,
    icon,
    count,
    color,
    noCurrency,
    hasMargin,
    isVisit,
  }) => {
    return (
      <MySpace
        direction="vertical"
        fullWidth
        style={{
          gap: 5,
          marginTop: hasMargin ? 20 : 0,
        }}
      >
        <MySpace fullWidth>
          {icon}
          <MyTextSecondary color={color}>{label}</MyTextSecondary>
        </MySpace>
        <MyTextSecondary color={color} size={15} bold>
          {noCurrency
            ? UtilNumber.formatDouble(data)
            : formatDoubleWithCurrency(data)}
        </MyTextSecondary>
        {isVisit ? null : (
          <MySpace fullWidth>
            <MyTextSecondary>{count}</MyTextSecondary>
            <MyTextSecondary>{t("WORD_ORDERS")}</MyTextSecondary>
          </MySpace>
        )}
      </MySpace>
    );
  };

  return (
    <MySpace
      direction="vertical"
      fullWidth
      style={{ gap: 0, marginBottom: 10 }}
    >
      <Flex>
        <VisitBlockRender
          color="#8247FF"
          hasMargin
          label={t("WORD_ORDERS")}
          data={149500}
          icon={<ShoppingCartPurpleCircleSVG size={20} />}
          count={11}
        />
        <VisitBlockRender
          color="#9FCC6B"
          hasMargin
          label={t("WORD_PAYMENTS")}
          data={30500}
          icon={<WalletSVG fill="#9FCC6B" size={15} />}
          count={5}
        />
      </Flex>
      <MyDivider />
      <Flex>
        <VisitBlockRender
          color="#F4C563"
          label={t("WORD_REFUNDS")}
          data={1900}
          icon={<RefundsYellowCircleSVG size={20} />}
          count={5}
        />
        <VisitBlockRender
          color="#268AFF"
          label={t("WORD_VISITS")}
          data={2}
          isVisit
          noCurrency
          icon={<LocationSVG fill="#268AFF" size={15} />}
          count={1}
        />
      </Flex>
    </MySpace>
  );
};

export default VisitViewRightCardStats;
