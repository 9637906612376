import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MySpace from "../../../components/mySpace/MySpace";
import MyPageHeader from "../../../components/myPageHeader/MyPageHeader";
import { useTranslation } from "react-i18next";
import MyCard from "../../../components/myCard/MyCard";
import MyDivider from "../../../components/myDivider/MyDivider";
import UtilNumber from "../../../utils/UtilNumber";
import UtilDate from "../../../utils/UtilDate";
import OrdersActions from "../../../actions/OrdersActions";
import UtilNotify from "../../../utils/UtilNotify";
import { useParams } from "react-router-dom";
import MyTable from "../../../components/myTable/MyTable";
import PrintSVG from "../../../icons/PrintSVG";
import OrderViewMapNotIncluded from "./OrderViewMapNotIncluded";
import { Placemark } from "react-yandex-maps";
import MyDropDown from "../../../components/myDropDown/MyDropDown";
import { useReactToPrint } from "react-to-print";
import ImagePlaceholderSVG from "../../../icons/ImagePlaceholderSVG";
import MyHelmet from "../../../components/helmet/MyHelmet";
import MyMapYandex from "../../../components/myMapYandex/MyMapYandex";
import MyLink from "../../../components/myLink/MyLink";
import MyImage from "../../../components/myImage/MyImage";
import TitleDataRowAlignEnd from "../../../components/titleDataRowAlignEnd/TitleDataRowAlignEnd";
import MyCardBorderless from "../../../components/myCard/MyCardBorderless";
import OrderViewPrintPage from "./OrderViewPrintPage";
import MyTextTitle from "../../../components/myText/MyTextTitle";
import LoadingIconPage from "../../../components/loadingIconApp/LoadingIconPage";
import MyButtonConfirm from "../../../components/myButton/MyButtonConfirm";
import { useFormatCurrency } from "../../../utils/useFormatWithCurrency";
import "./OrderView.scss";

const OrderView = ({ isPayment }) => {
  const { t } = useTranslation();
  const contentRef = useRef();
  const mapRef = useRef(null);
  const { _idOrder } = useParams();
  const { formatDoubleWithCurrency } = useFormatCurrency();

  const [loading, setloading] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [includeImage, setIncludeImage] = useState(false);

  const reactToPrintFn = useReactToPrint({ contentRef });

  const handleDropdownClick = (item) => {
    if (item.key === "0") {
      setIncludeImage(true);
    } else {
      setIncludeImage(false);
    }
    setTimeout(() => {
      reactToPrintFn();
    }, 0);
  };

  const fill = useCallback(async () => {
    setloading(true);
    const resultGetOne = await OrdersActions.getOne(_idOrder);
    if (resultGetOne.success) {
      setOrderData(resultGetOne.data);
    } else {
      UtilNotify.notifyErrorServer(resultGetOne.errMsg);
    }
    setloading(false);
  }, [_idOrder]);

  useEffect(() => {
    fill();
  }, [fill]);

  const columns = useMemo(() => {
    let col = [
      {
        title: t("WORD_ICON"),
        dataIndex: "icon",
        key: "icon",
        align: "center",
        width: 60,
        render: (_, { icon }) =>
          icon ? (
            <MyImage icon={icon} width={25} height={30} />
          ) : (
            <div>
              <ImagePlaceholderSVG size={25} />
            </div>
          ),
      },
      {
        title: t("WORD_PRODUCTS"),
        dataIndex: "nameProduct",
        key: "nameProduct",
        //  width: "120px",
        render: (value, { idProduct }) => (
          <MyLink className="product-link" path={`/product/edit/${idProduct}`}>
            {value}
          </MyLink>
        ),
      },
      {
        title: t("WORD_PRICE"),
        dataIndex: "price",
        key: "price",
        width: 150,
        align: "right",
        render: (value) => <div>{formatDoubleWithCurrency(value)}</div>,
      },
      {
        title: t("WORD_QUANTITY"),
        dataIndex: "quantity",
        key: "quantity",
        width: 100,
      },
      {
        title: t("WORD_TOTAL_AMOUNT"),
        dataIndex: "totalPrice",
        key: "totalPrice",
        width: 120,
        align: "right",
        render: (value) => <div>{formatDoubleWithCurrency(value)}</div>,
      },
    ];
    return col;
  }, [t, formatDoubleWithCurrency]);

  const ViewColumnRender = ({ label, data, isLink, path }) => {
    return (
      <MySpace direction="vertical" style={{ gap: 0 }} fullWidth align="top">
        <MyTextTitle size={12}>{label + ":"}</MyTextTitle>
        <div className="left-card-label">
          {!isLink ? <div>{data}</div> : <MyLink path={path}>{data}</MyLink>}
        </div>
      </MySpace>
    );
  };

  const items = [
    {
      key: "0",
      label: t("WORD_PRINT_WITH_IMAGE"),
      onClick: (item) => handleDropdownClick(item),
    },
    {
      key: "1",
      label: t("WORD_PRINT_WITHOUT_IMAGE"),
      onClick: (item) => handleDropdownClick(item),
    },
  ];

  let status = OrdersActions.getOrderStatusLabel(orderData?.status);
  let type = OrdersActions.getOrderTypeLabel(orderData?.type);
  let distance = OrdersActions.getDistanceStatusLabel(
    orderData?.distanceStatus
  );

  const getTitle = () => {
    return `${
      orderData?.type === 3 ? t("WORD_PAYMENTS") : t("WORD_ORDERS")
    } ${t("WORD_VIEW")}`;
  };

  const handleChangeToNotExported = async () => {
    const result = await OrdersActions.changeToNotExported(_idOrder);
    if (result.success) {
      await fill();
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
  };

  if (loading) {
    return <LoadingIconPage />;
  }

  return (
    <>
      <MyHelmet title={getTitle()} />
      <MySpace
        direction="vertical"
        size={"middle"}
        fullWidth
        className="no-print"
        style={{ marginTop: 15 }}
      >
        <div className="page-second-section-wrapper">
          <div>
            <MyCard style={{ width: 270 }} className="left-card">
              <>
                <MyPageHeader
                  title={` ${
                    orderData?.type === 3
                      ? t("WORD_PAYMENT_ID")
                      : t("WORD_ORDER_ID")
                  }: #${orderData?.idOrder}`}
                  extraRight={
                    <MyDropDown width={170} items={items} arrow={false}>
                      <PrintSVG />
                    </MyDropDown>
                  }
                  // onBack={() => navigateApi("/order/list")}
                  className="my-title-wrapper"
                />
                <MyDivider />
                <MySpace direction="vertical" fullWidth>
                  <ViewColumnRender
                    label={t("WORD_CUSTOMER")}
                    data={orderData?.nameCustomer}
                    isLink
                    path={`/customer/edit/${orderData?.idCustomer}`}
                  />
                  <ViewColumnRender
                    label={t("WORD_DISTRIBUTOR")}
                    data={orderData?.nameManager}
                    isLink
                    path={`/user/edit/${orderData?.idManager}`}
                  />
                  <ViewColumnRender
                    label={t("WORD_BRANCH")}
                    data={orderData?.nameBranch}
                    isLink
                    path={`/branch/edit/${orderData?.idBranch}`}
                  />
                  <MyDivider />
                  {orderData?.exported ? (
                    <MyButtonConfirm
                      label={t("WORD_EXPORTED")}
                      onConfirm={handleChangeToNotExported}
                      title={t("WORD_ARE_YOU_SURE_YOU_WANT_TO_CHANGE_EXPORT")}
                      placement="topLeft"
                      isText
                    />
                  ) : null}
                  <ViewColumnRender
                    label={t("WORD_TYPE")}
                    data={t(type.label)}
                  />
                  <ViewColumnRender
                    label={t("WORD_STATUS")}
                    data={t(status.label)}
                  />
                  <ViewColumnRender
                    label={t("WORD_CREATE_DATE")}
                    data={UtilDate.formatDateTime(orderData?.createdAt)}
                  />
                  {orderData?.syncDate ? (
                    <ViewColumnRender
                      label={t("WORD_SYNC_DATE")}
                      data={UtilDate.formatDateTime(orderData?.syncDate)}
                    />
                  ) : null}
                  <ViewColumnRender
                    label={t("WORD_DELIVERY_DATE")}
                    data={UtilDate.formatDateTime(orderData?.dueDate)}
                  />
                  <ViewColumnRender
                    label={t("WORD_PAYMENT_TYPE")}
                    data={orderData?.namePaymenttype}
                  />
                  {orderData?.tax ? (
                    <ViewColumnRender
                      label={t("WORD_TAX")}
                      data={orderData?.tax}
                    />
                  ) : null}
                  <ViewColumnRender
                    label={t("WORD_NOTE")}
                    data={orderData?.note}
                  />
                  {orderData?.addressCustomerBussiness ? (
                    <ViewColumnRender
                      label={t("WORD_ADDRESS")}
                      data={orderData?.addressCustomerBussiness}
                    />
                  ) : null}
                </MySpace>
              </>
            </MyCard>
            <OrderViewMapNotIncluded
              label={t(distance.label)}
              color={distance.color}
            />
            {orderData?.distanceStatus === 0 ? null : (
              <MyMapYandex ref={mapRef} width={270}>
                <Placemark
                  geometry={[orderData?.customerLat, orderData?.customerLan]}
                  options={{
                    iconColor: "green",
                    // boundsAutoApply: true,
                  }}
                />
                <Placemark
                  geometry={[orderData?.lat, orderData?.lan]}
                  options={{
                    iconColor: orderData?.typeObject?.color,
                  }}
                />
              </MyMapYandex>
            )}
          </div>
          <MySpace
            direction="vertical"
            fullWidth
            style={{ columnGap: 0, rowGap: 0 }}
          >
            <div>
              {isPayment ? null : (
                <MyTable
                  rowKey="idOrderItem"
                  columns={columns}
                  dataSource={orderData?.orderItems}
                  noFooter
                  className="table-no-footer"
                />
              )}
            </div>
            <MyCardBorderless
              className={`${
                isPayment
                  ? "payment-view-second-section"
                  : "order-view-second-section"
              }`}
            >
              <MySpace fullWidth spaceBetween>
                <div></div>
                <MySpace
                  direction="vertical"
                  fullWidth
                  style={{ columnGap: 0, rowGap: 0 }}
                  align="top"
                >
                  <TitleDataRowAlignEnd
                    title={t("WORD_TOTAL")}
                    withoutWidth
                    data={
                      <div style={{ textWrap: "nowrap" }}>
                        {formatDoubleWithCurrency(orderData?.totalSub)}
                      </div>
                    }
                  />
                  <TitleDataRowAlignEnd
                    title={t("WORD_DISCOUNT")}
                    withoutWidth
                    data={`${UtilNumber.formatDouble(orderData?.discountPer)}%`}
                  />
                  <TitleDataRowAlignEnd
                    title={t("WORD_TOTAL_AMOUNT")}
                    withoutWidth
                    data={
                      <div>
                        {formatDoubleWithCurrency(orderData?.totalAmount)}
                      </div>
                    }
                  />
                </MySpace>
              </MySpace>
            </MyCardBorderless>
          </MySpace>
        </div>
        <div className="print-media-layout">
          <OrderViewPrintPage
            orderData={orderData}
            withoutImage={includeImage}
            ref={contentRef}
            isPayment={isPayment}
            isOrderView
          />
        </div>
      </MySpace>
    </>
  );
};

export default OrderView;
