import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import UtilNumber from "../../../utils/UtilNumber";
import MyModal from "../../../components/myModal/MyModal";
import ActivityRightCardRow from "../../../components/activityRightCardRow/ActivityRightCardRow";
import MyTextSecondary from "../../../components/myText/MyTextSecondary";
import CustomerActions from "../../../actions/CustomerActions";
import UtilDate from "../../../utils/UtilDate";
import UtilNotify from "../../../utils/UtilNotify";
import paymentActions from "../../../actions/PaymentActions";
import MySpace from "../../../components/mySpace/MySpace";

const CustomerViewModal = forwardRef((props, ref) => {
  const refModal = useRef();
  const { t } = useTranslation();

  const [loading, setloading] = useState(true);
  const [data, setData] = useState({});

  const fill = useCallback(async (idCustomer) => {
    if (idCustomer) {
      setloading(true);
      const resultGetOne = await CustomerActions.getOne(idCustomer);
      if (resultGetOne.success) {
        const listPhones = [
          { type: CustomerActions.PHONE_TYPE.MOBILE },
          { type: CustomerActions.PHONE_TYPE.PHONE },
        ];
        for (const phone of listPhones) {
          const p = resultGetOne.data.phones?.find(
            (it) => it.type === phone.type
          );
          if (p) {
            Object.assign(phone, p);
          }
        }
        const listAddresses = [
          { type: CustomerActions.ADDRESS_TYPE.DELIVERY },
          { type: CustomerActions.ADDRESS_TYPE.BUSSINESS },
        ];
        for (const add of listAddresses) {
          const p = resultGetOne.data.address?.find(
            (it) => it.type === add.type
          );
          if (p) Object.assign(add, p);
        }
        setData(
          Object.assign(resultGetOne.data, {
            contractDate: UtilDate.getDate(resultGetOne.data.contractDate),
            phones: listPhones,
            address: listAddresses,
          })
        );
      } else {
        UtilNotify.notifyErrorServer(resultGetOne.errMsg);
      }
    }
    setloading(false);
  }, []);

  useImperativeHandle(ref, () => ({
    viewCustomer: async (idC) => {
      refModal.current.open();
      await fill(idC);
    },
  }));

  return (
    <MyModal
      ref={refModal}
      title={t("WORD_CUSTOMER_DETAILS")}
      loading={loading}
      hideFooter
    >
      <MySpace direction="vertical" style={{ gap: 5 }}>
        <ActivityRightCardRow
          noSpace
          title={t("WORD_PARTNER_ID")}
          bold
          data={data?.idCustomer}
        />
        <ActivityRightCardRow
          noSpace
          title={t("WORD_NAME")}
          bold
          data={data?.name}
        />
        <ActivityRightCardRow
          noSpace
          title={t("WORD_DIRECTOR")}
          bold
          data={data?.director}
        />
        <ActivityRightCardRow
          noSpace
          title={t("WORD_TAX")}
          bold
          data={data?.tax}
        />
        <ActivityRightCardRow
          noSpace
          title={t("WORD_CATEGORY")}
          bold
          data={data?.category}
        />
        <ActivityRightCardRow
          noSpace
          title={t("WORD_DEBIT_BALANCE")}
          bold
          data={UtilNumber.formatDouble(data?.debitLimit)}
        />
        <MyTextSecondary>{t("WORD_PHONE")}</MyTextSecondary>
        <div>
          {data?.phones?.map((ph, index) => {
            return (
              <ActivityRightCardRow
                key={index}
                noSpace
                title={
                  ph.type === CustomerActions.PHONE_TYPE.MOBILE
                    ? t("WORD_MOBILE")
                    : t("WORD_PHONE")
                }
                bold
                data={ph.number}
              />
            );
          })}
        </div>
        <MyTextSecondary>{t("WORD_ADDRESS")}</MyTextSecondary>
        {data?.address?.map((ad, index) => {
          return (
            <ActivityRightCardRow
              key={index}
              noSpace
              title={
                ad.type === CustomerActions.ADDRESS_TYPE.DELIVERY
                  ? t("WORD_DELIVERY_ADDRESS")
                  : t("WORD_ADDRESS_BUSSINESS")
              }
              bold
              data={ad.address}
              style={{ width: 126, display: "block" }}
            />
          );
        })}
        <MyTextSecondary>{t("WORD_DISCOUNTS")}</MyTextSecondary>
        <div>
          {data?.discounts?.map((dis, index) => {
            return (
              <div key={index}>
                <ActivityRightCardRow
                  noSpace
                  title={paymentActions.getType(dis.idPaymentType).label}
                  bold
                  data={dis.value + "%"}
                />
              </div>
            );
          })}
        </div>
      </MySpace>
    </MyModal>
  );
});

export default CustomerViewModal;
