import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelectFormItem from "../mySelectFormItem/MySelectFormItem";
import OrdersActions from "../../actions/OrdersActions";

const SelectPaymentStatus = ({ withAll, ...otherProps }) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    let data = [
      {
        label: t("WORD_NOT_CONFIRMED"),
        value: OrdersActions.PAYMENT_STATUS.NOTCONFIRMED,
      },
      {
        label: t("WORD_CONFIRMED"),
        value: OrdersActions.PAYMENT_STATUS.CONFIRMED,
      },
    ];
    if (withAll) data.unshift({ label: t("WORD_ALL"), value: "" });
    setlist(data);
  }, [t, withAll]);

  return <MySelectFormItem {...otherProps} options={list} />;
};

export default SelectPaymentStatus;
