import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import MyModal from "../../../components/myModal/MyModal";
import { useTranslation } from "react-i18next";
import FormApp from "../../../components/formApp/FormApp";
import SelectManager from "../../../components/selects/SelectManager";
import { useForm } from "antd/es/form/Form";
import MyButtonMain from "../../../components/myButton/MyButtonMain";
import MySpace from "../../../components/mySpace/MySpace";
import SelectDateFromTo from "../../../components/selects/SelectDateFromTo";
import UtilDate from "../../../utils/UtilDate";
import useNavigateApi from "../../../appRoute/useNavigateApi";

const formName = "formCustomerStatementModal";

const CustomerStatementModal = forwardRef((props, ref) => {
  const refModal = useRef();
  const { t } = useTranslation();
  const [form] = useForm();
  const navigateApi = useNavigateApi();

  const [customer, setCustomer] = useState({});
  const [filter, setFilter] = useState({});

  useImperativeHandle(ref, () => ({
    viewCustomer: (cu) => {
      refModal.current.open();
      setCustomer(cu);
    },
  }));

  const onFinish = (values) => {
    setFilter({ idManager: values.idManager });
    navigateApi(`/customer/statement/${customer.idCustomer}`, { state: { filter } });
  };

  const onChange = (e) => {
    if (e === "today") {
      setFilter((prevFilter) => ({
        ...prevFilter,
        dateFrom: UtilDate.getDateToday(),
        dateTo: UtilDate.getDateToday(),
      }));
    }
    if (e === "yesterday") {
      setFilter((prevFilter) => ({
        ...prevFilter,
        dateFrom: UtilDate.getDateYesterday(),
        dateTo: UtilDate.getDateYesterday(),
      }));
    }
    if (e === "3 days ago") {
      setFilter((prevFilter) => ({
        ...prevFilter,
        dateFrom: UtilDate.getDate3DaysAgo(),
        dateTo: UtilDate.getDate3DaysAgo(),
      }));
    }
    if (e === "week") {
      setFilter((prevFilter) => ({
        ...prevFilter,
        dateFrom: UtilDate.getLastWeekStart(),
        dateTo: UtilDate.getLastWeekEnd(),
      }));
    }
    if (e === "this month") {
      setFilter((prevFilter) => ({
        ...prevFilter,
        dateFrom: UtilDate.getThisMonthStart(),
        dateTo: UtilDate.getThisMonthEnd(),
      }));
    }
    if (e === "last month") {
      setFilter((prevFilter) => ({
        ...prevFilter,
        dateFrom: UtilDate.getLastMonthStart(),
        dateTo: UtilDate.getLastMonthEnd(),
      }));
    }
  };

  return (
    <MyModal ref={refModal} title={t("WORD_CUSTOMER_STATEMENT")} hideFooter>
      <FormApp name={formName} onFinish={onFinish} form={form}>
        <MySpace direction="vertical" fullWidth size="large">
          <SelectManager name="idManager" />
          <SelectDateFromTo name="date" onChange={onChange} />
          <MyButtonMain
            type="primary"
            htmlType="submit"
            form={formName}
            label={t("WORD_SEE_STATEMENT")}
          />
        </MySpace>
      </FormApp>
    </MyModal>
  );
});

export default CustomerStatementModal;
