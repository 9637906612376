import React from "react";
import { useTranslation } from "react-i18next";
import MyCard from "../myCard/MyCard";
import MySpace from "../mySpace/MySpace";
import MyTextSecondary from "../myText/MyTextSecondary";
import MyStatistic from "../myStatistic/MyStatistic";
import LoadingIconAppCenter from "../loadingIconApp/LoadingIconAppCenter";
import { useFormatCurrency } from "../../utils/useFormatWithCurrency";
import UtilNumber from "../../utils/UtilNumber";
import "./StatisticCard.scss";

const StatisticCard = ({
  title,
  amount,
  icon,
  // count, secondTitle,
  loading,
  danger,
  // suffix,
  withoutCurrency,
}) => {
  const { t } = useTranslation();
  const { formatDoubleWithCurrency } = useFormatCurrency();

  return (
    <MyCard className="stat-card-wrapper">
      {loading ? (
        <LoadingIconAppCenter />
      ) : (
        <MySpace size="middle">
          <div>{icon}</div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <MySpace align="center" style={{ columnGap: 5, rowGap: 0 }}>
              <MyStatistic
                style={{ fontWeight: "bold", color: danger ? "#fa4733" : "" }}
                value={
                  withoutCurrency
                    ? UtilNumber.formatDouble(amount)
                    : formatDoubleWithCurrency(amount)
                }
                className="card-stat"
              />
              {/* <MySpace style={{ paddingTop: 5, columnGap: 3, rowGap: 0 }}>
                <div>{secondTitle}</div>
                <MyTextSecondary>{count}</MyTextSecondary>
              </MySpace> */}
            </MySpace>
            <MyTextSecondary bold size={13}>
              {t(title)}
            </MyTextSecondary>
          </div>
        </MySpace>
      )}
    </MyCard>
  );
};

export default StatisticCard;
