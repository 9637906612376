import React from "react";
import InputText from "./InputText";
import { useTranslation } from "react-i18next";
import { SearchOutlined } from "@ant-design/icons";

const SearchInput = ({ onClear, onPressEnter }) => {
  const { t } = useTranslation();

  return (
    <InputText
      placeholder={t("WORD_SEARCH")}
      onClear={onClear}
      onPressEnter={onPressEnter}
      prefix={<SearchOutlined style={{ color: "#A7AAB5" }} />}
    />
  );
};

export default SearchInput;
