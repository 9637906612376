import { fetchFromUrlPOSTAsync } from "../utils/UrlHelper";

const URL_FILL_OPTIONS = "productUnitOptions/list";
const URL_SAVE_PRODUCT_UNIT_OPTIONS = "productUnitOptions/saveSettings";
const URL_LIST = "productUnit/list";

const fs = {
  productUnitOptions: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_FILL_OPTIONS, urlParams);
    return result;
  },
  save: async (values) => {
    const urlParams = [
      {
        key: "saveData",
        value: values,
      },
    ];
    const result = await fetchFromUrlPOSTAsync(
      URL_SAVE_PRODUCT_UNIT_OPTIONS,
      urlParams
    );
    return result;
  },
  fillList: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_LIST, urlParams);
    return result;
  },
};

const loc = {
  fillListOptions: async (idUnit) => {
    const result = await ProductUnitActions.fillList(idUnit);
    if (result.success) {
      result.data = result.data.map((it) => {
        return {
          label: it.description,
          value: it.idUnit,
          ...it,
        };
      });
    }
    return result;
  },
};

const ProductUnitActions = Object.assign(fs, loc);

export default ProductUnitActions;
