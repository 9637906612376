import React, { memo } from "react";
import MyIcon from "./MyIcon";

export const SVGIcon = ({ size, fill, withCircle }) => {
  return (
    <svg
      width={size || 35}
      height={size || 35}
      viewBox="0 0 49 49"
      fill={fill || "#fff"}
      xmlns="http://www.w3.org/2000/svg"
    >
      {withCircle ? (
        <circle opacity="0.2" cx="39" cy="39" r="39" fill="#92cf5c80"></circle>
      ) : null}
      <path d="M33.0653 6.65063C39.9577 6.65063 44.0306 10.6079 44.0306 17.3264V17.4009H35.5613C31.6199 17.4078 28.4271 20.5209 28.4199 24.3638C28.4146 28.2153 31.6111 31.3422 35.5613 31.3474H44.0377V31.9593C44.0377 38.6778 39.9648 42.6506 33.0724 42.6506H15.0048C8.11061 42.6506 4.03772 38.6778 4.03772 31.9593V17.3264C4.03772 10.6079 8.11061 6.65063 15.0048 6.65063H33.0653ZM24.8039 14.4004H13.5168C12.6777 14.4039 11.9968 15.0678 11.9933 15.8877C11.9897 16.711 12.6724 17.3818 13.5168 17.3853H24.8182C25.6626 17.3818 26.3453 16.711 26.3417 15.8859C26.3382 15.0626 25.6484 14.397 24.8039 14.4004Z"></path>
      <path
        opacity="0.4"
        d="M32.1125 25.244C32.5308 27.1464 34.1987 28.4848 36.1029 28.45H42.6028C43.395 28.45 44.0377 27.7939 44.0377 26.9828V21.9197C44.036 21.1103 43.395 20.4524 42.6028 20.4507H35.9499C33.7838 20.4576 32.0343 22.2556 32.0377 24.4712C32.0377 24.7306 32.0632 24.9899 32.1125 25.244Z"
      ></path>
      <path d="M36.0377 26.4507C37.1423 26.4507 38.0377 25.5553 38.0377 24.4507C38.0377 23.3461 37.1423 22.4507 36.0377 22.4507C34.9332 22.4507 34.0377 23.3461 34.0377 24.4507C34.0377 25.5553 34.9332 26.4507 36.0377 26.4507Z"></path>
    </svg>
  );
};

const WalletSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(WalletSVG);
