import React, { useState } from "react";
import MyCard from "../../../components/myCard/MyCard";
import MyText from "../../../components/myText/MyText";
import FormApp from "../../../components/formApp/FormApp";
import MySpace from "../../../components/mySpace/MySpace";
import InputTextFormItem from "../../../components/myInputForm/InputTextFormItem";
import { useTranslation } from "react-i18next";
import { useForm } from "antd/es/form/Form";
import UserActions from "../../../actions/UserActions";
import UtilNotify from "../../../utils/UtilNotify";
import MyCaptcha from "../../../components/myGoogleCaptcha/MyCaptcha";
import useNavigateApi from "../../../appRoute/useNavigateApi";
import MyPageHeaderOutside from "../../../components/myPageHeader/MyPageHeaderOutside";
import MyButtonPurple from "../../../components/myButton/MyButtonPurple";
import "../login/Login.scss";

const formName = "forgot_password_email_form";

const ForgotPasswordEmail = ({ onNext }) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const navigateApi = useNavigateApi();

  const [CaptchaCheck, setCaptchaCheck] = useState(false);
  const [saving, setsaving] = useState(false);

  const onFinish = async (values) => {
    setsaving(true);
    const result = await UserActions.forgotPassword_sendEmail(values);
    if (result.success) {
      onNext && onNext();
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
      setsaving(false);
    }
  };

  return (
    <div className="outside-card-wrapper">
      <MyCard withoutShadow>
        <MySpace direction="vertical" style={{ gap: 0, marginBottom: 25 }}>
          <MyPageHeaderOutside
            title={t("WORD_RESET_PASSWORD")}
            onBack={() => navigateApi(`/device/list`)}
          />
        </MySpace>
        <FormApp
          form={form}
          name={formName}
          onFinish={onFinish}
          labelCol={{ style: { fontWeight: "bold", padding: 0 } }}
          size="large"
        >
          <MySpace direction="vertical" fullWidth size="middle">
            <InputTextFormItem label={t("WORD_EMAIL")} name="email" required />
            <MyText>{t("WORD_YOU_WILL_GET_LINK_RECOVERY")}</MyText>
          </MySpace>
        </FormApp>
        <div className="registration-btn-wrapper">
          <MyButtonPurple
            fullWidth
            label={t("WORD_CONFIRM")}
            form={formName}
            loading={saving}
            disabled={!CaptchaCheck}
          />
        </div>
      </MyCard>
      <MyCaptcha
        onChange={(token) => {
          setCaptchaCheck(Boolean(token));
        }}
      />
    </div>
  );
};

export default ForgotPasswordEmail;
