import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useNavigateApi from "../../appRoute/useNavigateApi";
import { useSelector } from "react-redux";

const SettingsMenu = () => {
  const navigateApi = useNavigateApi();
  const location = useLocation();
  const { t } = useTranslation();

  const [selectedMenuKey, setselectedMenuKey] = useState(location.pathname);

  const lang = useSelector((state) => {
    return state.lang;
  });

  useEffect(() => {
    // if (location.pathname.startsWith("/settings/exportTemplates")) {
    //   setselectedMenuKey("/settings/exportTemplates");
    // } else {
    setselectedMenuKey(location.pathname);
    // }
  }, [location.pathname]);

  const items = [
    {
      label: t("WORD_COMPANY_SETTINGS"),
      key: `/${lang}/settings/company`,
      onClick: () => {
        navigateApi(`/settings/company`);
      },
    },
    {
      label: t("WORD_ACCOUNT_SETTINGS"),
      key: `/${lang}/settings/account`,
      onClick: () => {
        navigateApi(`/settings/account`);
      },
    },
    {
      label: t("WORD_PASSSWORD_CHANGE"),
      key: `/${lang}/settings/password`,
      onClick: () => {
        navigateApi(`/settings/password`);
      },
    },
    {
      label: t("WORD_GENERAL_SETTINGS"),
      key: `/${lang}/settings/general`,
      onClick: () => {
        navigateApi(`/settings/general`);
      },
    },
    {
      label: t("WORD_PAYMENT_TYPES"),
      key: `/${lang}/settings/payment`,
      onClick: () => {
        navigateApi(`/settings/payment`);
      },
    },
    {
      label: t("WORD_PACKAGE_DETAILS"),
      key: `/${lang}/settings/package`,
      onClick: () => {
        navigateApi(`/settings/package`);
      },
    },
    // {
    //   label: t("WORD_PRODUCT_UNITS"),
    //   key: `/${lang}/settings/product`,
    //   onClick: () => {
    //     navigateApi(`/settings/product`);
    //   },
    // },
    {
      label: t("WORD_IMPORT_XML"),
      key: `/${lang}/settings/importxml`,
      onClick: () => {
        navigateApi(`/settings/importxml`);
      },
    },
    {
      label: t("WORD_EXPORT_TEMPLATES"),
      key: `/${lang}/settings/exportTemplates/list`,
      onClick: () => {
        navigateApi(`/settings/exportTemplates/list`);
      },
    },
  ];

  return (
    <Menu
      // theme={isDark ? "dark" : "light"}
      className="second-side-menu"
      // style={{ padding: sideMenuColapsed ? "8px 24px" : "8px 15px" }}
      mode="vertical"
      defaultSelectedKeys={["1"]}
      items={items}
      selectedKeys={[selectedMenuKey]}
      onSelect={(menu) => {
        setselectedMenuKey(menu?.key);
      }}
    />
  );
};

export default SettingsMenu;
