import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import AnalyticsActions from "../../../actions/AnalyticsActions";
import UtilNotify from "../../../utils/UtilNotify";
import MyCard from "../../../components/myCard/MyCard";
import MySpace from "../../../components/mySpace/MySpace";
import MyTextAnalyticsStatsCard from "../../../components/myText/MyTextAnalyticsStatsCard";
import MyTable from "../../../components/myTable/MyTable";
import UtilNumber from "../../../utils/UtilNumber";

const AnalyticsCustomersRouteListCard = ({ filter }) => {
  const { t } = useTranslation();

  const [loading, setloading] = useState(true);
  const [data, setdata] = useState([]);

  useEffect(() => {
    const fill = async () => {
      setloading(true);
      const result = await AnalyticsActions.routCustomerCountList({
        filter: filter,
      });
      if (result.success) {
        setdata(result.data);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setloading(false);
    };
    fill();
  }, [filter]);

  const columns = useMemo(() => {
    let col = [
      {
        title: t("WORD_ROUTES"),
        dataIndex: "name",
        key: "name",
      },
      {
        title: t("WORD_CUSTOMERS"),
        dataIndex: "count",
        key: "count",
        align: "center",
        width: 100,
        render: (value) => {
          return <strong>{UtilNumber.formatDouble(value)}</strong>;
        },
      },
    ];
    return col;
  }, [t]);

  return (
    <MyCard style={{ width: "50%", minHeight: 350 }}>
      <MySpace direction="vertical" fullWidth>
        <MyTextAnalyticsStatsCard bold>
          {t("WORD_ROUTES")}
        </MyTextAnalyticsStatsCard>
        <MyTable
          loading={loading}
          columns={columns}
          dataSource={data}
          rowKey={(it) => it.idRoute}
          noFooter
        />
      </MySpace>
    </MyCard>
  );
};

export default AnalyticsCustomersRouteListCard;
