import React, { memo } from "react";
import MyIcon from "./MyIcon";

export const SVGIcon = ({ style, size }) => {
  return (
    <svg
      width={size || 48}
      height={size || 48}
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        opacity="0.4"
        d="M32.2584 4.32935H39.0303C41.8349 4.32935 44.1074 6.62105 44.1074 9.44927V16.2784C44.1074 19.1066 41.8349 21.3983 39.0303 21.3983H32.2584C29.4539 21.3983 27.1814 19.1066 27.1814 16.2784V9.44927C27.1814 6.62105 29.4539 4.32935 32.2584 4.32935Z"
        fill="white"
      ></path>
      <path
        d="M15.9564 27.2604C18.7609 27.2604 21.0334 29.5521 21.0334 32.3803V39.2094C21.0334 42.0357 18.7609 44.3293 15.9564 44.3293H9.18444C6.3799 44.3293 4.10739 42.0357 4.10739 39.2094V32.3803C4.10739 29.5521 6.3799 27.2604 9.18444 27.2604H15.9564ZM39.0303 27.2604C41.8349 27.2604 44.1074 29.5521 44.1074 32.3803V39.2094C44.1074 42.0357 41.8349 44.3293 39.0303 44.3293H32.2584C29.4539 44.3293 27.1814 42.0357 27.1814 39.2094V32.3803C27.1814 29.5521 29.4539 27.2604 32.2584 27.2604H39.0303ZM15.9564 4.32935C18.7609 4.32935 21.0334 6.62105 21.0334 9.44927V16.2784C21.0334 19.1066 18.7609 21.3983 15.9564 21.3983H9.18444C6.3799 21.3983 4.10739 19.1066 4.10739 16.2784V9.44927C4.10739 6.62105 6.3799 4.32935 9.18444 4.32935H15.9564Z"
        fill="white"
      ></path>
    </svg>
  );
};

const BranchesSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(BranchesSVG);
