import React from "react";
import { Pagination } from "antd";
import useWindowSize from "../../utils/useWindowSize";
import { useTranslation } from "react-i18next";

const MyPagination = (props) => {
  const { isMobile } = useWindowSize();
  const { t } = useTranslation();

  const {
    disabled = false,
    onShowSizeChange,
    onChange,
    total = 0,
    pageSize = 10,
    hideOnSinglePage = false,
    pageSizeOptions = [5, 10, 25, 50, 100, 500],
    responsive = true,
    showQuickJumper = true,
    // showSizeChanger = true,
    showTitle = true,
    simple = isMobile ? true : false, // true for mobile
    showLessItems = false, // true for mobile
    current = 1,
    size = "large",
  } = props;

  return (
    <Pagination
      defaultCurrent={1}
      total={total}
      disabled={disabled}
      hideOnSinglePage={hideOnSinglePage}
      pageSizeOptions={pageSizeOptions}
      responsive={responsive}
      showQuickJumper={showQuickJumper}
      showSizeChanger={false}
      showTotal={(total, range) => {
        return `${t("WORD_DISPLAYING")} ${range[1]} ${t(
          "WORD_OF"
        )} ${total} ${t("WORD_RESULTS")}`;
      }}
      showTitle={showTitle}
      size={size}
      simple={simple}
      showLessItems={showLessItems}
      current={current + 1}
      pageSize={pageSize}
      onShowSizeChange={(p, pp) => {
        onShowSizeChange && onShowSizeChange(0, pp);
      }}
      onChange={(p, pp) => {
        if (pp !== pageSize) {
          onChange && onChange(0, pp);
        } else {
          onChange && onChange(p - 1, pp);
        }
      }}
      align="end"
    />
  );
};

export default MyPagination;
