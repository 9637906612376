import React, { useCallback, useRef } from "react";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut, getElementAtEvent } from "react-chartjs-2";
import UtilNumber from "../../utils/UtilNumber";
import colors from "../../Color.scss";

Chart.register(ArcElement, Tooltip, Legend);

const MyDonutChart = ({ data, total, size, onSelect, labels }) => {
  const chartRef = useRef();

  const getRandomColor = () => {
    const col = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    return col;
  };

  const isEmpty = !data?.length;

  const dataSet = useCallback(() => {
    if (isEmpty) {
      return {
        labels: [],
        datasets: [
          {
            data: [100],
            backgroundColor: "#f4f6fa",
            borderColor: "transparent",
            borderWidth: 5,
            borderRadius: 0,
          },
        ],
      };
    }

    return {
      labels: labels || [],
      datasets: [
        {
          data: data?.length ? data?.map((oo) => oo.value) : [100],
          backgroundColor: data?.length
            ? data?.map((oo) => oo.color || getRandomColor())
            : `${colors.grayLightColor}`,
          borderWidth: 5,
          borderRadius: 15,
        },
      ],
    };
  }, [data, isEmpty, labels]);

  const options = {
    plugins: {
      tooltip: {
        padding: 10,
        callbacks: {
          label: (context) => {
            const value = context.raw || 0;
            return `  ${UtilNumber.formatDouble(value)}`;
          },
        },
      },
      legend: {
        display: false,
      },
    },
  };

  const plugins = [
    {
      id: "insideLabelsPlugin",
      // afterDatasetsDraw(chart) {
      //   const {
      //     ctx,
      //     // data: { datasets },
      //     // chartArea: { width, height },
      //   } = chart;
      //   const chartData = chart.getDatasetMeta(0).data;
      //   chartData.forEach((datapoint, i) => {
      //     const { x, y } = datapoint.getCenterPoint();
      //     const item = data[i];
      //     const value = item?.label;
      //     if (value) {
      //       ctx.textBaseline = "middle";
      //       const textX = x - ctx.measureText(value).width / 2;
      //       ctx.fillStyle = "#f4f6f7";
      //       ctx.fillText(value, textX, y);
      //     }
      //   });
      // },
      beforeDraw: function (chart) {
        let width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        ctx.textBaseline = "top";
        let text = UtilNumber.formatDouble(total),
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  return (
    <div className="my-donut-container" style={{ width: size || "unset" }}>
      <Doughnut
        ref={chartRef}
        data={dataSet()}
        plugins={plugins}
        options={options}
        onClick={(e) => {
          const it = getElementAtEvent(chartRef.current, e);
          if (it && it[0]) {
            const index = it[0].index;
            onSelect && onSelect(data[index]);
          }
        }}
      />
    </div>
  );
};

export default MyDonutChart;
