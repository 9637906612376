import React, { useEffect, useState } from "react";
import MySpace from "../../../components/mySpace/MySpace";
import { useTranslation } from "react-i18next";
import { useForm } from "antd/es/form/Form";
import { useParams } from "react-router-dom";
import MyDivider from "../../../components/myDivider/MyDivider";
import InputTextFormItem from "../../../components/myInputForm/InputTextFormItem";
import MyButtonDelete from "../../../components/myButton/MyButtonDelete";
import InputTextAreaFormItem from "../../../components/myInputForm/InputTextAreaFormItem";
import { useSelector } from "react-redux";
import UtilNotify from "../../../utils/UtilNotify";
import ProductActions from "../../../actions/ProductActions";
import SelectUnit from "../../../components/selects/SelectUnit";
import IconAvatarForm from "../../../components/uploads/uploadAvatar/IconAvatarForm";
import { Form } from "antd";
import IconAvatarFormMultipleOlderStyle from "../../../components/uploads/uploadAvatar/IconAvatarFormMultipleOlderStyle";
import useNavigateApi from "../../../appRoute/useNavigateApi";
import InputNumberFormItem from "../../../components/myInputForm/InputNumberFormItem";
import SelectProductFolder from "../../../components/selects/SelectProductFolder";
import EditPageForm from "../../../components/editPageForm/EditPageForm";
import "./ProductEdit.scss";

const formName = "formProductEdit";

const ProductEdit = ({ isNew }) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const navigateApi = useNavigateApi();

  const { _idProduct, _idParent } = useParams();

  const [loading, setLoading] = useState(!isNew);
  const [saving, setSaving] = useState(false);
  const [icon, setIcon] = useState("");
  const [images, setImages] = useState("");

  const currency = useSelector((state) => state.currentUser?.currency);
  const vatRate = useSelector((state) => state.currentUser?.vatRate);

  const excisRate = Form.useWatch("excisRate", form);
  const costPrice = Form.useWatch("costPrice", form);

  const goBack = () => {
    navigateApi(-1);
  };

  const onFinish = async (values) => {
    setSaving(true);
    values.icon = icon;
    if (!Array.isArray(images)) {
      values.images = [values.images];
    }
    values.images = JSON.stringify(images || []);
    delete values.vatPrice;
    let result;
    if (!isNew) {
      result = await ProductActions.updateProduct(values, _idProduct);
    } else {
      result = await ProductActions.createProduct(values);
    }
    if (result.success) {
      UtilNotify.notifySuccess(
        isNew ? t("WORD_PRODUCT_CREATED") : t("WORD_PRODUCT_UPDATED")
      );
      goBack();
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
      setSaving(false);
    }
  };

  useEffect(() => {
    const fill = async () => {
      setLoading(true);
      const resultGetOne = await ProductActions.getOne(_idProduct);
      if (resultGetOne.success) {
        const vatPrice =
          parseFloat(
            ((parseFloat(resultGetOne.data.excisRate) +
              parseFloat(resultGetOne.data.costPrice || 0)) *
              parseFloat(vatRate)) /
              100
          ) || 0;
        form?.setFieldsValue(
          Object.assign(resultGetOne.data, {
            vatPrice,
            idParent: resultGetOne.data.idParent || null,
          })
        );
        setIcon(resultGetOne.data?.icon);
        if (resultGetOne.data?.images) {
          try {
            setImages(JSON.parse(resultGetOne.data?.images || "[]"));
          } catch (error) {
            setImages([resultGetOne.data?.images]);
          }
        }
      } else {
        UtilNotify.notifyErrorServer(resultGetOne.errMsg);
      }
      setLoading(false);
    };
    if (!isNew) {
      fill();
    }
  }, [_idProduct, isNew, vatRate, form]);

  const getTitle = () => {
    return `${t("WORD_PRODUCT")} ${isNew ? t("WORD_CREATE") : t("WORD_EDIT")}`;
  };

  const handleDeleteProduct = async (idProduct) => {
    if (idProduct) {
      const result = await ProductActions.delete(idProduct);
      if (result.success) {
        goBack();
        UtilNotify.notifySuccess(t("WORD_PRODUCT_DELETED_SUCCESSFULLY"));
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    }
  };

  return (
    <EditPageForm
      title={getTitle()}
      form={form}
      formName={formName}
      onFinish={onFinish}
      saving={saving}
      onBack={goBack}
      loading={loading}
      isNew={isNew}
      withCancel
      initialValues={{ idParent: _idParent }}
      extraButtons={
        // isNew || isUser1C ? null : (
        isNew ? null : (
          <MyButtonDelete
            form={formName}
            label={t("WORD_DELETE")}
            onConfirm={() => {
              handleDeleteProduct(_idProduct);
            }}
          />
        )
      }
    >
      <InputTextFormItem label={t("WORD_NAME")} name="name" required />
      <MySpace className="space-row-input" fullWidth spaceBetween align="top">
        <InputNumberFormItem
          label={t("WORD_COST_PRICE")}
          name="costPrice"
          required
          suffix={<div>{currency}</div>}
          onChange={(val) => {
            const price = parseFloat(excisRate || 0) + parseFloat(val || 0);
            const vatPrice =
              parseFloat((price * parseFloat(vatRate)) / 100) || 0;
            form?.setFieldsValue({
              vatPrice: vatPrice,
              price: vatPrice + price || 0,
            });
          }}
        />
        <InputNumberFormItem
          label={t("WORD_EXCISE_RATE")}
          name="excisRate"
          suffix={<div>{currency}</div>}
          onChange={(val) => {
            const vatPrice =
              parseFloat(
                ((parseFloat(val || 0) + parseFloat(costPrice || 0)) *
                  parseFloat(vatRate)) /
                  100
              ) || 0;
            form?.setFieldsValue({
              vatPrice: vatPrice,
              price:
                vatPrice +
                  (parseFloat(val || 0) + parseFloat(costPrice || 0)) || 0,
            });
          }}
        />
      </MySpace>
      <MySpace className="space-row-input" fullWidth spaceBetween>
        <InputNumberFormItem
          label={t("WORD_VAT_PRICE")}
          name="vatPrice"
          suffix={<div>{currency}</div>}
          readOnly
        />
        <InputTextFormItem
          label={t("WORD_PRICE")}
          name="price"
          suffix={<div>{currency}</div>}
          readOnly
        />
      </MySpace>
      <SelectUnit
        name="idUnit"
        label={t("WORD_PRODUCT_UNIT")}
        placeholder={t("WORD_PRODUCT_SELECT_UNIT")}
        required
      />
      <InputTextFormItem label={t("WORD_BARCODE")} name="barcode" />
      <InputTextAreaFormItem label={t("WORD_DESCRIPTION")} name="description" />
      <SelectProductFolder
        name="idParent"
        label={t("WORD_SELECT_PARENT_FOLDER")}
      />
      <IconAvatarForm
        name="icon"
        label={t("WORD_IMAGE")}
        icon={icon}
        onChange={(path) => {
          setIcon(path);
        }}
        onClear={() => {
          setIcon("");
        }}
      />
      <MyDivider dashed />
      <IconAvatarFormMultipleOlderStyle
        name="images"
        image={images}
        onChange={(paths) => {
          setImages(paths);
        }}
        onClear={(clearedPath) => {
          setImages((prevImages) =>
            prevImages.filter((path) => path !== clearedPath)
          );
        }}
        maxCount={3}
      />
    </EditPageForm>
  );
};

export default ProductEdit;
