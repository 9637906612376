import React, { useEffect, useState } from "react";
import InputTextFormItem from "../../../components/myInputForm/InputTextFormItem";
import { useTranslation } from "react-i18next";
import { useForm } from "antd/es/form/Form";
import { useParams } from "react-router-dom";
import UtilNotify from "../../../utils/UtilNotify";
import ProductActions from "../../../actions/ProductActions";
import IconAvatarForm from "../../../components/uploads/uploadAvatar/IconAvatarForm";
import useNavigateApi from "../../../appRoute/useNavigateApi";
import MyButtonDelete from "../../../components/myButton/MyButtonDelete";
import EditPageForm from "../../../components/editPageForm/EditPageForm";

const formName = "formFolderEdit";

const FolderEdit = ({ isNew }) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const navigateApi = useNavigateApi();

  const { _idProduct, _idParent } = useParams();

  const [loading, setloading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [icon, setIcon] = useState("");

  const goBack = () => {
    navigateApi(-1);
  };

  const onFinish = async (values) => {
    setSaving(true);
    values.icon = icon;
    if (_idParent) {
      values.idParent = _idParent;
    }
    let result;
    if (!isNew) {
      result = await ProductActions.updateFolder(values, _idProduct);
      if (result.success) {
        UtilNotify.notifySuccess(t("WORD_FOLDER_UPDATED"));
        goBack();
        return;
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    } else {
      result = await ProductActions.createFolder(values);
      if (result.success) {
        UtilNotify.notifySuccess(t("WORD_FOLDER_CREATED"));
        goBack();
        return;
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    }
    setSaving(false);
  };

  useEffect(() => {
    const fill = async () => {
      setloading(true);
      const resultGetOne = await ProductActions.getOne(_idProduct);
      if (resultGetOne.success) {
        form?.setFieldsValue(resultGetOne.data);
        setIcon(resultGetOne.data?.icon);
      } else {
        UtilNotify.notifyErrorServer(resultGetOne.errMsg);
      }
      setloading(false);
    };
    if (!isNew) {
      fill();
    }
  }, [_idProduct, isNew, form]);

  const getTitle = () => {
    return `${t("WORD_FOLDER")} ${isNew ? t("WORD_CREATE") : t("WORD_EDIT")}`;
  };

  return (
    <EditPageForm
      title={getTitle()}
      form={form}
      formName={formName}
      onFinish={onFinish}
      saving={saving}
      onBack={goBack}
      loading={loading}
      withCancel
      extraButtons={
        // isNew || isUser1C ? null : (
        isNew ? null : (
          <MyButtonDelete form={formName} label={t("WORD_DELETE")} />
        )
      }
    >
      <InputTextFormItem label={t("WORD_NAME")} name="name" required />
      <IconAvatarForm
        name="icon"
        label={t("WORD_IMAGE")}
        icon={icon}
        onChange={(path) => {
          setIcon(path);
        }}
        onClear={() => {
          setIcon("");
        }}
      />
    </EditPageForm>
  );
};

export default FolderEdit;
