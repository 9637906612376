import React, { useCallback, useEffect, useRef, useState } from "react";
import GPSActions from "../../actions/GPSActions";
import UtilNotify from "../../utils/UtilNotify";
import { useTranslation } from "react-i18next";
import MyPageHeader from "../../components/myPageHeader/MyPageHeader";
import UtilObject from "../../utils/UtilObject";
import MyMapYandex from "../../components/myMapYandex/MyMapYandex";
import MySpace from "../../components/mySpace/MySpace";
import StatisticCard from "../../components/statisticCard/StatisticCard";
import ShoppingCartPurpleCircleSVG from "../../icons/ShoppingCartPurpleCircleSVG";
import DistanceBlueCircleSVG from "../../icons/DistanceBlueCircleSVG";
import TimeRedSVG from "../../icons/TimeRedSVG";
import useWindowSize from "../../utils/useWindowSize";
import FormApp from "../../components/formApp/FormApp";
import SelectManager from "../../components/selects/SelectManager";
import InputFormDatePicker from "../../components/inputFormDatePicker/InputFormDatePicker";
import MyButtonText from "../../components/myButton/MyButtonText";
import { useForm } from "antd/es/form/Form";
import "./Gps.scss";
import { Flex } from "antd";

const formName = "gpsFilterForm";

const GpsYandex = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const mapRef = useRef(null);
  const mapRefMain = useRef(null);
  const { height } = useWindowSize();

  const [MyYmaps, setMyYmaps] = useState();
  const [locations, setLocations] = useState([]);
  const [filter, setfilter] = useState({
    // idManager: 3380,
    // createDate: UtilDate.getDate("2023-07-02"),
  });

  const drawRoute = useCallback(
    (list) => {
      if (!MyYmaps) return;
      if (!mapRef) return;
      if (!mapRef.current) return;
      mapRef.current.geoObjects.removeAll();

      if (list.length < 2) return;

      const multiRoute = new MyYmaps.multiRouter.MultiRoute(
        {
          referencePoints: list,
          params: {},
        },
        {
          boundsAutoApply: false,
        }
      );
      mapRef.current?.geoObjects.add(multiRoute);
    },
    [mapRef, MyYmaps]
  );

  useEffect(() => {
    const divideArrayIntoChunks = (arr, chunkSize) => {
      const result = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        result.push(arr.slice(i, i + chunkSize + 1));
      }
      return result;
    };
    const fill = async () => {
      if (!UtilObject.isEmpty(filter)) {
        const fetchParams = {
          filter: filter,
        };
        const result = await GPSActions.fillList(fetchParams);
        if (result.success) {
          const coords = result.data.map((item) => {
            const it = JSON.parse(item.location);
            return [it.Lat, it.Lan];
          });
          setLocations(coords);
          const ll = divideArrayIntoChunks(coords, 3);
          for (const ar of ll) {
            drawRoute(ar);
          }
        } else {
          UtilNotify.notifyErrorServer(result.errMsg);
        }
      } else {
        setLocations([]);
      }
    };
    fill();
  }, [filter, drawRoute]);

  useEffect(() => {
    if (!mapRef.current) return;
    if (!locations.length) return;
    mapRef.current.setBounds(locations);
    mapRef.current.setZoom(15);
  }, [mapRef, locations]);

  const setFilterClear = () => {
    setfilter({});
  };

  return (
    <Flex vertical gap={10}>
      <MyPageHeader
        title={t("WORD_GPS_LOCATION")}
        inPage
        // extraRight={
        //   <GpsFilterDrawer
        //     onChange={setfilter}
        //     onReset={setFilterClear}
        //     filter={filter}
        //   />
        // }
      />
      <FormApp
        // onFinish={onFinish}
        name={formName}
        form={form}
        style={{ marginLeft: 8 }}
      >
        <MySpace>
          <SelectManager
            name="idManager"
            placeholder={t("WORD_SELECT_MANAGER")}
          />
          <InputFormDatePicker name="createDate" />
          <MyButtonText
            label={t("WORD_RESET_FILTERS")}
            onClick={setFilterClear}
          />
        </MySpace>
      </FormApp>
      <MySpace className="stat-card-row">
        <StatisticCard
          title={t("WORD_ORDERS")}
          amount={0}
          icon={<ShoppingCartPurpleCircleSVG />}
          secondTitle={t("WORD_IN")}
        />
        <StatisticCard
          title={t("WORD_DISTANCE")}
          amount={0}
          icon={<DistanceBlueCircleSVG />}
          secondTitle={t("WORD_IN")}
          withoutCurrency
        />
        <StatisticCard
          title={t("WORD_TIME")}
          amount={0}
          icon={<TimeRedSVG />}
          secondTitle={t("WORD_IN")}
          withoutCurrency
        />
      </MySpace>
      <div className="gps-map-wrapper">
        <MyMapYandex
          ref={mapRefMain}
          instanceRef={mapRef}
          width="85vw"
          height={height - 270}
          onLoadMap={setMyYmaps}
          modules={["multiRouter.MultiRoute"]}
        />
      </div>
    </Flex>
  );
};

export default GpsYandex;
