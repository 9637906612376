import React, { memo } from "react";
import MyIcon from "./MyIcon";

export const SVGIcon = ({ style, size }) => {
  return (
    <svg
      // class=""
      width={size || 21}
      height={size || 20}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ style }}
    >
      <path
        opacity="0.4"
        d="M18.3571 5H16.9286V2.14286C16.9286 1.57445 16.7028 1.02951 16.301 0.627551C15.8991 0.225763 15.3541 0 14.7857 0H6.21429C5.64588 0 5.10094 0.225763 4.69898 0.627551C4.29719 1.0295 4.07143 1.57445 4.07143 2.14286V5H2.64286C2.07445 5 1.52951 5.22576 1.12755 5.62755C0.725763 6.0295 0.5 6.57445 0.5 7.14286V12.8571C0.5 13.4256 0.725763 13.9705 1.12755 14.3724C1.5295 14.7742 2.07445 15 2.64286 15H4.07143V19.2857C4.07143 19.4751 4.14669 19.6569 4.28061 19.7908C4.41454 19.9247 4.5963 20 4.78571 20H16.2143C16.4037 20 16.5855 19.9247 16.7194 19.7908C16.8533 19.6569 16.9286 19.4751 16.9286 19.2857V15H18.3571C18.9256 15 19.4705 14.7742 19.8724 14.3724C20.2742 13.9705 20.5 13.4256 20.5 12.8571V7.14286C20.5 6.57445 20.2742 6.02951 19.8724 5.62755C19.4705 5.22576 18.9256 5 18.3571 5ZM5.5 2.14286C5.5 1.95344 5.57526 1.77168 5.70918 1.63776C5.84311 1.50383 6.02487 1.42857 6.21429 1.42857H14.7857C14.9751 1.42857 15.1569 1.50383 15.2908 1.63776C15.4247 1.77168 15.5 1.95344 15.5 2.14286V5H5.5V2.14286ZM3.35714 8.57143C3.35714 8.38202 3.4324 8.20025 3.56633 8.06633C3.70025 7.9324 3.88202 7.85714 4.07143 7.85714H6.92857C7.18383 7.85714 7.41963 7.9933 7.54718 8.21429C7.67474 8.43527 7.67474 8.70759 7.54718 8.92857C7.41963 9.14955 7.18382 9.28571 6.92857 9.28571H4.07143C3.88202 9.28571 3.70025 9.21046 3.56633 9.07653C3.4324 8.9426 3.35714 8.76084 3.35714 8.57143ZM15.5 18.5714H5.5V12.1429H15.5V18.5714Z"
        fill="#130F26"
      ></path>
      <path
        opacity="0.4"
        d="M7.64274 15H13.357C13.6123 15 13.8481 14.8638 13.9756 14.6428C14.1032 14.4219 14.1032 14.1495 13.9756 13.9286C13.8481 13.7076 13.6123 13.5714 13.357 13.5714H7.64274C7.38749 13.5714 7.15168 13.7076 7.02413 13.9286C6.89658 14.1495 6.89658 14.4219 7.02413 14.6428C7.15168 14.8638 7.38748 15 7.64274 15Z"
        fill="#130F26"
      ></path>
      <path
        opacity="0.4"
        d="M7.64274 17.1428H11.9285C12.1837 17.1428 12.4195 17.0067 12.5471 16.7857C12.6746 16.5647 12.6746 16.2924 12.5471 16.0714C12.4195 15.8504 12.1837 15.7143 11.9285 15.7143H7.64274C7.38749 15.7143 7.15168 15.8504 7.02413 16.0714C6.89658 16.2924 6.89658 16.5647 7.02413 16.7857C7.15168 17.0067 7.38748 17.1428 7.64274 17.1428Z"
        fill="#130F26"
      ></path>
    </svg>
  );
};

const PrintSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(PrintSVG);
