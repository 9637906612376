import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelect from "../mySelect/MySelect";
import NotificationActions from "../../actions/NotificationActions";

const SelectNotifStatus = ({ withAll, ...otherProps }) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    let data = [
      { label: t("WORD_READ"), value: NotificationActions.STATUS.READED },
      {
        label: t("WORD_UNREAD"),
        value: NotificationActions.STATUS.NOTREADED,
      },
    ];
    if (withAll) data.unshift({ label: t("WORD_ALL"), value: "" });
    setlist(data);
  }, [t, withAll]);

  return <MySelect {...otherProps} options={list} />;
};

export default SelectNotifStatus;
