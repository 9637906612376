import { Divider } from "antd";
import React from "react";

const MyDivider = ({ dashed, style, type, smaller }) => {
  return (
    <Divider
      dashed={dashed}
      style={style}
      type={type}
      className={smaller ? "divider-small" : ""}
    />
  );
};

export default MyDivider;
