import React, { useCallback, useEffect, useMemo, useState } from "react";
import MySpace from "../../../components/mySpace/MySpace";
import MyPageHeader from "../../../components/myPageHeader/MyPageHeader";
import { useTranslation } from "react-i18next";
import UtilNotify from "../../../utils/UtilNotify";
import UserActions from "../../../actions/UserActions";
import BranchActions from "../../../actions/BranchActions";
import { useSelector } from "react-redux";
import ApiStorage from "../../../redux/ApiStorage";
import MyTable from "../../../components/myTable/MyTable";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import MyHelmet from "../../../components/helmet/MyHelmet";
import useNavigateApi from "../../../appRoute/useNavigateApi";
import FormApp from "../../../components/formApp/FormApp";
import { useForm } from "antd/es/form/Form";
import MyButtonText from "../../../components/myButton/MyButtonText";
import SelectBranchStatus from "../../../components/selects/SelectBranchStatus";
import SearchInput from "../../../components/myInputForm/SearchInput";
import MyButtonTableAction from "../../../components/myButton/MyButtonTableAction";
import { Flex } from "antd";
import MyTag from "../../../components/tag/MyTag";

const formName = "formBranchFilter";

const BranchList = () => {
  const { t } = useTranslation();
  const navigateApi = useNavigateApi();
  const [form] = useForm();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setpagination] = useState({ page: 0, perPage: 50 });
  const [totalRows, settotalRows] = useState(0);
  const [search, setSearch] = useState("");
  const [sort, setsort] = useState("");

  const branchPageFilters = useSelector((state) => {
    return state.branchPageFilters;
  });

  const onFinish = useCallback(
    (fil) => {
      ApiStorage.setBranchPageFilters(
        Object.assign({}, branchPageFilters, fil)
      );
      setpage(0);
    },
    [branchPageFilters]
  );

  const setpage = (page) => {
    setpagination((ov) => ({ page: page, perPage: ov.perPage }));
  };

  const setFilterClear = () => {
    form.resetFields();
    ApiStorage.setBranchPageFilters({});
    setpage(0);
  };

  useEffect(() => {
    const fill = async () => {
      setLoading(true);
      const fetchParams = {
        page: pagination.page,
        perPage: pagination.perPage,
        sort: sort,
        q: branchPageFilters?.searchName || "",
        filter: { ...branchPageFilters },
        search: search,
      };
      const result = await BranchActions.fillPagination(fetchParams);
      if (result.success) {
        if (pagination.page !== 0) {
          setData((ov) => [...ov, ...(result.data ?? [])]);
        } else {
          setData(result.data);
        }
        settotalRows(result.totalRows);
        // settotals(result.totals);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setLoading(false);
    };

    fill();
  }, [pagination, search, branchPageFilters, sort]);

  useEffect(() => {
    if (branchPageFilters) {
      form?.setFieldsValue(branchPageFilters);
    }
  }, [branchPageFilters, form]);

  const columns = useMemo(() => {
    let col = [
      {
        title: t("WORD_USERNAME"),
        dataIndex: "username",
        key: "username",
        sorter: true,
      },
      {
        title: t("WORD_BRANCH_NAME"),
        dataIndex: "nameBranch",
        key: "nameBranch",
        width: 200,
        sorter: true,
      },
      {
        title: t("WORD_DEVICE_COUNT"),
        dataIndex: "deviceCount",
        key: "deviceCount",
        width: 140,
        render: (value, { deviceCountUsed }) => {
          return (
            <div style={{ fontWeight: "bold" }}>
              {deviceCountUsed}/{value}
            </div>
          );
        },
      },
      {
        title: t("WORD_STATUS"),
        dataIndex: "status",
        key: "status",
        width: 20,
        render: (value) => {
          const status = UserActions.getStatusLabel(value);
          return <MyTag label={t(status.label)} color={status.color} />;
        },
      },
      {
        title: t("WORD_ACTIONS"),
        dataIndex: "actions",
        width: 120,
        key: "actions",
        render: (_, { idBranch }) => (
          <MySpace>
            <MyButtonTableAction
              title={t("WORD_EDIT")}
              icon={faPen}
              onClick={() => navigateApi(`/branch/edit/${idBranch}`)}
            />
          </MySpace>
        ),
      },
    ];
    return col;
  }, [t, navigateApi]);

  const onChangeTable = (_, __, sorter) => {
    let so = "";
    if (sorter.order) {
      so = `${sorter.columnKey} ${sorter.order === "ascend" ? "ASC" : "DESC"}`;
    }
    setsort(so);
  };

  return (
    <>
      <MyHelmet title={t("WORD_BRANCHES")} />
      <Flex vertical gap={10}>
        <MyPageHeader inPage title={t("WORD_BRANCHES")} />
        <MySpace style={{ marginLeft: 8 }}>
          <SearchInput
            onClear={() => {
              setSearch("");
              setpage(0);
            }}
            onPressEnter={(value) => {
              setSearch(value);
              setpage(0);
            }}
          />
          <FormApp onFinish={onFinish} name={formName} form={form}>
            <MySpace size="middle" fullWidth>
              <SelectBranchStatus
                name="status"
                placeholder={t("WORD_STATUS")}
                onChange={() => form.submit()}
                width={160}
              />
              <MyButtonText
                label={t("WORD_RESET_FILTERS")}
                onClick={setFilterClear}
              />
            </MySpace>
          </FormApp>
        </MySpace>
        <MyTable
          rowKey="idBranch"
          columns={columns}
          dataSource={data}
          onChange={onChangeTable}
          total={totalRows}
          loading={loading}
          onChangePage={(e, ee) => {
            setpagination({ page: e, perPage: ee });
          }}
          page={pagination.page}
          pageSize={pagination.perPage}
          hideOnSinglePage={false}
        />
      </Flex>
    </>
  );
};

export default BranchList;
