import React, { memo, useEffect, useMemo, useState } from "react";
import MyCard from "../../components/myCard/MyCard";
import MyText from "../../components/myText/MyText";
import { useTranslation } from "react-i18next";
import DashboardSalesChart from "./DashboardSalesChart";
import MyTable from "../../components/myTable/MyTable";
import UtilNumber from "../../utils/UtilNumber";
import StatActions from "../../actions/StatActions";
import UtilNotify from "../../utils/UtilNotify";

const DashboardSalesCard = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dataChart, setdataChart] = useState([]);

  useEffect(() => {
    const fill = async () => {
      setLoading(true);
      const result = await StatActions.dashboardStatsSales();
      if (result.success) {
        setData(result.data.salesDays);
        setdataChart(result.data.salesChart);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setLoading(false);
    };
    fill();
  }, []);

  const columns = useMemo(() => {
    let col = [
      {
        title: " ",
        dataIndex: "period",
        key: "period",
        render: (value) => {
          return (
            <div>
              {t(
                value
                  .replace(/([a-z])([0-9])/g, "$1 $2")
                  .replace(/([0-9])([A-Z])/g, "$1 $2")
                  .toLowerCase()
              )}
            </div>
          );
        },
      },
      {
        title: t("WORD_TOTAL"),
        dataIndex: "salesAmount",
        key: "salesAmount",
        width: 90,
        align: "right",
        render: (value) => {
          return (
            <MyText className="dashboard-table-text" size={12}>
              {UtilNumber.formatWithK(value)}
            </MyText>
          );
        },
      },
      {
        title: t("WORD_ORDERS"),
        dataIndex: "orderCount",
        key: "orderCount",
        width: 70,
        align: "right",
        render: (value) => {
          return (
            <MyText className="dashboard-table-text" size={12}>
              {value}
            </MyText>
          );
        },
      },
    ];
    return col;
  }, [t]);

  return (
    <MyCard fullHeight>
      <MyText className="stat-header" size={16}>
        {t("WORD_SALES")}
      </MyText>
      <div className="dashboard-sales-card-stat-row">
        <MyTable
          columns={columns}
          dataSource={data}
          loading={loading}
          className="dashboard-table dashboard-table-first"
          rowKey={(it) => JSON.stringify(it)}
          rowHoverable={false}
          noFooter
        />
        <div style={{ marginTop: 20 }}>
          <DashboardSalesChart dataChart={dataChart} />
        </div>
      </div>
    </MyCard>
  );
};

export default memo(DashboardSalesCard);
