import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import MyCard from "../../../components/myCard/MyCard";
import MySpace from "../../../components/mySpace/MySpace";
import MyTextAnalyticsStatsCard from "../../../components/myText/MyTextAnalyticsStatsCard";
import MyTabs from "../../../components/myTabs/MyTabs";
import AnalyticsCustomerByPlaceTable from "./AnalyticsCustomerByPlaceTable";

const AnalyticsCustomerByPlace = ({ filter }) => {
  const { t } = useTranslation();

  const items = useMemo(() => {
    return [
      {
        key: "COUNTRY",
        label: t("WORD_COUNTRY"),
        children: (
          <AnalyticsCustomerByPlaceTable
            filter={filter}
            selectedRegion="COUNTRY"
          />
        ),
      },
      {
        key: "REGION",
        label: t("WORD_REGION"),
        children: (
          <AnalyticsCustomerByPlaceTable
            filter={filter}
            selectedRegion="REGION"
          />
        ),
      },
      {
        key: "CITY",
        label: t("WORD_CITY"),
        children: (
          <AnalyticsCustomerByPlaceTable
            filter={filter}
            selectedRegion="CITY"
          />
        ),
      },
      {
        key: "DISTRICT",
        label: t("WORD_DISTRICT"),
        children: (
          <AnalyticsCustomerByPlaceTable
            filter={filter}
            selectedRegion="DISTRICT"
            className="analytics-table"
          />
        ),
      },
    ];
  }, [filter, t]);

  return (
    <MyCard style={{ width: '50%', minHeight: 350 }}>
      <MySpace direction="vertical" fullWidth>
        <MyTextAnalyticsStatsCard bold>
          {t("WORD_CUSTOMERS_BY_REGION")}
        </MyTextAnalyticsStatsCard>
        <MyTabs defaultActiveKey="COUNTRY" items={items} animated />
      </MySpace>
    </MyCard>
  );
};

export default AnalyticsCustomerByPlace;
