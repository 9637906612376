import React from "react";
import MyHelmet from "../helmet/MyHelmet";
import MyCard from "../myCard/MyCard";
import MyPageHeader from "../myPageHeader/MyPageHeader";
import MyDivider from "../myDivider/MyDivider";
import FormApp from "../formApp/FormApp";
import MySpaceColumn from "../mySpace/MySpaceColumn";
import MySpace from "../mySpace/MySpace";
import MyButtonSuccess from "../myButton/MyButtonSuccess";
import { useTranslation } from "react-i18next";
import MyButtonSecondary from "../myButton/MyButtonSecondary";

const EditPageForm = ({
  title,
  form,
  formName,
  onFinish,
  children,
  extraButtons,
  saving,
  loading,
  withCancel,
  cancelOnClick,
  onBack,
  isNew,
  initialValues,
  spaceBetween,
  okLabel,
  fullHeight,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <MyHelmet title={title} />
      <MyCard loading={loading} fullHeight={fullHeight}>
        <div style={{ marginBottom: 20 }}>
          <MyPageHeader onBack={onBack} title={title} />
        </div>
        <MyDivider />
        <FormApp
          form={form}
          name={formName}
          onFinish={onFinish}
          loading={loading}
          initialValues={initialValues}
          style={{ marginTop: 10 }}
        >
          <MySpaceColumn fullWidth size="middle">
            {children}
          </MySpaceColumn>
        </FormApp>
        <MySpace
          align="center"
          style={{ marginTop: 30 }}
          spaceBetween={spaceBetween}
          fullWidth
        >
          <MyButtonSuccess
            label={
              okLabel || (isNew ? t("WORD_CREATE") : t("WORD_SAVE_CHANGES"))
            }
            form={formName}
            loading={saving}
          />
          {withCancel ? (
            <MyButtonSecondary
              onClick={onBack}
              label={t("WORD_CANCEL")}
              reset
            />
          ) : null}
          {extraButtons}
        </MySpace>
      </MyCard>
    </>
  );
};

export default EditPageForm;
