import { Form, Radio } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import MySpace from "../../../components/mySpace/MySpace";

const RadioMonthlyYearlySelect = ({
  value,
  onChange,
  name,
  label,
  hidden,
  className,
  required,
  placeholder,
}) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      name={name}
      label={label}
      hasFeedback={false}
      hidden={hidden}
      rules={[
        {
          required: required,
          message: `${t("WORD_ERROR_PLEASE_INPUT")} ${placeholder || label}!`,
        },
      ]}
      className={`app-select-radio ${className || ""}`}
    >
      <Radio.Group
        onChange={(e) => {
          onChange && onChange(e.target.value);
        }}
        value={value}
      >
        <MySpace fullWidth spaceBetween>
          <Radio value={12}>{t("WORD_YEARLY")}</Radio>
          <Radio value={1}>{t("WORD_MONTHLY")}</Radio>
        </MySpace>
      </Radio.Group>
    </Form.Item>
  );
};

export default RadioMonthlyYearlySelect;
