import React, { forwardRef, useEffect, useState } from "react";
import { Form, Table, Transfer } from "antd";
import MyEditableDragableTable from "./MyEditableDragableTable";
import i18n from "../../i18n";
import MyButtonTableActionDelete from "../../components/myButton/MyButtonTableActionDelete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import MyTag from "../../components/tag/MyTag";

const mockTags = [
  { label: "Customer", color: "orange" },
  { label: "Order", color: "pink" },
  { label: "OrderItem", color: "blue" },
  { label: "Product", color: "green" },
  { label: "Manager", color: "gray" },
  { label: "Payment", color: "yellow" },
  { label: "Branch", color: "brown" },
];

const mockDataBranch = [
  {
    key: "idBranch",
    keyAs: "BranchID",
    title: "Branch id",
    description: "id of Branch",
    tag: mockTags[6],
  },
  {
    key: "nameBranch",
    keyAs: "nameBranch",
    title: "Branch name",
    description: "name of Branch",
    tag: mockTags[6],
  },
];

const mockDataPayment = [
  {
    key: "idPaymentType",
    keyAs: "PaymentTypeID",
    title: "Payment id",
    description: "id of Payment",
    tag: mockTags[5],
  },
  {
    key: "namePayment",
    keyAs: "namePaymentType",
    title: "Payment name",
    description: "name of Payment",
    tag: mockTags[5],
  },
];

const mockDataManager = [
  {
    key: "idManager",
    keyAs: "ManagerID",
    title: "Manager id",
    description: "id of the Manager",
    tag: mockTags[4],
  },
  {
    key: "nameManager",
    keyAs: "nameManager",
    title: "Manager name",
    description: "name of Manager",
    tag: mockTags[4],
  },
  {
    key: "managerNumber",
    keyAs: "managerNumber",
    title: "Manager number",
    description: "number of Manager",
    tag: mockTags[4],
  },
];

const mockDataOrderItems = [
  {
    key: "idOrderItem",
    keyAs: "OrderItemID",
    title: "Order item id",
    description: "id of Order item",
    tag: mockTags[2],
  },
  {
    key: "orderItemPrice",
    keyAs: "ItemPrice",
    title: "Order Item price",
    description: "item price of Order item",
    tag: mockTags[2],
  },
  {
    key: "orderItemDiscount",
    keyAs: "ItemDiscount",
    title: "Order Item discount",
    description: "item discount of Order item",
    tag: mockTags[2],
  },
  {
    key: "orderItemQuantity",
    keyAs: "ItemQuantity",
    title: "Order Item quantity",
    description: "item quantity of Order item",
    tag: mockTags[2],
  },
  {
    key: "orderItemTotalAmount",
    keyAs: "orderItemTotalAmount",
    title: "Order Item Total amount",
    description: "Total amount of Order Item",
    tag: mockTags[2],
  },
  // {
  //   key: "orderItemNumber",
  //   keyAs: "OrderItemNumber",
  //   title: "Item Number",
  //   description: "number of Order item",
  //   tag: mockTags[2],
  // },
];

const mockDataOrder = [
  {
    key: "idOrder",
    keyAs: "OrderID",
    title: "Order id",
    description: "id of the Order",
    tag: mockTags[1],
  },
  {
    key: "orderDate",
    keyAs: "OrderDate",
    title: "Order Date",
    description: "Date of Order",
    tag: mockTags[1],
  },
  {
    key: "dueDate",
    keyAs: "OrderDueDate",
    title: "Order Due Date",
    description: "Due date of Order",
    tag: mockTags[1],
  },
  // {
  //   key: "orderNumber",
  //   keyAs: "OrderNumber",
  //   title: "Order number",
  //   description: "Number of Order",
  //   tag: mockTags[1],
  // },
  {
    key: "orderStatusLabel",
    keyAs: "OrderStatusLabel",
    title: "Order Status Label",
    description: "Status Label of Order",
    tag: mockTags[1],
  },
  {
    key: "orderStatus",
    keyAs: "OrderStatus",
    title: "Order Status",
    description: "Status of Order",
    tag: mockTags[1],
  },
  {
    key: "orderType",
    keyAs: "OrderType",
    title: "Order Type",
    description: "Type of Order",
    tag: mockTags[1],
  },
  {
    key: "orderTypeLabel",
    keyAs: "orderTypeLabel",
    title: "Order Type Label",
    description: "Type Label of Order",
    tag: mockTags[1],
  },
  {
    key: "exported",
    keyAs: "OrderExported",
    title: "Order Exported",
    description: "Exported of Order",
    tag: mockTags[1],
  },
  {
    key: "exportedAdmin",
    keyAs: "OrderExportedAdmin",
    title: "Order Exported admin",
    description: "Admin exported of Order",
    tag: mockTags[1],
  },
  {
    key: "orderNote",
    keyAs: "OrderNote",
    title: "Order Note",
    description: "Note of Order",
    tag: mockTags[1],
  },
  {
    key: "orderCordinantsLat",
    keyAs: "OrderCordinants Lat",
    title: "Order Cordinants Lat",
    description: "Cordinants of Order",
    tag: mockTags[1],
  },
  {
    key: "orderCordinantsLan",
    keyAs: "OrderCordinants Lan",
    title: "Order Cordinants Lan",
    description: "Cordinants of Order",
    tag: mockTags[1],
  },
  {
    key: "OrderSyncDate",
    keyAs: "OrderSyncDate",
    title: "Order Sync date",
    description: "Sync date of Order",
    tag: mockTags[1],
  },
  {
    key: "orderTotalAmount",
    keyAs: "OrderTotalAmount",
    title: "Order Total amount",
    description: "Total amount of Order",
    tag: mockTags[1],
  },
];

const mockDataProduct = [
  {
    key: "idProduct",
    keyAs: "ProductID",
    title: "Product id",
    description: "id of product",
    tag: mockTags[3],
  },
  {
    key: "nameProduct",
    keyAs: "nameProduct",
    title: "Name",
    description: "name of product",
    tag: mockTags[3],
  },
  {
    key: "barcode",
    keyAs: "Barcode",
    title: "Barcode",
    description: "Barcode of product",
    tag: mockTags[3],
  },
  {
    key: "productCostPrice",
    keyAs: "ProductCostPrice",
    title: "Cost Price",
    description: "Cost price of product",
    tag: mockTags[3],
  },
  {
    key: "productPrice",
    keyAs: "ProductPrice",
    title: "Price",
    description: "Price of product",
    tag: mockTags[3],
  },
  {
    key: "productDescription",
    keyAs: "ProductDescription",
    title: "Description",
    description: "Description of product",
    tag: mockTags[3],
  },
  {
    key: "productNumber",
    keyAs: "ProductNumber",
    title: "Product number",
    description: "Number of product",
    tag: mockTags[3],
  },
  {
    key: "productExcisRate",
    keyAs: "ProductExcisRate",
    title: "Excis rate",
    description: "Excis rate of product",
    tag: mockTags[3],
  },
  {
    key: "productDiscount",
    keyAs: "ProductDiscount",
    title: "Discount",
    description: "Discount of product",
    tag: mockTags[3],
  },
];

const mockDataCustomers = [
  {
    key: "idCustomer",
    keyAs: "CustomerID",
    title: "Customer id",
    description: "id of customer",
    tag: mockTags[0],
  },
  {
    key: "nameCustomer",
    keyAs: "CustomerName",
    title: "Name",
    description: "name of customer",
    tag: mockTags[0],
  },
  {
    key: "legalName",
    keyAs: "LegalName",
    title: "Legal name",
    description: "Legal name of customer",
    tag: mockTags[0],
  },
  {
    key: "director",
    keyAs: "Director",
    title: "Director name",
    description: "Director name of customer",
    tag: mockTags[0],
  },
  {
    key: "customerTax",
    keyAs: "CustomerTax",
    title: "Tax name",
    description: "tax of customer",
    tag: mockTags[0],
  },
  {
    key: "customercCategory",
    keyAs: "CustomerCategory",
    title: "Category name",
    description: "category of customer",
    tag: mockTags[0],
  },
  {
    key: "customerNumber",
    keyAs: "CustomerNumber",
    title: "Customer number",
    description: "number of customer",
    tag: mockTags[0],
  },
  {
    key: "customerDescription",
    keyAs: "CustomerDescription",
    title: "Description",
    description: "description of customer",
    tag: mockTags[0],
  },
  {
    key: "contractDate",
    keyAs: "ContractDate",
    title: "Contract date",
    description: "contract date of customer",
    tag: mockTags[0],
  },
  {
    key: "contractNumber",
    keyAs: "ContractNumber",
    title: "contract Number",
    description: "contract number of customer",
    tag: mockTags[0],
  },
  {
    key: "bankName",
    keyAs: "BankName",
    title: "Bank name",
    description: "Bank name of customer",
    tag: mockTags[0],
  },
  {
    key: "bankNumber",
    keyAs: "BankNumber",
    title: "Bank number",
    description: "Bank number of customer",
    tag: mockTags[0],
  },
  {
    key: "customerDebitLimit",
    keyAs: "DebitLimit",
    title: "Debit limit",
    description: "Debit limit of customer",
    tag: mockTags[0],
  },
];

const columns = [
  {
    dataIndex: "title",
    title: "Name",
  },
  {
    dataIndex: "tag",
    title: "Source",
    align: "center",
    render: (tag) => (
      <MyTag label={tag.label.toUpperCase()} color={tag.color} />
    ),
  },
  {
    dataIndex: "description",
    title: "Description",
  },
];

const TableTransfer = ({
  leftColumns,
  rightColumns,
  onChangeSorting,
  ...restProps
}) => (
  <Transfer {...restProps} oneWay={true} titles={["Source", "Target"]}>
    {({
      direction,
      filteredItems,
      onItemSelect,
      onItemSelectAll,
      selectedKeys: listSelectedKeys,
    }) => {
      const isTarget = direction !== "left";
      return isTarget ? (
        <MyEditableDragableTable
          columns={rightColumns}
          dataSource={filteredItems}
          rowKey="key"
          onChange={onChangeSorting}
        />
      ) : (
        <Table
          rowSelection={{
            onChange(selectedRowKeys) {
              onItemSelectAll(selectedRowKeys, "replace");
            },
            selectedRowKeys: listSelectedKeys,
            selections: [
              Table.SELECTION_ALL,
              Table.SELECTION_INVERT,
              Table.SELECTION_NONE,
            ],
          }}
          columns={leftColumns}
          dataSource={filteredItems}
          rowKey="key"
          scroll={{ y: 500 }}
          size="small"
          pagination={false}
          onRow={({ key }) => ({
            onClick: () => {
              onItemSelect(key, !listSelectedKeys.includes(key));
            },
          })}
          className="export-left-table"
        />
      );
    }}
  </Transfer>
);

const TreeTransfer = forwardRef(({ form }, ref) => {
  const fieldListData = Form.useWatch("fieldListData", form);
  const source = Form.useWatch("source", form);
  const fieldList = fieldListData?.map((it) => it.key);

  const [mockList, setmockList] = useState([]);

  const onChange = (_, nextTargetObj) => {
    form?.setFieldsValue({
      fieldListData: nextTargetObj?.map((it) => ({
        key: it.key,
        keyAs: it.keyAs,
      })),
    });
    setmockList((ov) => {
      return ov.map((it) => {
        const tar = nextTargetObj?.find((item) => item.key === it.key);
        if (tar) {
          it.keyAs = tar.keyAs;
        }
        return it;
      });
    });
  };

  useEffect(() => {
    const setVlaues = (ov) => {
      const list = ov.map((it) => {
        const tar = fieldListData?.find((item) => item.key === it.key);
        if (tar) {
          it.keyAs = tar.keyAs;
        }
        return it;
      });
      setmockList(list);
    };

    const mockData = [
      ...mockDataOrder,
      ...mockDataCustomers,
      ...mockDataManager,
      ...mockDataPayment,
      ...mockDataBranch,
    ];
    if (source === "OrderItem")
      setVlaues([...mockData, ...mockDataOrderItems, ...mockDataProduct]);
    else if (source === "Product")
      setVlaues([...mockDataOrderItems, ...mockDataProduct]);
    else if (source === "Customer") setVlaues(mockDataCustomers);
    else setVlaues(mockData);
  }, [source, fieldListData]);

  const handleDelete = (key) => {
    const newData = fieldListData.filter((item) => item.key !== key);
    onChange([], newData);
  };

  return (
    <div>
      <TableTransfer
        dataSource={mockList}
        targetKeys={fieldList}
        showSelectAll={false}
        showSearch={true}
        onChange={(targetKeys, _, moveKeys) => {
          const targetKeysData = mockList?.filter((item) =>
            moveKeys.includes(item.key)
          );
          onChange(targetKeys, [
            ...targetKeysData,
            ...(fieldListData || [])?.map((it) => ({
              key: it.key,
              keyAs: it.keyAs,
            })),
          ]);
        }}
        onChangeSorting={onChange}
        filterOption={(inputValue, item) => {
          return (
            String(item?.title || "").indexOf(inputValue) !== -1 ||
            String(item?.tag || "").indexOf(inputValue) !== -1
          );
        }}
        leftColumns={columns}
        rightColumns={[
          {
            dataIndex: "title",
            title: "Name",
          },
          {
            dataIndex: "tag",
            title: "Source",
            align: "center",
            render: (tag) => (
              <MyTag label={tag.label.toUpperCase()} color={tag.color} />
            ),
          },
          {
            dataIndex: "keyAs",
            title: "AS",
            editable: true,
          },
          {
            dataIndex: "operation",
            width: 50,
            render: (_, { key }) =>
              fieldList.length >= 1 ? (
                <MyButtonTableActionDelete
                  onConfirm={() => handleDelete(key)}
                  title={i18n.t("WORD_ARE_YOU_SURE_YOU_WANT_TO_REMOVE")}
                  okText={i18n.t("WORD_REMOVE")}
                  icon={
                    <FontAwesomeIcon icon={faTrashCan} color="#fff" size="xs" />
                  }
                />
              ) : null,
          },
        ]}
      />
    </div>
  );
});

export default TreeTransfer;
