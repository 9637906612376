import React from "react";
import { Checkbox, Form } from "antd";

const CheckBoxFormItem = ({ name, label, onChange, className }) => {
  return (
    <Form.Item
      className={`my-checkbox-form-item ${className || ""}`}
      name={name}
      valuePropName="checked"
    >
      <Checkbox
        className="my-checkbox"
        onChange={(e) => {
          onChange && onChange(e.target.checked);
        }}
      >
        {label}
      </Checkbox>
    </Form.Item>
  );
};

export default CheckBoxFormItem;
