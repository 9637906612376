import ApiStorage from "../redux/ApiStorage";
import { fetchFromUrlPOSTAsync } from "../utils/UrlHelper";

const URL_CLIENT_SAVE = "client/save";
const URL_CLIENT_OPTIONS_SAVE = "clientOptions/save";
const URL_ACBA_PAYMENT = "client/arcaPayment";
const URL_REGISTER = "client/register";
const URL_INIT_SOCKET = "client/initSocket";
const URL_STEPS_SKIP = "client/skipStepDone";
// const URL_DELETE_PROFILE = "client/drop";

const fs = {
  saveClient: async (values, idUser) => {
    const urlParams = [
      {
        key: "saveData",
        value: values,
      },
    ];
    let result;
    if (idUser) {
      result = fetchFromUrlPOSTAsync(URL_CLIENT_SAVE, urlParams);
    } else {
      result = await fetchFromUrlPOSTAsync(URL_CLIENT_SAVE, urlParams);
    }
    return result;
  },
  saveClientOptions: async (values) => {
    const urlParams = [
      {
        key: "saveData",
        value: values,
      },
    ];
    let result = await fetchFromUrlPOSTAsync(
      URL_CLIENT_OPTIONS_SAVE,
      urlParams
    );

    if (result.success) {
      ApiStorage.setCurrentUser(Object.assign(result.data));
    }
    return result;
  },
  arcaPayment: async () => {
    const result = fetchFromUrlPOSTAsync(URL_ACBA_PAYMENT, []);
    return result;
  },
  initSocket: async () => {
    const result = fetchFromUrlPOSTAsync(URL_INIT_SOCKET, []);
    return result;
  },
  register: async (values) => {
    const result = await fetchFromUrlPOSTAsync(URL_REGISTER, {
      saveData: values,
    });
    // if (result.success) {
    //   ApiStorage.setCurrentUser(Object.assign(result.data));
    // }
    return result;
  },
  skipStepDone: async () => {
    const result = await fetchFromUrlPOSTAsync(URL_STEPS_SKIP, []);
    if (result.success) {
      ApiStorage.setCurrentUser(
        Object.assign({}, ApiStorage.getCurrentUser(), { stepsDone: 1 })
      );
    }
    return result;
  },
  deleteProfile: async (idClient) => {
    return { success: true };
    // const result = await fetchFromUrlPOSTAsync(
    //   URL_DELETE_PROFILE + "/" + idClient
    // );
    // return result;
  },
};

const loc = {
  MAPPROVIDERTYPE: {
    GOOGLE: 1,
    YANDEX: 2,
  },
  TYPEOFTAXACCOUNT: {
    TAX_ACCOUNTS: 1,
    SETTLEMENT_DOCUMENTS: 2,
  },
  STATUS: {
    BLOCKED: 0,
    ACTIVE: 1,
    PENDING: 2,
    NOTCONFIRMED: 3,
    NOTREGISTERED: 4,
    TRIAL: 5,
    DELETED: 6,
  }
};

const ClientSaveActions = Object.assign(fs, loc);

export default ClientSaveActions;
