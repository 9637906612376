import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "antd/es/form/Form";
import SelectOrderBranch from "../../../components/selects/SelectOrderBranch";
import SelectDeviceStatus from "../../../components/selects/SelectDeviceStatus";
import InputTextAreaFormItem from "../../../components/myInputForm/InputTextAreaFormItem";
import InputTextFormItem from "../../../components/myInputForm/InputTextFormItem";
import MyButtonDelete from "../../../components/myButton/MyButtonDelete";
import { useParams } from "react-router-dom";
import DeviceActions from "../../../actions/DeviceActions";
import UtilNotify from "../../../utils/UtilNotify";
import useNavigateApi from "../../../appRoute/useNavigateApi";
import { Form } from "antd";
import MyButtonConfirm from "../../../components/myButton/MyButtonConfirm";
import EditPageForm from "../../../components/editPageForm/EditPageForm";

const formName = "formDeviceEdit";

const DeviceEdit = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const navigateApi = useNavigateApi();
  const status = Form.useWatch("status", form);

  const [loading, setloading] = useState(false);
  const [saving, setSaving] = useState(false);

  const { _idDevice } = useParams();

  const goBack = () => {
    navigateApi(`/device/list`);
  };

  useEffect(() => {
    const fill = async () => {
      setloading(true);
      const resultGetOne = await DeviceActions.getOne(_idDevice);
      if (resultGetOne.success) {
        form?.setFieldsValue(resultGetOne.data);
      } else {
        UtilNotify.notifyErrorServer(resultGetOne.errMsg);
      }
      setloading(false);
    };
    fill();
  }, [_idDevice, form]);

  const deleteDevice = async () => {
    const result = await DeviceActions.delete(_idDevice);
    if (result.success) {
      if (!_idDevice) {
        form.resetFields();
      }
      UtilNotify.notifySuccess(t("WORD_DEVICE_DELETED_SUCCESSFULLY"));
      goBack();
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    setSaving(false);
  };

  const blockDevice = async () => {
    const result = await DeviceActions.block(_idDevice);
    if (result.success) {
      if (!_idDevice) {
        form.resetFields();
      }
      goBack();
      UtilNotify.notifySuccess(t("WORD_BLOCKED_SUCCESSFULLY"));
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    setSaving(false);
  };

  const unblockDevice = async () => {
    const result = await DeviceActions.unblock(_idDevice);
    if (result.success) {
      if (!_idDevice) {
        form.resetFields();
      }
      goBack();
      UtilNotify.notifySuccess(t("WORD_UNBLOCKED_SUCCESSFULLY"));
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    setSaving(false);
  };

  const onFinish = async (values) => {
    setSaving(true);
    const result = await DeviceActions.update(values, _idDevice);
    if (result.success) {
      UtilNotify.notifySuccess(t("WORD_DEVICE_UPDATED_SUCCESSFULLY"));
      goBack();
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    setSaving(false);
  };

  const getTitle = () => {
    return `${t("WORD_DEVICE")} ${
      _idDevice ? t("WORD_EDIT") : t("WORD_CREATE")
    }`;
  };

  return (
    <EditPageForm
      title={getTitle()}
      form={form}
      formName={formName}
      onFinish={onFinish}
      saving={saving}
      onBack={goBack}
      loading={loading}
      withCancel
      extraButtons={
        <>
          {status === DeviceActions.STATUS.BLOCKED ? (
            <MyButtonConfirm
              label={t("WORD_UNBLOCK")}
              onConfirm={unblockDevice}
              title={t("WORD_ARE_YOU_SURE_YOU_WANT_TO_UNBLOCK")}
            />
          ) : (
            <MyButtonDelete
              label={t("WORD_BLOCK")}
              form={formName}
              onConfirm={blockDevice}
              blockBtn
            />
          )}
          <MyButtonDelete
            label={t("WORD_DELETE")}
            form={formName}
            onConfirm={deleteDevice}
          />
        </>
      }
    >
      <SelectOrderBranch
        label={t("WORD_BRANCH")}
        name="idBranch"
        required
        onLoad={(val) => {
          form.setFieldsValue({ idBranch: val });
        }}
      />
      <InputTextFormItem
        label={t("WORD_MAC_ADDRESS")}
        name="macAddress"
        disabled
      />
      <InputTextFormItem label={t("WORD_SERIAL_NUMBER")} name="serialNumber" />
      <InputTextFormItem label={t("WORD_DEVICE_NAME")} name="deviceName" />
      <SelectDeviceStatus label={t("WORD_STATUS")} name="status" />
      <InputTextAreaFormItem label={t("WORD_ADDITIONAL")} name="additional" />
      <InputTextFormItem name="status" hidden />
    </EditPageForm>
  );
};

export default DeviceEdit;
