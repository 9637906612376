import React from "react";
import MyTextSecondary from "./MyTextSecondary";
import colors from "../../Color.scss";

const MyTextAnalyticsStatsCard = ({
  children,
  size = 16,
  bold,
  style,
  color,
  className,
}) => {
  return (
    <MyTextSecondary
      className={className || ""}
      style={{ fontSize: size, color: color || colors.secondaryText, ...style }}
      strong={bold}
    >
      {children}
    </MyTextSecondary>
  );
};

export default MyTextAnalyticsStatsCard;
