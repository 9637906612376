import React from "react";
import MyCard from "../../components/myCard/MyCard";
import MySpace from "../../components/mySpace/MySpace";
import MyTextTitle from "../../components/myText/MyTextTitle";
import { useTranslation } from "react-i18next";
import MyTextSecondary from "../../components/myText/MyTextSecondary";
import MyDivider from "../../components/myDivider/MyDivider";
import MyLinkA from "../../components/myLinkA/MyLinkA";

const PricingSegmentedCard = ({ selectedPlan }) => {
  const { t } = useTranslation();

  const obj = [
    {
      title: t("WORD_PRODUCT_MANAGEMENT"),
      contents: [
        t("WORD_UNLIMITED_PRODUCTS"),
        t("WORD_CATALOG_WITH_IMAGES"),
        t("WORD_QUANTITY_MANAGEMENT"),
        t("WORD_INS_AND_OUTS_HISTORY"),
        t("WORD_IMPORT_PRODUCTS"),
        t("WORD_MULTI_LEVEL_FOLDERS"),
      ],
    },
    {
      title: t("WORD_ORDER_MANAGEMENT"),
      contents: [
        t("WORD_UNLIMITED_ORDERS"),
        t("WORD_SIMPLE_ORDERS"),
        t("WORD_OPEN_ORDERS"),
        t("WORD_REFUNDS"),
        t("WORD_PAYMENTS"),
        t("WORD_PAYMENT_REFUND"),
        t("WORD_ORDER_NOTES"),
        t("WORD_ORDER_DISCOUNTS"),
        t("WORD_PRODUCT_PRICE_EDITING"),
        t("WORD_ORDER_FILTERING"),
      ],
    },
    {
      title: t("WORD_SUPPORT"),
      contents: [
        t("WORD_COMMUNITY_FORUMS"),
        t("WORD_EMAIL"),
        t("WORD_TECHNICAL_ACCOUNT_MANAGER"),
        t("WORD_PREMIUM_CUSTOMER_SUCCESS_OPTIONS"),
      ],
    },
    {
      title: t("WORD_PRINTING_EXPORTING"),
      contents: [
        t("WORD_ORDER_PRINTING"),
        t("WORD_EXPORT_ORDER_TO_CSV"),
        t("WORD_EXPORT_E_INVOICE_XLS"),
        t("WORD_EXPORT_E_INVOICE_XML_PER_SALES_REP"),
        t("WORD_EXPORT_E_INVOICE_XML"),
        t("WORD_EXPORT_NEW_ORDERS_TO_CSV"),
        t("WORD_EXPORT_ORDER_ITEMS_LIST"),
      ],
    },
    {
      title: t("WORD_CUSTOMERS"),
      contents: [
        t("WORD_CUSTOMER_PROFILE"),
        t("WORD_CUSTOMER_ORDER_HISTORY"),
        t("WORD_LOCATION_ON_MAP"),
        t("WORD_CUSTOMER_DISCOUNT"),
        t("WORD_CUSTOMER_STATEMENTS"),
        t("WORD_CUSTOMER_OPENING_BALANCE"),
        t("WORD_DEBIT_LIMIT"),
      ],
    },
    {
      title: t("WORD_REPORTING"),
      contents: [
        t("WORD_ATIVITY_BY_PERIOD"),
        t("WORD_CUSTOMER_DEBTS"),
        t("WORD_CUSTOMER_DEBIT_LIMIT_ALERT"),
        t("WORD_PRODUCT_INS_OUTS_TRACKING_PER_SALES_REP"),
      ],
    },
    {
      title: t("WORD_GPS_AND_TRACKING"),
      contents: [
        t("WORD_SALES_REPS_LOCATION_TRACKING"),
        t("WORD_PAST_ROUTS_ARCHIVE"),
        t("WORD_ORDER_TAKING_LOCATION"),
      ],
    },
    {
      title: t("WORD_SALES_REPS"),
      contents: [
        t("WORD_CUSTOM_LISTS_OF_CUSTOMERS"),
        t("WORD_CUSTOMER_GROUPS"),
        t("WORD_CUSTOM_ROUTS"),
        t("WORD_MOBILE_APP_ACCESS"),
        t("WORD_OFFLINE_ORDER_TAKING"),
        t("WORD_CUSTOMER_LOCATION_ON_MAP"),
        t("WORD_ADD_NEW_CUSTOMERS"),
        t("WORD_CUSTOMER_DEBTS_TRACKING"),
        t("WORD_BARCODE_SCANNER"),
        t("WORD_CUSTOM_DISCOUNT_TO_ORDER"),
        t("WORD_EDIT_PRICE"),
        t("WORD_ANALYTICS"),
      ],
    },
    {
      title: t("WORD_ACCOUNTS_MANAGEMENT"),
      contents: [
        t("WORD_ADMINT_ACCOUNT_WEB"),
        t("WORD_BRANCH_ACCOUNT_WEB"),
        t("WORD_SALES_REPS_ACCOUNT_MOBILE_WEB"),
        t("WORD_DEVICE_MANAGEMENT"),
        t("WORD_MULTIPLE_PAYMENT_METHODS"),
        t("WORD_UNIT_TRACKING"),
        t("WORD_TYPE_OF_TAX_ACCOUNT"),
        t("WORD_TAX_RATES"),
      ],
    },
    {
      title: t("WORD_INTEGRATIONS"),
      contents: [t("WORD_1C_ACCOUNTING")],
    },
  ];

  const linkTypeMap = {
    support: {
      small: {
        specialLink: {
          0: "purple",
          1: "purple",
          2: "striked",
          3: "striked",
        },
      },
      standard: {
        specialLink: {
          0: "purple",
          1: "purple",
          2: "striked",
          3: "striked",
        },
      },
      premium: {
        specialLink: {
          0: "purple",
          1: "purple",
          3: "striked",
        },
      },
      custom: {
        specialLink: {
          0: "purple",
          1: "purple",
        },
      },
    },
    reporting: {
      small: {
        specialLink: {
          1: "striked",
          2: "striked",
          3: "striked",
        },
      },
    },
    gps: {
      small: {
        specialLink: {
          0: "striked",
          1: "striked",
          2: "striked",
        },
      },
      standard: {
        specialLink: {
          0: "striked",
          1: "striked",
          2: "striked",
        },
      },
    },
    integrations: {
      small: {
        specialLink: {
          0: "striked",
        },
      },
      standard: {
        specialLink: {
          0: "striked",
        },
      },
    },
  };

  const indexMap = {
    2: "support",
    5: "reporting",
    6: "gps",
    9: "integrations",
  };

  return (
    <MyCard borderRadius={9} style={{ width: "100%" }}>
      <MySpace
        spaceBetween
        fullWidth
        align="top"
        style={{ flexWrap: "wrap", gap: 20 }}
        className="card-item"
      >
        {obj.map((item, index) => (
          <div key={index} style={{ marginBottom: 30 }}>
            <MySpace
              direction="vertical"
              fullWidth
              className="card-item-second"
            >
              <MyTextTitle
                className="pricing-card-title"
                size={14}
                style={{ color: "#545F7E" }}
              >
                {item.title}
              </MyTextTitle>
              {item.contents.map((content, contentIndex) => {
                const planType = linkTypeMap[indexMap[index]];
                const selectedPlanType = planType?.[selectedPlan];
                const planLinkType =
                  selectedPlanType?.specialLink?.[contentIndex];
                return (
                  <div key={`${index}-${contentIndex}`}>
                    {planLinkType === "purple" ? (
                      <MyLinkA
                        href={
                          contentIndex === 0
                            ? "https://m.me/araqich"
                            : "mailto:info@ordersbook.com"
                        }
                        label={content}
                        color="purple"
                      />
                    ) : planLinkType === "striked" ? (
                      <MyTextSecondary
                        color="#A6AAAC"
                        style={{ textDecoration: "line-through" }}
                      >
                        {content}
                      </MyTextSecondary>
                    ) : (
                      <MyTextSecondary color="#545F7E">
                        {content}
                      </MyTextSecondary>
                    )}
                  </div>
                );
              })}
            </MySpace>
          </div>
        ))}
      </MySpace>
      <MyDivider />
      <MyTextSecondary>
        {t("WORD_CONTACT_OUR_SALES_TEAM_FOR_MORE_INFO")}
      </MyTextSecondary>
    </MyCard>
  );
};

export default PricingSegmentedCard;
