import React, { memo, useEffect, useMemo, useState } from "react";
import MyCard from "../../components/myCard/MyCard";
import MyText from "../../components/myText/MyText";
import { useTranslation } from "react-i18next";
import MyTable from "../../components/myTable/MyTable";
import MyLink from "../../components/myLink/MyLink";
import { Flex } from "antd";
import UtilNumber from "../../utils/UtilNumber";
import StatActions from "../../actions/StatActions";
import UtilNotify from "../../utils/UtilNotify";

const DashboardTopProductsCard = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fill = async () => {
      setLoading(true);
      const result = await StatActions.dashboardStatsProducts();
      if (result.success) {
        setData(result.data);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setLoading(false);
    };
    fill();
  }, []);

  const columns = useMemo(() => {
    let col = [
      {
        title: " ",
        dataIndex: "nameProduct",
        key: "nameProduct",
        // width: "40%",
        render: (value, { idProduct }) => (
          <MyLink path={`/product/edit/${idProduct}`}>{value}</MyLink>
        ),
      },
      // {
      //   title: t("WORD_TOTAL"),
      //   dataIndex: "totalAmount",
      //   key: "totalAmount",
      //   align: "right",
      //   width: "40%",
      //   render: (value) => {
      //     return UtilNumber.formatWithK(value);
      //   },
      // },
      {
        title: t("WORD_SOLD"),
        dataIndex: "totalQuantity",
        key: "totalQuantity",
        align: "right",
        width: "40%",
        render: (value) => {
          return UtilNumber.formatDouble(value);
        },
      },
    ];
    return col;
  }, [t]);

  return (
    <MyCard fullHeight>
      <Flex justify="space-between" align="center">
        <MyText className="stat-header" size={16}>
          {t("WORD_TOP_PRODUCTS")}
        </MyText>
        <MyText className="stat-header" size={13}>
          {t("WORD_LAST_30_DAYS")}
        </MyText>
      </Flex>
      <MyTable
        rowKey="idProduct"
        className="dashboard-table"
        columns={columns}
        dataSource={data}
        rowHoverable={false}
        loading={loading}
        scroll={{ y: 200 }}
        noFooter
      />
    </MyCard>
  );
};

export default memo(DashboardTopProductsCard);
