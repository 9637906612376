import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "antd/es/form/Form";
import InputTextFormItem from "../../components/myInputForm/InputTextFormItem";
import InputNumberFormItem from "../../components/myInputForm/InputNumberFormItem";
import { UserContext } from "../../layouts/settingsLayout/SettingsLayout";
import SelectCountry from "../../components/selects/SelectCountry";
import SelectBusinessType from "../../components/selects/SelectBusinessType";
import UtilNotify from "../../utils/UtilNotify";
import ClientSaveActions from "../../actions/ClientSaveActions";
import InputEmailFormItem from "../../components/myInputForm/InputEmailFormItem";
import IconAvatarForm from "../../components/uploads/uploadAvatar/IconAvatarForm";
import ApiStorage from "../../redux/ApiStorage";
import EditPageForm from "../../components/editPageForm/EditPageForm";
import MySpace from "../../components/mySpace/MySpace";
import InputTextAreaFormItem from "../../components/myInputForm/InputTextAreaFormItem";

const formName = "company_form";

const CompanySettings = () => {
  const { t } = useTranslation();
  const [form] = useForm();

  const { userData, changeUserSettings } = useContext(UserContext);

  const [saving, setSaving] = useState(false);
  const [icon, setIcon] = useState("");

  useEffect(() => {
    form?.setFieldsValue(userData);
    setIcon(userData.companyLogo);
  }, [userData, form]);

  const onFinish = async (values) => {
    setSaving(true);
    values.companyLogo = icon;
    let result = await ClientSaveActions.saveClient(values);
    if (result.success) {
      changeUserSettings(values);
      ApiStorage.setCurrentUser(
        Object.assign({}, ApiStorage.getCurrentUser(), values)
      );
      UtilNotify.notifySuccess(t("WORD_SETTINGS_SAVED"));
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    setSaving(false);
  };

  return (
    <EditPageForm
      title={t("WORD_COMPANY_SETTINGS")}
      form={form}
      formName={formName}
      onFinish={onFinish}
      saving={saving}
    >
      <MySpace direction="vertical" fullWidth style={{ gap: 20 }}>
        <IconAvatarForm
          name="companyLogo"
          label={t("WORD_ICON")}
          style={{ cursor: "pointer" }}
          icon={icon}
          onChange={(path) => {
            setIcon(path);
          }}
          onClear={() => {
            setIcon("");
          }}
        />
        <InputTextFormItem
          name="companyName"
          required
          label={t("WORD_COMPANY_NAME")}
        />
        <InputEmailFormItem
          name="companyEmail"
          label={t("WORD_COMPANY_EMAIL")}
        />
        <InputTextFormItem name="legalName" label={t("WORD_LEGAL_NAME")} />
      </MySpace>
      <InputNumberFormItem name="tin" label={t("WORD_TIN")} />
      <SelectCountry name="IdCountries" label={t("WORD_COUNTRY")} required />
      <InputTextFormItem name="city" label={t("WORD_CITY")} />
      <InputTextFormItem name="companyAddress" label={t("WORD_ADDRESS")} />
      <InputTextFormItem name="companyPhone" label={t("WORD_PHONE")} required />
      <InputTextFormItem
        name="companyMobilePhone"
        label={t("WORD_MOBILE_PHONE")}
      />
      <SelectBusinessType
        name="idBusiness"
        label={t("WORD_BUSINESS_TYPE")}
        required
      />
      <InputNumberFormItem name="employers" label={t("WORD_EMPLOYERS_COUNT")} />
      <div style={{ marginTop: 10 }}>
        <InputTextAreaFormItem name="additional" label={t("WORD_MORE_INFO")} />
      </div>
    </EditPageForm>
  );
};

export default CompanySettings;
