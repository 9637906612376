import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelectFormItem from "../mySelectFormItem/MySelectFormItem";
import UtilNotify from "../../utils/UtilNotify";
import UserActions from "../../actions/UserActions";

const SelectOrderBranch = ({ withAll, onLoad, ...otherProps }) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    const fill = async () => {
      const result = await UserActions.fillPagination({
        filter: { role: UserActions.ROLE.BRANCH },
        page: 0,
        perPage: 1000,
      });
      if (result.success) {
        let data = result.data.map((it) => ({
          label: it.fName,
          value: it.idUser,
          ...it,
        }));
        if (withAll) data.unshift({ label: t("WORD_ALL"), value: "" });
        setlist(data);
        if (data && data.length === 1) {
          onLoad && onLoad(data[0].value, data[0]);
        }
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    };
    fill();
  }, [t, withAll, onLoad]);

  return <MySelectFormItem {...otherProps} options={list} />;
};

export default SelectOrderBranch;
