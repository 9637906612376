import { fetchFromUrlPOSTAsync } from "../utils/UrlHelper";

const URL_CHECK_STATUS = "arcaPayApi/checkStatus";

const fs = {
    checkOrderStatus: async (orderId) => {
        const result = fetchFromUrlPOSTAsync(URL_CHECK_STATUS + '/' + orderId, []);
        return result;
    },

};

const loc = {};

const ArcaPaymentApi = Object.assign(fs, loc);

export default ArcaPaymentApi;
