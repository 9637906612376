import React, { memo } from "react";
import MyIcon from "./MyIcon";

export const SVGIcon = ({ size }) => {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M18.8089 9.021C18.3574 9.021 17.7594 9.011 17.0149 9.011C15.199 9.011 13.7059 7.508 13.7059 5.675V2.459C13.7059 2.206 13.503 2 13.2525 2H7.96436C5.49604 2 3.5 4.026 3.5 6.509V17.284C3.5 19.889 5.59109 22 8.1703 22H16.0455C18.5059 22 20.5 19.987 20.5 17.502V9.471C20.5 9.217 20.298 9.012 20.0465 9.013C19.6238 9.016 19.1168 9.021 18.8089 9.021"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M16.0847 2.56737C15.7857 2.25637 15.2637 2.47037 15.2637 2.90137V5.53837C15.2637 6.64437 16.1737 7.55437 17.2797 7.55437C17.9777 7.56237 18.9457 7.56437 19.7677 7.56237C20.1887 7.56137 20.4027 7.05837 20.1107 6.75437C19.0557 5.65737 17.1667 3.69137 16.0847 2.56737"
        fill="white"
      />
      <path
        d="M15.1057 12.7093C14.8137 12.4203 14.3437 12.4183 14.0517 12.7113L12.4627 14.3083V9.48133C12.4627 9.07033 12.1287 8.73633 11.7177 8.73633C11.3067 8.73633 10.9727 9.07033 10.9727 9.48133V14.3083L9.38273 12.7113C9.09173 12.4183 8.62073 12.4203 8.32973 12.7093C8.03773 12.9993 8.03773 13.4703 8.32673 13.7623L11.1897 16.6383H11.1907C11.2587 16.7063 11.3397 16.7613 11.4307 16.7993C11.5207 16.8363 11.6187 16.8563 11.7177 16.8563C11.8177 16.8563 11.9157 16.8363 12.0057 16.7983C12.0947 16.7613 12.1757 16.7063 12.2437 16.6393L12.2457 16.6383L15.1077 13.7623C15.3977 13.4703 15.3977 12.9993 15.1057 12.7093"
        fill="white"
      />
    </svg>
  );
};

const ExportWhiteSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(ExportWhiteSVG);
