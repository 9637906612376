import React, { memo } from "react";
import MyIcon from "./MyIcon";

export const SVGIcon = ({ style, size, fill }) => {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.75 13.9549H1.75C1.336 13.9549 1 13.6189 1 13.2049C1 12.7909 1.336 12.4549 1.75 12.4549H22.75C23.164 12.4549 23.5 12.7909 23.5 13.2049C23.5 13.6189 23.164 13.9549 22.75 13.9549"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8799 9.745C20.4659 9.745 20.1299 9.409 20.1299 8.995V7.481C20.1299 5.838 18.7919 4.5 17.1469 4.5H15.9419C15.5279 4.5 15.1919 4.164 15.1919 3.75C15.1919 3.336 15.5279 3 15.9419 3H17.1469C19.6189 3 21.6299 5.011 21.6299 7.481V8.995C21.6299 9.409 21.2939 9.745 20.8799 9.745"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.62012 9.745C3.20612 9.745 2.87012 9.409 2.87012 8.995V7.481C2.87012 5.011 4.88112 3 7.35312 3H8.58912C9.00312 3 9.33912 3.336 9.33912 3.75C9.33912 4.164 9.00312 4.5 8.58912 4.5H7.35312C5.70812 4.5 4.37012 5.838 4.37012 7.481V8.995C4.37012 9.409 4.03412 9.745 3.62012 9.745"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1469 21.7606H15.9419C15.5279 21.7606 15.1919 21.4246 15.1919 21.0106C15.1919 20.5966 15.5279 20.2606 15.9419 20.2606H17.1469C18.7919 20.2606 20.1299 18.9226 20.1299 17.2786V13.2036C20.1299 12.7896 20.4659 12.4536 20.8799 12.4536C21.2939 12.4536 21.6299 12.7896 21.6299 13.2036V17.2786C21.6299 19.7496 19.6189 21.7606 17.1469 21.7606"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.58887 21.7606H7.35287C4.88087 21.7606 2.86987 19.7496 2.86987 17.2786V13.2036C2.86987 12.7896 3.20587 12.4536 3.61987 12.4536C4.03387 12.4536 4.36987 12.7896 4.36987 13.2036V17.2786C4.36987 18.9226 5.70787 20.2606 7.35287 20.2606H8.58887C9.00287 20.2606 9.33887 20.5966 9.33887 21.0106C9.33887 21.4246 9.00287 21.7606 8.58887 21.7606"
        fill={fill}
      />
    </svg>
  );
};

const ProductSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(ProductSVG);
