import React, { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, Navigate } from "react-router-dom";
import UtilSocket from "../utils/UtilSocket";

const AppRoutePrivate = ({ children }) => {
  const location = useLocation();

  const idClient = useSelector((state) => {
    return state.currentUser?.idClient;
  });

  const lang = useSelector((state) => {
    return state.lang;
  });

  useEffect(() => {
    if (idClient) {
      UtilSocket.startClientChannel(idClient)
    } else {
      UtilSocket.closeSocket()
    }
  }, [idClient])

  if (!idClient) {
    return (
      <Navigate to={`/${lang}/login`} state={{ from: location, force: true }} replace />
    );
  }
  return children;
};

export default memo(AppRoutePrivate);
