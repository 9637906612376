import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelectFormItem from "../mySelectFormItem/MySelectFormItem";
import AnalyticsActions from "../../actions/AnalyticsActions";

const SelectDateFromTo = ({ onChange, withCustom, ...otherProps }) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    let data = [
      { label: t("WORD_TODAY"), value: "TODAY" },
      { label: t("WORD_YESTERDAY"), value: "YESTERDAY" },
      { label: t("WORD_LAST_3_DAYS"), value: "3DAYS" },
      { label: t("WORD_LAST_7_DAYS"), value: "WEEK" },
      { label: t("WORD_THIS_MONTH"), value: "THIS_MONTH" },
      { label: t("WORD_LAST_MONTH"), value: "LAST_MONTH" },
      { label: t("WORD_THIS_YEAR"), value: "THIS_YEAR" },
      { label: t("WORD_LAST_YEAR"), value: "LAST_YEAR" },
    ];
    if (withCustom) {
      data.push({ label: t("WORD_CUSTOM"), value: "CUSTOM" });
    }
    setlist(data);
  }, [t, withCustom]);

  const handleOnChange = (e) => {
    onChange && onChange(e, AnalyticsActions.getRangeOptionsValue(e));
  };

  return (
    <MySelectFormItem
      options={list}
      onChange={handleOnChange}
      {...otherProps}
    />
  );
};

export default SelectDateFromTo;
