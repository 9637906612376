import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import MyPageHeader from "../../components/myPageHeader/MyPageHeader";
import DashboardActivityCard from "./DashboardActivityCard";
import DashboardStatRow from "./DashboardStatRow";
import MyHelmet from "../../components/helmet/MyHelmet";
import DashboardNewUserPage from "./DashboardNewUserPage";
import { useSelector } from "react-redux";
import { Flex } from "antd";
import "./Dashboard.scss";

const Dashboard = () => {
  const { t } = useTranslation();

  const currentUserSteps = useSelector((state) => {
    return state.currentUser?.stepsDone;
  });

  return (
    <>
      <MyHelmet title={t("WORD_DASHBOARD")} />
      <Flex vertical gap={10}>
        <MyPageHeader title={t("WORD_DASHBOARD")} inPage />
        {!currentUserSteps ? (
          <DashboardNewUserPage />
        ) : (
          <>
            <DashboardStatRow />
            <DashboardActivityCard />
          </>
        )}
      </Flex>
    </>
  );
};

export default memo(Dashboard);
