import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelectFormItem from "../mySelectFormItem/MySelectFormItem";
import CurrencyActions from "../../actions/CurrencyActions";
import UtilNotify from "../../utils/UtilNotify";

const SelectCurrency = ({ ...otherProps }) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    const fill = async () => {
      const result = await CurrencyActions.fillListOptions();
      if (result.success) {
        setlist(result.data);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    };
    fill();
  }, [t]);

  return <MySelectFormItem options={list} showSearch {...otherProps} />;
};

export default SelectCurrency;
