import React, { useEffect, useState } from "react";
import UtilNotify from "../../utils/UtilNotify";
import ProductActions from "../../actions/ProductActions";
import { Form, TreeSelect } from "antd";
import { useTranslation } from "react-i18next";
import { CaretDownOutlined } from "@ant-design/icons";

const SelectProductFolder = ({ name, label, ...otherProps }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [list, setlist] = useState([]);

  useEffect(() => {
    const fill = async () => {
      setLoading(true);
      const result = await ProductActions.getAllForTree();
      if (result.success) {
        setlist(result.data);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setLoading(false);
    };
    fill();
  }, []);

  return (
    <Form.Item name={name} label={label}>
      <TreeSelect
        {...otherProps}
        loading={loading}
        placeholder={t("WORD_SELECT_PARENT_FOLDER")}
        treeLine={true}
        switcherIcon={<CaretDownOutlined />}
        showSearch
        style={{
          width: "100%",
        }}
        dropdownStyle={{
          maxHeight: 400,
          overflow: "auto",
        }}
        allowClear
        treeDefaultExpandAll={false}
        treeData={list}
        filterTreeNode={(input, treeNode) => {
          const match = treeNode?.title?.indexOf(input) > -1;
          return match;
        }}
      />
    </Form.Item>
  );
};

export default SelectProductFolder;
