import React from "react";
import { useTranslation } from "react-i18next";
import PartnersSVG from "../../../icons/PartnersSVG";
import NewUserPage from "../../../components/newUserPage/NewUserPage";

const CustomerNewUserPage = () => {
  const { t } = useTranslation();

  return (
    <NewUserPage
      title={t("WORD_CUSTOMERS")}
      text={t("WORD_CUSTOMER_INTRO_TEXT")}
      icon={<PartnersSVG size={150} fill={"#a7b4d3"} />}
    />
  );
};

export default CustomerNewUserPage;
