import React from "react";
import { getBaseURLShowImage } from "../../utils/UrlHelper";
import { Image } from "antd";
import { EyeOutlined } from "@ant-design/icons";

const MyImageSmallPreview = ({ icon, alt, width, height, withMargin }) => {
  return (
    <div
      style={{
        marginBottom: withMargin ? 10 : 0,
      }}
    >
      <Image
        src={getBaseURLShowImage(icon)}
        alt={alt || "icon"}
        width={width || 100}
        height={height || 100}
        preview={{
          mask: (
            <div>
              <EyeOutlined
                style={{
                  color: "white",
                }}
              />
            </div>
          ),
        }}
      />
    </div>
  );
};

export default MyImageSmallPreview;
