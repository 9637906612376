import React, { memo } from "react";
import MyIcon from "./MyIcon";

const SVGIcon = ({ size, fill, style }) => {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M11.9488 14.54C8.49884 14.54 5.58789 15.1038 5.58789 17.2795C5.58789 19.4562 8.51765 20.0001 11.9488 20.0001C15.3988 20.0001 18.3098 19.4364 18.3098 17.2606C18.3098 15.084 15.38 14.54 11.9488 14.54Z"
        fill={fill || "white"}
      />
      <path
        opacity="0.4"
        d="M11.9495 12.467C14.2856 12.467 16.1588 10.5831 16.1588 8.23351C16.1588 5.88306 14.2856 4 11.9495 4C9.61342 4 7.74023 5.88306 7.74023 8.23351C7.74023 10.5831 9.61342 12.467 11.9495 12.467Z"
        fill={fill || "white"}
      />
      <path
        opacity="0.4"
        d="M21.0886 9.21972C21.693 6.84225 19.9209 4.70703 17.6645 4.70703C17.4192 4.70703 17.1846 4.73405 16.9554 4.77998C16.9249 4.78718 16.8909 4.80249 16.873 4.82951C16.8524 4.86373 16.8676 4.90966 16.89 4.93937C17.5678 5.89577 17.9573 7.06018 17.9573 8.31016C17.9573 9.5079 17.6001 10.6246 16.9733 11.5513C16.9088 11.6467 16.9661 11.7755 17.0798 11.7953C17.2374 11.8232 17.3986 11.8376 17.5634 11.8421C19.2064 11.8854 20.6811 10.8218 21.0886 9.21972Z"
        fill={fill || "white"}
      />
      <path
        d="M22.8094 14.8165C22.5086 14.1717 21.7824 13.7295 20.6783 13.5125C20.1572 13.3846 18.747 13.2045 17.4352 13.2288C17.4155 13.2315 17.4048 13.245 17.403 13.254C17.4003 13.2666 17.4057 13.2882 17.4316 13.3017C18.0378 13.6034 20.3811 14.9155 20.0865 17.6829C20.074 17.8027 20.1698 17.9063 20.2888 17.8883C20.8655 17.8054 22.3492 17.4848 22.8094 16.4861C23.0637 15.9584 23.0637 15.3451 22.8094 14.8165Z"
        fill={fill || "white"}
      />
      <path
        opacity="0.4"
        d="M7.04508 4.77998C6.81675 4.73315 6.58126 4.70703 6.33592 4.70703C4.0795 4.70703 2.3075 6.84225 2.91279 9.21972C3.31931 10.8218 4.79403 11.8854 6.4371 11.8421C6.60185 11.8376 6.76392 11.8223 6.92061 11.7953C7.03433 11.7755 7.09164 11.6467 7.02717 11.5513C6.40039 10.6237 6.04312 9.5079 6.04312 8.31016C6.04312 7.05928 6.43352 5.89486 7.11134 4.93937C7.13283 4.90966 7.14894 4.86373 7.12745 4.82951C7.10954 4.80159 7.07642 4.78718 7.04508 4.77998Z"
        fill={fill || "white"}
      />
      <path
        d="M3.32156 13.5127C2.21752 13.7297 1.49225 14.1719 1.19139 14.8167C0.936203 15.3453 0.936203 15.9586 1.19139 16.4872C1.65163 17.4851 3.13531 17.8066 3.71195 17.8885C3.83104 17.9065 3.92595 17.8038 3.91342 17.6832C3.61883 14.9167 5.9621 13.6046 6.56918 13.3029C6.59425 13.2885 6.59962 13.2677 6.59694 13.2542C6.59515 13.2452 6.5853 13.2317 6.5656 13.2299C5.25294 13.2047 3.84358 13.3848 3.32156 13.5127Z"
        fill={fill || "white"}
      />
    </svg>
  );
};

const PartnersSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(PartnersSVG);
