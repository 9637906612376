import React from "react";
import { Form, DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import UtilDate from "../../utils/UtilDate";

const InputFormDatePicker = ({
  label,
  name = "DateValue",
  required = false,
  placeholder = "Select Date",
  hidden,
  style,
  readOnly = false,
  allowClear = true,
  onChange,
  hasFeedback = false,
  format = "DD/MM/YYYY",
  disabled = false,
}) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        {
          required: required && !hidden,
          message: `${label || placeholder} ${t("WORD_IS_REQUIRED")}`,
        },
      ]}
      layout="vertical"
      hidden={hidden}
      hasFeedback={hasFeedback}
      style={style}
      className="input-date-picker"
      getValueFromEvent={(date) => {
        if (date) {
          const currentTime = UtilDate.getDate();
          return date
            .hour(currentTime.hour())
            .minute(currentTime.minute())
            .second(currentTime.second());
        }
        return date;
      }}
    >
      <DatePicker
        className="my-date-picker"
        placeholder={placeholder || label}
        format={format}
        showNow={false}
        suffixIcon={null}
        inputReadOnly={readOnly}
        allowClear={(!readOnly && allowClear) || false}
        onChange={(date, dateString) => {
          if (!readOnly) {
            onChange && onChange(date, dateString);
          }
        }}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default InputFormDatePicker;
