import React from "react";
import { Form, Switch } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

const MySwitchFormItem = ({
  label,
  name,
  onChange,
  className,
  disabled,
  required,
  tooltipTitle,
}) => {
  return (
    <Form.Item
      label={label}
      name={name}
      valuePropName="checked"
      layout="horizontal"
      required={required}
      className={`"switch-row" ${className || ""}`}
      tooltip={
        tooltipTitle
          ? {
              title: tooltipTitle,
              icon: <InfoCircleOutlined />,
            }
          : null
      }
    >
      <Switch onChange={onChange} className="form-switch" disabled={disabled} />
    </Form.Item>
  );
};

export default MySwitchFormItem;
