import { fetchFromUrlPOSTAsync } from "../utils/UrlHelper";

const URL_FILL = "managerCustomer/list";
const URL_FILL_CAN_ADD = "managerCustomer/list/toAdd";
const URL_REMOVE_CUSTOMER = "managerCustomer/removeCustomers";
const URL_ADD_CUSTOMER = "managerCustomer/addCustomer";
const URL_SAVE_OPENING_BALANCE = "managerCustomer/saveCustomerOpeningBalance";
const URL_GET_OPENING_BALANCE = "managerCustomer/getCustomerOpeningBalance";

const fs = {
  getList: async (idManager, fetchParams) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_FILL + "/" + idManager,
      fetchParams
    );
    return result;
  },
  getListCustomerCanAdd: async (idManager, fetchParams) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_FILL_CAN_ADD + "/" + idManager,
      fetchParams
    );
    return result;
  },
  removeCustomers: async (idManager, ids) => {
    const urlParams = [
      {
        key: "ids",
        value: ids,
      },
    ];
    const result = await fetchFromUrlPOSTAsync(
      URL_REMOVE_CUSTOMER + "/" + idManager,
      urlParams
    );
    return result;
  },
  addCustomers: async (idManager, ids) => {
    const urlParams = [
      {
        key: "ids",
        value: ids,
      },
    ];
    const result = await fetchFromUrlPOSTAsync(
      URL_ADD_CUSTOMER + "/" + idManager,
      urlParams
    );
    return result;
  },
  saveCustomerOpeningBalance: async (values) => {
    let result = await fetchFromUrlPOSTAsync(URL_SAVE_OPENING_BALANCE, {
      saveData: values,
    });
    return result;
  },
  getCustomerOpeningBalance: async (idManager, idCustomer) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_GET_OPENING_BALANCE + "/" + idManager + "/" + idCustomer,
      []
    );
    return result;
  },
};

const loc = {};

const CustomerManagerActions = Object.assign(fs, loc);

export default CustomerManagerActions;
