import React from "react";
import MySpace from "../mySpace/MySpace";
import MyTextSecondary from "../myText/MyTextSecondary";
import MyText from "../myText/MyText";
import colors from "../../Color.scss";

const ActivityRightCardRow = ({
  title,
  data,
  style,
  noSpace,
  bold,
  secondStyle,
  secondClassName,
  secondary,
  className,
}) => {
  return (
    <MySpace fullWidth spaceBetween={!noSpace} align="top">
      {title ? (
        <MyTextSecondary
          style={style || { width: 120, display: "block" }}
          bold={bold}
          color={secondary ? colors.gray : colors.primaryText}
          className={className}
        >
          {title}:
        </MyTextSecondary>
      ) : null}
      <MyText className={secondClassName} style={secondStyle}>
        {data}
      </MyText>
    </MySpace>
  );
};

export default ActivityRightCardRow;
