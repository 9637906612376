import { useSelector } from "react-redux";

const numberFormat1 = new Intl.NumberFormat("en-US");

export const useFormatCurrency = () => {
  const currency = useSelector((state) => state.currentUser?.currency);

  const formatDoubleWithCurrency = (value) => {
    if (isNaN(value)) {
      return "";
    }
    try {
      return numberFormat1.format(value) + currency;
    } catch (error) {
      console.error("Error formatting number:", error);
    }
  };

  return { formatDoubleWithCurrency };
};
