import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, Form, Table, Input } from "antd";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { HolderOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";

const MyEditableDragableTable = ({
  dataSource,
  columns,
  rowKey,
  rowSelection,
  onChange,
}) => {
  const RowContext = React.createContext({});
  const EditableContext = React.createContext(null);

  const DragHandle = () => {
    const { setActivatorNodeRef, listeners } = useContext(RowContext);
    return (
      <Button
        type="text"
        size="small"
        icon={<HolderOutlined />}
        style={{
          cursor: "move",
        }}
        ref={setActivatorNodeRef}
        {...listeners}
      />
    );
  };

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      const activeIndex = dataSource.findIndex(
        (record) => record[rowKey] === active?.id
      );
      const overIndex = dataSource.findIndex(
        (record) => record[rowKey] === over?.id
      );
      const list = arrayMove(dataSource, activeIndex, overIndex);
      onChange &&
        onChange(
          list.map((it) => it[rowKey]),
          list
        );
    }
  };

  const Row = (props) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props["data-row-key"],
    });
    const style = {
      ...props.style,
      transform: CSS.Translate.toString(transform),
      transition,
      ...(isDragging
        ? {
            position: "relative",
            zIndex: 9999,
          }
        : {}),
    };
    const contextValue = useMemo(
      () => ({
        setActivatorNodeRef,
        listeners,
      }),
      [setActivatorNodeRef, listeners]
    );

    const [form] = Form.useForm();

    return (
      <RowContext.Provider value={contextValue}>
        <Form form={form} component={false}>
          <EditableContext.Provider value={form}>
            <tr {...props} ref={setNodeRef} style={style} {...attributes} />
          </EditableContext.Provider>
        </Form>
      </RowContext.Provider>
    );
  };

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);

    useEffect(() => {
      if (editing) {
        inputRef.current?.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      // form.setFieldsValue({
      //     [dataIndex]: record[dataIndex],
      // });
    };

    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({
          ...record,
          ...values,
        });
      } catch (errInfo) {
        console.warn("Save failed:", errInfo);
      }
    };
    let childNode = children;
    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }
    return <td {...restProps}>{childNode}</td>;
  };

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row[rowKey] === item[rowKey]);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    onChange(
      newData.map((it) => it[rowKey]),
      newData
    );
  };

  const columnsChanged = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  columnsChanged.unshift({
    key: "sort",
    align: "center",
    width: 80,
    render: () => <DragHandle />,
  });

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext
        items={dataSource.map((i) => i[rowKey])}
        strategy={verticalListSortingStrategy}
      >
        <Table
          rowSelection={rowSelection}
          columns={columnsChanged}
          dataSource={dataSource}
          className="editable-dragable-table"
          scroll={{ y: 500 }}
          size="small"
          pagination={false}
          rowKey={rowKey}
          components={{
            body: {
              row: Row,
              cell: EditableCell,
            },
          }}
        />
      </SortableContext>
    </DndContext>
  );
};

export default MyEditableDragableTable;
