import React, { useEffect, useRef, useState } from "react";
import MyCard from "../../components/myCard/MyCard";
import MySpace from "../../components/mySpace/MySpace";
import MyTextAnalyticsStatsCard from "../../components/myText/MyTextAnalyticsStatsCard";
import { useTranslation } from "react-i18next";
import AnalyticsActions from "../../actions/AnalyticsActions";
import UtilNotify from "../../utils/UtilNotify";
import BasicChart from "./BasicChart";
import { Flex } from "antd";

const AnalyticsOrderBasicChartSection = () => {
  const { t } = useTranslation();

  const [listData, setlistData] = useState([]);

  const chartRef = useRef(null);

  const [chartData, setchartData] = useState({ datasets: [], labels: [] });
  const [loading, setloading] = useState(true);

  useEffect(() => {
    const fill = async () => {
      setloading(true);
      const result = await AnalyticsActions.saleReturnPaymentByMonthList();
      if (result.success) {
        setlistData(result.data);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setloading(false);
    };
    fill();
  }, []);

  useEffect(() => {
    const chartData2 = () => {
      const safeListData = listData || [];

      const gradientOne = () => {
        const chart = chartRef.current;
        const ctxCanvas = chart?.ctx;
        if (!ctxCanvas) return "rgba(103, 65, 250, 0.3)"; // Fallback color
        const gradient = ctxCanvas.createLinearGradient(
          0,
          0,
          0,
          ctxCanvas?.height || 200
        );
        gradient.addColorStop(0, "rgba(103, 65, 250, 0.3)");
        gradient.addColorStop(1, "rgba(1, 168, 241, 0.05)");

        return gradient;
      };

      const gradientTwo = () => {
        const chart = chartRef.current;
        const ctxCanvas = chart?.ctx;
        if (!ctxCanvas) return "rgba(255, 122, 0, 0.3)";
        const gradient = ctxCanvas.createLinearGradient(
          0,
          0,
          0,
          ctxCanvas?.height || 200
        );
        gradient.addColorStop(0, "rgba(255, 122, 0, 0.3)");
        gradient.addColorStop(1, "rgba(255, 242, 129, 0.1)");
        return gradient;
      };

      const gradient3 = () => {
        const chart = chartRef.current;
        const ctxCanvas = chart?.ctx;
        if (!ctxCanvas) return "rgba(255, 122, 0, 0.3)";
        const gradient = ctxCanvas.createLinearGradient(
          0,
          0,
          0,
          ctxCanvas?.height || 200
        );
        gradient.addColorStop(0, "rgba(255, 122, 0, 0.3)");
        gradient.addColorStop(1, "rgba(255, 242, 129, 0.1)");
        return gradient;
      };

      const result = {
        labels: listData?.map((it) => {
          return t(it.monthName);
        }),
        datasets: [
          {
            id: 1,
            label: t("ORDERS"),
            data: safeListData?.map((it) => {
              return it.saleTotalAmount || 0;
            }),
            fill: true,
            backgroundColor: gradientOne(),
            borderColor: "#5861f7",
            tension: 0.3,
          },
          {
            id: 2,
            label: t("RETURNS"),
            data: safeListData?.map((it) => {
              return it.returnTotalAmount || 0;
            }),
            fill: true,
            backgroundColor: gradientTwo(),
            borderColor: "#ff9e34",
            tension: 0.3,
          },
          {
            id: 3,
            label: t("RETURNdd"),
            data: safeListData?.map((it) => {
              return it.p?.saleTotalAmount || 0;
            }),
            borderColor: "#5861f7",
            borderDash: [4, 8],
            tension: 0.3,
            borderWidth: 2,
          },
          {
            id: 4,
            data: safeListData?.map((it) => {
              return it.p?.returnTotalAmount || 0;
            }),
            fill: true,
            backgroundColor: gradient3(),
            borderColor: "#ff9e34",
            tension: 0.3,
            borderWidth: 2,
          },
        ],
      };
      return result;
    };
    setchartData(chartData2());
  }, [listData, chartRef, t]);

  return (
    <MyCard loading={loading}>
      <MySpace
        direction="vertical"
        fullWidth
        className="analytics-space-chart-parent"
        size="middle"
      >
        <MyTextAnalyticsStatsCard bold>
          {t("WORD_LAST_12_MONTHS")}
        </MyTextAnalyticsStatsCard>
        <Flex gap={10}>
          <div className="statsCard__amountHolder--amount--label label-wrapper">
            <div className="value-name value-name-color1"></div>
            {t("WORD_ORDERS")}
          </div>
          <div className="statsCard__amountHolder--amount--label label-wrapper">
            <div className="value-name value-name-color2"></div>
            {t("WORD_RETURNS")}
          </div>
        </Flex>
        <BasicChart ref={chartRef} data={chartData} />
      </MySpace>
    </MyCard>
  );
};

export default AnalyticsOrderBasicChartSection;
