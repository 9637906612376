import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import OrdersActions from "../../actions/OrdersActions";
import UtilNotify from "../../utils/UtilNotify";
import MySpace from "../../components/mySpace/MySpace";
import MyDivider from "../../components/myDivider/MyDivider";
import UtilDate from "../../utils/UtilDate";
import ActivityRightCardRow from "../../components/activityRightCardRow/ActivityRightCardRow";
import MyTable from "../../components/myTable/MyTable";
import PrintSVG from "../../icons/PrintSVG";
import UtilNumber from "../../utils/UtilNumber";
import { useReactToPrint } from "react-to-print";
import MyTextTitle from "../../components/myText/MyTextTitle";
import MyDropDown from "../../components/myDropDown/MyDropDown.js";
import useNavigateApi from "../../appRoute/useNavigateApi.js";
import LoadingIconAppCenter from "../../components/loadingIconApp/LoadingIconAppCenter.js";
import OrderViewPrintPage from "../orders/orderView/OrderViewPrintPage.js";
import { useFormatCurrency } from "../../utils/useFormatWithCurrency.js";
import "./Dashboard.scss";

const DashboardActivityRightCard = ({ itemId }) => {
  const { t } = useTranslation();
  const contentRef = useRef(null);
  const navigateApi = useNavigateApi();
  const { formatDoubleWithCurrency } = useFormatCurrency();

  const [loading, setloading] = useState(true);
  const [orderData, setOrderData] = useState({});
  const [includeImage, setIncludeImage] = useState(false);

  const reactToPrintFn = useReactToPrint({ contentRef });

  const handleDropdownClick = (item) => {
    if (item.key === "0") {
      setIncludeImage(true);
    } else {
      setIncludeImage(false);
    }
    setTimeout(() => {
      reactToPrintFn();
    }, 0);
  };

  useEffect(() => {
    const fill = async () => {
      if (itemId) {
        setloading(true);
        const resultGetOne = await OrdersActions.getOne(itemId);
        if (resultGetOne.success) {
          setOrderData(resultGetOne.data);
        } else {
          UtilNotify.notifyErrorServer(resultGetOne.errMsg);
        }
        setloading(false);
      }
    };
    fill();
  }, [itemId]);

  const columns = useMemo(() => {
    let col = [
      {
        title: t("WORD_PRODUCTS"),
        dataIndex: "nameProduct",
        key: "nameProduct",
      },
      {
        title: t("WORD_PRICE"),
        dataIndex: "price",
        key: "price",
        width: 130,
        align: "right",
        render: (value) => <div>{formatDoubleWithCurrency(value)}</div>,
      },
      {
        title: t("WORD_QUANTITY"),
        dataIndex: "quantity",
        key: "quantity",
        width: 50,
      },
      {
        title: t("WORD_TOTAL_AMOUNT"),
        dataIndex: "totalPrice",
        key: "totalPrice",
        width: 180,
        align: "right",
        render: (value) => <div>{formatDoubleWithCurrency(value)}</div>,
      },
    ];
    return col;
  }, [t, formatDoubleWithCurrency]);

  const items = [
    {
      key: "0",
      label: t("WORD_PRINT_WITH_IMAGE"),
      onClick: (item) => handleDropdownClick(item),
    },
    {
      key: "1",
      label: t("WORD_PRINT_WITHOUT_IMAGE"),
      onClick: (item) => handleDropdownClick(item),
    },
  ];

  let isPayment = orderData?.type === OrdersActions.TYPE.PAYMENT;

  return (
    <MySpace
      fullWidth
      direction="vertical"
      style={{
        width: "100%",
        overflowY: "auto",
        height: 400,
      }}
    >
      {loading ? (
        <LoadingIconAppCenter height={380} />
      ) : (
        <div className="right-card-print">
          <MySpace fullWidth spaceBetween>
            <MyTextTitle className="view-print-title">
              {orderData?.type === 3
                ? t("WORD_PAYMENT_ID")
                : t("WORD_ORDER_ID")}
              : #{orderData?.idOrder}
            </MyTextTitle>
            {isPayment ? (
              <div
                className="print-btn"
                style={{ cursor: "pointer" }}
                onClick={reactToPrintFn}
              >
                <PrintSVG />
              </div>
            ) : (
              <MyDropDown width={170} items={items} arrow={false}>
                <div className="print-btn" style={{ cursor: "pointer" }}>
                  <PrintSVG />
                </div>
              </MyDropDown>
            )}
          </MySpace>
          <MyDivider />
          <MySpace>
            <div>
              <span className="column-render-title">
                {t("WORD_CUSTOMER")} :{" "}
              </span>
              <span
                className="card-row-for-print"
                onClick={() =>
                  navigateApi(`/customer/edit/${orderData?.idCustomer}`)
                }
              >
                {orderData?.nameCustomer}
              </span>
              <span> | </span>
              <span className="column-render-title">
                {t("WORD_DISTRIBUTOR")} :{" "}
              </span>
              <span
                className="card-row-for-print"
                onClick={() =>
                  navigateApi(`/user/edit/${orderData?.idManager}`)
                }
              >
                {orderData?.nameManager}
              </span>
              <span className="line-color"> | </span>
              <span className="column-render-title">{t("WORD_BRANCH")} : </span>
              <span
                className="card-row-for-print"
                onClick={() =>
                  navigateApi(`/branch/edit/${orderData?.idBranch}`)
                }
              >
                {orderData?.nameBranch}
              </span>
            </div>
          </MySpace>
          <MyDivider />
          <MySpace fullWidth spaceBetween align="top" size="large">
            <div></div>
            <MySpace direction="vertical">
              <ActivityRightCardRow
                title={t("WORD_CREATE_DATE")}
                data={UtilDate.formatDateTime(orderData?.createdAt)}
              />
              <ActivityRightCardRow
                title={t("WORD_DELIVERY_DATE")}
                data={UtilDate.formatDateTime(orderData?.dueDate)}
              />
              <ActivityRightCardRow
                title={t("WORD_PAYMENT_TYPE")}
                data={orderData?.namePaymenttype}
              />
            </MySpace>
          </MySpace>
          <MyDivider />
          {orderData?.type !== OrdersActions.TYPE.PAYMENT ? (
            <MyTable
              rowKey="idOrderItem"
              columns={columns}
              loading={loading}
              className="dashboard-activity-table"
              dataSource={orderData?.orderItems}
              noFooter
            />
          ) : null}
          <MySpace
            fullWidth
            spaceBetween
            style={{
              marginTop:
                orderData?.type === OrdersActions.TYPE.PAYMENT ? 0 : 20,
            }}
          >
            <div></div>
            <MySpace direction="vertical" fullWidth style={{ gap: 0 }}>
              <ActivityRightCardRow
                title={t("WORD_DISCOUNT")}
                data={`${UtilNumber.formatDouble(orderData?.discountPer)}%`}
              />
              <MyDivider />
              <MySpace>
                <ActivityRightCardRow
                  title={t("WORD_TOTAL_AMOUNT")}
                  data={formatDoubleWithCurrency(orderData?.totalAmount)}
                />
              </MySpace>
            </MySpace>
          </MySpace>
        </div>
      )}
      <div style={{ display: "none" }}>
        <OrderViewPrintPage
          orderData={orderData}
          withoutImage={includeImage}
          ref={contentRef}
          isPayment={isPayment}
        />
      </div>
    </MySpace>
  );
};

export default DashboardActivityRightCard;
