import { Form, Input } from "antd";
import React from "react";

const MatchingInput = ({
  name,
  label,
  errorMessage,
  readOnly,
  disabled,
  variant,
  matchField,
  style,
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      dependencies={[matchField]}
      style={style}
      rules={[
        ({ getFieldValue }) => ({
          validator(_, value) {
            const matchValue = getFieldValue(matchField);
            if (!value || value === matchValue) {
              return Promise.resolve();
            }
            return Promise.reject(
              new Error(errorMessage || "Values must match!")
            );
          },
        }),
      ]}
    >
      <Input disabled={disabled} readOnly={readOnly} variant={variant} />
    </Form.Item>
  );
};

export default MatchingInput;
