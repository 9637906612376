import { fetchFromUrlPOSTAsync } from "../utils/UrlHelper";

const URL_FILL_PAGINATION = "notification/list";
const URL_CHANGE_READED = "notification/changeRead";

const fs = {
  fillPagination: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_FILL_PAGINATION, urlParams);
    return result;
  },
  save: async (idNotif, values) => {
    const urlParams = [
      {
        key: "saveData",
        value: values,
      },
    ];
    const result = await fetchFromUrlPOSTAsync(
      URL_CHANGE_READED + "/" + idNotif,
      urlParams
    );
    return result;
  },
};

const loc = {
  STATUS: { NOTREADED: 1, READED: 2 },
  getNotifStatusLabel: (status) => {
    switch (status) {
      case 1:
        return { label: "Unread", color: "#fcc44d" };
      case 2:
        return { label: "Read", color: "#9fcc6b" };
      default:
        return {};
    }
  },
};

const NotificationActions = Object.assign(fs, loc);

export default NotificationActions;
