import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "antd/es/form/Form";
import { FilterOutlined } from "@ant-design/icons";
import FormApp from "../../../components/formApp/FormApp";
import MySpace from "../../../components/mySpace/MySpace";
import SelectManager from "../../../components/selects/SelectManager";
import SelectProductQuantityType from "../../../components/selects/SelectProductQuantityType";
import InputFormDatePicker from "../../../components/inputFormDatePicker/InputFormDatePicker";
import MyDrawerWithReset from "../../../components/myDrawer/MyDrawerWithReset";
import UtilDate from "../../../utils/UtilDate";

const formName = "formProductQuantitiesFilter";

const ProductQuantitiesFilterDrawer = ({
  onChange,
  onReset,
  loading,
  filter,
}) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const refDrawer = useRef();

  useEffect(() => {
    if (filter) {
      const obj = { ...filter };
      if (filter?.dateFrom) {
        obj.dateFrom = UtilDate.getDate(filter.dateFrom);
      }
      if (filter?.dateTo) {
        obj.dateTo = UtilDate.getDate(filter.dateTo);
      }
      form?.setFieldsValue(obj);
    }
  }, [filter, form]);

  const onFinish = (values) => {
    onChange && onChange(values);
  };

  const handleReset = () => {
    form.resetFields();
    onReset && onReset();
    // refDrawer?.current?.closeDrawer();
  };

  const countDefinedValues = (productQuantitiesFilters) => {
    return Object.values(productQuantitiesFilters).filter(
      (value) =>
        value !== undefined && (Array.isArray(value) ? value.length > 0 : true)
    ).length;
  };

  const definedCount = countDefinedValues(filter);

  return (
    <MyDrawerWithReset
      ref={refDrawer}
      label={t("WORD_FILTER")}
      icon={<FilterOutlined />}
      loading={loading}
      formName={formName}
      handleReset={handleReset}
      definedCount={definedCount}
    >
      <FormApp onFinish={onFinish} name={formName} form={form}>
        <MySpace direction="vertical" size="large" fullWidth>
          <SelectProductQuantityType
            name="type"
            label={t("WORD_SELECT_TYPE")}
            placeholder={t("WORD_SELECT_TYPE")}
          />
          <SelectManager
            name="idManager"
            label={t("WORD_SELECT_MANAGER")}
            placeholder={t("WORD_SELECT_MANAGER")}
          />
          <InputFormDatePicker name="dateFrom" label={t("WORD_DATE_FROM")} />
          <InputFormDatePicker name="dateTo" label={t("WORD_DATE_TO")} />
        </MySpace>
      </FormApp>
    </MyDrawerWithReset>
  );
};

export default ProductQuantitiesFilterDrawer;
