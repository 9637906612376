import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import MyButtonConfirmDelete from "./MyButtonConfirmDelete";

const MyButtonDelete = ({
  onClick,
  loading,
  label,
  icon,
  style,
  blockBtn,
  withIcon,
  size,
  onConfirm,
  ...otherProps
}) => {
  return (
    <MyButtonConfirmDelete
      {...otherProps}
      onConfirm={onConfirm}
      loading={loading}
      blockBtn={blockBtn}
      size={size}
      icon={
        icon ||
        (withIcon ? (
          <FontAwesomeIcon
            icon={blockBtn ? faBan : faTrashCan}
            color="#fff"
            size="xs"
          />
        ) : null)
      }
      label={label}
      style={style}
      danger
    />
  );
};

export default MyButtonDelete;
