import React, { memo } from "react";
import MyIcon from "./MyIcon";

export const SVGIcon = ({ size }) => {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M18.8088 9.021C18.3573 9.021 17.7592 9.011 17.0146 9.011C15.1987 9.011 13.7055 7.508 13.7055 5.675V2.459C13.7055 2.206 13.5026 2 13.253 2H7.96363C5.49517 2 3.5 4.026 3.5 6.509V17.284C3.5 19.889 5.59022 22 8.16958 22H16.0453C18.5058 22 20.5 19.987 20.5 17.502V9.471C20.5 9.217 20.298 9.012 20.0465 9.013C19.6247 9.016 19.1168 9.021 18.8088 9.021"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M16.0847 2.56737C15.7857 2.25637 15.2637 2.47037 15.2637 2.90137V5.53837C15.2637 6.64437 16.1747 7.55437 17.2797 7.55437C17.9777 7.56237 18.9457 7.56437 19.7677 7.56237C20.1887 7.56137 20.4027 7.05837 20.1107 6.75437C19.0557 5.65737 17.1667 3.69137 16.0847 2.56737"
        fill="white"
      />
      <path
        d="M15.1052 12.8833C14.8142 13.1723 14.3432 13.1743 14.0512 12.8813L12.4622 11.2843V16.1113C12.4622 16.5223 12.1282 16.8563 11.7172 16.8563C11.3062 16.8563 10.9732 16.5223 10.9732 16.1113V11.2843L9.38223 12.8813C9.09223 13.1743 8.62023 13.1723 8.32923 12.8833C8.03823 12.5943 8.03723 12.1223 8.32723 11.8303L11.1892 8.95533C11.1902 8.95433 11.1902 8.95433 11.1902 8.95433C11.2582 8.88633 11.3402 8.83133 11.4302 8.79433C11.5202 8.75633 11.6182 8.73633 11.7172 8.73633C11.8172 8.73633 11.9152 8.75633 12.0052 8.79433C12.0942 8.83133 12.1752 8.88633 12.2432 8.95333C12.2442 8.95433 12.2452 8.95433 12.2452 8.95533L15.1072 11.8303C15.3972 12.1223 15.3972 12.5943 15.1052 12.8833"
        fill="white"
      />
    </svg>
  );
};

const ImportWhiteSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(ImportWhiteSVG);
