import React, { memo } from "react";
import MyIcon from "./MyIcon";

export const SVGIcon = ({ style }) => {
  return (
    <svg
      width="267"
      height="55"
      viewBox="0 0 267 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ style }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6479 11.8279C16.791 13.5213 18.7757 18.2495 17.0807 22.3887C16.5223 23.7523 16.2105 25.2515 16.2105 26.8437C16.2105 33.3486 21.4889 38.6219 28 38.6219C34.5111 38.6219 39.7895 33.3486 39.7895 26.8437C39.7895 25.2515 39.4777 23.7523 38.9193 22.3887C37.2243 18.2495 39.209 13.5213 43.3521 11.8279C47.4952 10.1346 52.2279 12.1174 53.9228 16.2565C55.2645 19.5329 56 23.1126 56 26.8437C56 42.2929 43.464 54.8171 28 54.8171C12.536 54.8171 0 42.2929 0 26.8437C0 23.1126 0.735518 19.5329 2.07717 16.2565C3.77212 12.1174 8.5048 10.1346 12.6479 11.8279Z"
        fill="url(#paint0_angular_2_607)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.3521 11.8279C47.4952 10.1346 52.2279 12.1174 53.9228 16.2565C55.2645 19.5329 56 23.1126 56 26.8437C56 42.2929 43.464 54.8171 28 54.8171C21.3261 54.8171 15.1781 52.4742 10.3667 48.574C6.89064 45.7561 6.35918 40.6566 9.17967 37.1838C12.0002 33.7111 17.1045 33.1801 20.5806 35.9979C22.6092 37.6424 25.1791 38.6219 28 38.6219C34.5111 38.6219 39.7895 33.3486 39.7895 26.8437C39.7895 25.2515 39.4776 23.7523 38.9193 22.3887C37.2243 18.2495 39.2089 13.5213 43.3521 11.8279Z"
        fill="url(#paint1_angular_2_607)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.3521 11.8279C47.4952 10.1346 52.2279 12.1174 53.9228 16.2565C55.2645 19.5329 56 23.1126 56 26.8437C56 35.6212 51.9439 43.4583 45.6332 48.574C42.1572 51.3918 37.0528 50.8608 34.2323 47.388C31.4118 43.9153 31.9433 38.8157 35.4194 35.9979C38.0995 33.8253 39.7895 30.5347 39.7895 26.8437C39.7895 25.2515 39.4776 23.7523 38.9193 22.3887C37.2243 18.2495 39.2089 13.5213 43.3521 11.8279Z"
        fill="url(#paint2_linear_2_607)"
      />
      <ellipse cx="28" cy="8.28046" rx="8.10526" ry="8.09756" fill="#ED7C4C" />
      <path
        d="M240.807 40.1966V14.7274H245.961V40.1966H240.807ZM252.704 40.1966L246.862 31.8304L250.127 27.9121L258.652 40.1966H252.704ZM245.326 34.6192V28.8475H246.067L252.457 21.2756H258.299L249.562 31.2127H248.415L245.326 34.6192Z"
        fill="white"
      />
      <path
        d="M228.232 40.585C226.314 40.585 224.655 40.1908 223.255 39.4024C221.854 38.614 220.772 37.4844 220.007 36.0136C219.242 34.5427 218.86 32.7836 218.86 30.7361V30.7008C218.86 28.6652 219.248 26.9178 220.025 25.4587C220.801 23.9879 221.89 22.8582 223.29 22.0699C224.69 21.2815 226.332 20.8873 228.214 20.8873C230.109 20.8873 231.756 21.2815 233.157 22.0699C234.569 22.8465 235.663 23.9702 236.439 25.4411C237.216 26.9001 237.604 28.6534 237.604 30.7008V30.7361C237.604 32.7953 237.216 34.5603 236.439 36.0312C235.675 37.5021 234.592 38.6317 233.192 39.42C231.792 40.1967 230.138 40.585 228.232 40.585ZM228.25 36.5431C229.085 36.5431 229.809 36.3195 230.421 35.8723C231.044 35.4134 231.521 34.7545 231.85 33.8955C232.192 33.0248 232.362 31.9716 232.362 30.7361V30.7008C232.362 29.4771 232.192 28.4357 231.85 27.5767C231.509 26.7178 231.027 26.0647 230.403 25.6176C229.779 25.1587 229.05 24.9292 228.214 24.9292C227.391 24.9292 226.667 25.1587 226.043 25.6176C225.432 26.0647 224.955 26.7178 224.614 27.5767C224.273 28.4357 224.102 29.4771 224.102 30.7008V30.7361C224.102 31.9716 224.267 33.0248 224.596 33.8955C224.937 34.7545 225.42 35.4134 226.043 35.8723C226.667 36.3195 227.403 36.5431 228.25 36.5431Z"
        fill="white"
      />
      <path
        d="M207.149 40.585C205.231 40.585 203.572 40.1908 202.172 39.4024C200.772 38.614 199.689 37.4844 198.924 36.0136C198.159 34.5427 197.777 32.7836 197.777 30.7361V30.7008C197.777 28.6652 198.165 26.9178 198.942 25.4587C199.718 23.9879 200.807 22.8582 202.207 22.0699C203.607 21.2815 205.249 20.8873 207.132 20.8873C209.026 20.8873 210.673 21.2815 212.074 22.0699C213.486 22.8465 214.58 23.9702 215.357 25.4411C216.133 26.9001 216.521 28.6534 216.521 30.7008V30.7361C216.521 32.7953 216.133 34.5603 215.357 36.0312C214.592 37.5021 213.509 38.6317 212.109 39.42C210.709 40.1967 209.055 40.585 207.149 40.585ZM207.167 36.5431C208.002 36.5431 208.726 36.3195 209.338 35.8723C209.961 35.4134 210.438 34.7545 210.768 33.8955C211.109 33.0248 211.279 31.9716 211.279 30.7361V30.7008C211.279 29.4771 211.109 28.4357 210.768 27.5767C210.426 26.7178 209.944 26.0647 209.32 25.6176C208.697 25.1587 207.967 24.9292 207.132 24.9292C206.308 24.9292 205.584 25.1587 204.961 25.6176C204.349 26.0647 203.872 26.7178 203.531 27.5767C203.19 28.4357 203.019 29.4771 203.019 30.7008V30.7361C203.019 31.9716 203.184 33.0248 203.513 33.8955C203.854 34.7545 204.337 35.4134 204.961 35.8723C205.584 36.3195 206.32 36.5431 207.167 36.5431Z"
        fill="white"
      />
      <path
        d="M187.743 40.5849C186.837 40.5849 186.008 40.4379 185.254 40.1437C184.501 39.8495 183.842 39.4377 183.278 38.9082C182.713 38.3669 182.26 37.7197 181.919 36.9667H181.813V40.1966H176.659V14.7274H181.813V24.5585H181.919C182.272 23.7937 182.73 23.1406 183.295 22.5994C183.86 22.0463 184.519 21.6227 185.272 21.3285C186.037 21.0344 186.866 20.8873 187.761 20.8873C189.349 20.8873 190.714 21.2815 191.856 22.0699C193.009 22.8465 193.897 23.9702 194.521 25.441C195.144 26.9001 195.456 28.6593 195.456 30.7185V30.7361C195.456 32.7835 195.144 34.5427 194.521 36.0135C193.897 37.4844 193.009 38.614 191.856 39.4024C190.702 40.1908 189.332 40.5849 187.743 40.5849ZM186.031 36.3136C186.89 36.3136 187.631 36.09 188.255 35.6429C188.879 35.184 189.361 34.5368 189.702 33.7014C190.044 32.8659 190.214 31.8775 190.214 30.7361V30.7185C190.214 29.5653 190.044 28.5769 189.702 27.7532C189.361 26.9178 188.873 26.2765 188.237 25.8294C187.614 25.3822 186.878 25.1586 186.031 25.1586C185.196 25.1586 184.46 25.3881 183.825 25.847C183.201 26.2941 182.707 26.9354 182.342 27.7709C181.989 28.5946 181.813 29.5771 181.813 30.7185V30.7361C181.813 31.8775 181.989 32.8659 182.342 33.7014C182.695 34.5368 183.189 35.184 183.825 35.6429C184.46 36.09 185.196 36.3136 186.031 36.3136Z"
        fill="white"
      />
      <path
        d="M165.354 40.585C163.613 40.585 162.13 40.332 160.906 39.826C159.683 39.32 158.724 38.6199 158.029 37.7256C157.347 36.8196 156.935 35.7723 156.794 34.5839L156.776 34.425H161.806L161.842 34.5839C162.007 35.3252 162.377 35.9018 162.954 36.3136C163.53 36.7254 164.33 36.9314 165.354 36.9314C166.013 36.9314 166.572 36.8549 167.031 36.7019C167.49 36.5372 167.843 36.3077 168.09 36.0136C168.349 35.7194 168.478 35.3664 168.478 34.9545V34.9369C168.478 34.4309 168.296 34.025 167.931 33.719C167.566 33.4131 166.919 33.1542 165.99 32.9424L162.777 32.2717C161.553 32.0128 160.53 31.6422 159.706 31.1597C158.894 30.6655 158.282 30.0595 157.87 29.3418C157.459 28.6122 157.253 27.7768 157.253 26.8354V26.8178C157.253 25.6058 157.576 24.5585 158.223 23.676C158.871 22.7935 159.788 22.111 160.977 21.6286C162.177 21.1344 163.577 20.8873 165.178 20.8873C166.849 20.8873 168.266 21.1579 169.431 21.6992C170.608 22.2287 171.508 22.9465 172.132 23.8525C172.767 24.7586 173.103 25.7646 173.138 26.8707V27.0472H168.39L168.372 26.906C168.29 26.2236 167.972 25.6587 167.419 25.2116C166.878 24.7527 166.131 24.5232 165.178 24.5232C164.589 24.5232 164.077 24.6056 163.642 24.7703C163.207 24.9351 162.871 25.1704 162.636 25.4764C162.401 25.7705 162.283 26.1235 162.283 26.5354V26.553C162.283 26.8707 162.36 27.159 162.512 27.4179C162.677 27.665 162.948 27.8827 163.324 28.0709C163.701 28.2592 164.207 28.4239 164.842 28.5652L168.055 29.2535C170.043 29.6653 171.467 30.2831 172.326 31.1068C173.197 31.9305 173.632 33.0365 173.632 34.425V34.4427C173.632 35.6782 173.279 36.7549 172.573 37.6727C171.867 38.5905 170.89 39.3083 169.643 39.826C168.408 40.332 166.978 40.585 165.354 40.585Z"
        fill="white"
      />
      <path
        d="M144.042 40.1967V21.2756H149.196V24.5762H149.302C149.584 23.4113 150.096 22.5052 150.837 21.8581C151.579 21.2109 152.502 20.8873 153.608 20.8873C153.891 20.8873 154.161 20.9049 154.42 20.9403C154.679 20.9756 154.903 21.0226 155.091 21.0815V25.6176C154.879 25.5352 154.608 25.4705 154.279 25.4234C153.95 25.3646 153.597 25.3352 153.22 25.3352C152.373 25.3352 151.649 25.4999 151.049 25.8294C150.449 26.1588 149.99 26.6413 149.672 27.2767C149.355 27.9121 149.196 28.6887 149.196 29.6065V40.1967H144.042Z"
        fill="white"
      />
      <path
        d="M132.208 40.585C130.278 40.585 128.619 40.1908 127.23 39.4024C125.842 38.6022 124.771 37.4668 124.018 35.9959C123.277 34.525 122.906 32.7836 122.906 30.7714V30.7538C122.906 28.7417 123.277 27.0002 124.018 25.5293C124.771 24.0467 125.824 22.9053 127.177 22.1052C128.542 21.2933 130.149 20.8873 131.996 20.8873C133.843 20.8873 135.438 21.2815 136.779 22.0699C138.132 22.8465 139.174 23.9467 139.903 25.3705C140.645 26.7942 141.015 28.4592 141.015 30.3655V31.954H125.43V28.7064H138.579L136.126 31.7422V29.7654C136.126 28.6593 135.955 27.7356 135.614 26.9943C135.273 26.2412 134.796 25.6764 134.185 25.2999C133.584 24.9233 132.884 24.735 132.084 24.735C131.284 24.735 130.572 24.9292 129.948 25.3175C129.337 25.7058 128.854 26.2765 128.501 27.0296C128.16 27.7709 127.989 28.6828 127.989 29.7654V31.7598C127.989 32.8071 128.16 33.7014 128.501 34.4427C128.854 35.184 129.354 35.7547 130.001 36.1547C130.649 36.5431 131.419 36.7372 132.314 36.7372C133.02 36.7372 133.632 36.6254 134.149 36.4019C134.667 36.1783 135.091 35.8959 135.42 35.5546C135.75 35.2134 135.973 34.8663 136.091 34.5133L136.126 34.4074H140.856L140.803 34.6015C140.662 35.2958 140.386 35.9959 139.974 36.7019C139.562 37.4079 139.003 38.0551 138.297 38.6434C137.603 39.2318 136.75 39.7024 135.738 40.0555C134.738 40.4085 133.561 40.585 132.208 40.585Z"
        fill="white"
      />
      <path
        d="M108.707 40.5849C107.118 40.5849 105.747 40.1908 104.594 39.4024C103.441 38.614 102.553 37.4844 101.929 36.0135C101.317 34.5427 101.011 32.7835 101.011 30.7361V30.7185C101.011 28.6593 101.323 26.9001 101.947 25.441C102.57 23.9702 103.453 22.8465 104.594 22.0699C105.747 21.2815 107.118 20.8873 108.707 20.8873C109.601 20.8873 110.425 21.0344 111.178 21.3285C111.931 21.6227 112.59 22.0463 113.155 22.5994C113.731 23.1406 114.19 23.7937 114.531 24.5585H114.637V14.7274H119.791V40.1966H114.637V36.9667H114.531C114.202 37.7197 113.755 38.3669 113.19 38.9082C112.625 39.4377 111.966 39.8495 111.213 40.1437C110.46 40.4379 109.625 40.5849 108.707 40.5849ZM110.437 36.3136C111.272 36.3136 112.007 36.09 112.643 35.6429C113.278 35.184 113.772 34.5368 114.125 33.7014C114.478 32.8659 114.655 31.8775 114.655 30.7361V30.7185C114.655 29.5771 114.473 28.5946 114.108 27.7709C113.755 26.9354 113.261 26.2941 112.625 25.847C112.002 25.3881 111.272 25.1586 110.437 25.1586C109.578 25.1586 108.836 25.3822 108.213 25.8294C107.589 26.2765 107.107 26.9178 106.765 27.7532C106.424 28.5769 106.253 29.5653 106.253 30.7185V30.7361C106.253 31.8775 106.418 32.8659 106.748 33.7014C107.089 34.5368 107.571 35.184 108.195 35.6429C108.83 36.09 109.578 36.3136 110.437 36.3136Z"
        fill="white"
      />
      <path
        d="M88.7712 40.1967V21.2756H93.9251V24.5762H94.031C94.3134 23.4113 94.8252 22.5052 95.5666 21.8581C96.3079 21.2109 97.2316 20.8873 98.3376 20.8873C98.62 20.8873 98.8907 20.9049 99.1495 20.9403C99.4084 20.9756 99.632 21.0226 99.8203 21.0815V25.6176C99.6085 25.5352 99.3378 25.4705 99.0083 25.4234C98.6789 25.3646 98.3259 25.3352 97.9493 25.3352C97.1021 25.3352 96.3785 25.4999 95.7784 25.8294C95.1782 26.1588 94.7193 26.6413 94.4016 27.2767C94.0839 27.9121 93.9251 28.6887 93.9251 29.6065V40.1967H88.7712Z"
        fill="white"
      />
      <path
        d="M76.3723 40.585C74.4543 40.585 72.7952 40.1908 71.3949 39.4024C69.9947 38.614 68.9121 37.4844 68.1473 36.0136C67.3824 34.5427 67 32.7836 67 30.7361V30.7008C67 28.6652 67.3883 26.9178 68.1649 25.4587C68.9415 23.9879 70.03 22.8582 71.4302 22.0699C72.8305 21.2815 74.4719 20.8873 76.3546 20.8873C78.2491 20.8873 79.8964 21.2815 81.2967 22.0699C82.7087 22.8465 83.803 23.9702 84.5796 25.4411C85.3562 26.9001 85.7445 28.6534 85.7445 30.7008V30.7361C85.7445 32.7953 85.3562 34.5603 84.5796 36.0312C83.8148 37.5021 82.7322 38.6317 81.332 39.42C79.9317 40.1967 78.2785 40.585 76.3723 40.585ZM76.3899 36.5431C77.2254 36.5431 77.949 36.3195 78.5609 35.8723C79.1845 35.4134 79.6611 34.7545 79.9906 33.8955C80.3318 33.0248 80.5024 31.9716 80.5024 30.7361V30.7008C80.5024 29.4771 80.3318 28.4357 79.9906 27.5767C79.6493 26.7178 79.1669 26.0647 78.5433 25.6176C77.9196 25.1587 77.1901 24.9292 76.3546 24.9292C75.5309 24.9292 74.8073 25.1587 74.1836 25.6176C73.5718 26.0647 73.0952 26.7178 72.754 27.5767C72.4127 28.4357 72.2421 29.4771 72.2421 30.7008V30.7361C72.2421 31.9716 72.4069 33.0248 72.7363 33.8955C73.0776 34.7545 73.56 35.4134 74.1836 35.8723C74.8073 36.3195 75.5427 36.5431 76.3899 36.5431Z"
        fill="white"
      />
      <circle cx="262.928" cy="15.2132" r="4.0724" fill="#FF710B" />
      <defs>
        <radialGradient
          id="paint0_angular_2_607"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(28 28.1563) rotate(90) scale(26.6608 27.2632)"
        >
          <stop offset="0.192753" stopColor="#5BC02E" />
          <stop offset="0.392916" stopColor="#B4F100" />
        </radialGradient>
        <radialGradient
          id="paint1_angular_2_607"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(28.7368 27.4202) rotate(83.86) scale(27.555 28.8577)"
        >
          <stop offset="0.178737" stopColor="#00E386" />
          <stop offset="0.904913" stopColor="#00A1D5" />
        </radialGradient>
        <linearGradient
          id="paint2_linear_2_607"
          x1="46.1811"
          y1="1.07313"
          x2="38.6553"
          y2="44.0115"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.170771" stopColor="#877CFF" />
          <stop offset="1" stopColor="#4EC9FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const LogoLightSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(LogoLightSVG);
