import React, { memo } from "react";
import MyIcon from "./MyIcon";

export const SVGIcon = ({ size }) => {
  return (
    <svg
      width={size || 41}
      height={size || 41}
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M40.0771 20.4225C40.0771 31.4705 31.1231 40.4225 20.0771 40.4225C9.03115 40.4225 0.0771484 31.4705 0.0771484 20.4225C0.0771484 9.37849 9.03115 0.422485 20.0771 0.422485C31.1231 0.422485 40.0771 9.37849 40.0771 20.4225Z"
        fill="#df2d1b"
      ></path>
      <path
        d="M27.225 28.0515C26.963 28.0515 26.699 27.9835 26.457 27.8415L18.605 23.1575C18.153 22.8855 17.875 22.3955 17.875 21.8675V11.7735C17.875 10.9455 18.547 10.2735 19.375 10.2735C20.203 10.2735 20.875 10.9455 20.875 11.7735V21.0155L27.995 25.2615C28.705 25.6875 28.939 26.6075 28.515 27.3195C28.233 27.7895 27.735 28.0515 27.225 28.0515Z"
        fill="#df2d1b"
      ></path>
    </svg>
  );
};

const TimeRedSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(TimeRedSVG);
