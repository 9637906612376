import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelectFormItem from "../mySelectFormItem/MySelectFormItem";
import ClientSaveActions from "../../actions/ClientSaveActions";

const SelectTypeOfTaxAccount = ({ ...otherProps }) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    let data = [
      { label: t("WORD_TAX_ACCOUNTS"), value: ClientSaveActions.TYPEOFTAXACCOUNT.TAX_ACCOUNTS },
      { label: t("WORD_SETTLEMENT_DOCUMENTS"), value: ClientSaveActions.TYPEOFTAXACCOUNT.SETTLEMENT_DOCUMENTS },
    ];
    setlist(data);
  }, [t]);

  return <MySelectFormItem {...otherProps} options={list} />;
};

export default SelectTypeOfTaxAccount;
