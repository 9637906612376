// import { socketAction } from '../actions/socketAction';
import i18n from "../i18n";
import UtilSocket from "../utils/UtilSocket";
import ApiLocalStorage from "./ApiLocalStorage";
import { store } from "./store";

const ApiStorage = {
  setLanguage: (lang) => {
    store.dispatch({ type: "SET_LANG", payload: lang });
    i18n.changeLanguage(lang);
  },
  setSelectedTheme: (theme) => {
    store.dispatch({ type: "SET_SELECTED_THEME", payload: theme });
  },
  setStepDetailsDone: (obj) => {
    const currentUser = ApiStorage.getCurrentUser();
    if (!currentUser?.stepsDone) {
      ApiStorage.setCurrentUser(
        Object.assign({}, currentUser, {
          stepsDoneDetails: { ...currentUser?.stepsDoneDetails, ...obj },
        })
      );
    }
  },
  setCurrentUser: (user) => {
    store.dispatch({ type: "SET_CURRENT_USER", payload: user });
    if (user?.hasOwnProperty("sessionToken")) {
      ApiStorage.setAccessToken(user.sessionToken);
      ApiStorage.setTrialClose(false);
    }
    if (user?.hasOwnProperty("lang")) {
      ApiStorage.setLanguage(user.lang);
    }
    ApiStorage.setBadgeCountOrder(0);
    if (user?.hasOwnProperty("badgeCountOrder")) {
      ApiStorage.setBadgeCountOrder(user.badgeCountOrder);
    }
    ApiStorage.setBadgeCountPayment(0);
    if (user?.hasOwnProperty("badgeCountPayment")) {
      ApiStorage.setBadgeCountPayment(user.badgeCountPayment);
    }
    // if (user?.idClient) {
    //   UtilSocket.startClientChannel(user.idClient)
    // }
  },
  setAccessToken: (accessToken) => {
    store.dispatch({ type: "SET_ACCESS_TOKEN", payload: accessToken });
  },
  getAccessToken: () => {
    const { accessToken } = store.getState();
    return accessToken;
  },
  setBadgeCountOrder: (badgeCount) => {
    store.dispatch({ type: "SET_BADGE_COUNT_ORDER", payload: badgeCount });
  },
  getBadgeCountOrder: () => {
    const { badgeCountOrder } = store.getState();
    return badgeCountOrder;
  },
  setBadgeCountPayment: (badgeCount) => {
    store.dispatch({ type: "SET_BADGE_COUNT_PAYMENT", payload: badgeCount });
  },
  getBadgeCountPayment: () => {
    const { badgeCountOrder } = store.getState();
    return badgeCountOrder;
  },
  getSelectedTheme: () => {
    const { selectedTheme } = store.getState();
    return selectedTheme;
  },
  getOrderPageFilters: () => {
    const { orderPageFilters } = store.getState();
    return orderPageFilters;
  },
  setOrderPageFilters: (orderPageFilters) => {
    store.dispatch({
      type: "SET_ORDER_PAGE_FILTERS",
      payload: orderPageFilters,
    });
  },
  getPaymentPageFilters: () => {
    const { paymentPageFilters } = store.getState();
    return paymentPageFilters;
  },
  setPaymentPageFilters: (paymentPageFilters) => {
    store.dispatch({
      type: "SET_PAYMENT_PAGE_FILTERS",
      payload: paymentPageFilters,
    });
  },
  getVisitPageFilters: () => {
    const { visitPageFilters } = store.getState();
    return visitPageFilters;
  },
  setVisitPageFilters: (visitPageFilters) => {
    store.dispatch({
      type: "SET_VISIT_PAGE_FILTERS",
      payload: visitPageFilters,
    });
  },
  getUserPageFilters: () => {
    const { userPageFilters } = store.getState();
    return userPageFilters;
  },
  setUserPageFilters: (userPageFilters) => {
    store.dispatch({
      type: "SET_USER_PAGE_FILTERS",
      payload: userPageFilters,
    });
  },
  getDevicePageFilters: () => {
    const { devicePageFilters } = store.getState();
    return devicePageFilters;
  },
  setDevicePageFilters: (devicePageFilters) => {
    store.dispatch({
      type: "SET_DEVICE_PAGE_FILTERS",
      payload: devicePageFilters,
    });
  },
  getBranchPageFilters: () => {
    const { branchPageFilters } = store.getState();
    return branchPageFilters;
  },
  setBranchPageFilters: (branchPageFilters) => {
    store.dispatch({
      type: "SET_BRANCH_PAGE_FILTERS",
      payload: branchPageFilters,
    });
  },
  getDebitPageFilters: () => {
    const { debitPageFilters } = store.getState();
    return debitPageFilters;
  },
  setDebitPageFilters: (debitPageFilters) => {
    store.dispatch({
      type: "SET_DEBIT_PAGE_FILTERS",
      payload: debitPageFilters,
    });
  },
  getUserQuantitiesFilters: () => {
    const { userQuantitiesFilters } = store.getState();
    return userQuantitiesFilters;
  },
  setUserQuantitiesFilters: (userQuantitiesFilters) => {
    store.dispatch({
      type: "SET_USER_QUANTITIES_FILTERS",
      payload: userQuantitiesFilters,
    });
  },
  getProductQuantitiesFilters: () => {
    const { productQuantitiesFilters } = store.getState();
    return productQuantitiesFilters;
  },
  setProductQuantitiesFilters: (productQuantitiesFilters) => {
    store.dispatch({
      type: "SET_PRODUCT_QUANTITIES_FILTERS",
      payload: productQuantitiesFilters,
    });
  },
  getCustomerStatementFilters: () => {
    const { customerStatementFilters } = store.getState();
    return customerStatementFilters;
  },
  setCustomerStatementFilters: (customerStatementFilters) => {
    store.dispatch({
      type: "SET_CUSTOMER_STATEMENT_FILTERS",
      payload: customerStatementFilters,
    });
  },
  getAnalyticsFilters: () => {
    const { analyticsFilters } = store.getState();
    return analyticsFilters;
  },
  setAnalyticsFilters: (analyticsFilters) => {
    store.dispatch({
      type: "SET_ANALYTICS_FILTERS",
      payload: analyticsFilters,
    });
  },
  setCustomerPageFilters: (customerPageFilters) => {
    store.dispatch({
      type: "SET_CUSTOMER_PAGE_FILTERS",
      payload: customerPageFilters,
    });
  },
  setDeviceKey: (deviceKey) => {
    store.dispatch({ type: "SET_DEVICEKEY", payload: deviceKey });
  },
  getDeviceKey: () => {
    const { deviceKey } = store.getState();
    return deviceKey;
  },
  getCurrentUser: () => {
    const { currentUser } = store.getState();
    return currentUser;
  },
  getCanEdit: () => {
    const { canEdit } = store.getState();
    return canEdit;
  },
  setTrialClose: (close) => {
    store.dispatch({ type: "SET_TRIAL_CLOSE", payload: close });
  },
  setSideMenuColapsed: (opened) => {
    store.dispatch({ type: "SET_SIDE_MENU_OPENED", payload: opened });
  },
  clearUser: () => {
    UtilSocket.closeSocket();
    ApiStorage.setCurrentUser({});
    ApiStorage.setAccessToken("");
    ApiStorage.setSideMenuColapsed(false);
    ApiLocalStorage.removeItem(ApiLocalStorage.TEMP_REMEMBER_ME);
  },
};

export default ApiStorage;
