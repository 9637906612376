import React, { forwardRef, useImperativeHandle } from "react";
import { Drawer } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import MyButtonSecondary from "../myButton/MyButtonSecondary";
import MySpace from "../mySpace/MySpace";
import MyButtonSuccess from "../myButton/MyButtonSuccess";

const MyDrawer = forwardRef(
  (
    {
      children,
      title,
      label,
      formName,
      icon,
      loading,
      forceRender,
      destroyOnClose,
      noExtraBtn,
      className,
      btnClassName,
      style,
      closable = true,
      width,
      placement = "right",
    },
    ref
  ) => {
    const { t } = useTranslation();

    const [visible, setVisible] = useState(false);

    const showDrawer = () => {
      setVisible(true);
    };

    const closeDrawer = () => {
      setVisible(false);
    };

    useImperativeHandle(ref, () => ({
      showDrawer: showDrawer,
      closeDrawer: closeDrawer,
    }));

    return (
      <>
        <div className={btnClassName}>
          <MyButtonSecondary
            onClick={showDrawer}
            label={label}
            icon={icon}
            loading={loading}
          />
        </div>
        <Drawer
          title={title}
          placement={placement}
          onClose={closeDrawer}
          open={visible}
          className={className}
          forceRender={forceRender}
          destroyOnClose={destroyOnClose}
          // getContainer={false}
          style={style}
          closable={closable}
          width={width}
          extra={
            noExtraBtn ? null : (
              <MySpace>
                <MyButtonSecondary
                  onClick={closeDrawer}
                  label={t("WORD_CANCEL")}
                  reset
                />
                <MyButtonSuccess
                  type="primary"
                  htmlType="submit"
                  form={formName}
                  onClick={closeDrawer}
                  label={t("WORD_APPLY")}
                />
              </MySpace>
            )
          }
        >
          {children}
        </Drawer>
      </>
    );
  }
);

export default MyDrawer;
