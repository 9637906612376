import React, { useState } from "react";
import { Layout, Space } from "antd";
import { useSelector } from "react-redux";
import ApiStorage from "../../redux/ApiStorage";
import { MenuOutlined } from "@ant-design/icons";
import useWindowSize from "../../utils/useWindowSize";
import MyDrawer from "../../components/myDrawer/MyDrawer";
import MyDivider from "../../components/myDivider/MyDivider";
import BlockedSideBarMenu from "./BlockedSideBarMenu";
import BlockedMenuHeader from "./BlockedMenuHeader";
import "../mainLayout/HeaderNav.scss";

const { Sider } = Layout;

const BlockedLayoutSideMenu = () => {
  const { isMobile } = useWindowSize();

  const [menuOpen, setmenuOpen] = useState(false);

  const sideMenuColapsed = useSelector((state) => {
    return state.sideMenuColapsed;
  });

  return isMobile ? (
    <MyDrawer
      className="ant-drawer-left-menu-mobile"
      closable={false}
      width={"70%"}
      onClose={setmenuOpen}
      open={menuOpen}
      btnClassName="mobile-menu-btn"
      icon={<MenuOutlined />}
      placement="left"
      noExtraBtn
    >
      <BlockedMenuHeader />
      <MyDivider style={{ borderColor: "rgba(255, 255, 255, 0.1)" }} />
      <BlockedSideBarMenu />
    </MyDrawer>
  ) : (
    <Sider
      collapsed={sideMenuColapsed}
      onCollapse={ApiStorage.setSideMenuColapsed}
      width={260}
      className="main-sider"
    >
      <Space.Compact direction="vertical" block="true">
        <BlockedMenuHeader />
        <MyDivider style={{ borderColor: "rgba(255, 255, 255, 0.1)" }} />
        <BlockedSideBarMenu />
      </Space.Compact>
    </Sider>
  );
};

export default BlockedLayoutSideMenu;
