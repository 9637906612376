import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InputEmailFormItem from "../../components/myInputForm/InputEmailFormItem";
import { useForm } from "antd/es/form/Form";
import { UserContext } from "../../layouts/settingsLayout/SettingsLayout";
import UserActions from "../../actions/UserActions";
import UtilNotify from "../../utils/UtilNotify";
import EditPageForm from "../../components/editPageForm/EditPageForm";

const formName = "account_form";

const AccountSettings = () => {
  const { t } = useTranslation();
  const [form] = useForm();

  const { userData, changeUserSettings } = useContext(UserContext);

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    form?.setFieldsValue(userData);
  }, [userData, form]);

  const onFinish = async (values) => {
    setSaving(true);
    values.username = values.email;
    let result = await UserActions.saveSettings(values);
    if (result.success) {
      changeUserSettings(values);
      UtilNotify.notifySuccess(t("WORD_SETTINGS_SAVED"));
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    setSaving(false);
  };

  return (
    <EditPageForm
      title={t("WORD_ACCOUNT_SETTINGS")}
      form={form}
      formName={formName}
      onFinish={onFinish}
      saving={saving}
    >
      <InputEmailFormItem label={t("WORD_EMAIL")} name="email" required />
    </EditPageForm>
  );
};
export default AccountSettings;
