import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { memo } from "react";
import useWindowSize from "../../utils/useWindowSize";
import BlockedLayoutSideMenu from "./BlockedLayoutSideMenu";

const BlockedLayout = ({ children, Top, fullPage }) => {
  const { isMobile } = useWindowSize();

  return (
    <Layout
      style={{
        height: "100vh",
        paddingTop: Top ? 30 : 0,
        background: "#fff",
      }}
    >
      <BlockedLayoutSideMenu />
      <Layout
        style={{
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Content
          className="main-content"
          style={{
            width: fullPage || isMobile ? "100%" : 700,
            padding: 10,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default memo(BlockedLayout);
