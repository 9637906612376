import React, { memo, useEffect, useRef, useState } from "react";
import MyCard from "../../components/myCard/MyCard";
import { useTranslation } from "react-i18next";
import MyDivider from "../../components/myDivider/MyDivider";
import OrdersActions from "../../actions/OrdersActions";
import UtilNotify from "../../utils/UtilNotify";
import MyList from "../../components/myList/MyList";
import DashboardActivityItem from "./DashboardActivityItem";
import MySpace from "../../components/mySpace/MySpace";
import MyButtonSecondary from "../../components/myButton/MyButtonSecondary";
import DashboardActivityRightCard from "./DashboardActivityRightCard";
import useWindowSize from "../../utils/useWindowSize";
import DashboardActivityRightModal from "./DashboardActivityRightModal";
import MyText from "../../components/myText/MyText";

const DashboardActivityCard = () => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const refModal = useRef();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setpage] = useState(0);
  const [selectedItemId, setSelectedItemId] = useState(0);
  const [totalRows, settotalRows] = useState(0);

  useEffect(() => {
    const fill = async () => {
      setLoading(true);
      const fetchParams = {
        page: page,
        perPage: 10,
        filter: {},
      };
      if (!fetchParams?.filter?.type) {
        fetchParams.filter.type = [];
      }
      const result = await OrdersActions.fillPagination(fetchParams);
      if (result.success) {
        if (page !== 0) {
          setData((ov) => [...ov, ...(result.data ?? [])]);
        } else {
          setData(result.data);
        }
        settotalRows(result.totalRows);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setLoading(false);
    };
    fill();
  }, [page]);

  const handleItemClick = (item) => {
    if (!isMobile) {
      setSelectedItemId(item.idOrder);
    }
    if (isMobile) {
      refModal?.current?.viewItem(item);
    }
  };

  return (
    <>
      <MyCard className="activity-card">
        <MyText className="stat-header" size={16}>
          {t("WORD_ACTIVITY")}
        </MyText>
        <MyDivider />
        <div className={selectedItemId ? "activity-row-flex" : ""}>
          <MyList
            data={data}
            className="activity-list"
            loading={loading}
            style={{
              width: selectedItemId ? "72%" : "100%",
              maxHeight: 400,
              overflowY: "auto",
            }}
            renderItem={(item) => (
              <DashboardActivityItem
                item={item}
                onClick={() => handleItemClick(item)}
                style={{
                  backgroundColor:
                    selectedItemId === item.idOrder ? "#4BC5C3" : "",
                  color: selectedItemId === item.idOrder ? "#fff" : "",
                  padding: 5,
                }}
              />
            )}
            rowKey={(it) => it.idOrder}
          />
          {selectedItemId && !isMobile ? (
            <DashboardActivityRightCard itemId={selectedItemId} />
          ) : null}
        </div>
        {data?.length < totalRows ? (
          <MySpace
            align={"center"}
            style={{
              justifyContent: "center",
              marginTop: 20,
              width: selectedItemId ? "40%" : "100%",
            }}
          >
            <MyButtonSecondary
              loading={loading}
              label={t("WORD_LOAD_MORE")}
              onClick={() => {
                setpage((ov) => ov + 1);
              }}
            />
          </MySpace>
        ) : null}
        <DashboardActivityRightModal ref={refModal} />
      </MyCard>
    </>
  );
};

export default memo(DashboardActivityCard);
