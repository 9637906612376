import React from "react";
import useWindowSize from "../../utils/useWindowSize";
import MySpace from "../mySpace/MySpace";
import MyTextTitle from "../myText/MyTextTitle";
import MyTextSecondary from "../myText/MyTextSecondary";
import arrow from "../../icons/arrow.png";
import "./NewUserPage.scss";

const NewUserPage = ({ icon, title, text, children, noArrow, dashboard }) => {
  const { isMobile } = useWindowSize();

  return (
    <div
      className={`${
        dashboard ? "dashboard-first-step-wrapper" : "device-first-step-wrapper"
      }`}
    >
      <MySpace direction="vertical" className="dashboard-first-step-space">
        <MyTextTitle style={{ color: "#00384b" }} size={30}>
          {title}
        </MyTextTitle>
        <MyTextSecondary size={18} color={"#7C7C7C"}>
          {text}
        </MyTextSecondary>
        <div style={{ marginTop: !icon ? 0 : 40 }}>{icon || null}</div>
      </MySpace>
      {isMobile || noArrow ? null : (
        <div className="customer-arrow-wrapper">
          <img src={arrow} alt="arrow" className="customer-arrow" />
        </div>
      )}
      {children}
    </div>
  );
};

export default NewUserPage;
