import { fetchFromUrlPOSTAsync } from "../utils/UrlHelper";

const URL_FILL_PAGINATION = "branch/list";
const URL_GET_ONE = "branch/getOne";
const URL_SAVE_BRANCH = "branch/update";
const URL_DELETE_BRANCH = "branch/delete";
const URL_SAVE_PASSWORD = "branch/changePassword";
const URL_CHANGE_STATUS = "branch/changeStatus";

const fs = {
  fillPagination: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_FILL_PAGINATION, urlParams);
    return result;
  },
  getOne: async (idBranch) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_GET_ONE + "/" + idBranch,
      []
    );
    return result;
  },
  updateBranch: async (values, idBranch) => {
    let result = await fetchFromUrlPOSTAsync(URL_SAVE_BRANCH + "/" + idBranch, {
      saveData: values,
    });
    return result;
  },
  deleteBranch: async (idBranch) => {
    let result = await fetchFromUrlPOSTAsync(
      URL_DELETE_BRANCH + "/" + idBranch,
      []
    );
    return result;
  },
  updatePassword: async (values, idBranch) => {
    let result = await fetchFromUrlPOSTAsync(
      URL_SAVE_PASSWORD + "/" + idBranch,
      {
        saveData: values,
      }
    );
    return result;
  },
  updateStatus: async (values, idBranch) => {
    let result = await fetchFromUrlPOSTAsync(
      URL_CHANGE_STATUS + "/" + idBranch,
      {
        saveData: values,
      }
    );
    return result;
  },
};

const loc = {
  STATUS: {
    BLOCKED: 0,
    ACTIVE: 1,
    PENDING: 2,
  },
};

const BranchActions = Object.assign(fs, loc);

export default BranchActions;
