import React, { forwardRef } from "react";
import {
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend,
  Filler,
  Chart,
} from "chart.js";
import useWindowSize from "../../utils/useWindowSize";
import { Line } from "react-chartjs-2";

Chart.register(
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend,
  Filler
);

const BasicChart = forwardRef(({ data, ...otherProps }, ref) => {
  const { isMobile } = useWindowSize();

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Remove gridlines for the x-axis
        },
      },
      y: {
        grid: {
          borderDash: [5, 7], // Set dashed gridlines
          drawBorder: false, // Remove the border line
          color: "rgba(118, 118, 118, 0.3)", // Light gray gridline color
        },
        ticks: {
          callback: (value, index) => (index % 2 === 0 ? "" : value), // Skip every other label
        },
      },
    },
  };

  return (
    <div style={{ height: isMobile ? "150px" : "250px", width: "100%" }}>
      <Line
        {...otherProps}
        ref={ref}
        options={options}
        data={data}
      />
    </div>
  );
});

export default BasicChart;
