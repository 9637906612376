import React from "react";
import LoadingIconApp from "./LoadingIconApp";

const LoadingIconPage = ({ ...otherProps }) => {
  return (
    <div style={{ height: "100vh", display: "flex", justifyContent: "center" }}>
      <LoadingIconApp {...otherProps} />
    </div>
  );
};

export default LoadingIconPage;
