import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelectFormItem from "../../components/mySelectFormItem/MySelectFormItem";

const SelectSource = ({ ...otherProps }) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    let data = [
      { label: "OrderItem", value: "OrderItem" },
      { label: "Order", value: "Order" },
      { label: "Customer", value: "Customer" },
      { label: "Product", value: "Product" },
    ];
    setlist(data);
  }, [t]);

  return <MySelectFormItem {...otherProps} options={list} />;
};

export default SelectSource;
