import React from "react";
import { Helmet } from "react-helmet";

const MyHelmetOutside = ({ title }) => {
  return (
    <Helmet>
      <title>{`Ordersbook | ${title}`}</title>
      <meta
        property="og:description"
        content="Ordersbook is a business automatization app for managing of sales, supply and delivery processes."
      />
    </Helmet>
  );
};

export default MyHelmetOutside;
