import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelectFormItem from "../mySelectFormItem/MySelectFormItem";

const SelectProductQuantityType = ({ ...otherProps }) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    let data = [
      { label: t("WORD_IN"), value: 1 },
      { label: t("WORD_OUT"), value: 2 },
    ];
    setlist(data);
  }, [t]);

  return <MySelectFormItem {...otherProps} options={list} />;
};

export default SelectProductQuantityType;
