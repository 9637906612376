import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SelectOrderBranch from "../../components/selects/SelectOrderBranch";
import MySpace from "../../components/mySpace/MySpace";
import { useForm } from "antd/es/form/Form";
import UploadFilesManuelStart from "../../components/uploads/UploadFilesManuelStart";
import ApiStorage from "../../redux/ApiStorage";
import EditPageForm from "../../components/editPageForm/EditPageForm";
import "./Settings.scss";

const formName = "uploadXML";

const ImportXmlSettings = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const inputRef = useRef();

  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    inputRef.current.startUpload(`user/uploadfilexml/${values.idBranch}`);
  };

  return (
    <EditPageForm
      form={form}
      formName={formName}
      onFinish={onFinish}
      title={t("WORD_IMPORT_XML")}
      loading={loading}
      okLabel={t("WORD_UPLOAD")}
    >
      <div className="upload-select-row">
        <SelectOrderBranch
          name="idBranch"
          placeholder={t("WORD_SELECT_BRANCH")}
          required
          onLoad={(val) => {
            form.setFieldsValue({ idBranch: val });
          }}
        />
        <UploadFilesManuelStart
          ref={inputRef}
          className="file-xml-upload"
          name="userfile"
          fileTypes={[".xml"]}
          maxCount={1}
          required
          onLoadingChange={setLoading}
          onChange={(result) => {
            if (result.success) {
              ApiStorage.setStepDetailsDone({
                done_customers: 1,
                done_product: 1,
                done_managers: 1,
              });
              form.resetFields();
              setLoading(false)
            }
          }}
        />
      </div>
      <MySpace align="top" className="xml-row">
        <div>{t("WORD_DOWNLOAD_DESCRIPTION", { FILETYPE: "XML" })}</div>
        <div
          className="xml-download-btn"
          onClick={() => {
            const fileUrl =
              process.env.PUBLIC_URL + "/Templates/ordersbook.xml";
            fetch(fileUrl)
              .then((response) => response.blob())
              .then((blob) => {
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = "ordersbook.xml";
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url);
              })
              .catch((error) =>
                console.error("Error downloading file:", error)
              );
          }}
        >
          {t("WORD_DOWNLOAD")}
        </div>
      </MySpace>
    </EditPageForm>
  );
};
export default ImportXmlSettings;
