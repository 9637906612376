import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MySpace from "../../../components/mySpace/MySpace";
import MyCard from "../../../components/myCard/MyCard";
import MyDivider from "../../../components/myDivider/MyDivider";
import NewUserPage from "../../../components/newUserPage/NewUserPage";
import UserActions from "../../../actions/UserActions";
import UtilNotify from "../../../utils/UtilNotify";
import MyButtonSuccess from "../../../components/myButton/MyButtonSuccess";

const DeviceNewUserPage = ({ email }) => {
  const { t } = useTranslation();

  const [isDisabled, setIsDisabled] = useState(false);
  const [data] = useState([
    {
      label: t("WORD_DOWNLOAD_AND_SET_THE_APP"),
      btnLabel: t("WORD_DOWNLOAD"),
      complete: false,
      key: "1",
      labelSecond: t("WORD_SEND_APPLICATION_TO_EMAIL"),
      btnLabelSecond: t("WORD_SEND"),
      // pathSecond: `/device/list`,
      secondComplete: false,
      onClick: () => {
        window.open(
          "https://play.google.com/store/apps/details?id=com.araqichmobilemanager",
          "_blank",
          "noopener,noreferrer"
        );
      },
      hasbtn: true,
    },
    {
      label: t("WORD_LOGIN_FROM_YOUR_DEVICE"),
      btnLabel: t("WORD_SEND"),
      complete: false,
      key: "2",
    },
  ]);

  const handleEmailSend = async () => {
    setIsDisabled(true);
    if (email) {
      const result = await UserActions.sendEmailUser(email);
      if (result.success) {
        setTimeout(() => {
          setIsDisabled(false);
        }, 60000);
        UtilNotify.notifySuccess(t("WORD_EMAIL_SENT_SUCCESSFULY"));
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    }
  };

  return (
    <NewUserPage
      title={t("WORD_WELCOME_TO_ORDERSBOOK")}
      text={t("WORD_DEVICE_INTRO_TEXT")}
      noArrow
    >
      <MyCard className="first-step-card">
        <MySpace direction="vertical" fullWidth size="middle">
          {data.map((dt) => {
            return (
              <div key={dt.key}>
                <MySpace fullWidth spaceBetween align="top">
                  <div
                    style={{
                      textDecoration: dt.complete ? "line-through" : "unset",
                    }}
                    className="dashboard-first-step-row-label"
                  >
                    {dt.key + ". "}
                    {dt.label}
                    {dt.hasbtn ? ":" : null}
                  </div>
                  {dt.hasbtn ? (
                    <MyButtonSuccess
                      label={dt.btnLabel}
                      style={{ width: 120, borderRadius: 20 }}
                      onClick={dt.onClick}
                    />
                  ) : null}
                </MySpace>
                {dt.labelSecond ? (
                  <MySpace
                    fullWidth
                    spaceBetween
                    style={{ marginTop: 20 }}
                    align="top"
                  >
                    <div
                      style={{
                        textDecoration: dt.secondComplete
                          ? "line-through"
                          : "unset",
                      }}
                      className="dashboard-first-step-row-label"
                    >
                      {dt.labelSecond}:
                    </div>
                    <MyButtonSuccess
                      label={dt.btnLabelSecond}
                      disabled={isDisabled}
                      style={{ width: 120, borderRadius: 20 }}
                      onClick={handleEmailSend}
                    />
                  </MySpace>
                ) : null}
                <MyDivider />
              </div>
            );
          })}
        </MySpace>
      </MyCard>
    </NewUserPage>
  );
};

export default DeviceNewUserPage;
