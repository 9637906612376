import { fetchFromUrlPOSTAsync } from "../utils/UrlHelper";

// const URL_FILL_PAGINATION = "statistic/dashboard/stats";
const URL_DASHBOARD_STATS_SALES = "statistic/dashboard/stats/sales";
const URL_DASHBOARD_STATS_PRODUCTS = "statistic/dashboard/stats/products";
const URL_DASHBOARD_STATS_CUSTOMERS = "statistic/dashboard/stats/customers";

const fs = {
  // fillPagination: async (urlParams) => {
  //   const result = await fetchFromUrlPOSTAsync(URL_FILL_PAGINATION, urlParams);
  //   return result;
  // },
  dashboardStatsSales: async () => {
    const result = await fetchFromUrlPOSTAsync(URL_DASHBOARD_STATS_SALES, []);
    return result;
  },
  dashboardStatsProducts: async () => {
    const result = await fetchFromUrlPOSTAsync(URL_DASHBOARD_STATS_PRODUCTS, []);
    return result;
  },
  dashboardStatsCustomers: async () => {
    const result = await fetchFromUrlPOSTAsync(URL_DASHBOARD_STATS_CUSTOMERS, []);
    return result;
  },
};

const loc = {};

const StatActions = Object.assign(fs, loc);

export default StatActions;
