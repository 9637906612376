import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { Form, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import { InfoCircleOutlined } from "@ant-design/icons";

const InputNumberFormItem = forwardRef(
  (
    {
      classNameInput,
      controls = false,
      className = "",
      label,
      name,
      onEscape,
      placeholder,
      required = false,
      hidden,
      readOnly,
      onChange,
      onPressEnter,
      onBlur,
      hasFeedback = false,
      errorMsg,
      autoFocus = false,
      style,
      suffix,
      prefix,
      tooltipTitle,
    },
    ref
  ) => {
    const refComponent = useRef();

    const { t } = useTranslation();

    useImperativeHandle(ref, () => ({
      focus: () => {
        refComponent.current.focus();
        refComponent.current.select();
      },
    }));

    return (
      <Form.Item
        name={name}
        label={label}
        hidden={hidden}
        hasFeedback={hasFeedback}
        className={`app-input ${className}`}
        style={{ height: hidden ? 0 : undefined, ...style }}
        validateTrigger="onSubmit"
        rules={[
          {
            required: required && !hidden,
            message:
              errorMsg || `${label || placeholder} ${t("WORD_IS_REQUIRED")}`,
          },
        ]}
        tooltip={
          tooltipTitle
            ? {
                title: tooltipTitle,
                icon: <InfoCircleOutlined />,
              }
            : null
        }
      >
        <InputNumber
          ref={refComponent}
          className={`my-input input-number ${classNameInput || ""}`}
          placeholder={placeholder || label}
          autoFocus={autoFocus}
          readOnly={readOnly}
          autoComplete={"nope"}
          onChange={onChange}
          onPressEnter={onPressEnter}
          controls={controls}
          onBlur={onBlur}
          suffix={suffix}
          prefix={prefix}
          type="number"
          // formatter={formatValue}
          // parser={parseValue}
          onFocus={() => {
            refComponent?.current?.select();
          }}
          onKeyUp={(e) => {
            if (e.key === "Escape") {
              onEscape && onEscape();
            }
          }}
        />
      </Form.Item>
    );
  }
);

export default InputNumberFormItem;
