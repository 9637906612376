import React from "react";
import MySpace from "../../../components/mySpace/MySpace";
import MapSVG from "../../../icons/MapSVG";
// import MyLink from "../../../components/myLink/MyLink";
// import { useTranslation } from "react-i18next";

const OrderViewMapNotIncluded = ({ label, color }) => {
  // const { t } = useTranslation();

  return (
    <div
      className="gps-not-included-wrapper"
      style={{ backgroundColor: color }}
    >
      <MySpace>
        <MapSVG />
        <MySpace direction="vertical" style={{ rowGap: 5, columnGap: 5 }}>
          <span style={{ color: "#fff" }}>{label}</span>
          {/* <MyLink path="/settings/package_change" blank>
            {t("WORD_CHANGE_PACKAGE") + "."}
          </MyLink> */}
        </MySpace>
      </MySpace>
    </div>
  );
};

export default OrderViewMapNotIncluded;
