const intState = {
  lang: "en",
  sideMenuColapsed: false,
  currentUser: {},
  accessToken: "",
  deviceKey: "",
  selectedTheme: "system",
  orderPageFilters: {},
  paymentPageFilters: {},
  visitPageFilters: {},
  userPageFilters: {},
  devicePageFilters: {},
  branchPageFilters: {},
  debitPageFilters: {},
  userQuantitiesFilters: {},
  productQuantitiesFilters: {},
  customerStatementFilters: {},
  analyticsFilters: {},
  customerPageFilters: {},
  badgeCountOrder: 0,
  badgeCountPayment: 0,
  trialClose: false,
};

const AuthReducer = (state = intState, action) => {
  switch (action.type) {
    case "SET_LANG":
      return { ...state, lang: action.payload };
    case "SET_DEVICEKEY":
      return { ...state, deviceKey: action.payload };
    case "SET_CURRENT_USER":
      return { ...state, currentUser: action.payload };
    case "SET_ACCESS_TOKEN":
      return { ...state, accessToken: action.payload };
    case "SET_SIDE_MENU_OPENED":
      return { ...state, sideMenuColapsed: action.payload };
    case "SET_SELECTED_THEME":
      return { ...state, selectedTheme: action.payload };
    case "SET_ORDER_PAGE_FILTERS":
      return { ...state, orderPageFilters: action.payload };
    case "SET_PAYMENT_PAGE_FILTERS":
      return { ...state, paymentPageFilters: action.payload };
    case "SET_VISIT_PAGE_FILTERS":
      return { ...state, visitPageFilters: action.payload };
    case "SET_USER_PAGE_FILTERS":
      return { ...state, userPageFilters: action.payload };
    case "SET_DEVICE_PAGE_FILTERS":
      return { ...state, devicePageFilters: action.payload };
    case "SET_BRANCH_PAGE_FILTERS":
      return { ...state, branchPageFilters: action.payload };
    case "SET_DEBIT_PAGE_FILTERS":
      return { ...state, debitPageFilters: action.payload };
    case "SET_USER_QUANTITIES_FILTERS":
      return { ...state, userQuantitiesFilters: action.payload };
    case "SET_PRODUCT_QUANTITIES_FILTERS":
      return { ...state, productQuantitiesFilters: action.payload };
    case "SET_CUSTOMER_STATEMENT_FILTERS":
      return { ...state, customerStatementFilters: action.payload };
    case "SET_ANALYTICS_FILTERS":
      return { ...state, analyticsFilters: action.payload };
    case "SET_CUSTOMER_PAGE_FILTERS":
      return { ...state, customerPageFilters: action.payload };
    case "SET_BADGE_COUNT_ORDER":
      return { ...state, badgeCountOrder: action.payload };
    case "SET_BADGE_COUNT_PAYMENT":
      return { ...state, badgeCountPayment: action.payload };
    case "SET_TRIAL_CLOSE":
      return { ...state, trialClose: action.payload };
    default:
      return state;
  }
};

export default AuthReducer;
