import React, { memo } from "react";
import { Table } from "antd";
import MyPagination from "../myPagination/MyPagination";
import "./MyTable.scss";

const MyTable = ({
  onRow,
  className,
  total,
  onShowSizeChange,
  pageSize,
  onChangePage,
  page,
  title,
  onRowClick,
  onRowDoubleClick,
  summary,
  footer = false,
  noFooter,
  scroll,
  corners,
  rowHoverable = true,
  ...otherProps
}) => {
  return (
    <div className="table-wrapper">
      <Table
        {...otherProps}
        className={`app-table ${className || ""} ${
          noFooter && corners ? "app-table-no-footer" : "app-table-footer"
        }`}
        pagination={false}
        title={title || false}
        rowHoverable={rowHoverable}
        showSorterTooltip={false}
        footer={
          noFooter
            ? false
            : () => {
                return (
                  <>
                    {footer}
                    {!footer ? (
                      <MyPagination
                        {...otherProps}
                        total={total}
                        onShowSizeChange={onShowSizeChange}
                        pageSize={pageSize}
                        onChange={onChangePage}
                        current={page}
                        showQuickJumper={false}
                      />
                    ) : null}
                  </>
                );
              }
        }
        onRow={
          onRow ||
          ((record, rowIndex) => {
            return {
              onClick: onRowClick, // click row
              onDoubleClick: onRowDoubleClick, // double click row
            };
          })
        }
        summary={summary}
        scroll={scroll}
      />
    </div>
  );
};

export default memo(MyTable);
