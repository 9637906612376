import React from "react";
import MySpace from "../../components/mySpace/MySpace";
import OrdersActions from "../../actions/OrdersActions";
import UtilDate from "../../utils/UtilDate";
import { useTranslation } from "react-i18next";
import LocationSVG from "../../icons/GpsSVG";
import MyTooltip from "../../components/myTooltip/MyTooltip";

const DashboardActivityItem = ({ item, onClick, style }) => {
  const { t } = useTranslation();

  let statusLabel = OrdersActions.getOrderTypeLabel(item.type);
  let type = OrdersActions.getDistanceStatusLabel(item.distanceStatus);

  return (
    <div
      onClick={onClick}
      style={{ cursor: "pointer", width: "100%", ...style }}
      className="dashboard-activity-card-row"
    >
      <MySpace fullWidth spaceBetween align="top">
        <MySpace>
          <MyTooltip title={t(type.label)} placement="rightBottom">
            <LocationSVG fill={type.color} size={22} />
          </MyTooltip>
          <MySpace direction="vertical" style={{ columnGap: 0, rowGap: 0 }}>
            <MySpace align="top">
              <div className="customer-row">{t(statusLabel.label)}:</div>
              <div className="customer-row">{item?.nameCustomer}</div>
            </MySpace>
            <MySpace>
              <div className="row-font-size-small">
                | {t("WORD_DISTRIBUTOR")}:
              </div>
              <div className="row-font-size-small">{item?.nameManager}</div>
            </MySpace>
          </MySpace>
        </MySpace>
        <div className="customer-date-row">
          {UtilDate.formatAsDaysAgo(item?.createdAt)}
        </div>
      </MySpace>
    </div>
  );
};

export default DashboardActivityItem;
