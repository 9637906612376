import React from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import PhoneInput from "antd-phone-input";

const InputPhoneNumFormItem = ({
  className = "",
  label,
  name,
  placeholder,
  required = false,
  hidden = false,
  hasFeedback = false,
  country,
}) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      name={name}
      label={label}
      hidden={hidden}
      hasFeedback={hasFeedback}
      className={`app-input ${className || ""}`}
      validateTrigger="onSubmit"
      rules={[
        {
          required: required && !hidden,
          message: `${label || placeholder} ${t("WORD_IS_REQUIRED")}`,
        },
      ]}
      normalize={(value) => {
        return {
          ...value,
          countryCode: `+${value?.countryCode}`,
          fullNumber: `+${value.countryCode}${value.areaCode || ""}${
            value.phoneNumber
          }`,
          phoneNumber: `${value.areaCode || ""}${value.phoneNumber}`,
        };
      }}
    >
      <PhoneInput distinct country={country} excludeCountries={["az"]} />
    </Form.Item>
  );
};

export default InputPhoneNumFormItem;
