import React, { useState } from "react";
import ArrowSVG from "../../icons/ArrowSVG";
import { Dropdown, Space } from "antd";

const MyDropDown = ({
  items = [],
  children,
  arrow = true,
  width,
  placement = "bottomLeft",
  className,
}) => {
  const [isOpen, setisOpen] = useState(false);

  return (
    <Dropdown
      menu={{ items }}
      trigger={["click"]}
      dropdownRender={(menu) => (
        <div style={{ width: width }} className="my-dropdown-menu">
          {menu}
        </div>
      )}
      onOpenChange={setisOpen}
      placement={placement}
      arrow={false}
      className={className || "link-cursor"}
    >
      <Space>
        {children}
        {arrow === true ? (
          <div>
            <div className="arrow-bg" style={{ marginBottom: 4 }}>
              <ArrowSVG rotate={isOpen} color="#fff" />
            </div>
          </div>
        ) : null}
      </Space>
    </Dropdown>
  );
};

export default MyDropDown;
