import React, { memo } from "react";
import MyIcon from "./MyIcon";

export const SVGIcon = ({ size, fill }) => {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M7.2959 6.446C7.2959 3.995 9.35569 2 11.8873 2H16.9196C19.4451 2 21.4997 3.99 21.4997 6.436V17.552C21.4997 20.004 19.4409 22 16.9093 22H11.877C9.35156 22 7.2959 20.009 7.2959 17.562V16.622V6.446Z"
        fill={fill || "white"}
      />
      <path
        d="M16.0374 11.4541L13.0695 8.54506C12.7627 8.24506 12.2691 8.24506 11.9634 8.54706C11.6587 8.84906 11.6597 9.33606 11.9654 9.63606L13.5905 11.2291H3.2821C2.85042 11.2291 2.5 11.5741 2.5 12.0001C2.5 12.4251 2.85042 12.7691 3.2821 12.7691H13.5905L11.9654 14.3631C11.6597 14.6631 11.6587 15.1501 11.9634 15.4521C12.1168 15.6031 12.3168 15.6791 12.518 15.6791C12.717 15.6791 12.9171 15.6031 13.0695 15.4541L16.0374 12.5451C16.1847 12.4001 16.268 12.2041 16.268 12.0001C16.268 11.7951 16.1847 11.5991 16.0374 11.4541"
        fill={fill || "white"}
      />
    </svg>
  );
};

const VisitSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(VisitSVG);
