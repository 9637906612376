import {
  fetchFromUrlPOSTAsync,
  fetchFromUrlPOSTAsyncDownloadFile,
} from "../utils/UrlHelper";

const URL_GET_ROUTES = "routes/list";
const URL_GET_ONE_ROUTE = "routes/getOne";
const URL_GET_CUSTOMERS = "routes/getCustomers";
const URL_FILL_CAN_ADD = "routes/getCustomers/toAdd";
const URL_REMOVE_CUSTOMER = "routes/removeCustomers";
const URL_ADD_CUSTOMER = "routes/addCustomer";
const URL_REMOVE_ROUTE = "routes/delete";
const URL_CREATE_ROUTE = "routes/create";
const URL_UPDATE_ROUTE = "routes/update";
const URL_EXPORT_EXCEL = "routes/exportExcel";
const URL_CLONE = "routes/clone";

const fs = {
  getCustomers: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_GET_CUSTOMERS, urlParams);
    return result;
  },
  getRoutes: async (idManager) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_GET_ROUTES + "/" + idManager
    );
    return result;
  },
  getOneRoute: async (idRoute) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_GET_ONE_ROUTE + "/" + idRoute
    );
    return result;
  },
  // removeCustomer: async (idRouteCustomer) => {
  //   const result = await fetchFromUrlPOSTAsync(
  //     URL_REMOVE_CUSTOMER + "/" + idRouteCustomer
  //   );
  //   return result;
  // },
  removeCustomers: async (_idRoute, ids) => {
    const urlParams = [
      {
        key: "ids",
        value: ids,
      },
    ];
    const result = await fetchFromUrlPOSTAsync(URL_REMOVE_CUSTOMER + "/" + _idRoute, urlParams);
    return result;
  },
  addCustomers: async (idRoute, ids) => {
    const urlParams = [
      {
        key: "ids",
        value: ids,
      },
    ];
    const result = await fetchFromUrlPOSTAsync(URL_ADD_CUSTOMER + "/" + idRoute, urlParams);
    return result;
  },
  removeRoute: async (idRoute) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_REMOVE_ROUTE + "/" + idRoute
    );
    return result;
  },
  createRoute: async (values) => {
    let result = fetchFromUrlPOSTAsync(URL_CREATE_ROUTE, {
      saveData: values,
    });
    return result;
  },
  updateRoute: async (values, idRoute) => {
    let result = await fetchFromUrlPOSTAsync(URL_UPDATE_ROUTE + "/" + idRoute, {
      saveData: values,
    });
    return result;
  },
  exportExcel: async (idRoute) => {
    const result = await fetchFromUrlPOSTAsyncDownloadFile(
      URL_EXPORT_EXCEL + "/" + idRoute,
      [],
      "group.xlsx"
    );
    return result;
  },
  clone: async (values) => {
    let result = await fetchFromUrlPOSTAsync(URL_CLONE + "/" + values.idRoute, {
      saveData: values,
    });
    return result;
  },
  getListCustomerCanAdd: async (idManager, fetchParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_FILL_CAN_ADD + "/" + idManager, fetchParams);
    return result;
  },
};

const loc = {};

const RouteActions = Object.assign(fs, loc);

export default RouteActions;
