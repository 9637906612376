import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const useNavigateApi = () => {
  const navigate = useNavigate();
  const lang = useSelector((state) => state.lang);

  return useMemo(
    () => (path, options) => {
      if (path === -1 || path === "-1") {
        navigate(path, options);
      } else {
        const pathWithLang = `/${lang}${
          path.startsWith("/") ? "" : "/"
        }${path}`;
        navigate(pathWithLang, options);
      }
    },
    [lang, navigate]
  );
};

export default useNavigateApi;
