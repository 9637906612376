import React, { memo } from "react";
import MyIcon from "./MyIcon";

const SVGIcon = ({ size }) => {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.538 1.5H5.469C3.096 1.5 1.5 3.166 1.5 5.644V11.363C1.5 13.834 3.096 15.5 5.469 15.5H11.538C13.911 15.5 15.5 13.834 15.5 11.363V5.644C15.5 3.166 13.911 1.5 11.538 1.5"
        fill="white"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 17V18.0675C7.5 20.715 9.21 22.5 11.7525 22.5H18.255C20.7975 22.5 22.5 20.715 22.5 18.0675V11.94C22.5 9.285 20.7975 7.5 18.255 7.5H17V12C17 14.7614 14.7614 17 12 17H7.5Z"
        fill="white"
      />
    </svg>
  );
};

const CloneGroupSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(CloneGroupSVG);
