import React from "react";
import { Popconfirm } from "antd";
import { useTranslation } from "react-i18next";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import MyButtonGraySmall from "./MyButtonGraySmall";
import "./MyButton.scss";

const MyButtonTableActionConfirm = ({
  disabled,
  cancelText,
  icon,
  label,
  onCancel,
  onConfirm,
  title,
  okText,
  loading,
  onClick,
  success,
  ...otherProps
}) => {
  const { t } = useTranslation();

  const handleCancel = () => {
    onCancel && onCancel();
  };

  return (
    <Popconfirm
      title={title || t("WORD_ARE_YOU_SURE")}
      onConfirm={onConfirm}
      onCancel={handleCancel}
      okText={okText || t("WORD_YES")}
      cancelText={cancelText || t("WORD_NO")}
      disabled={disabled}
      cancelButtonProps={{
        ghost: true,
      }}
      icon={<ExclamationCircleOutlined />}
    >
      <div>
        <MyButtonGraySmall
          {...otherProps}
          loading={loading}
          label={label}
          icon={icon}
          onClick={onClick}
          success={success}
        />
      </div>
    </Popconfirm>
  );
};

export default MyButtonTableActionConfirm;
