import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelectFormItem from "../mySelectFormItem/MySelectFormItem";
import UtilNotify from "../../utils/UtilNotify";
import CustomerActions from "../../actions/CustomerActions";

const SelectCustomer = ({ withAll, ...otherProps }) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fill = async () => {
      const fetchParams = {
        page: 0,
        perPage: 10000,
        search: search,
        filter: {},
      };
      const result = await CustomerActions.fillPagination(fetchParams);
      if (result.success) {
        let data = result.data.map((it) => ({
          label: it.name,
          value: it.idCustomer,
          ...it,
        }));
        if (withAll) data.unshift({ label: t("WORD_ALL"), value: "" });
        setlist(data);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    };
    fill();
  }, [t, withAll, search]);

  return (
    <MySelectFormItem
      showSearch
      options={list}
      // className={hasValue ? "bg-primary-color" : "bg-normal-color"}
      onSearch={setSearch}
      {...otherProps}
    />
  );
};

export default SelectCustomer;
