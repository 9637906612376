import { fetchFromUrlPOSTAsync } from "../utils/UrlHelper";
import UtilIPInfo from "../utils/UtilIPInfo";

const URL_LIST = "service/list";
const URL_GET_CLIENT_SERVICE = "service/getClientService";

const fs = {
  fillList: async () => {
    const res = await UtilIPInfo.getInfo();
    const result = await fetchFromUrlPOSTAsync(URL_LIST, {
      countryCode: res?.countryCode || 'AM',
    });
    return result;
  },
  getClientService: async () => {
    const result = await fetchFromUrlPOSTAsync(URL_GET_CLIENT_SERVICE, []);
    return result;
  },
};

const loc = {};

const ServiceActions = Object.assign(fs, loc);

export default ServiceActions;
