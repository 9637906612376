import React from "react";
import { Form, Select } from "antd";
import { useTranslation } from "react-i18next";
import { InfoCircleOutlined } from "@ant-design/icons";

const MySelectFormItem = ({
  name,
  label,
  options,
  showSearch,
  onSearch,
  loading,
  hidden,
  disabled,
  required,
  placeholder,
  onChange,
  className,
  allowClear,
  layout = "vertical",
  style,
  width,
  onPopupScroll,
  onChangeItem,
  tooltipTitle,
  variant,
  errorMessage,
  labelRender,
  ...otherProps
}) => {
  const { t } = useTranslation();

  const errorMessageLabel =
    errorMessage || `${label || placeholder} ${t("WORD_IS_REQUIRED")}`;

  return (
    <Form.Item
      name={name}
      label={label}
      hasFeedback={false}
      layout={layout}
      hidden={hidden}
      className={`app-select ${className || ""}`}
      rules={[
        { required: required && !hidden, message: errorMessageLabel },
        ({ getFieldValue }) => ({
          validator(rule, value) {
            if (!value) {
              return Promise.resolve();
            }
            if (getFieldValue(name) !== "") {
              return Promise.resolve();
            }
            return Promise.reject(errorMessage);
          },
        }),
      ]}
      tooltip={
        tooltipTitle
          ? {
            title: tooltipTitle,
            icon: <InfoCircleOutlined />,
          }
          : null
      }
    >
      <Select
        {...otherProps}
        showSearch={showSearch}
        onPopupScroll={onPopupScroll}
        optionFilterProp="children"
        onSearch={onSearch}
        loading={loading}
        variant={variant}
        onChange={(oo, ooo) => {
          onChange && onChange(oo, ooo);
          onChangeItem && onChangeItem(ooo);
        }}
        disabled={disabled}
        onClear={onSearch}
        allowClear={!required}
        style={{ width: width, ...style }}
        placeholder={placeholder || label}
        labelRender={labelRender}
      >
        {options &&
          options.map((oo, index) => {
            return (
              <Select.Option
                key={index}
                value={oo.value}
                label={oo.label}
                {...oo}
              >
                {oo.label}
              </Select.Option>
            );
          })}
      </Select>
    </Form.Item>
  );
};

export default MySelectFormItem;
