import React, { useEffect } from "react";
import { Layout } from "antd";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import OutsideLayoutHeader from "./OutsideLayoutHeader";
import outsideLayoutRightSideImg from "../../icons/login-hero.webp";
import useWindowSize from "../../utils/useWindowSize";
import "../mainLayout/HeaderNav.scss";

const { Content } = Layout;

const OutsideLayout = ({ children }) => {
  const { t, i18n } = useTranslation();
  const { isMobile } = useWindowSize();
  const { lang } = useParams();

  const langRedux = useSelector((state) => {
    return state.lang;
  });

  useEffect(() => {
    if (lang !== langRedux) {
      i18n.changeLanguage(lang);
    }
  }, [lang, langRedux, i18n]);

  return (
    <Layout
      style={{
        height: isMobile ? "90vh" : "100vh",
        width: "100vw",
        overflow: "hidden",
        backgroundColor: "#fff",
      }}
    >
      <Content
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <div style={{ width: isMobile ? "100%" : "50%", padding: 30 }}>
          <OutsideLayoutHeader />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90%",
            }}
          >
            {children}
          </div>
        </div>
        {isMobile ? null : (
          <div
            style={{
              width: "50%",
              height: "100vh",
              display: "flex",
              backgroundColor: "#8A43FF",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              color: "#fff",
            }}
          >
            <img
              src={outsideLayoutRightSideImg}
              alt="outside-layout-right-image"
              style={{ objectFit: "contain", height: "50%", width: "100%" }}
            />
            <h2 style={{ fontSize: 30, marginBottom: 10 }}>
              {t("WORD_INTRODUCING_ORDERS_ANALYTICS")}
            </h2>
            <h3 style={{ fontSize: 14, maxWidth: 500, textAlign: "center" }}>
              {t("WORD_INTRODUCING_ORDERS_ANALYTICS_SUBTITLE")}
            </h3>
          </div>
        )}
      </Content>
    </Layout>
  );
};

export default OutsideLayout;
