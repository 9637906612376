import React, { memo } from "react";
import MyIcon from "./MyIcon";

export const SVGIcon = ({ style, size }) => {
  return (
    <svg
      width={size || 23}
      height={size || 23}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M2.96381 9.01232H20.0451"
        stroke="#FFA41D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M15.757 12.7551H15.7658"
        stroke="#FFA41D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M11.5044 12.7551H11.5133"
        stroke="#FFA41D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M7.24299 12.7551H7.25187"
        stroke="#FFA41D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M15.757 16.4797H15.7658"
        stroke="#FFA41D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M11.5044 16.4797H11.5133"
        stroke="#FFA41D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M7.24299 16.4797H7.25187"
        stroke="#FFA41D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M15.3752 1.91663V5.07029"
        stroke="#FFA41D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M7.6336 1.91663V5.07029"
        stroke="#FFA41D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5617 3.43005H7.44717C4.63285 3.43005 2.875 4.99782 2.875 7.87962V16.5522C2.875 19.4793 4.63285 21.0833 7.44717 21.0833H15.5528C18.376 21.0833 20.125 19.5065 20.125 16.6247V7.87962C20.1338 4.99782 18.3849 3.43005 15.5617 3.43005Z"
        stroke="#FFA41D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
};

const CalenderOrangeSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(CalenderOrangeSVG);
