import { fetchFromUrlPOSTAsync } from "../utils/UrlHelper";

const URL_LIST = "gpsLocation/list";

const fs = {
  fillList: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_LIST, urlParams);
    return result;
  },
};

const loc = {};

const GPSActions = Object.assign(fs, loc);

export default GPSActions;
