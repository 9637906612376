import React, { forwardRef } from "react";
import MySpace from "../../../components/mySpace/MySpace";
import { useTranslation } from "react-i18next";
import MyDivider from "../../../components/myDivider/MyDivider";
import UtilNumber from "../../../utils/UtilNumber";
import UtilDate from "../../../utils/UtilDate";
import OrdersActions from "../../../actions/OrdersActions";
import MyTextTitle from "../../../components/myText/MyTextTitle";
import { useFormatCurrency } from "../../../utils/useFormatWithCurrency";
import "./OrderPrint.scss";

const OrderViewPrintThermal = forwardRef(({ orderData }, ref) => {
  const { t } = useTranslation();
  const { formatDoubleWithCurrency } = useFormatCurrency();

  let status = OrdersActions.getOrderStatusLabel(orderData?.status);
  let type = OrdersActions.getOrderTypeLabel(orderData?.type);
  // let exported = OrdersActions.getPaymentExportLabel(orderData.exportedAdmin);

  return (
    <div style={{ padding: 10 }} className="thermal-print-page">
      <MySpace direction="vertical" fullWidth>
        <MyTextTitle bold>
          {orderData?.type === 3 ? t("WORD_PAYMENT_ID") : t("WORD_ORDER_ID")}: #
          {orderData?.idOrder}
        </MyTextTitle>
        <MyDivider smaller />
        <MySpace direction="vertical" fullWidth>
          <table className="table-wrapper-printer half">
            <tbody>
              <tr>
                <td>
                  <b>{t("WORD_CUSTOMER")}:</b>
                </td>
                <td>{orderData?.nameCustomer}</td>
              </tr>
              <tr>
                <td>
                  <b>{t("WORD_DISTRIBUTOR")}:</b>
                </td>
                <td>{orderData?.nameManager}</td>
              </tr>
              <tr>
                <td>
                  <b>{t("WORD_BRANCH")}:</b>
                </td>
                <td>{orderData?.nameBranch}</td>
              </tr>
            </tbody>
          </table>
          <MyDivider smaller />
          <table className="table-wrapper-printer half">
            <tbody>
              <tr>
                <td>
                  <strong>{t("WORD_TYPE")}:</strong>
                </td>
                <td>{t(type.label)}</td>
              </tr>
              <tr>
                <td>
                  <strong>{t("WORD_STATUS")}:</strong>
                </td>
                <td>{t(status.label)}</td>
              </tr>
              <tr>
                <td>
                  <strong>{t("WORD_CREATE_DATE")}:</strong>
                </td>
                <td>{UtilDate.formatDateTime(orderData?.createdAt)}</td>
              </tr>
              <tr>
                <td>
                  <strong>{t("WORD_DELIVERY_DATE")}:</strong>
                </td>
                <td>{UtilDate.formatDate(orderData?.dueDate)}</td>
              </tr>
              <tr>
                <td>
                  <strong>{t("WORD_PAYMENT_TYPE")}:</strong>
                </td>
                <td>{orderData?.namePaymenttype}</td>
              </tr>
              <tr>
                <td>
                  <strong>{t("WORD_NOTE")}:</strong>
                </td>
                <td>{orderData?.note}</td>
              </tr>
            </tbody>
          </table>
          <MyDivider smaller />
          <table className="table-wrapper-printer table-wrapper-printer-right">
            {orderData?.orderItems?.map((it, index) => {
              return (
                <tbody key={index}>
                  <tr>
                    <td colSpan="4">
                      <strong>{it.nameProduct}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>{it.quantity}</td>
                    <td>x</td>
                    <td>{formatDoubleWithCurrency(it.price)}</td>
                    <td>{formatDoubleWithCurrency(it.totalPrice)}</td>
                  </tr>
                </tbody>
              );
            })}
            <tbody className="total">
              <tr>
                <td colSpan="2">
                  <strong>{t("WORD_TOTAL")}</strong>
                </td>
                <td colSpan="1">
                  <strong>
                    {formatDoubleWithCurrency(orderData?.totalSub)}
                  </strong>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <strong>{t("WORD_DISCOUNT")}</strong>
                </td>
                <td colSpan="1">
                  <strong>
                    {`${UtilNumber.formatDouble(orderData?.discountPer)}%`}
                  </strong>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <strong>{t("WORD_TOTAL_AMOUNT")}</strong>
                </td>
                <td colSpan="1">
                  <strong>
                    {formatDoubleWithCurrency(orderData?.totalAmount)}
                  </strong>
                </td>
              </tr>
            </tbody>
          </table>
          <MyDivider smaller />
        </MySpace>
      </MySpace>
    </div>
  );
});

export default OrderViewPrintThermal;
