import React, { useEffect, useState } from "react";
import { Upload, message, Avatar, Form } from "antd";
import ImgCrop from "antd-img-crop";
import { getBaseURL, getBaseURLShowImage } from "../../../utils/UrlHelper";
import ApiStorage from "../../../redux/ApiStorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import LoadingIconApp from "../../loadingIconApp/LoadingIconApp";
import noIconImage from "../../../icons/noimage.jpeg";
import { useTranslation } from "react-i18next";
import "./IconAvatarForm.scss";

const URL_UPLOAD_IMAGE = "file/upload/image";

function beforeUpload(file) {
  const isJPG = true;
  // const isJPG = file.type === 'image/*';
  if (!isJPG) {
    message.error("You can only upload JPG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image file size must be less than 2MB!");
  }
  return isJPG && isLt2M;
}

const errorMessage = "Error Image";

const IconAvatarForm = ({
  onChange,
  label,
  name,
  required,
  placeholder,
  icon,
  image,
  hidden,
  style,
  onClear,
}) => {
  const { t } = useTranslation();

  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);

  useEffect(() => {
    setImageUrl(icon || image);
  }, [icon, image]);

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      if (info.file.response) {
        setImageUrl(info.file.response.data.path);
        onChange && onChange(info.file.response.data.path);
      }
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
    setLoading(false);
  };

  const handleClear = () => {
    setImageUrl("");
    onClear && onClear();
  };

  return (
    <Form.Item
      label={label}
      name={name}
      hidden={hidden}
      rules={[
        {
          required: required && !hidden,
          message:
            errorMessage || `${label || placeholder} ${t("WORD_IS_REQUIRED")}`,
        },
      ]}
      onMouseEnter={() => setShowDeleteIcon(true)}
      onMouseLeave={() => setShowDeleteIcon(false)}
      extra="2 MB / Images"
      className="icon-avatar-form-item"
    >
      <div>
        <ImgCrop rotationSlider>
          <Upload
            className="avatar-uploader"
            name="image"
            multiple={false}
            showUploadList={false}
            action={getBaseURL() + URL_UPLOAD_IMAGE}
            headers={{
              authorization: `Bearer ${ApiStorage.getAccessToken()}`,
            }}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            maxCount={1}
          >
            <Avatar
              src={getBaseURLShowImage(imageUrl)}
              size={150}
              // shape={icon ? "circle" : "square"}
              shape="square"
              style={{ cursor: "pointer" }}
              icon={
                loading ? (
                  <LoadingIconApp />
                ) : (
                  <div className="image-container">
                    <img src={noIconImage} alt="noimage" />
                  </div>
                )
              }
            />
          </Upload>
        </ImgCrop>
        {showDeleteIcon && imageUrl && (
          <div
            onClick={handleClear}
            style={{
              position: "absolute",
              top: 5,
              left: 130,
              cursor: "pointer",
            }}
          >
            <FontAwesomeIcon icon={faXmark} color="#D3D3D3" size="sm" />
          </div>
        )}
      </div>
    </Form.Item>
  );
};

export default IconAvatarForm;
