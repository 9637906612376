import React, { memo } from "react";
import MyIcon from "./MyIcon";

export const SVGIcon = ({ style, size }) => {
  return (
    <svg
      width={size || 48}
      height={size || 48}
      viewBox="0 0 78 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ style }}
    >
      <g opacity="0.2">
        <circle cx="39" cy="39" r="39" fill="#F4C563"></circle>
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.8064 55.6644H32.1931C26.2964 55.6644 22.3331 51.8711 22.3331 46.2227V31.7744C22.3331 26.1261 26.2964 22.3311 32.1931 22.3311H45.8064C51.7047 22.3311 55.6664 26.1244 55.6664 31.7727V46.2227C55.6664 51.8711 51.7047 55.6644 45.8064 55.6644ZM37.6851 31.8018C37.9791 32.3898 37.7407 33.1049 37.1527 33.399L35.758 34.0963H40.8924C40.9177 34.0963 40.9428 34.0971 40.9676 34.0986H42.36C45.7649 34.0986 48.525 36.8588 48.525 40.2636C48.525 43.6684 45.7648 46.4286 42.36 46.4285L30.6679 46.4285C30.0104 46.4285 29.4774 45.8955 29.4774 45.238C29.4774 44.5805 30.0104 44.0475 30.6679 44.0475L42.36 44.0476C44.4499 44.0476 46.144 42.3534 46.144 40.2636C46.144 38.1737 44.4499 36.4796 42.36 36.4796H39.0437C39.0184 36.4796 38.9933 36.4788 38.9685 36.4773H35.6637L37.1527 37.2217C37.7407 37.5158 37.9791 38.2309 37.6851 38.8189C37.391 39.407 36.676 39.6454 36.0879 39.3513L30.1355 36.3751C29.7322 36.1735 29.4774 35.7613 29.4774 35.3103C29.4774 34.8594 29.7322 34.4472 30.1355 34.2456L36.0879 31.2694C36.676 30.9753 37.391 31.2137 37.6851 31.8018Z"
        fill="#F4C563"
      ></path>
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.1582 33.404C37.7463 33.1099 37.9846 32.3948 37.6906 31.8068C37.3966 31.2187 36.6815 30.9803 36.0934 31.2744L30.141 34.2506C29.7377 34.4522 29.483 34.8644 29.483 35.3154C29.483 35.7663 29.7377 36.1785 30.141 36.3802L36.0934 39.3564C36.6815 39.6504 37.3966 39.412 37.6906 38.824C37.9846 38.2359 37.7463 37.5208 37.1582 37.2268L35.6681 36.4817H38.9787C39.002 36.4831 39.0255 36.4837 39.0492 36.4837H42.3655C44.4554 36.4837 46.1495 38.1779 46.1495 40.2677C46.1495 42.3576 44.4554 44.0517 42.3655 44.0517L30.6734 44.0517C30.0159 44.0517 29.4829 44.5847 29.4829 45.2422C29.4829 45.8996 30.0159 46.4326 30.6734 46.4326L42.3655 46.4327C45.7703 46.4327 48.5305 43.6726 48.5305 40.2677C48.5305 36.8629 45.7704 34.1028 42.3655 34.1028H40.9699C40.9465 34.1014 40.923 34.1007 40.8993 34.1007L35.7647 34.1007L37.1582 33.404Z"
        fill="#F4C563"
      ></path>
    </svg>
  );
};

const RefundsYellowCircleSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(RefundsYellowCircleSVG);
