import ApiStorage from "../redux/ApiStorage";
import {
  fetchFromUrlPOSTAsync,
  fetchFromUrlPOSTAsyncDownloadFile,
} from "../utils/UrlHelper";

const URL_FILL_PAGINATION = "customers/list";
const URL_FILL_PAGINATION_DEBIT = "customers/debit/list";
const URL_GET_ONE = "customers/getOne";
const URL_EXPORT_EXCEL = "customers/debit/exportExcel";
const URL_STATEMENT = "customers/statment/list";
const URL_UPDATE_CUSTOMER = "customers/update";
const URL_CREATE_CUSTOMER = "customers/create";
const URL_DELETE_CUSTOMER = "customers/delete";

const fs = {
  fillPagination: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_FILL_PAGINATION, urlParams);
    return result;
  },
  fillPaginationDebit: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_FILL_PAGINATION_DEBIT,
      urlParams
    );
    return result;
  },
  getOne: async (idCustomer) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_GET_ONE + "/" + idCustomer,
      []
    );
    return result;
  },
  exportExcel: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsyncDownloadFile(
      URL_EXPORT_EXCEL,
      urlParams,
      "debts.xlsx"
    );
    return result;
  },
  getStatement: async (idCustomer, urlParams) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_STATEMENT + "/" + idCustomer,
      urlParams
    );
    return result;
  },
  saveCustomer: async (idCustomer, values) => {
    const urlParams = {
      saveData: values,
    };
    let result;
    if (idCustomer) {
      result = await fetchFromUrlPOSTAsync(
        URL_UPDATE_CUSTOMER + "/" + idCustomer,
        urlParams
      );
    } else {
      result = await fetchFromUrlPOSTAsync(URL_CREATE_CUSTOMER, urlParams);
      if (result.success) {
        ApiStorage.setStepDetailsDone({ done_customers: 1 });
        // const currentUser = ApiStorage.getCurrentUser();
        // if (!currentUser?.stepsDone) {
        //   ApiStorage.setCurrentUser(
        //     Object.assign({}, currentUser, {
        //       stepsDoneDetails: {
        //         ...currentUser?.stepsDoneDetails,
        //         done_customers: 1,
        //       },
        //     })
        //   );
        // }
      }
    }
    return result;
  },
  deleteCustomer: async (idCustomer) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_DELETE_CUSTOMER + "/" + idCustomer
    );
    return result;
  },
};

const loc = {
  ADDRESS_TYPE: {
    DELIVERY: 2,
    BUSSINESS: 1,
  },
  PHONE_TYPE: {
    MOBILE: 1,
    PHONE: 2,
  },
};

const CustomerActions = Object.assign(fs, loc);

export default CustomerActions;
