import React, { memo } from "react";
import MyIcon from "./MyIcon";

export const SVGIcon = ({ style, size }) => {
  return (
    <svg
      width={size || 20}
      height={size || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ style }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.084 20H5.916C2.378 20 0 17.724 0 14.335V5.666C0 2.277 2.378 0 5.916 0H14.084C17.623 0 20 2.276 20 5.665V14.335C20 17.724 17.623 20 14.084 20ZM9.21033 5.6796C9.38675 6.03244 9.24374 6.4615 8.89089 6.63792L8.05408 7.05633H11.1347C11.1499 7.05633 11.1649 7.0568 11.1799 7.05773H12.0153C14.0582 7.05773 15.7143 8.71382 15.7143 10.7567C15.7143 12.7996 14.0582 14.4557 12.0153 14.4557L5 14.4556C4.60551 14.4556 4.28571 14.1358 4.28571 13.7413C4.28571 13.3469 4.60551 13.0271 5 13.0271L12.0153 13.0271C13.2692 13.0271 14.2857 12.0106 14.2857 10.7567C14.2857 9.50279 13.2692 8.4863 12.0153 8.4863H10.0255C10.0103 8.4863 9.99529 8.48583 9.98036 8.4849H7.9975L8.89089 8.93159C9.24373 9.10801 9.38675 9.53707 9.21033 9.88991C9.03391 10.2428 8.60486 10.3858 8.25202 10.2093L4.68059 8.42363C4.4386 8.30264 4.28574 8.05531 4.28574 7.78475C4.28574 7.5142 4.4386 7.26687 4.68059 7.14588L8.25202 5.36016C8.60486 5.18374 9.03391 5.32676 9.21033 5.6796Z"
        fill="#767776"
      />
    </svg>
  );
};

const RefundsSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(RefundsSVG);
