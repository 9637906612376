import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelect from "../mySelect/MySelect";

const SelectTableAction = ({ ...otherProps }) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    let data = [
      { label: t("WORD_SELECT_ACTION"), value: "select" },
      { label: t("WORD_REMOVE"), value: "remove" },
    ];
    setlist(data);
  }, [t]);

  return <MySelect {...otherProps} options={list} defaultValue={"select"} />;
};

export default SelectTableAction;
