import React, { useLayoutEffect, useState } from "react";
import { Upload, message, Form } from "antd";
import { getBaseURL, getBaseURLShowImage } from "../../../utils/UrlHelper";
import ApiStorage from "../../../redux/ApiStorage";
import { useTranslation } from "react-i18next";
import "./IconAvatarForm.scss";

const URL_UPLOAD_IMAGE = "file/upload/image";

function beforeUpload(file) {
  const isJPG = true;
  // const isJPG = file.type === 'image/*';
  if (!isJPG) {
    message.error("You can only upload JPG file!");
    return false;
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image file size must be less than 2MB!");
    return false;
  }
  return isJPG && isLt2M;
}

const errorMessage = "Error Image";

const IconAvatarFormMultipleOlderStyle = ({
  onChange,
  label,
  name,
  required,
  placeholder,
  image,
  hidden,
  maxCount,
  onClear,
}) => {
  const { t } = useTranslation();

  // const [imageUrls, setImageUrls] = useState([]);
  const [fileList, setfileList] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [showDeleteIcon, setShowDeleteIcon] = useState(false);

  // useEffect(() => {
  //   setImageUrls(image || [])
  // }, [image])
  useLayoutEffect(() => {
    if (image) {
      setfileList(
        image?.map((im, index) => {
          const oo = {
            uid: "-" + index,
            name: `image_${index}.png`,
            status: "done",
            url: getBaseURLShowImage(im),
            path: im,
          };
          return oo;
        })
      );
    }
  }, [image]);

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      // setLoading(true);
      setfileList(info.fileList);
      return;
    }
    if (info.file.status === "done") {
      if (info.file.response) {
        const newImageUrl = info.file.response.data.path;
        const list = [...image, newImageUrl];
        // const list = [...imageUrls, newImageUrl]
        // setImageUrls(newImageUrl);
        // onChange && onChange(newImageUrl);
        onChange && onChange(list);
      }
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
      setfileList(info.fileList.filter((f) => f.uid !== info.file.uid));
    }
    // setLoading(false);
  };

  return (
    <Form.Item
      label={label}
      name={name}
      hidden={hidden}
      rules={[
        {
          required: required && !hidden,
          message:
            errorMessage || `${label || placeholder} ${t("WORD_IS_REQUIRED")}`,
        },
      ]}
      // extra="2 MB / Images"
      className="icon-avatar-form-item"
    >
      <div>
        <div
          style={{
            marginTop: 10,
            marginBottom: 15,
            fontWeight: "bold",
          }}
        >
          {t("WORD_ADD_PHOTOS_OF_THIS_PRODUCT")}
        </div>
        <Upload
          className="avatar-uploader"
          name="image"
          multiple={false}
          // showUploadList={false}
          action={getBaseURL() + URL_UPLOAD_IMAGE}
          headers={{
            authorization: `Bearer ${ApiStorage.getAccessToken()}`,
          }}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          onRemove={(e) => {
            setfileList((prevFileList) =>
              prevFileList.filter((file) => file.status !== "deleted")
            );
            onClear && onClear(e.path);
          }}
          maxCount={maxCount}
          // listType="picture"
          listType="picture-card"
          fileList={fileList}
        >
          {fileList.length < maxCount && t("WORD_CHOOSE_THE_FILES")}
        </Upload>
      </div>
    </Form.Item>
  );
};

export default IconAvatarFormMultipleOlderStyle;
