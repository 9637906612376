import React from "react";
import MyButton from "./MyButton";
import "./MyButton.scss";

const MyButtonSecondary = ({ className, reset, style, ...otherProps }) => {
  return (
    <MyButton
      {...otherProps}
      className={`${className} ${reset ? "reset-btn" : null}`}
      type="primary"
      style={style}
      size="large"
      ghost
    />
  );
};

export default MyButtonSecondary;
