import { Modal } from "antd";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import MyText from "../myText/MyText";
import MySpace from "../mySpace/MySpace";
import MyButtonSecondary from "../myButton/MyButtonSecondary";
import MyButtonMain from "../myButton/MyButtonMain";

const MyModalForm = forwardRef(
  (
    {
      trigerText,
      children,
      width = 450,
      saving,
      destroyOnClose = true,
      title,
      onClose,
      forceRender = false,
      getContainer = false,
      disableOk,
      onVisibleChange,
      // footerExtra,
      loading,
      hideFooter,
      // defaultHeight,
      formName,
      okText,
      btnOkClassName,
      footer,
      customOkButton,
      success = true,
      danger = false,
      fontSize,
    },
    ref
  ) => {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    const showModal = () => {
      setOpen(true);
      onVisibleChange && onVisibleChange(true);
    };

    const handleCancel = () => {
      setOpen(false);
      onVisibleChange && onVisibleChange(false);
    };

    useImperativeHandle(ref, () => ({
      open: () => {
        showModal();
      },
      close: () => {
        handleCancel();
      },
    }));

    useEffect(() => {
      if (!open) {
        onClose && onClose();
      }
    }, [open, onClose]);

    return (
      <>
        <div className="app-modal-trigger-button" onClick={showModal}>
          {trigerText}
        </div>
        <Modal
          className="my-modal"
          loading={loading}
          title={
            <MyText size={fontSize} bold>
              {title}
            </MyText>
          }
          open={open}
          confirmLoading={saving}
          onCancel={handleCancel}
          width={width}
          destroyOnClose={destroyOnClose}
          centered
          getContainer={getContainer}
          forceRender={forceRender}
          footer={
            <MySpace fullWidth spaceBetween>
              {hideFooter ? (
                footer
              ) : (
                <MySpace align="start" style={{ marginTop: 10 }}>
                  {customOkButton ? (
                    customOkButton
                  ) : (
                    <MyButtonMain
                      label={okText ? okText : t("WORD_SAVE_CHANGES")}
                      form={formName}
                      style={{ height: 40 }}
                      loading={saving}
                      disabled={disableOk}
                      className={btnOkClassName}
                      success={success}
                      danger={danger}
                      size="large"
                    />
                  )}
                  <MyButtonSecondary
                    key="cancel"
                    onClick={handleCancel}
                    label={t("WORD_CANCEL")}
                    reset
                  />
                </MySpace>
              )}
            </MySpace>
          }
        >
          {loading ? null : children}
        </Modal>
      </>
    );
  }
);

export default MyModalForm;
