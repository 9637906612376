import { Space } from "antd";
import React from "react";

const MySpaceRow = ({
  children,
  fullWidth,
  size = "small",
  style,
  spaceBetween,
  align,
  className,
}) => {
  return (
    <Space
      style={{
        width: fullWidth ? "100%" : "",
        justifyContent: spaceBetween ? "space-between" : "",
        ...style,
      }}
      size={size}
      align={align}
      className={className || ""}
    >
      {children}
    </Space>
  );
};

export default MySpaceRow;
