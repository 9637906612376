import React, { memo } from "react";
import MyIcon from "./MyIcon";

export const SVGIcon = ({ size, fill }) => {
  return (
    <svg
      width={size || 25}
      height={size || 25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M22.9166 12.4997C22.9166 18.2538 18.253 22.9163 12.4999 22.9163C6.74679 22.9163 2.08325 18.2538 2.08325 12.4997C2.08325 6.74759 6.74679 2.08301 12.4999 2.08301C18.253 2.08301 22.9166 6.74759 22.9166 12.4997Z"
        fill={fill || "#767676"}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4063 13.157C13.4063 13.6591 12.9969 14.0684 12.4948 14.0684C11.9927 14.0684 11.5834 13.6591 11.5834 13.157V8.55282C11.5834 8.05073 11.9927 7.64136 12.4948 7.64136C12.9969 7.64136 13.4063 8.05073 13.4063 8.55282V13.157ZM11.5886 16.462C11.5886 15.9599 11.9959 15.5505 12.4948 15.5505C13.0084 15.5505 13.4167 15.9599 13.4167 16.462C13.4167 16.9641 13.0084 17.3734 12.5052 17.3734C12 17.3734 11.5886 16.9641 11.5886 16.462Z"
        fill={fill || "#767676"}
      ></path>
    </svg>
  );
};

const DebtsSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(DebtsSVG);
