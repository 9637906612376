import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySpace from "../../components/mySpace/MySpace";
import MyCard from "../../components/myCard/MyCard";
import useNavigateApi from "../../appRoute/useNavigateApi";
import MyDivider from "../../components/myDivider/MyDivider";
import MyLink from "../../components/myLink/MyLink";
import { useSelector } from "react-redux";
import ClientSaveActions from "../../actions/ClientSaveActions";
import UtilNotify from "../../utils/UtilNotify";
import NewUserPage from "../../components/newUserPage/NewUserPage";
import MyButtonSuccess from "../../components/myButton/MyButtonSuccess";

const DashboardNewUserPage = () => {
  const { t } = useTranslation();
  const navigateApi = useNavigateApi();

  const stepsDoneDetails = useSelector((state) => {
    return state.currentUser?.stepsDoneDetails;
  });

  const [data, setData] = useState([]);

  useEffect(() => {
    setData([
      {
        label: t("WORD_ADD_FIRST_DISTRIBUTOR"),
        btnLabel: t("WORD_SALES_TEAM"),
        complete: stepsDoneDetails?.done_managers,
        path: `/user/create`,
        key: "1",
      },
      {
        label: t("WORD_ADD_FIRST_PRODUCT"),
        btnLabel: t("WORD_PRODUCTS"),
        complete: stepsDoneDetails?.done_product,
        path: `/product/create`,
        key: "2",
      },
      {
        label: t("WORD_ADD_FIRST_CUSTOMER"),
        btnLabel: t("WORD_CUSTOMERS"),
        complete: stepsDoneDetails?.done_customers,
        path: `/customer/create`,
        key: "3",
      },
      {
        label: t("WORD_ADD_FIRST_DEVICE"),
        btnLabel: t("WORD_DEVICES"),
        complete: stepsDoneDetails?.done_devices,
        path: `/device/list`,
        key: "4",
      },
    ]);
  }, [stepsDoneDetails, t]);

  const handleSkipStep = async () => {
    const result = await ClientSaveActions.skipStepDone();
    if (result.success) {
      UtilNotify.notifySuccess(t("WORD_TOTURIAL_SKIPPED"));
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
  };

  return (
    <NewUserPage
      title={t("WORD_WELCOME_TO_ORDERSBOOK")}
      text={t("WORD_DASHBOARD_DESCRIPTION")}
      dashboard
      noArrow
    >
      <MyCard className="first-step-card">
        <MySpace direction="vertical" fullWidth size="middle" align="top">
          {data.map((dt, index) => {
            return (
              <div key={dt.key}>
                <MySpace fullWidth spaceBetween>
                  <div
                    style={{
                      textDecoration: dt.complete ? "line-through" : "unset",
                    }}
                    className="dashboard-first-step-row-label"
                    onClick={() => navigateApi(dt.path)}
                  >
                    {dt.label}:
                  </div>
                  <MyButtonSuccess
                    label={dt.btnLabel}
                    style={{ width: 120, borderRadius: 20 }}
                    onClick={() => navigateApi(dt.path)}
                  />
                </MySpace>
                {index < data.length - 1 ? <MyDivider /> : null}
              </div>
            );
          })}
        </MySpace>
      </MyCard>
      <div style={{ paddingTop: 20, paddingBottom: 40 }}>
        <MyLink className="product-link" onClick={handleSkipStep}>
          {t("WORD_SKIP_TUTORIAL")}
        </MyLink>
      </div>
    </NewUserPage>
  );
};

export default DashboardNewUserPage;
