import React from "react";
import { Card } from "antd";

const MyCardBorderless = ({
  children,
  className,
  style,
  fullHeight,
  onClick,
  onDoubleClick,
  loading,
}) => {
  return (
    <Card
      style={{
        height: fullHeight ? "100%" : "",
        ...style,
      }}
      className={`my-card-borderless ${className || ""} `}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      variant="borderless"
      loading={loading}
    >
      {loading ? null : children}
    </Card>
  );
};

export default MyCardBorderless;
