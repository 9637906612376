import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Result } from "antd";
import { useTranslation } from "react-i18next";
import MyTextSecondary from "../components/myText/MyTextSecondary";
import LoadingIconApp from "../components/loadingIconApp/LoadingIconApp";
import MyButtonBlue from "../components/myButton/MyButtonBlue";
import UserActions from "../actions/UserActions";
import "../pages/users/login/Login.scss";

const UserEmailConfirm = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [finshedError, setfinshedError] = useState(false);

  useEffect(() => {
    const check = async () => {
      if (_id) {
        const result = await UserActions.activateUser(_id);
        if (result.success) {
          navigate(`${result.data.lang}/home`);
        } else {
          setfinshedError(true);
        }
      }
    };
    check();
  }, [_id, navigate]);

  if (finshedError) {
    return (
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={
          <MyButtonBlue
            onClick={() => navigate("login")}
            label={t("WORD_BACK_TO_LOGIN")}
          />
        }
      />
    );
  }

  return (
    <div className="registration-status-pending-wrapper">
      <div className="registration-status-parent">
        <MyTextSecondary size={20}>
          {t("WORD_YOUR_ACCOUNT_PENDING_STATUS")}
        </MyTextSecondary>
        <LoadingIconApp style={{ fontSize: 40 }} />
      </div>
    </div>
  );
};

export default UserEmailConfirm;
