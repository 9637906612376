import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MySpace from "../../../components/mySpace/MySpace";
import MyPageHeader from "../../../components/myPageHeader/MyPageHeader";
import { useTranslation } from "react-i18next";
import UtilDate from "../../../utils/UtilDate";
import ProductActions from "../../../actions/ProductActions";
import UtilNotify from "../../../utils/UtilNotify";
import { useParams } from "react-router-dom";
import MyLink from "../../../components/myLink/MyLink";
import UtilNumber from "../../../utils/UtilNumber";
import {
  faArrowsUpDown,
  faEye,
  faList,
  faPen,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductViewModal from "./ProductViewModal";
import ProductQuantityModal from "./ProductQuantityModal";
import ImagePlaceholderSVG from "../../../icons/ImagePlaceholderSVG";
import FolderPlaceholderSVG from "../../../icons/FolderPlaceholderSVG";
import ProductImportModal from "./ProductImportModal";
import MyDraggableTable from "../../exportTemplates/MyDraggableTable";
import MyHelmet from "../../../components/helmet/MyHelmet";
import ProductQuantityActions from "../../../actions/ProductQuantityActions";
import MyTooltip from "../../../components/myTooltip/MyTooltip";
import FolderCreateWhiteSVG from "../../../icons/FolderCreateWhiteSVG";
import ImportWhiteSVG from "../../../icons/ImportWhiteSVG";
import ExportWhiteSVG from "../../../icons/ExportWhiteSVG";
import useNavigateApi from "../../../appRoute/useNavigateApi";
import ProductNewUserPage from "./ProductNewUserPage";
import { useSelector } from "react-redux";
import useWindowSize from "../../../utils/useWindowSize";
import HeaderMobile from "../../../components/headerForMobile/HeaderMobile";
import MyButtonTableActionDelete from "../../../components/myButton/MyButtonTableActionDelete";
import MyDropDown from "../../../components/myDropDown/MyDropDown";
import ProductSVG from "../../../icons/ProductSVG";
import FolderCreateSVG from "../../../icons/FolderCreateSVG";
import MyText from "../../../components/myText/MyText";
import FolderMoveModal from "./FolderMoveModal";
import MyImage from "../../../components/myImage/MyImage";
import { useFormatCurrency } from "../../../utils/useFormatWithCurrency";
import MyButtonSuccess from "../../../components/myButton/MyButtonSuccess";
import SearchInput from "../../../components/myInputForm/SearchInput";
import { Flex } from "antd";
import MyButtonTableAction from "../../../components/myButton/MyButtonTableAction";
import MyButtonGray from "../../../components/myButton/MyButtonGray";
import "./ProductList.scss";

const ProductList = () => {
  const { t } = useTranslation();
  const navigateApi = useNavigateApi();
  const refModal = useRef();
  const secondRefModal = useRef();
  const refModalImport = useRef();
  const refModalFolder = useRef();
  const { isMobile } = useWindowSize();
  const { formatDoubleWithCurrency } = useFormatCurrency();
  const { _idParent } = useParams();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setpagination] = useState({ page: 0, perPage: 50 });
  const [totalRows, settotalRows] = useState(0);
  const [search, setSearch] = useState("");
  const [sort, setsort] = useState("ordering");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const currentUserSteps = useSelector((state) => {
    return state.currentUser?.stepsDone;
  });

  const isUser1C = useSelector((state) => {
    return state.currentUser?.is1c;
  });

  const fillFS = useCallback(async () => {
    setLoading(true);
    const fetchParams = {
      page: pagination.page,
      perPage: pagination.perPage,
      search: search,
      sort: sort,
      filter: {
        idParent: _idParent || 0,
        type: "",
      },
    };
    const result = await ProductActions.fillPagination(fetchParams);
    if (result.success) {
      setData(result.data);
      settotalRows(result.totalRows);
      setSelectedRowKeys([]);
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    setLoading(false);
  }, [pagination, _idParent, search, sort]);

  useEffect(() => {
    fillFS();
  }, [fillFS]);

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const setpage = (page) => {
    setpagination((ov) => ({ page: page, perPage: ov.perPage }));
  };

  const handleDeleteProduct = useCallback(
    async (idProduct) => {
      if (idProduct) {
        const result = await ProductActions.delete(idProduct);
        if (result.success) {
          // setData((prevData) =>
          //   prevData.filter((item) => item.idProduct !== idProduct)
          // );
          fillFS();
          // setSelectedRowKeys([]);
          UtilNotify.notifySuccess(t("WORD_PRODUCT_DELETED_SUCCESSFULLY"));
        } else {
          UtilNotify.notifyErrorServer(result.errMsg);
        }
      }
    },
    [t, fillFS]
  );

  const handleDeleteProductFolder = useCallback(
    async (idProduct) => {
      if (idProduct) {
        const result = await ProductActions.deleteFolder(idProduct);
        if (result.success) {
          fillFS();
          UtilNotify.notifySuccess(t("WORD_PRODUCT_DELETED_SUCCESSFULLY"));
        } else {
          UtilNotify.notifyErrorServer(result.errMsg);
        }
      }
    },
    [t, fillFS]
  );

  const columns = useMemo(() => {
    let col = [
      {
        title: t("WORD_IMAGE"),
        dataIndex: "icon",
        key: "icon",
        width: 70,
        align: "center",
        render: (_, { type, icon }) =>
          icon ? (
            <MyImage icon={icon} width={25} height={30} preview={false} />
          ) : type === 0 ? (
            <div>
              <ImagePlaceholderSVG size={25} />
            </div>
          ) : (
            <div>
              <FolderPlaceholderSVG size={25} />
            </div>
          ),
      },
      {
        title: t("WORD_NAME"),
        dataIndex: "name",
        key: "name",
        sorter: true,
        //    width: "160px",
        render: (value, { idProduct, type }) =>
          type === ProductActions.TYPE.PRODUCT ? (
            <MyLink
              className="product-link"
              path={`/product/edit/${idProduct}`}
            >
              {value}
            </MyLink>
          ) : (
            <MyLink
              className="product-link"
              path={`/product/list/${idProduct}`}
              onClick={() => setpage(0)}
            >
              {value}
            </MyLink>
          ),
      },
      {
        title: t("WORD_TYPE"),
        dataIndex: "type",
        key: "type",
        width: 70,
        sorter: true,
        align: "center",
        render: (value) => {
          return <div>{ProductActions.getType(value).icon}</div>;
        },
      },
      {
        title: t("WORD_QUANTITY"),
        dataIndex: "quantity",
        key: "quantity",
        align: "right",
        width: 160,
        sorter: true,
        render: (value) => {
          return <div>{value ? UtilNumber.formatDouble(value) : value}</div>;
        },
      },
      {
        title: t("WORD_PRICE"),
        dataIndex: "price",
        key: "price",
        align: "right",
        width: 160,
        sorter: true,
        render: (value, { type }) => {
          if (type === 1) return null;
          return <div>{value ? formatDoubleWithCurrency(value) : value}</div>;
        },
      },
      {
        title: t("WORD_MODIFIED_DATE"),
        dataIndex: "updateAt",
        key: "updateAt",
        width: 150,
        align: "center",
        sorter: true,
        render: (value) => {
          return <div>{UtilDate.formatDateTime(value)}</div>;
        },
      },
      {
        title: t("WORD_ACTIONS"),
        dataIndex: "actions",
        width: 120,
        key: "actions",
        render: (_, { idProduct, type, name }) =>
          type === ProductActions.TYPE.PRODUCT ? (
            <MySpace>
              <MyButtonTableAction
                title={t("WORD_EDIT")}
                icon={faPen}
                onClick={() => {
                  navigateApi(`/product/edit/${idProduct}`);
                }}
              />
              <MyButtonTableAction
                title={t("WORD_VIEW")}
                icon={faEye}
                onClick={() => {
                  refModal?.current?.viewProduct(idProduct);
                }}
              />
              <MyButtonTableAction
                title={t("WORD_DETAILS")}
                icon={faList}
                onClick={() => {
                  navigateApi(`/product/quantities/${idProduct}`);
                }}
              />
              <>
                {isUser1C ? null : (
                  <MyButtonTableAction
                    title={t("WORD_QUANTITY")}
                    icon={faArrowsUpDown}
                    onClick={() => {
                      secondRefModal?.current?.viewQuantity({
                        idProduct,
                        name,
                      });
                    }}
                  />
                )}
                <MyTooltip title={t("WORD_DELETE")}>
                  <MyButtonTableActionDelete
                    icon={
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        color="#fff"
                        size="xs"
                      />
                    }
                    onConfirm={() => handleDeleteProduct(idProduct)}
                  />
                </MyTooltip>
              </>
            </MySpace>
          ) : (
            <MySpace>
              <MyButtonTableAction
                title={t("WORD_EDIT")}
                icon={faPen}
                onClick={() => {
                  navigateApi(`/product/folder/edit/${idProduct}`);
                }}
              />
              <MyTooltip title={t("WORD_DELETE")}>
                <MyButtonTableActionDelete
                  icon={
                    <FontAwesomeIcon icon={faTrashCan} color="#fff" size="xs" />
                  }
                  onConfirm={() => handleDeleteProductFolder(idProduct)}
                />
              </MyTooltip>
            </MySpace>
          ),
      },
    ];
    return col;
  }, [
    t,
    navigateApi,
    handleDeleteProduct,
    handleDeleteProductFolder,
    isUser1C,
    formatDoubleWithCurrency,
  ]);

  const onChangeTable = (_, __, sorter) => {
    let so = "";
    if (sorter.order) {
      so = `${sorter.columnKey} ${sorter.order === "ascend" ? "ASC" : "DESC"}`;
    }
    setsort(so);
  };

  const onChangeSorting = async (_, nextTargetObj) => {
    if (nextTargetObj) {
      setData(nextTargetObj);
      let index = nextTargetObj[0].ordering;
      for (const obj of nextTargetObj) {
        obj.ordering = index;
        index++;
      }
      const result = await ProductActions.changeOrdering(
        nextTargetObj.map((oo) => {
          return {
            idProduct: oo.idProduct,
            ordering: oo.ordering,
          };
        })
      );
      if (!result.success) {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    }
  };

  const handleExport = async () => {
    const fetchParams = {
      filter: { idParent: _idParent },
    };
    await ProductQuantityActions.exportExcelQauntity(fetchParams);
  };

  const handleRefreshRow = (obj) => {
    setData((ov) => {
      return ov.map((it) => {
        if (it.idProduct === obj.idProduct) {
          if (obj.type === ProductQuantityActions.TYPE.IN) {
            it.quantity =
              parseFloat(it.quantity || 0) + parseFloat(obj.quantity);
          } else {
            it.quantity =
              parseFloat(it.quantity || 0) - parseFloat(obj.quantity);
          }
        }
        return it;
      });
    });
  };

  const size = 20;

  const items = [
    {
      key: "product",
      label: (
        <MySpace style={{ height: 30 }} fullWidth>
          <div>
            <ProductSVG fill="black" size={size} />
          </div>
          <MyText size={16}>{t("WORD_ADD_PRODUCT")}</MyText>
        </MySpace>
      ),
      onClick: () => {
        if (_idParent) {
          navigateApi(`/product/create/${_idParent}`);
        } else {
          navigateApi(`/product/create`);
        }
      },
    },
    {
      type: "divider",
    },
    {
      key: "folder",
      label: (
        <MySpace style={{ height: 30 }} fullWidth>
          <div>
            <FolderCreateSVG size={size} />
          </div>
          <MyText size={16}>{t("WORD_ADD_FOLDER")}</MyText>
        </MySpace>
      ),
      onClick: () => {
        if (_idParent) {
          navigateApi(`/product/folder/create/${_idParent}`);
        } else {
          navigateApi(`/product/folder/create`);
        }
      },
    },
  ];

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      <MyHelmet title={t("WORD_PRODUCTS")} />
      <Flex vertical gap={10}>
        {isMobile ? (
          <div>
            <HeaderMobile>
              <MyPageHeader title={t("WORD_PRODUCTS")} />
              <MySpace>
                <MyButtonTableAction
                  nextToFilter
                  antdIcon
                  title={t("WORD_IMPORT")}
                  icon={<ImportWhiteSVG />}
                  onClick={() => {
                    refModalImport?.current?.viewProduct();
                  }}
                />
                <MyButtonTableAction
                  nextToFilter
                  antdIcon
                  title={t("WORD_EXPORT_QUANTITY")}
                  icon={<ExportWhiteSVG />}
                  onClick={handleExport}
                />
                <MyButtonTableAction
                  nextToFilter
                  antdIcon
                  title={t("WORD_ADD_FOLDER")}
                  icon={<FolderCreateWhiteSVG />}
                  onClick={() => {
                    if (_idParent) {
                      navigateApi(`/product/folder/create/${_idParent}`);
                    } else {
                      navigateApi(`/product/folder/create`);
                    }
                  }}
                />
                <MyButtonSuccess
                  onClick={() => {
                    if (_idParent) {
                      navigateApi(`/product/create/${_idParent}`);
                    } else {
                      navigateApi(`/product/create`);
                    }
                  }}
                />
              </MySpace>
            </HeaderMobile>
            <SearchInput
              onClear={() => {
                setSearch("");
                setpage(0);
              }}
              onPressEnter={(value) => {
                setSearch(value);
                setpage(0);
              }}
            />
          </div>
        ) : (
          <Flex vertical gap="small">
            <MyPageHeader
              inPage
              onBack={
                _idParent
                  ? () => {
                    navigateApi(-1);
                  }
                  : undefined
              }
              extraLeft={
                <MySpace>
                  <div>
                    {hasSelected
                      ? `${t("WORD_SELECTED")} ${selectedRowKeys.length} ${selectedRowKeys.length > 1
                        ? t("WORD_ITEMS")
                        : t("WORD_ITEM")
                      }`
                      : ""}
                  </div>
                  {hasSelected ? (
                    <MyButtonGray
                      nextToFilter
                      label={t("WORD_MOVE")}
                      style={{ width: "auto" }}
                      onClick={() => {
                        refModalFolder?.current?.viewFolders();
                      }}
                    />
                  ) : null}
                </MySpace>
              }
              extraRight={
                <MySpace>
                  <MyButtonGray
                    label={t("WORD_IMPORT")}
                    icon={<ImportWhiteSVG />}
                    onClick={() => {
                      refModalImport?.current?.viewProduct();
                    }}
                  />
                  <MyButtonGray
                    label={t("WORD_EXPORT")}
                    icon={<ExportWhiteSVG />}
                    onClick={handleExport}
                  />
                  <MyDropDown items={items} arrow={false} width={250}>
                    <MyButtonSuccess withIcon label={t("WORD_ADD")} />
                  </MyDropDown>
                </MySpace>
              }
              title={t("WORD_PRODUCTS")}
            />
            <MySpace style={{ marginLeft: 8 }}>
              <SearchInput
                onClear={() => {
                  setSearch("");
                  setpage(0);
                }}
                onPressEnter={(value) => {
                  setSearch(value);
                  setpage(0);
                }}
              />
            </MySpace>
          </Flex>
        )}
        {!currentUserSteps && !loading && data.length === 0 ? (
          <ProductNewUserPage />
        ) : (
          <MyDraggableTable
            rowKey="idProduct"
            columns={columns}
            dataSource={data}
            loading={loading}
            total={totalRows}
            rowSelection={rowSelection}
            onChangeTable={onChangeTable}
            onChange={onChangeSorting}
            onShowSizeChange={(e, ee) => {
              setpagination({ page: 0, perPage: ee });
            }}
            onChangePage={(e) => {
              setpage(e);
            }}
            page={pagination.page}
            pageSize={pagination.perPage}
          />
        )}
      </Flex>
      <ProductViewModal ref={refModal} />
      <ProductQuantityModal ref={secondRefModal} onChange={handleRefreshRow} />
      <ProductImportModal ref={refModalImport} />
      <FolderMoveModal
        ref={refModalFolder}
        selectedRowKeys={selectedRowKeys}
        onChange={fillFS}
        idParent={_idParent}
      />
    </>
  );
};

export default ProductList;
