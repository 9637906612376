import React, { memo } from "react";
import MyIcon from "./MyIcon";

export const SVGIcon = (props) => {
  return (
    <svg
      width="452"
      height="404"
      viewBox="0 0 452 404"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1175_511)">
        <g opacity="0.82">
          <g opacity="0.82">
            <g opacity="0.82">
              <path
                opacity="0.4"
                d="M189.14 232.52C189.14 232.52 217.26 199.72 190.87 178.87C190.87 178.87 171.6 167.21 155.87 168.15C155.87 168.15 178.14 205.5 170.94 209.21C163.74 212.92 149.12 165.81 149.12 165.81C149.12 165.81 105.26 161.56 91.12 168.34C91.12 168.34 128.62 199.03 120.72 200.86C112.82 202.69 72.32 172.56 72.32 172.56C72.32 172.56 31.49 179.51 33.67 204.42C35.85 229.33 49.99 237 56.14 236.35C62.29 235.7 98.49 221.88 99.28 227C100.07 232.12 78.28 248.62 63.05 249.4C63.05 249.4 91.83 281.14 110.96 271.98C130.09 262.82 131.72 248.3 143.65 242.85C155.58 237.4 165.75 237.42 160.83 242.98C155.91 248.54 134.33 253.93 127.5 265.98C120.67 278.03 114.57 286.18 134.36 283.2C154.15 280.22 186.36 265.74 187.73 249.2L189.14 232.52Z"
                fill="url(#paint0_linear_1175_511)"
              />
            </g>
            <g opacity="0.82">
              <path
                style={{ mixBlendMode: "multiply" }}
                opacity="0.82"
                d="M287.46 191.26C287.46 191.26 242.73 166.26 263.6 132.26C263.6 132.26 280.3 111.38 298.2 106.01C298.2 106.01 288.66 156.71 298.2 157.91C307.74 159.11 304.77 100.65 304.77 100.65C304.77 100.65 351.89 77.9801 370.38 79.7701C370.38 79.7701 341.15 129.28 350.69 128.09C360.23 126.9 393.05 76.7901 393.05 76.7901C393.05 76.7901 441.36 67.8401 449.12 96.4701C456.88 125.1 444.34 139.47 437.19 141.21C430.04 142.95 384.1 142.4 385.29 148.37C386.48 154.34 417.5 163.88 434.8 158.51C434.8 158.51 415.71 205.63 390.66 203.24C365.61 200.85 357.85 185.35 342.34 184.16C326.83 182.97 315.5 187.16 323.26 191.32C331.02 195.48 357.26 192.68 369.78 203.32C382.3 213.96 392.45 220.53 369.18 225.32C345.91 230.11 304.18 227.11 295.81 209.22L287.46 191.26Z"
                fill="url(#paint1_linear_1175_511)"
              />
            </g>
            <g opacity="0.82">
              <g style={{ mixBlendMode: "multiply" }} opacity="0.82">
                <path
                  d="M427.04 105.37C427.04 105.37 301.78 145.93 287.47 191.26C287.47 191.26 288.07 211.54 275.54 234.81V243.75C275.54 243.75 288.66 213.33 295.82 209.15C295.82 209.16 267.78 169.79 427.04 105.37Z"
                  fill="#FF931A"
                />
              </g>
              <g style={{ mixBlendMode: "multiply" }} opacity="0.82">
                <path
                  d="M275.54 234.8C275.54 234.8 260.849 257.11 264.396 286.5H271.312C270.696 273.288 270.972 252.231 277.53 239.4C287.64 219.62 275.54 234.8 275.54 234.8Z"
                  fill="#FF931A"
                />
              </g>
            </g>
            <g opacity="0.82">
              <path
                opacity="0.55"
                d="M216.3 156.71C216.3 156.71 275.3 123.71 247.75 78.87C247.75 78.87 225.75 51.35 202.15 44.27C202.15 44.27 214.73 111.11 202.15 112.68C189.57 114.25 193.5 37.2 193.5 37.2C193.5 37.2 131.38 7.32002 106.99 9.68002C106.99 9.68002 145.51 74.94 132.99 73.36C120.47 71.78 77.1299 5.74002 77.1299 5.74002C77.1299 5.74002 13.4399 -5.99998 3.21991 31.69C-7.00009 69.38 9.50991 88.3 18.9899 90.66C28.4699 93.02 88.9899 92.24 87.3899 100.1C85.7899 107.96 44.8899 120.54 22.0899 113.46C22.0899 113.46 47.2499 175.58 80.2799 172.46C113.31 169.34 123.52 148.87 143.96 147.3C164.4 145.73 179.35 151.23 169.13 156.73C158.91 162.23 124.31 158.53 107.79 172.57C91.2699 186.61 77.9199 195.26 108.58 201.57C139.24 207.88 194.28 203.93 205.29 180.34L216.3 156.71Z"
                fill="url(#paint2_linear_1175_511)"
              />
              <path
                opacity="0.82"
                d="M32.3101 43.49C32.3101 43.49 197.42 97 216.3 156.71C216.3 156.71 215.51 183.44 232.02 214.11V225.9C232.02 225.9 214.72 185.8 205.29 180.3C205.29 180.3 242.24 128.4 32.3101 43.49Z"
                fill="url(#paint3_linear_1175_511)"
              />
              <path
                opacity="0.82"
                d="M231.99 214.11C231.99 214.11 252.781 245.688 246.079 286.5H237.384C238.427 269.685 238.8 238.533 229.39 220.17C216.03 194.1 231.99 214.11 231.99 214.11Z"
                fill="url(#paint4_linear_1175_511)"
              />
            </g>
            <path
              opacity="0.9"
              d="M218.28 401.92C187.644 401.917 158.264 389.745 136.602 368.081C114.939 346.418 102.77 317.036 102.77 286.4H333.77C333.77 317.033 321.603 346.411 299.946 368.074C278.288 389.738 248.913 401.912 218.28 401.92Z"
              fill="url(#paint5_linear_1175_511)"
            />
            <path
              opacity="0.82"
              d="M337.6 286.4H100.15V289.94H337.6V286.4Z"
              fill="url(#paint6_linear_1175_511)"
            />
            <path
              opacity="0.82"
              d="M337.6 286.4H100.15V289.94H337.6V286.4Z"
              fill="url(#paint7_linear_1175_511)"
            />
            <path
              opacity="0.82"
              d="M337.6 286.4H100.15V289.94H337.6V286.4Z"
              fill="url(#paint8_linear_1175_511)"
            />
            <path
              opacity="0.6"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M53.7698 205.08C53.7698 205.08 164.65 200.81 189.14 232.52C189.14 232.52 194.552 248.717 211.185 263.488L211.22 263.51C211.22 263.51 222.057 271.524 229.616 286.4H223.255C220.485 280.32 217.11 274.351 213.172 269.924C212.671 269.406 211.902 268.615 210.934 267.639C208.968 265.818 207.577 264.436 206.634 263.405C200.439 257.45 191.862 249.849 187.73 249.11L187.731 249.108C187.817 248.772 197.874 209.8 53.7698 205.08ZM223.255 286.4H100.15V289.94H337.6V286.4H229.616C229.633 286.433 229.65 286.467 229.667 286.5H223.301C223.286 286.467 223.271 286.433 223.255 286.4Z"
              fill="url(#paint9_linear_1175_511)"
            />
          </g>
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1175_511"
          x1="117.871"
          y1="164.495"
          x2="117.871"
          y2="283.814"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6442FF" />
          <stop offset="1" stopColor="#009CF9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1175_511"
          x1="354.81"
          y1="75.4656"
          x2="354.81"
          y2="227.357"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF281" />
          <stop offset="1" stopColor="#FF7A00" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1175_511"
          x1="-0.0362848"
          y1="104.11"
          x2="255.046"
          y2="104.11"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#01D557" />
          <stop offset="1" stopColor="#01D2FC" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1175_511"
          x1="32.2847"
          y1="134.675"
          x2="231.995"
          y2="134.675"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#01D557" />
          <stop offset="1" stopColor="#01D2FC" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1175_511"
          x1="224.056"
          y1="253.296"
          x2="247.407"
          y2="253.296"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#01D557" />
          <stop offset="1" stopColor="#01D2FC" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1175_511"
          x1="318.5"
          y1="147"
          x2="175.5"
          y2="393"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6442FF" />
          <stop offset="1" stopColor="#009CF9" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1175_511"
          x1="9.99977"
          y1="263.501"
          x2="347.5"
          y2="309.501"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#27108C" />
          <stop offset="1" stopColor="#21B5E3" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1175_511"
          x1="9.99977"
          y1="263.501"
          x2="347.5"
          y2="309.501"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#27108C" />
          <stop offset="1" stopColor="#21B5E3" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1175_511"
          x1="9.99977"
          y1="263.501"
          x2="347.5"
          y2="309.501"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#27108C" />
          <stop offset="1" stopColor="#21B5E3" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1175_511"
          x1="312.986"
          y1="248.829"
          x2="312.986"
          y2="289.939"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6442FF" />
          <stop offset="1" stopColor="#009CF9" />
        </linearGradient>
        <clipPath id="clip0_1175_511">
          <rect width="452" height="404" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const PricingLeftSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(PricingLeftSVG);
