import { fetchFromUrlPOSTAsync } from "../utils/UrlHelper";

const URL_LIST = "currency/list";

const fs = {
  fillList: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_LIST, urlParams);
    return result;
  },
};

const loc = {
  fillListOptions: async () => {
    const result = await CurrencyActions.fillList();
    if (result.success) {
      result.data = result.data.map((it) => {
        return {
          label: it.currancyName + " " + it.currencyCode,
          value: it.idCurrency,
          ...it,
        };
      });
    }
    return result;
  },
};

const CurrencyActions = Object.assign(fs, loc);

export default CurrencyActions;
