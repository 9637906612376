import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import FormApp from "../../../../components/formApp/FormApp";
import InputTextFormItem from "../../../../components/myInputForm/InputTextFormItem";
import RouteActions from "../../../../actions/RouteActions";
import UtilNotify from "../../../../utils/UtilNotify";
import MySpace from "../../../../components/mySpace/MySpace";
import { Form } from "antd";
import MyModalForm from "../../../../components/myModal/MyModalForm";

const formName = "formEditGroup";

const UserRouteEditModal = forwardRef(
  ({ idManager, nameManager, onChange }, ref) => {
    const refModal = useRef();
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const [saving, setSaving] = useState(false);

    const idRoute = Form.useWatch("idRoute", form);

    useImperativeHandle(ref, () => ({
      viewGroup: async (idRoute) => {
        if (idRoute) {
          const result = await RouteActions.getOneRoute(idRoute);
          if (result.success) {
            form?.setFieldsValue(result.data);
          } else {
            UtilNotify.notifyErrorServer(result.errMsg);
          }
        } else {
          form?.setFieldsValue({ idManager, nameManager });
        }
        refModal.current.open();
      },
    }));

    const isNew = Boolean(!idRoute);

    const onFinish = async (values) => {
      setSaving(true);
      let result;
      delete values.nameManager;
      if (isNew) {
        result = await RouteActions.createRoute(values);
      } else {
        result = await RouteActions.updateRoute(values, values.idRoute);
      }
      if (result.success) {
        if (isNew) {
          UtilNotify.notifySuccess(t("WORD_GROUP_CREATED"));
        } else {
          UtilNotify.notifySuccess(t("WORD_GROUP_SAVED"));
        }
        onChange && onChange();
        refModal.current.close();
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setSaving(false);
    };

    return (
      <MyModalForm
        ref={refModal}
        title={isNew ? t("WORD_CREATE_GROUP") : t("WORD_EDIT_GROUP")}
        saving={saving}
        labelOk={t("WORD_SAVE_CHANGES")}
        formName={formName}
        success
      >
        <FormApp form={form} name={formName} onFinish={onFinish}>
          <MySpace direction="vertical" fullWidth>
            <InputTextFormItem name="name" label={t("WORD_NAME")} required />
            <InputTextFormItem
              name="nameManager"
              readOnly
              label={t("WORD_DISTRIBUTOR")}
            />
            <InputTextFormItem name="idManager" hidden />
            <InputTextFormItem name="idRoute" hidden />
          </MySpace>
        </FormApp>
      </MyModalForm>
    );
  }
);

export default UserRouteEditModal;
