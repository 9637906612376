import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import TrialNotifRow from "./TrialNotifRow";
import UtilDate from "../../utils/UtilDate";
import { useSelector } from "react-redux";
import UserActions from "../../actions/UserActions";
import MainLayoutResubscribeNotif from "./MainLayoutResubscribeNotif";

const MainLayoutTrialNotif = () => {
  const { t } = useTranslation();

  const statusClient = useSelector((state) => {
    return state.currentUser?.statusClient;
  });

  const paidUntilDateRedux = useSelector((state) => {
    return state?.currentUser?.paidUntilDate;
  });

  const statusDate = useSelector((state) => {
    return state?.currentUser?.statusDate;
  });

  const paidUntilDate = paidUntilDateRedux
    ? UtilDate.getDate(paidUntilDateRedux)
    : UtilDate.getDateToday();
  const trialDaysLeft =
    14 - UtilDate.getDiffDaysCount(UtilDate.getDate(statusDate));

  if (statusClient === UserActions.STATUS.TRIAL) {
    return (
      <TrialNotifRow
        title={`${t("WORD_14_DAY_TRIAL_TEXT")} ${trialDaysLeft} ${t(
          "WORD_DAY_LEFT_TRIAL"
        )}`}
        btnLabel={t("WORD_BUY")}
        path={`/settings/package_change`}
      />
    );
  } else if (UtilDate.isSameOrAfter(UtilDate.getDateToday(), paidUntilDate)) {
    return <MainLayoutResubscribeNotif />;
  }
  return null;
};

export default memo(MainLayoutTrialNotif);
