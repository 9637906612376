import ApiStorage from "../redux/ApiStorage";
import {
  fetchFromUrlPOSTAsync,
  fetchFromUrlPOSTAsyncDownloadFile,
} from "../utils/UrlHelper";
import UtilDate from "../utils/UtilDate";

const URL_LIST = "exportTemplets/list";
const URL_GET_ONE = "exportTemplets/getOne";
const URL_DELETE = "exportTemplets/delete";
const URL_CREATE = "exportTemplets/create";
const URL_UPDATE = "exportTemplets/update";
const URL_EXPORT = "exportTemplets/export";

const fs = {
  fillList: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_LIST, urlParams);
    return result;
  },
  getOne: async (_id) => {
    const result = await fetchFromUrlPOSTAsync(URL_GET_ONE + "/" + _id, []);
    return result;
  },
  create: async (values) => {
    let result = fetchFromUrlPOSTAsync(URL_CREATE, {
      saveData: values,
    });
    return result;
  },
  update: async (values, _id) => {
    let result = await fetchFromUrlPOSTAsync(URL_UPDATE + "/" + _id, {
      saveData: values,
    });
    return result;
  },
  delete: async (_id) => {
    const result = await fetchFromUrlPOSTAsync(URL_DELETE + "/" + _id, []);
    return result;
  },
  exportFile: async (urlParams, _id) => {
    let fileName = '';
    const fileDate = UtilDate.getDateToday().format("DD-MM-YYYY_HH-MM-ss");
    if (_id === -100) {
      const currentUser = ApiStorage.getCurrentUser();
      fileName = `E_Invoice_${currentUser?.idUser}_${fileDate}.xml`;
    } else if (_id === -101) {
      fileName = `orders_${fileDate}.zip`;
    }
    const result = await fetchFromUrlPOSTAsyncDownloadFile(
      URL_EXPORT + "/" + _id,
      urlParams,
      fileName
    );
    return result;
  },
};

const loc = {
  VIEWIN: {
    ORDERS: "Orders",
    PAYMENTS: "Payments",
    CUSTOMER: "Customer",
  },
  SOURCE: {
    ORDERITEM: "OrderItem",
    ORDER: "Order",
    CUSTOMER: "Customer",
  },
  FILETYPE: {
    TXT: "txt",
    XLXS: "xlxs",
    XML: "xml",
  },
};

const ExportTemplatesActions = Object.assign(fs, loc);

export default ExportTemplatesActions;
