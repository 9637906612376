import { Tooltip } from "antd";
import React from "react";

const MyTooltip = ({
  children,
  title,
  onClick,
  className,
  placement,
  trigger = "hover",
}) => {
  return (
    <div>
      <Tooltip
        title={title}
        trigger={trigger}
        placement={placement}
        className="link-cursor"
      >
        <div className={className || ""} onClick={onClick}>
          {children}
        </div>
      </Tooltip>
    </div>
  );
};

export default MyTooltip;
