import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import AnalyticsActions from "../../actions/AnalyticsActions";
import UtilNotify from "../../utils/UtilNotify";
import MyTable from "../../components/myTable/MyTable";
import UtilNumber from "../../utils/UtilNumber";
import { useSelector } from "react-redux";
import { Flex } from "antd";

const AnalyticsByPlaceSortTable = ({ selectedRegion, filter, className }) => {
  const { t } = useTranslation();

  const currency = useSelector((state) => {
    return state.currentUser?.currency;
  });

  const [loading, setloading] = useState(true);

  const [data, setdata] = useState([]);

  useEffect(() => {
    const fill = async () => {
      setloading(true);
      const params = {
        filter: filter,
      };
      let result;
      if (selectedRegion === "COUNTRY") {
        result = await AnalyticsActions.sale_return_payment_by_country_list(
          params
        );
      } else if (selectedRegion === "REGION") {
        result = await AnalyticsActions.sale_return_payment_by_region_list(
          params
        );
      } else if (selectedRegion === "CITY") {
        result = await AnalyticsActions.sale_return_payment_by_city_list(
          params
        );
      } else if (selectedRegion === "DISTRICT") {
        result = await AnalyticsActions.sale_return_payment_by_district_list(
          params
        );
      }
      if (result?.success) {
        setdata(result?.data);
      } else {
        UtilNotify.notifyErrorServer(result?.errMsg);
      }
      setloading(false);
    };
    fill();
  }, [filter, selectedRegion]);

  const columns = useMemo(() => {
    return [
      {
        title: t("WORD_NAME"),
        dataIndex: "name",
        key: "name",
      },
      {
        title: t("WORD_ORDER"),
        dataIndex: "count",
        key: "count",
        align: "center",
        width: 60,
      },
      {
        title: t("WORD_AMOUNT"),
        dataIndex: "totalAmount",
        key: "totalAmount",
        align: "center",
        width: 150,
        render: (value, row) => {
          return (
            <Flex vertical align="center">
              <strong>{`${currency}${UtilNumber.formatWithK(value)}`}</strong>
              <Flex gap={0}>
                {`${UtilNumber.calcTwoNumbersPercentage(
                  row?.p?.totalAmount,
                  value
                )}% (${currency}${UtilNumber.formatWithK(
                  row?.p?.totalAmount
                )})`}{" "}
              </Flex>
            </Flex>
          );
        },
      },
    ];
  }, [t, currency]);

  return (
    <MyTable
      columns={columns}
      dataSource={data}
      rowKey={(record) => JSON.stringify(record)}
      noFooter
      loading={loading}
      className={className}
    />
  );
};

export default AnalyticsByPlaceSortTable;
