import React from "react";
import MySpace from "../mySpace/MySpace";
import MyTextSecondary from "../myText/MyTextSecondary";
import colors from "../../Color.scss";
import "./AddressView.scss";

const AddressView = ({ title, data }) => {
  return (
    <MySpace align="top" fullWidth direction="vertical">
      <MyTextSecondary color={colors.gray}>{title}:</MyTextSecondary>
      <div className="address-second-row">{data}</div>
    </MySpace>
  );
};

export default AddressView;
