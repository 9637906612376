import { Layout } from "antd";
import React, { memo } from "react";

const BlankLayout = ({ children, Top }) => {
  return (
    <Layout
      style={{
        height: "100vh",
        paddingTop: Top ? 30 : 0,
        background: "#fff",
      }}
    >
      {children}
    </Layout>
  );
};

export default memo(BlankLayout);
