import React, { useMemo } from "react";
import MyCard from "../../components/myCard/MyCard";
import MySpace from "../../components/mySpace/MySpace";
import MyTextAnalyticsStatsCard from "../../components/myText/MyTextAnalyticsStatsCard";
import { useTranslation } from "react-i18next";
import MyTabs from "../../components/myTabs/MyTabs";
import AnalyticsByPlaceSortTable from "./AnalyticsByPlaceSortTable";

const AnalyticsByPlaceSort = ({ filter }) => {
  const { t } = useTranslation();

  const items = useMemo(() => {
    return [
      {
        key: "COUNTRY",
        label: t("WORD_COUNTRY"),
        children: (
          <AnalyticsByPlaceSortTable filter={filter} selectedRegion="COUNTRY" />
        ),
      },
      {
        key: "REGION",
        label: t("WORD_REGION"),
        children: (
          <AnalyticsByPlaceSortTable filter={filter} selectedRegion="REGION" />
        ),
      },
      {
        key: "CITY",
        label: t("WORD_CITY"),
        children: (
          <AnalyticsByPlaceSortTable filter={filter} selectedRegion="CITY" />
        ),
      },
      {
        key: "DISTRICT",
        label: t("WORD_DISTRICT"),
        children: (
          <AnalyticsByPlaceSortTable
            filter={filter}
            selectedRegion="DISTRICT"
            className="analytics-table"
          />
        ),
      },
    ];
  }, [filter, t]);

  return (
    <MyCard style={{ width: '100%' }}>
      <MySpace direction="vertical" fullWidth>
        <MyTextAnalyticsStatsCard bold>
          {t("WORD_ORDERS_BY_REGION")}
        </MyTextAnalyticsStatsCard>
        <MyTabs defaultActiveKey="COUNTRY" items={items} animated />
      </MySpace>
    </MyCard>
  );
};

export default AnalyticsByPlaceSort;
