import { Upload, message, Form } from "antd";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { getBaseURL } from "../../utils/UrlHelper";
import ApiStorage from "../../redux/ApiStorage";
import UtilNotify from "../../utils/UtilNotify";
import axios from "axios";
import { useTranslation } from "react-i18next";
import MyButtonSuccess from "../myButton/MyButtonSuccess";

const maxFileSize = 7;

const UploadFilesManualModal = forwardRef(
  (
    {
      onChange,
      label,
      name,
      required,
      placeholder,
      hidden,
      maxCount,
      multiple,
      fileTypes,
      className,
    },
    ref
  ) => {
    const { t } = useTranslation();

    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);

    const handleUpload = (url) => {
      const finalUrl = `${getBaseURL()}${url}`;
      const formData = new FormData();
      if (fileList.length === 1) {
        formData.append("file", fileList[0]);
        // formData.append(name, fileList[0]);
      } else {
        fileList.forEach((file) => {
          formData.append("files[]", file);
        });
      }

      let deviceKey = ApiStorage.getDeviceKey();
      formData.append("deviceKey", deviceKey);
      formData.append("device", 'web');

      setUploading(true);
      axios
        .post(finalUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${ApiStorage.getAccessToken()}`,
          },
        })
        .then((response) => {
          const result = response.data;
          if (result.success) {
            setFileList([]);
            // message.success('upload successfully.');
            onChange && onChange(result);
            UtilNotify.notifySuccess("upload successfully.");
          } else {
            UtilNotify.notifyErrorServer(result.errMsg);
          }
        })
        .catch((error) => {
          console.error("error :>> ", error);
          message.error("upload failed.");
        })
        .finally(() => {
          setUploading(false);
        });
    };

    useImperativeHandle(ref, () => ({
      startUpload: handleUpload,
    }));

    const beforeUpload = (file) => {
      let fileTypesList = Array.isArray(fileTypes) ? fileTypes : [fileTypes];
      const fileSize = file.size / 1024 / 1024;
      const isLt2M = fileSize < maxFileSize;
      if (!isLt2M) {
        message.error(`File size must be less than ${maxFileSize}MB!`);
        return false;
      }
      if (!fileTypesList.some((v) => file.name.includes(v))) {
        message.error(`File type should be ${fileTypes}`);
        return false;
      }
      setFileList([...fileList, file]);
      return false;
    };

    return (
      <Form.Item
        label={label}
        name={name}
        placeholder={placeholder || label}
        hidden={hidden}
        extra={`${maxFileSize} MB / File`}
        rules={[
          {
            required: required && !hidden,
            message: `${label || placeholder || name} ${t("WORD_IS_REQUIRED")}`,
          },
        ]}
      >
        <Upload
          className={className || "file-uploader"}
          fileList={fileList}
          multiple={multiple || maxCount > 1 || false}
          maxCount={maxCount || 1}
          beforeUpload={beforeUpload}
          onRemove={(file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
          }}
        >
          <MyButtonSuccess
            disabled={uploading}
            label={t("WORD_CHOOSE_FILE")}
            noForm={true}
          />
        </Upload>
      </Form.Item>
    );
  }
);

export default UploadFilesManualModal;
