import { fetchFromUrlPOSTAsync } from "../utils/UrlHelper";
import ApiStorage from "../redux/ApiStorage";

const URL_LOGIN_MAGIC = "user/loginMagic";
const URL_LOGIN = "user/login";
const URL_LOG_OUT = "user/logout";
const URL_LIST = "user/list";
const URL_GET_ONE = "user/getOne";
const URL_SAVE_SETTINGS = "user/saveSettings";
const URL_USER_MANAGER_CREATE = "user/manager/create";
const URL_USER_MANAGER_UPDATE = "user/update";
const URL_CHANGE_PASSWORD = "user/changePassword";
const URL_ACTIVATE_USER = "user/activate";
const URL_USER_FORGOT_PASSSWORD_SEND_EMAIL = "user/forgotPassword/sendEamil";
const URL_USER_FORGOT_PASSSWORD_RESET = "user/forgotPassword/resetPassword";
const URL_SEND_USER_EMAIL = "user/sendDownloadLinkEmail";
const URL_DELETE_USER_TEAM = "user/deleteTeam";

const fs = {
  login: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_LOGIN, urlParams);
    if (result.success) {
      ApiStorage.setCurrentUser(Object.assign(result.data));
    }
    return result;
  },
  loginMagic: async (token) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_LOGIN_MAGIC + "/" + token,
      []
    );
    if (result.success) {
      ApiStorage.setCurrentUser(Object.assign(result.data));
    }
    return result;
  },
  logOut: async () => {
    const result = await fetchFromUrlPOSTAsync(URL_LOG_OUT, []);
    return result;
  },
  fillPagination: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_LIST, urlParams);
    return result;
  },
  getOne: async (idUser) => {
    const result = await fetchFromUrlPOSTAsync(URL_GET_ONE + "/" + idUser, []);
    return result;
  },
  saveSettings: async (values, idUser) => {
    const urlParams = [
      {
        key: "saveData",
        value: values,
      },
    ];
    let result;
    if (idUser) {
      result = fetchFromUrlPOSTAsync(URL_SAVE_SETTINGS, urlParams);
    } else {
      result = await fetchFromUrlPOSTAsync(URL_SAVE_SETTINGS, urlParams);
    }
    return result;
  },
  createManager: async (values) => {
    const result = await fetchFromUrlPOSTAsync(URL_USER_MANAGER_CREATE, {
      saveData: values,
    });
    if (result.success) {
      ApiStorage.setStepDetailsDone({ done_managers: 1 });
      // const currentUser = ApiStorage.getCurrentUser();
      // if (!currentUser?.stepsDone) {
      //   ApiStorage.setCurrentUser(
      //     Object.assign({}, currentUser, { stepsDoneDetails: { ...currentUser?.stepsDoneDetails, done_managers: 1 } })
      //   );
      // }
    }
    return result;
  },
  updateManager: async (values, idUser) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_USER_MANAGER_UPDATE + "/" + idUser,
      { saveData: values }
    );
    return result;
  },
  updatePassword: async (values, idUser) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_CHANGE_PASSWORD + "/" + idUser,
      { saveData: values }
    );
    return result;
  },
  activateUser: async (_key) => {
    // await UserActions.logOut();
    ApiStorage.clearUser();
    const result = await fetchFromUrlPOSTAsync(URL_ACTIVATE_USER + "/" + _key);
    if (result.success) {
      ApiStorage.setCurrentUser(result.data);
    }
    return result;
  },
  forgotPassword_sendEmail: async (values) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_USER_FORGOT_PASSSWORD_SEND_EMAIL,
      { saveData: values }
    );
    return result;
  },
  sendEmailUser: async (email) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_SEND_USER_EMAIL + "/" + email
    );
    return result;
  },
  deleteUserTeam: async (idUser) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_DELETE_USER_TEAM + "/" + idUser
    );
    return result;
  },
  forgotPassword_reset: async (values, activationKey) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_USER_FORGOT_PASSSWORD_RESET + "/" + activationKey,
      { saveData: values }
    );
    return result;
  },
};

const loc = {
  STATUS: {
    BLOCKED: 0,
    ACTIVE: 1,
    PENDING: 2,
    NOTCONFIRMED: 3,
    NOTREGISTERED: 4,
    TRIAL: 5,
    DELETED: 6,
  },
  getStatusLabel: (status) => {
    switch (status) {
      case 1:
        return { label: "Active", color: "#92cf5c" };
      case 2:
        return { label: "Pending", color: "#92cf5c" };
      case 3:
        return { label: "Not confirmed", color: "#92cf5c" };
      case 4:
        return { label: "Not registered", color: "#92cf5c" };
      case 5:
        return { label: "Trial", color: "#92cf5c" };
      case 6:
        return { label: "Deleted", color: "#fb6b5b" };
      case 0:
        return { label: "Blocked", color: "#fb6b5b" };
      default:
        return { label: "", color: "" };
    }
  },
  ROLE: {
    SUPERADMIN: 1,
    BRANCH: 2,
    MANAGER: 3,
  },
  getRoleLabel: (role) => {
    switch (role) {
      case 1:
        return { label: "Admin", color: "#92cf5c" };
      case 2:
        return { label: "Branch", color: "#92cf5c" };
      case 3:
        return { label: "Manager", color: "#92cf5c" };
      default:
        return { label: "", color: "" };
    }
  },
};

const UserActions = Object.assign(fs, loc);

export default UserActions;
