import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import UtilNumber from "../../../utils/UtilNumber";
import MySpace from "../../../components/mySpace/MySpace";
import MyPageHeader from "../../../components/myPageHeader/MyPageHeader";
import UtilNotify from "../../../utils/UtilNotify";
import StatisticCard from "../../../components/statisticCard/StatisticCard";
import CustomerActions from "../../../actions/CustomerActions";
import ApiStorage from "../../../redux/ApiStorage";
import { useSelector } from "react-redux";
import MyTable from "../../../components/myTable/MyTable";
import MyHelmet from "../../../components/helmet/MyHelmet";
import TotalDebtsSVG from "../../../icons/TotalDebtsSVG";
import ImportWhiteSVG from "../../../icons/ImportWhiteSVG";
import FormApp from "../../../components/formApp/FormApp";
import InputFormRangeDatePicker from "../../../components/inputFormRangeDatePicker/InputFormRangeDatePicker";
import SelectManager from "../../../components/selects/SelectManager";
import SelectDebitLimit from "../../../components/selects/SelectDebitLimit";
import { useForm } from "antd/es/form/Form";
import MyButtonText from "../../../components/myButton/MyButtonText";
import UtilDate from "../../../utils/UtilDate";
import { useFormatCurrency } from "../../../utils/useFormatWithCurrency";
import SearchInput from "../../../components/myInputForm/SearchInput";
import MyButtonGray from "../../../components/myButton/MyButtonGray";
import { Flex } from "antd";

const formName = "formDebtFilter";

const DebitList = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const { formatDoubleWithCurrency } = useFormatCurrency();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setpagination] = useState({ page: 0, perPage: 50 });
  const [totalRows, settotalRows] = useState(0);
  const [search, setSearch] = useState("");
  const [totals, settotals] = useState({});

  const debitPageFilters = useSelector((state) => {
    return state.debitPageFilters;
  });

  const is1c = useSelector((state) => {
    return state.currentUser?.is1c;
  });

  const onFinish = useCallback(
    (fil) => {
      if (fil.date && fil.date.length) {
        fil.dateFrom = fil.date[0];
        fil.dateTo = fil.date[1];
      } else {
        fil.dateFrom = null;
        fil.dateTo = null;
      }
      const obj = Object.assign({}, debitPageFilters, fil);
      ApiStorage.setDebitPageFilters(obj);
      setpage(0);
    },
    [debitPageFilters]
  );

  const setpage = (page) => {
    setpagination((ov) => ({ page: page, perPage: ov.perPage }));
  };

  const setFilterClear = () => {
    form.resetFields();
    ApiStorage.setDebitPageFilters({});
    setpage(0);
  };

  const handleExport = async () => {
    const fetchParams = {
      page: pagination.page,
      perPage: pagination.perPage,
      search: search,
      filter: { ...debitPageFilters },
    };
    await CustomerActions.exportExcel(fetchParams);
  };

  useEffect(() => {
    const fill = async () => {
      setLoading(true);
      const fetchParams = {
        page: pagination.page,
        perPage: pagination.perPage,
        search: search,
        filter: { ...debitPageFilters },
      };
      const result = await CustomerActions.fillPaginationDebit(fetchParams);
      if (result.success) {
        setData(result.data);
        settotalRows(result.totalRows);
        settotals(result.totals);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setLoading(false);
    };

    fill();
  }, [pagination, search, debitPageFilters]);

  useEffect(() => {
    if (debitPageFilters) {
      form?.setFieldsValue({
        ...debitPageFilters,
        date:
          debitPageFilters.date && debitPageFilters.date.length
            ? [
                UtilDate.getDate(debitPageFilters.date[0]),
                UtilDate.getDate(debitPageFilters.date[1]),
              ]
            : [],
      });
    }
  }, [debitPageFilters, form]);

  const columns = useMemo(() => {
    let col = [
      {
        title: t("WORD_NAME"),
        dataIndex: "name",
        key: "name",
        // width: 300,
      },
      {
        title: t("WORD_DEBIT"),
        dataIndex: "debit",
        key: "debit",
        width: 160,
        align: "right",
        render: (value) => {
          return <div>{formatDoubleWithCurrency(value)}</div>;
        },
      },
      {
        title: t("WORD_DEBIT_LIMIT"),
        dataIndex: "debitLimit",
        key: "debitLimit",
        width: 160,
        align: "right",
        render: (value) => {
          return <div>{value ? UtilNumber.formatDouble(value) : ""}</div>;
        },
      },
      {
        title: t("WORD_DIFFERENCE"),
        dataIndex: "diff",
        key: "diff",
        width: 160,
        align: "right",
        render: (value, { debitLimit }) => {
          return debitLimit > 0 ? (
            <div>{UtilNumber.formatDouble(value)}</div>
          ) : null;
        },
      },
    ];
    return col;
  }, [t, formatDoubleWithCurrency]);

  return (
    <>
      <MyHelmet title={t("WORD_DEBIT_REPORTING")} />
      <Flex vertical gap={10}>
        <MyPageHeader
          inPage
          extraRight={
            <MySpace>
              <MyButtonGray
                label={t("WORD_EXPORT")}
                icon={<ImportWhiteSVG />}
                onClick={handleExport}
              />
            </MySpace>
          }
          title={t("WORD_DEBTS")}
        />
        <MySpace style={{ marginLeft: 8 }}>
          <SearchInput
            onClear={() => {
              setSearch("");
              setpage(0);
            }}
            onPressEnter={(value) => {
              setSearch(value);
              setpage(0);
            }}
          />
          <FormApp onFinish={onFinish} name={formName} form={form}>
            <MySpace>
              {is1c ? null : (
                <InputFormRangeDatePicker
                  name="date"
                  onChange={() => form.submit()}
                />
              )}
              <SelectManager
                name="idManager"
                placeholder={t("WORD_SELECT_DISTRIBUTOR")}
                onChange={() => form.submit()}
                width={170}
              />
              {is1c ? null : (
                <SelectDebitLimit
                  name="limit"
                  placeholder={t("WORD_SELECT_DEBIT_LIMIT")}
                  onChange={() => form.submit()}
                  width={200}
                />
              )}
              <MyButtonText
                label={t("WORD_RESET_FILTERS")}
                onClick={setFilterClear}
              />
            </MySpace>
          </FormApp>
        </MySpace>
        <MySpace style={{ marginLeft: 8 }}>
          <StatisticCard
            title={t("WORD_TOTAL")}
            loading={loading}
            amount={Math.round(totals.totalDebit)}
            secondTitle={t("WORD_IN")}
            count={totals.totalRows}
            icon={<TotalDebtsSVG />}
          />
        </MySpace>
        <MyTable
          rowKey="idCustomer"
          // rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          loading={loading}
          total={totalRows}
          onChangePage={(e, ee) => {
            setpagination({ page: e, perPage: ee });
          }}
          page={pagination.page}
          pageSize={pagination.perPage}
        />
      </Flex>
    </>
  );
};

export default DebitList;
