import React from "react";
import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import UtilNumber from "../../utils/UtilNumber";

const InputEmailFormItem = ({
  classNameInput,
  className = "",
  label,
  name,
  placeholder,
  required = true,
  prefix,
  hidden = false,
  readOnly,
  allowClear = true,
  style,
}) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={label}
      name={name}
      hidden={hidden}
      style={style}
      className={`app-input ${className || ""}`}
      validateTrigger="onSubmit"
      rules={[
        {
          required: required && !hidden,
          message: `${label || placeholder} ${t("WORD_IS_REQUIRED")}`,
        },
        {
          type: "email",
          message: `${t("WORD_NOT_VALID_EMAIL")}`,
        },
      ]}
    >
      <Input
        className={`my-input input-email ${classNameInput || ""}`}
        placeholder={placeholder || label}
        prefix={prefix}
        readOnly={readOnly}
        allowClear={allowClear}
        autoComplete="nope"
        id={UtilNumber.getNewUniqID()}
      />
    </Form.Item>
  );
};

export default InputEmailFormItem;
