import React, { memo } from "react";
import MyIcon from "./MyIcon";

export const SVGIcon = ({ style, size }) => {
  return (
    <svg
      width={size || 41}
      height={size || 41}
      viewBox="0 0 41 41"
      style={style}
      fill="#a7a6b9"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.3338 0.277191C36.1123 0.277191 40.6661 5.03342 40.6661 12.1106V28.4437C40.6661 35.521 36.1123 40.2772 29.3318 40.2772H11.9985C5.21993 40.2772 0.666138 35.521 0.666138 28.4437V12.1106C0.666138 5.03342 5.21993 0.277191 11.9985 0.277191H29.3338ZM31.5393 21.3773C29.3954 20.0398 27.7403 21.9181 27.2938 22.5187C26.8634 23.0987 26.4933 23.7385 26.1032 24.3783C25.1499 25.9573 24.0578 27.7777 22.1673 28.8365C19.4201 30.3577 17.3345 28.9562 15.8342 27.9366C15.2711 27.5569 14.7241 27.1977 14.179 26.9583C12.8356 26.3783 11.6269 27.0388 9.83294 29.3174C8.89172 30.5084 7.95855 31.689 7.01331 32.8654C6.44817 33.5692 6.58292 34.6549 7.34515 35.1255C8.56189 35.8747 10.0461 36.2772 11.7234 36.2772H28.5789C29.5302 36.2772 30.4835 36.1472 31.3925 35.8499C33.4399 35.1812 35.0649 33.6497 35.9136 31.627C36.6296 29.9263 36.9775 27.8623 36.3078 26.1451C36.0845 25.5754 35.7507 25.045 35.2821 24.5785C34.0533 23.3587 32.9049 22.2194 31.5393 21.3773ZM13.6639 8.27719C10.9066 8.27719 8.66614 10.5207 8.66614 13.2772C8.66614 16.0337 10.9066 18.2772 13.6639 18.2772C16.4191 18.2772 18.6616 16.0337 18.6616 13.2772C18.6616 10.5207 16.4191 8.27719 13.6639 8.27719Z"
        fill="#a7a6b9"
      ></path>
    </svg>
  );
};

const ImagePlaceholderSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(ImagePlaceholderSVG);
