import React from "react";
import MyText from "../../../components/myText/MyText";
import { useTranslation } from "react-i18next";
import MySpace from "../../../components/mySpace/MySpace";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import useNavigateApi from "../../../appRoute/useNavigateApi";
import MyButtonSuccess from "../../../components/myButton/MyButtonSuccess";

const RegistrationFinish = () => {
  const { t } = useTranslation();
  const navigateApi = useNavigateApi();

  return (
    <div className="registration-finish-wrapper">
      <MySpace
        direction="vertical"
        align="center"
        style={{ justifyContent: "center" }}
      >
        <div>
          <FontAwesomeIcon icon={faCheck} color="rgb(78, 190, 100)" size="6x" />
        </div>
        <div className="registration-finish-text-wrapper">
          <MyText className="registration-finish-text">
            {t("WORD_REGISTRATION_FINISH_TEXT")}
          </MyText>
        </div>
        <MyButtonSuccess
          label={t("WORD_PROCEED_TO_LOGIN")}
          style={{ height: 44 }}
          onClick={() => navigateApi("/login", { state: { force: true } })}
          noForm
        />
      </MySpace>
    </div>
  );
};

export default RegistrationFinish;
