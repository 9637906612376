import { fetchFromUrlPOSTAsync } from "../utils/UrlHelper";

const URL_FILL_PAYMENT_TYPE_OPTIONS = "paymentTypeOptions/list";
const URL_SAVE_PAYMENT_TYPE_OPTIONS = "paymentType/saveSettings";
const URL_PAYMENT_TYPE_LIST = "paymentType/list";
const URL_CREATE_PAYMENT = "orders/payment/create";
const URL_UPDATE_PAYMENT = "orders/payment/update";

const fs = {
  paymentTypeOptions: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_FILL_PAYMENT_TYPE_OPTIONS,
      urlParams
    );
    return result;
  },
  save: async (values) => {
    const urlParams = [
      {
        key: "saveData",
        value: values,
      },
    ];
    const result = await fetchFromUrlPOSTAsync(
      URL_SAVE_PAYMENT_TYPE_OPTIONS,
      urlParams
    );
    return result;
  },
  create: async (values) => {
    let result = await fetchFromUrlPOSTAsync(URL_CREATE_PAYMENT, {
      saveData: values,
    });
    return result;
  },
  update: async (values, _idOrder) => {
    let result = await fetchFromUrlPOSTAsync(
      URL_UPDATE_PAYMENT + "/" + _idOrder,
      {
        saveData: values,
      }
    );
    return result;
  },
  paymentTypeList: async () => {
    const result = await fetchFromUrlPOSTAsync(URL_PAYMENT_TYPE_LIST, []);
    return result;
  },
  fillListOptions: async () => {
    const result = await paymentActions.paymentTypeList();
    if (result.success) {
      result.data = result.data.map((it) => {
        return {
          label: it.name,
          value: it.idPaymentType,
          ...it,
        };
      });
    }
    return result;
  },
};

const loc = {
  TYPE: {
    CASH: 1,
    TRANSFER: 2,
    OTHER: 3,
  },
  getType: (type) => {
    switch (type) {
      case 1:
        return {
          labelShort: "Cash",
          label: "Cash on delivery",
          value: 1,
        };
      case 2:
        return {
          labelShort: "Transfer",
          label: "Bank transfer",
          value: 2,
        };
      default:
        return {
          label: "Other",
          value: 3,
        };
    }
  },
};

const paymentActions = Object.assign(fs, loc);

export default paymentActions;
