import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AppRoutePrivate from "./AppRoutePrivate";
import MainLayout from "../layouts/mainLayout/MainLayout";
import AccountSettings from "../pages/settings/AccountSettings";
import SettingsLayout from "../layouts/settingsLayout/SettingsLayout";
import Dashboard from "../pages/dashboard/Dashboard";
import Login from "../pages/users/login/Login";
import CompanySettings from "../pages/settings/CompanySettings";
import PasswordSettings from "../pages/settings/PasswordSettings";
import GeneralSettings from "../pages/settings/GeneralSettings";
import PaymentTypeSettings from "../pages/settings/PaymentTypeSettings";
import PackageSettings from "../pages/settings/package/PackageSettings";
import ChangePackagePage from "../pages/settings/package/ChangePackagePage";
import ProductSettings from "../pages/settings/ProductSettings";
import ImportXmlSettings from "../pages/settings/ImportXmlSettings";
import NotificationSettings from "../pages/settings/NotificationSettings";
import ExportTemplatesList from "../pages/exportTemplates/ExportTemplatesList";
import ExportTemplatesEdit from "../pages/exportTemplates/ExportTemplatesEdit";
import Registration from "../pages/users/registration/Registration";
import RegistrationFinish from "../pages/users/registration/RegistrationFinish";
import ArcaPayApiCheck from "../redirects/ArcaPayApiCheck";
import ForgotPasswordPage from "../pages/users/forgotPassword/ForgotPasswordPage";
import ForgotPasswordSetNewPassword from "../pages/users/forgotPassword/ForgotPasswordSetNewPassword";
import PricingPage from "../pages/pricing/PricingPage";
import BlankLayout from "../layouts/blankLayout/BlankLayout";
import UserEmailConfirm from "../redirects/UserEmailConfirm";
import Test from "../pages/Test";
import Gps from "../pages/gps/Gps";
import OrderList from "../pages/orders/orderList/OrderList";
import OrderView from "../pages/orders/orderView/OrderView";
import PaymentList from "../pages/payments/paymentList/PaymentList";
import PaymentEdit from "../pages/payments/paymentEdit/PaymentEdit";
import VisitList from "../pages/visits/visitList/VisitList";
import VisitView from "../pages/visits/visitView/VisitView";
import ProductList from "../pages/products/productList/ProductList";
import ProductEdit from "../pages/products/productEdit/ProductEdit";
import FolderEdit from "../pages/products/productEdit/FolderEdit";
import ProductQuantities from "../pages/products/productQuantities/ProductQuantities";
import CustomerList from "../pages/customers/customerList/CustomerList";
import CustomerStatement from "../pages/customers/customerStatement/CustomerStatement";
import CustomerEdit from "../pages/customers/customerEdit/CustomerEdit";
import UserList from "../pages/users/userList/UserList";
import UserQuantities from "../pages/users/userQuantities/UserQuantities";
import UserEdit from "../pages/users/userEdit/UserEdit";
import UserRouteList from "../pages/users/userList/userRouteCustomers/UserRouteList";
import UserListCustomerTable from "../pages/users/userList/userRouteCustomers/UserListCustomerTable";
import UserListCustomerTableAddSection from "../pages/users/userList/userRouteCustomers/UserListCustomerTableAddSection";
import UserListCustomerDetails from "../pages/users/userList/userRouteCustomers/UserListCustomerDetails";
import DebitList from "../pages/debits/debitList/DebitList";
import DeviceList from "../pages/devices/deviceList/DeviceList";
import DeviceEdit from "../pages/devices/deviceEdit/DeviceEdit";
import BranchList from "../pages/branches/branchList/BranchList";
import BranchEdit from "../pages/branches/branchEdit/BranchEdit";
import AnalyticsLayout from "../layouts/analyticsLayout/AnalyticsLayout";
import AnalyticsOrders from "../pages/analytics/orders/AnalyticsOrders";
import AnalyticsPayments from "../pages/analytics/payments/AnalyticsPayments";
import AnalyticsCustomers from "../pages/analytics/customers/AnalyticsCustomers";
import PayIdramFail from "../redirects/idram/PayIdramFail";
import PayIdramSuccess from "../redirects/idram/PayIdramSuccess";
import LoginMagic from "../redirects/LoginMagic";
import InvoicePaymentFail from "../redirects/invoicePayment/InvoicePaymentFail";
import InvoicePaymentSuccess from "../redirects/invoicePayment/InvoicePaymentSuccess";
import OutsideLayout from "../layouts/outsideLayout/OutsideLayout";

const AppRoute = () => {
  return (
    <Routes>
      <Route path="/:lang">
        <Route index element={<Navigate to="login" replace />} />
        <Route
          path="login"
          element={
            <OutsideLayout>
              <Login />
            </OutsideLayout>
          }
        />
        <Route
          path="loginMagic/:_token"
          element={
            <BlankLayout>
              <LoginMagic />
            </BlankLayout>
          }
        />
        <Route
          path="registration"
          element={
            <OutsideLayout>
              <Registration />
            </OutsideLayout>
          }
        />
        <Route
          path="registration_finish"
          element={
            <OutsideLayout isFinish>
              <RegistrationFinish />
            </OutsideLayout>
          }
        />
        <Route
          path="arcaPayApiCheck/:_OrderNumber"
          element={
            <BlankLayout>
              <ArcaPayApiCheck />
            </BlankLayout>
          }
        />
        <Route
          path="forgot_password"
          element={
            <OutsideLayout>
              <ForgotPasswordPage />
            </OutsideLayout>
          }
        />
        <Route
          path="forgot_password_set/:activationKey"
          element={
            <OutsideLayout>
              <ForgotPasswordSetNewPassword />
            </OutsideLayout>
          }
        />
        <Route
          path="pricing"
          element={
            <OutsideLayout>
              <PricingPage />
            </OutsideLayout>
          }
        />
        <Route
          path="activate/:_id"
          element={
            <BlankLayout>
              <UserEmailConfirm />
            </BlankLayout>
          }
        />
        <Route
          path="dashboard"
          element={
            <AppRoutePrivate>
              <MainLayout fullPage>
                <Dashboard />
              </MainLayout>
            </AppRoutePrivate>
          }
        />
        <Route
          path="test"
          element={
            <AppRoutePrivate>
              <MainLayout fullPage>
                <Test />
              </MainLayout>
            </AppRoutePrivate>
          }
        />
        <Route
          path="gps"
          element={
            <AppRoutePrivate>
              <MainLayout fullPage>
                <Gps />
              </MainLayout>
            </AppRoutePrivate>
          }
        />
        <Route path="order">
          <Route index element={<Navigate to="list" replace />} />
          <Route
            path="list"
            replace
            index
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <OrderList />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="view/:_idOrder"
            replace
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <OrderView />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
        </Route>
        <Route path="payment">
          <Route index element={<Navigate to="list" replace />} />
          <Route
            path="list"
            replace
            index
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <PaymentList />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="view/:_idOrder"
            replace
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <OrderView isPayment />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="create"
            replace
            element={
              <AppRoutePrivate>
                <MainLayout>
                  <PaymentEdit isNew />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="edit/:_idOrder"
            replace
            element={
              <AppRoutePrivate>
                <MainLayout>
                  <PaymentEdit />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
        </Route>
        <Route path="visit">
          <Route index element={<Navigate to="list" replace />} />
          <Route
            path="list"
            replace
            index
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <VisitList />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="view/:_idManagerVisit"
            replace
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <VisitView />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
        </Route>
        <Route path="product">
          <Route index element={<Navigate to="list" replace />} />
          <Route
            path="list"
            replace
            index
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <ProductList />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="create"
            replace
            element={
              <AppRoutePrivate>
                <MainLayout>
                  <ProductEdit isNew />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="create/:_idParent"
            replace
            element={
              <AppRoutePrivate>
                <MainLayout>
                  <ProductEdit isNew />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="edit/:_idProduct"
            replace
            element={
              <AppRoutePrivate>
                <MainLayout>
                  <ProductEdit />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="list/:_idParent"
            replace
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <ProductList />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="quantities/:_idProduct"
            replace
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <ProductQuantities />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route path="folder">
            <Route
              path="create"
              replace
              element={
                <AppRoutePrivate>
                  <MainLayout>
                    <FolderEdit isNew />
                  </MainLayout>
                </AppRoutePrivate>
              }
            />
            <Route
              path="create/:_idParent"
              replace
              element={
                <AppRoutePrivate>
                  <MainLayout>
                    <FolderEdit isNew />
                  </MainLayout>
                </AppRoutePrivate>
              }
            />
            <Route
              path="edit/:_idProduct"
              replace
              element={
                <AppRoutePrivate>
                  <MainLayout>
                    <FolderEdit />
                  </MainLayout>
                </AppRoutePrivate>
              }
            />
          </Route>
        </Route>
        <Route path="customer">
          <Route index element={<Navigate to="list" replace />} />
          <Route
            path="list"
            replace
            index
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <CustomerList />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="statement/:_idCustomer"
            replace
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <CustomerStatement />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="edit/:_idCustomer"
            replace
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <CustomerEdit />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="create"
            replace
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <CustomerEdit />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
        </Route>
        <Route path="user">
          <Route index element={<Navigate to="list" replace />} />
          <Route
            path="list"
            replace
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <UserList />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="quantities/:_idUser"
            replace
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <UserQuantities />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="edit/:_idUser"
            replace
            element={
              <AppRoutePrivate>
                <MainLayout>
                  <UserEdit />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="create"
            replace
            element={
              <AppRoutePrivate>
                <MainLayout>
                  <UserEdit isNew />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route path="route">
            <Route path=":_idManager">
              <Route
                index
                element={
                  <AppRoutePrivate>
                    <MainLayout fullPage>
                      <UserRouteList>
                        <UserListCustomerTable />
                      </UserRouteList>
                    </MainLayout>
                  </AppRoutePrivate>
                }
              />
              <Route
                path="add"
                replace
                element={
                  <AppRoutePrivate>
                    <MainLayout fullPage>
                      <UserRouteList>
                        <UserListCustomerTableAddSection />
                      </UserRouteList>
                    </MainLayout>
                  </AppRoutePrivate>
                }
              />
              <Route
                path=":_idRoute"
                replace
                element={
                  <AppRoutePrivate>
                    <MainLayout fullPage>
                      <UserRouteList>
                        <UserListCustomerTable />
                      </UserRouteList>
                    </MainLayout>
                  </AppRoutePrivate>
                }
              />
              <Route
                path=":_idRoute/add"
                replace
                element={
                  <AppRoutePrivate>
                    <MainLayout fullPage>
                      <UserRouteList>
                        <UserListCustomerTableAddSection />
                      </UserRouteList>
                    </MainLayout>
                  </AppRoutePrivate>
                }
              />
            </Route>
            <Route
              path="customer/details/:_idManager/:_idCustomer"
              replace
              element={
                <AppRoutePrivate>
                  <MainLayout>
                    <UserListCustomerDetails />
                  </MainLayout>
                </AppRoutePrivate>
              }
            />
          </Route>
        </Route>
        <Route
          path="debit/list"
          replace
          element={
            <AppRoutePrivate>
              <MainLayout fullPage>
                <DebitList />
              </MainLayout>
            </AppRoutePrivate>
          }
        />
        <Route path="device">
          <Route index element={<Navigate to="list" replace />} />
          <Route
            path="list"
            replace
            index
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <DeviceList />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="edit/:_idDevice"
            replace
            element={
              <AppRoutePrivate>
                <MainLayout>
                  <DeviceEdit />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
        </Route>
        <Route path="branch">
          <Route index element={<Navigate to="list" replace />} />
          <Route
            path="list"
            replace
            index
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <BranchList />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="edit/:_idBranch"
            replace
            element={
              <AppRoutePrivate>
                <MainLayout>
                  <BranchEdit />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
        </Route>
        <Route path="settings">
          <Route index element={<Navigate to="account" replace />} />
          <Route
            path="account"
            replace
            index
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <SettingsLayout>
                    <AccountSettings />
                  </SettingsLayout>
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="company"
            replace
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <SettingsLayout>
                    <CompanySettings />
                  </SettingsLayout>
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="password"
            replace
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <SettingsLayout>
                    <PasswordSettings />
                  </SettingsLayout>
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="general"
            replace
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <SettingsLayout>
                    <GeneralSettings />
                  </SettingsLayout>
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="payment"
            replace
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <SettingsLayout>
                    <PaymentTypeSettings />
                  </SettingsLayout>
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="package"
            replace
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <SettingsLayout fullPage>
                    <PackageSettings />
                  </SettingsLayout>
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="package_change"
            replace
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <ChangePackagePage />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="product"
            replace
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <SettingsLayout>
                    <ProductSettings />
                  </SettingsLayout>
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="importxml"
            replace
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <SettingsLayout>
                    <ImportXmlSettings />
                  </SettingsLayout>
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="notification"
            replace
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <NotificationSettings />
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route path="exportTemplates">
            <Route
              path="list"
              replace
              element={
                <AppRoutePrivate>
                  <MainLayout fullPage>
                    <SettingsLayout fullPage>
                      <ExportTemplatesList />
                    </SettingsLayout>
                  </MainLayout>
                </AppRoutePrivate>
              }
            />
            <Route
              path="edit/:_id"
              replace
              element={
                <AppRoutePrivate>
                  <MainLayout fullPage>
                    <SettingsLayout fullPage>
                      <ExportTemplatesEdit />
                    </SettingsLayout>
                  </MainLayout>
                </AppRoutePrivate>
              }
            />
            <Route
              path="create"
              replace
              element={
                <AppRoutePrivate>
                  <MainLayout fullPage>
                    <SettingsLayout fullPage>
                      <ExportTemplatesEdit isNew />
                    </SettingsLayout>
                  </MainLayout>
                </AppRoutePrivate>
              }
            />
          </Route>
        </Route>
        <Route path="analytics">
          <Route index element={<Navigate to="orders" replace />} />
          <Route
            path="orders"
            replace
            index
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <AnalyticsLayout fullPage>
                    <AnalyticsOrders />
                  </AnalyticsLayout>
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="payments"
            replace
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <AnalyticsLayout fullPage>
                    <AnalyticsPayments />
                  </AnalyticsLayout>
                </MainLayout>
              </AppRoutePrivate>
            }
          />
          <Route
            path="customers"
            replace
            element={
              <AppRoutePrivate>
                <MainLayout fullPage>
                  <AnalyticsLayout fullPage>
                    <AnalyticsCustomers />
                  </AnalyticsLayout>
                </MainLayout>
              </AppRoutePrivate>
            }
          />
        </Route>
        <Route path="home" element={<Navigate to={"dashboard"} replace />} />
      </Route>
      <Route path="payment">
        <Route
          path="idramfail"
          replace
          index
          element={
            // <AppRoutePrivate>
            <BlankLayout>
              <PayIdramFail />
            </BlankLayout>
            // </AppRoutePrivate>
          }
        />
        <Route
          path="idramsuccess"
          element={
            // <AppRoutePrivate>
            <BlankLayout>
              <PayIdramSuccess />
            </BlankLayout>
            // </AppRoutePrivate>
          }
        />
        <Route
          path="fail"
          replace
          index
          element={
            <AppRoutePrivate>
              <BlankLayout>
                <InvoicePaymentFail />
              </BlankLayout>
            </AppRoutePrivate>
          }
        />
        <Route
          path="success"
          element={
            <AppRoutePrivate>
              <BlankLayout>
                <InvoicePaymentSuccess />
              </BlankLayout>
            </AppRoutePrivate>
          }
        />
      </Route>
      {/* <Route path="page_not_found" element={<ErrorPage404 />} /> */}
      <Route path="/" index element={<Navigate to={"/en/login"} replace />} />
      <Route path="*" element={<Navigate to={"/hy/dashboard"} />} />
    </Routes>
  );
};

export default AppRoute;
