import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelectFormItem from "../mySelectFormItem/MySelectFormItem";
import OrdersActions from "../../actions/OrdersActions";

const SelectOrderType = ({ withAll, ...otherProps }) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    let data = [
      { label: t("WORD_ORDERS"), value: OrdersActions.TYPE.DEFAULT },
      { label: t("WORD_RETURNS"), value: OrdersActions.TYPE.RETURN },
    ];
    if (withAll) data.unshift({ label: t("WORD_ALL"), value: "" });
    setlist(data);
  }, [t, withAll]);

  return <MySelectFormItem {...otherProps} options={list} />;
};

export default SelectOrderType;
