import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MyHelmet from "../../../components/helmet/MyHelmet";
import MySpace from "../../../components/mySpace/MySpace";
import MyPageHeader from "../../../components/myPageHeader/MyPageHeader";
import AnalyticsOrdersFilterDrawer from "../orders/AnalyticsOrdersFilterDrawer";
import AnalyticsStatsCard from "../analyticsStatsCard/AnalyticsStatsCard";
import SalesSVG from "../../../icons/SalesSVG";
import PaymentsSVG from "../../../icons/PaymentsSVG";
import DebtsSVG from "../../../icons/DebtsSVG";
import { useSelector } from "react-redux";
import AnalyticsActions from "../../../actions/AnalyticsActions";
import UtilNotify from "../../../utils/UtilNotify";
import AnalyticsVsSection from "../AnalyticsVsSection";
import OrdersActions from "../../../actions/OrdersActions";
import AnalyticsByPlaceSort from "../AnalyticsByPlaceSort";
import PaymentBarChartSection from "./PaymentBarChartSection";
import AnalyticsBySortSection from "../AnalyticsBySortSection";
import { Flex } from "antd";

const AnalyticsPayments = () => {
  const { t } = useTranslation();

  const filter = useSelector((state) => {
    return state.analyticsFilters;
  });

  const [resultOrders, setresultOrders] = useState({});
  const [loading, setloading] = useState(true);

  useEffect(() => {
    const fill = async () => {
      setloading(true);
      const result = await AnalyticsActions.ordersStats({ filter: filter });
      if (result.success) {
        setresultOrders(result.data);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setloading(false);
    };
    fill();
  }, [filter]);

  const totalDebt =
    resultOrders?.totalAmountNet - resultOrders?.totalAmountPayment;
  const totalDebtP =
    resultOrders?.p?.totalAmountNet - resultOrders?.p?.totalAmountPayment;

  return (
    <>
      <MyHelmet title={t("WORD_ANALYTICS_PAYMENTS")} />
      <Flex vertical gap={16}>
        <MyPageHeader
          inPage
          extraRight={
            <MySpace>
              <AnalyticsOrdersFilterDrawer />
            </MySpace>
          }
          title={t("WORD_ANALYTICS_PAYMENTS")}
        />
        <Flex gap={13}>
          <AnalyticsStatsCard
            icon={<SalesSVG />}
            title={t("WORD_SALES")}
            count={resultOrders?.countNet}
            total={resultOrders?.totalAmountNet}
            totalP={resultOrders?.p?.totalAmountNet}
            loading={loading}
          />
          <AnalyticsStatsCard
            icon={<PaymentsSVG />}
            title={t("WORD_PAYMENTS")}
            count={resultOrders?.countTypePayment}
            total={resultOrders?.totalAmountPayment}
            totalP={resultOrders?.p?.totalAmountPayment}
            loading={loading}
          />
          <AnalyticsStatsCard
            icon={<DebtsSVG />}
            title={t("WORD_DEBTS")}
            withoutQuantity
            total={totalDebt}
            totalP={totalDebtP}
            loading={loading}
          />
        </Flex>
        <PaymentBarChartSection />
        <AnalyticsBySortSection
          title={t("WORD_ORDERS_BY_PAYMENT_METHOD")}
          filter={Object.assign({}, filter, {
            type: OrdersActions.TYPE.PAYMENT,
          })}
        />
        <Flex gap={13}>
          <AnalyticsVsSection
            title={t("WORD_PAYMENTS_VS_DEBTS")}
            loading={loading}
            chartData={[
              {
                label: t("WORD_PAYMENTS"),
                value: resultOrders?.totalAmountPayment,
                valueP: resultOrders?.p?.totalAmountPayment,
                per: resultOrders?.totalAmountNet
                  ? resultOrders.totalAmountNet !== 0
                    ? (resultOrders.totalAmountPayment * 100) /
                      resultOrders.totalAmountNet
                    : 0
                  : 0,
                className: "value-name-color1",
                color: "rgb(88, 97, 247)",
              },
              {
                label: t("WORD_DEBTS"),
                value: totalDebt,
                valueP: totalDebtP,
                per: resultOrders?.totalAmountNet
                  ? resultOrders.totalAmountNet !== 0
                    ? (totalDebt * 100) / resultOrders.totalAmountNet
                    : 0
                  : 0,
                className: "value-name-color2",
                color: "rgb(255, 158, 52)",
              },
            ]}
          />
          <AnalyticsByPlaceSort
            filter={Object.assign({}, filter, {
              type: OrdersActions.TYPE.PAYMENT,
            })}
          />
        </Flex>
      </Flex>
    </>
  );
};

export default AnalyticsPayments;
