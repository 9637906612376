import React, { forwardRef, useImperativeHandle } from "react";
import { Drawer } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import MyButtonSecondary from "../myButton/MyButtonSecondary";
import MySpace from "../mySpace/MySpace";
import { ReloadOutlined } from "@ant-design/icons";
import useWindowSize from "../../utils/useWindowSize";
import MyButtonSuccess from "../myButton/MyButtonSuccess";

const MyDrawerWithReset = forwardRef(
  (
    {
      children,
      title,
      label,
      formName,
      icon,
      loading,
      forceRender = false,
      destroyOnClose,
      noExtraBtn,
      handleReset,
      definedCount,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { isMobile } = useWindowSize();

    const [visible, setVisible] = useState(false);

    const showDrawer = () => {
      setVisible(true);
    };

    const closeDrawer = () => {
      setVisible(false);
    };

    useImperativeHandle(ref, () => ({
      showDrawer: showDrawer,
      closeDrawer: closeDrawer,
    }));

    return (
      <>
        <MyButtonSecondary
          onClick={showDrawer}
          label={isMobile ? null : label}
          icon={icon}
          loading={loading}
        />
        <Drawer
          title={title}
          placement="right"
          onClose={closeDrawer}
          open={visible}
          forceRender={forceRender}
          destroyOnClose={destroyOnClose}
          // getContainer={false}
          // ! ^ causes issue in mobile
          extra={
            noExtraBtn ? null : (
              <MySpace>
                <MyButtonSecondary
                  onClick={handleReset}
                  label={t("WORD_RESET")}
                  icon={<ReloadOutlined />}
                  disabled={!Boolean(definedCount)}
                  reset
                />
                <MyButtonSuccess
                  type="primary"
                  htmlType="submit"
                  form={formName}
                  onClick={closeDrawer}
                  label={t("WORD_APPLY")}
                />
              </MySpace>
            )
          }
        >
          {children}
        </Drawer>
      </>
    );
  }
);

export default MyDrawerWithReset;
