import { useForm } from "antd/es/form/Form";
import md5 from "md5";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import UserActions from "../../../actions/UserActions";
import UtilNotify from "../../../utils/UtilNotify";
import { Form } from "antd";
import FormApp from "../../../components/formApp/FormApp";
import InputTextFormItem from "../../../components/myInputForm/InputTextFormItem";
import InputPasswordFormItem from "../../../components/myInputForm/InputPasswordFormItem";
import MySpace from "../../../components/mySpace/MySpace";
import CheckBoxFormItem from "../../../components/myCheckbox/CheckBoxFormItem";
import MyLink from "../../../components/myLink/MyLink";
import MyCaptcha from "../../../components/myGoogleCaptcha/MyCaptcha";
import MyCard from "../../../components/myCard/MyCard";
import ApiLocalStorage from "../../../redux/ApiLocalStorage";
import UtilObject from "../../../utils/UtilObject";
import LoadingIconApp from "../../../components/loadingIconApp/LoadingIconApp";
import MyHelmetOutside from "../../../components/helmet/MyHelmetOutside";
import useNavigateApi from "../../../appRoute/useNavigateApi";
import MyTextTitle from "../../../components/myText/MyTextTitle";
import MyText from "../../../components/myText/MyText";
import MyButtonPurple from "../../../components/myButton/MyButtonPurple";
import "./Login.scss";

const formName = "login_form";

const Login = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const location = useLocation();

  const navigateApi = useNavigateApi();

  const [CaptchaCheck, setCaptchaCheck] = useState(false);
  const [saving, setsaving] = useState(false);
  const rememberMe = Form.useWatch("rememberMe", form);
  const [loading, setloading] = useState(true);

  // useEffect(() => {
  //   ApiStorage.clearUser()
  // }, [])

  useEffect(() => {
    const checkLogin = async () => {
      if (
        (location && !location.state) ||
        (location.state.hasOwnProperty("force") && !location.state.force)
      ) {
        let obj = ApiLocalStorage.getItem(ApiLocalStorage.TEMP_REMEMBER_ME);
        if (!UtilObject.isEmpty(obj)) {
          let result = await UserActions.login(obj);
          if (result.success) {
            navigateApi("/home");
            return;
          }
        }
      }
      // ApiStorage.clearUser();
      setloading(false);
    };
    checkLogin();
    // setloading(false);
  }, [location, navigateApi]);

  const onFinish = async (values) => {
    setsaving(true);
    let password = md5(values.password);
    values.password = password;
    let result = await UserActions.login(values);
    if (result.success) {
      if (rememberMe) {
        values.rememberMe = true;
        ApiLocalStorage.setItem(ApiLocalStorage.TEMP_REMEMBER_ME, values);
      }
      navigateApi("/home");
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    setsaving(false);
  };

  if (loading) {
    return (
      <div
        style={{ height: "100vh", display: "flex", justifyContent: "center" }}
      >
        <LoadingIconApp />
      </div>
    );
  }

  return (
    <>
      <MyHelmetOutside title={t("WORD_SIGN_IN")} />
      <div className="outside-card-wrapper">
        <MyCard withoutShadow>
          <MySpace direction="vertical" style={{ gap: 10, marginBottom: 10 }}>
            <MyTextTitle size={30}>{t("WORD_SIGN_IN")}</MyTextTitle>
            <MySpace>
              <MyText>{t("WORD_DONT_HAVE_AN_ACCOUNT")}</MyText>
              <MyLink
                style={{ fontWeight: 500 }}
                color="primary"
                underline
                path="/registration"
              >
                {t("WORD_CREATE_NOW")}
              </MyLink>
            </MySpace>
          </MySpace>
          <FormApp
            form={form}
            name={formName}
            onFinish={onFinish}
            initialValues={{ rememberMe: true }}
            labelCol={{ style: { fontWeight: "bold", padding: 0 } }}
            size="large"
          >
            <MySpace direction="vertical" fullWidth style={{ gap: 20 }}>
              <InputTextFormItem
                label={t("WORD_USERNAME")}
                name="login"
                required
              />
              <InputPasswordFormItem
                label={t("WORD_PASSWORD")}
                min={4}
                name="password"
              />
            </MySpace>
            <MySpace spaceBetween fullWidth className="login-space-row">
              <CheckBoxFormItem
                name="rememberMe"
                label={t("WORD_REMEMBER_ME")}
              />
              <MyLink
                style={{ fontWeight: 500 }}
                color="primary"
                underline
                path="/forgot_password"
              >
                {t("WORD_FORGOT_PASSWORD?")}
              </MyLink>
            </MySpace>
          </FormApp>
          <div className="registration-btn-wrapper">
            <MyButtonPurple
              fullWidth
              label={t("WORD_SIGN_IN")}
              form={formName}
              disabled={!CaptchaCheck}
              loading={saving}
            />
          </div>
          <MyCaptcha
            onChange={(token) => {
              setCaptchaCheck(Boolean(token));
            }}
          />
        </MyCard>
      </div>
    </>
  );
};

export default Login;
