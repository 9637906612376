import { Tabs } from "antd";
import React from "react";

const MyTabs = ({ className, ...otherProps }) => {

  return (
    <Tabs
      {...otherProps}
      className={`${className} my-tabs`}
    />
  );
};

export default MyTabs;
