import React, { useEffect, useRef } from "react";
import MySpace from "../../../components/mySpace/MySpace";
import SelectOrderPaymentType from "../../../components/selects/SelectOrderPaymentType";
import FormApp from "../../../components/formApp/FormApp";
import SelectOrderBranch from "../../../components/selects/SelectOrderBranch";
import { FilterOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import SelectOrderExported from "../../../components/selects/SelectOrderExported";
import SelectPaymentStatus from "../../../components/selects/SelectPaymentStatus";
import { useTranslation } from "react-i18next";
import InputFormDatePicker from "../../../components/inputFormDatePicker/InputFormDatePicker";
import MyDrawerWithReset from "../../../components/myDrawer/MyDrawerWithReset";
import SelectManagerMulti from "../../../components/selects/SelectManagerMulti";
import SelectCustomerMulti from "../../../components/selects/SelectCustomerMulti";
import useWindowSize from "../../../utils/useWindowSize";
import UtilDate from "../../../utils/UtilDate";

const formName = "formPaymentFilter";

const PaymentListFilterDrawer = ({ onChange, onReset, loading, filter }) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const refDrawer = useRef();
  const { isMobile } = useWindowSize();

  useEffect(() => {
    if (filter) {
      const obj = { ...filter };
      if (filter?.dateFrom) {
        obj.dateFrom = UtilDate.getDate(filter.dateFrom);
      }
      if (filter?.dateTo) {
        obj.dateTo = UtilDate.getDate(filter.dateTo);
      }
      form?.setFieldsValue(obj);
    }
  }, [filter, form]);

  const onFinish = (values) => {
    onChange && onChange(values);
    refDrawer?.current?.closeDrawer();
  };

  const handleReset = () => {
    form?.resetFields();
    onReset && onReset();
    refDrawer?.current?.closeDrawer();
  };

  const countDefinedValues = (paymentPageFilters) => {
    return Object.values(paymentPageFilters).filter(
      (value) =>
        value !== undefined && (Array.isArray(value) ? value.length > 0 : true)
    ).length;
  };

  const definedCount = countDefinedValues(filter);

  return (
    <MyDrawerWithReset
      ref={refDrawer}
      label={!isMobile ? t("WORD_FILTER") : ""}
      icon={<FilterOutlined />}
      loading={loading}
      formName={formName}
      handleReset={handleReset}
      definedCount={definedCount}
    >
      <FormApp onFinish={onFinish} name={formName} form={form}>
        <MySpace direction="vertical" size="middle" fullWidth>
          <SelectPaymentStatus
            label={t("WORD_SELECT_STATUS")}
            placeholder={t("WORD_SELECT_STATUS")}
            name="confirmStatus"
            withAll
          />
          <SelectOrderPaymentType
            placeholder={t("WORD_SELECT_PAYMENT_TYPE")}
            label={t("WORD_SELECT_PAYMENT_TYPE")}
            name="idPaymentType"
            withAll
          />
          <InputFormDatePicker
            placeholder={t("WORD_DATE_FROM")}
            label={t("WORD_DATE_FROM")}
            name="dateFrom"
          />
          <InputFormDatePicker
            placeholder={t("WORD_DATE_TO")}
            name="dateTo"
            label={t("WORD_DATE_TO")}
          />
          <SelectOrderBranch
            placeholder={t("WORD_SELECT_BRANCH")}
            label={t("WORD_SELECT_BRANCH")}
            name="idBranch"
            withAll
          // onLoad={(val) => {
          //   form.setFieldsValue({ idBranch: val });
          // }}
          />
          <SelectManagerMulti
            placeholder={t("WORD_SELECT_DISTRIBUTOR")}
            label={t("WORD_SELECT_DISTRIBUTOR")}
            name="idManager"
            withAll
          />
          <SelectCustomerMulti
            placeholder={t("WORD_SELECT_CUSTOMER")}
            label={t("WORD_SELECT_CUSTOMER")}
            name="idCustomer"
            withAll
          />
          <SelectOrderExported
            placeholder={t("WORD_SELECT_EXPORT")}
            label={t("WORD_SELECT_EXPORT")}
            name="exportedAdmin"
            withAll
          />
        </MySpace>
      </FormApp>
    </MyDrawerWithReset>
  );
};

export default PaymentListFilterDrawer;
