import React from "react";
import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";

const InputFormPasswordConfirm = ({
  label,
  name = "confirmPassword",
  hasFeedback = false,
  passwordToConfirm = "password",
  required = true,
  message = "WORD_PLEASE_CONFIRM_YOUR_PASSWORD",
  placeholder = "Confirm password",
  value,
  onChange,
  allowClear = true,
}) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={label}
      name={name}
      hasFeedback={hasFeedback}
      dependencies={[passwordToConfirm]}
      className="passowowrd-confirm-form-item"
      rules={[
        { required: required, message: t(message) },
        ({ getFieldValue }) => ({
          validator(rule, value) {
            if (!value || getFieldValue(passwordToConfirm) === value) {
              return Promise.resolve();
            }
            return Promise.reject(
              "The two passwords that you entered do not match!"
            );
          },
        }),
      ]}
    >
      <Input.Password
        placeholder={label ? label : placeholder}
        value={value}
        onChange={onChange}
        allowClear={allowClear}
      />
    </Form.Item>
  );
};

export default InputFormPasswordConfirm;
