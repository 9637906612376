import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "antd/es/form/Form";
import md5 from "md5";
import ClientSaveActions from "../../../actions/ClientSaveActions";
import UtilNotify from "../../../utils/UtilNotify";
import MyCard from "../../../components/myCard/MyCard";
import FormApp from "../../../components/formApp/FormApp";
import MySpace from "../../../components/mySpace/MySpace";
import InputTextFormItem from "../../../components/myInputForm/InputTextFormItem";
import InputEmailFormItem from "../../../components/myInputForm/InputEmailFormItem";
import SelectCountry from "../../../components/selects/SelectCountry";
import CheckBoxFormItem from "../../../components/myCheckbox/CheckBoxFormItem";
import MyLink from "../../../components/myLink/MyLink";
import MyCaptcha from "../../../components/myGoogleCaptcha/MyCaptcha";
import InputPasswordFormItem from "../../../components/myInputForm/InputPasswordFormItem";
import MyHelmetOutside from "../../../components/helmet/MyHelmetOutside";
import useNavigateApi from "../../../appRoute/useNavigateApi";
import UtilIPInfo from "../../../utils/UtilIPInfo";
import CountryActions from "../../../actions/CountryActions";
import InputPhoneNumFormItem from "../../../components/myInputForm/InputPhoneNumFormItem";
import MyLinkA from "../../../components/myLinkA/MyLinkA";
import MyTextTitle from "../../../components/myText/MyTextTitle";
import MyText from "../../../components/myText/MyText";
import MyButtonPurple from "../../../components/myButton/MyButtonPurple";
import "../login/Login.scss";

const formName = "registration_form";

const Registration = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const navigateApi = useNavigateApi();

  const [CaptchaCheck, setCaptchaCheck] = useState(false);
  const [saving, setsaving] = useState(false);
  const [agree, setAgree] = useState(false);

  const handleCheckboxChange = (isChecked) => {
    setAgree(isChecked);
  };

  useEffect(() => {
    const setDefault = () => {
      form.setFieldsValue({ phoneCode: "+374", idCountry: 11 });
    };

    const fill = async () => {
      const info = await UtilIPInfo.getInfo();
      if (info?.country_code) {
        const resultCountry = await CountryActions.getOneByCountryCode(
          info?.country_code
        );
        if (resultCountry.success) {
          form.setFieldsValue({
            phoneCode: resultCountry.data.codePhone,
            idCountry: resultCountry.data.IdCountries,
          });
        } else {
          setDefault();
        }
      } else {
        setDefault();
      }
    };
    fill();
  }, [form]);

  const onFinish = async (values) => {
    setsaving(true);
    values.password = md5(values.password);
    values.companyPhone = values.phone.phoneNumber;
    values.phoneCode = values.phone.countryCode;
    values.userName = values.companyEmail;
    const result = await ClientSaveActions.register(values);
    if (result.success) {
      navigateApi("/registration_finish");
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    setsaving(false);
  };

  return (
    <>
      <MyHelmetOutside title={t("WORD_REGISTRATION")} />
      <div className="registration-card-wrapper">
        <MyCard withoutShadow>
          <MySpace direction="vertical" style={{ gap: 14, marginBottom: 30 }}>
            <MyTextTitle size={30}>{t("WORD_REGISTRATION")}</MyTextTitle>
            <MySpace>
              <MyText>{t("WORD_ALREADY_HAVE_AN_ACCOUNT")}</MyText>
              <MyLink
                style={{ fontWeight: 500 }}
                color="primary"
                underline
                path="/login"
                state={{ force: true }}
              >
                {t("WORD_SIGN_IN")}
              </MyLink>
            </MySpace>
          </MySpace>
          <FormApp
            form={form}
            name={formName}
            onFinish={onFinish}
            labelCol={{ style: { fontWeight: "bold", padding: 0 } }}
            size="large"
          >
            <MySpace direction="vertical" fullWidth size="middle">
              <InputTextFormItem
                name="companyName"
                label={t("WORD_COMPANY_NAME")}
                placeholder={t("WORD_EX_APPLE")}
                required
              />
              <InputEmailFormItem
                name="companyEmail"
                label={t("WORD_EMAIL")}
                placeholder={t("WORD_EX_JOHN_SMITH")}
                required
              />
              <InputPasswordFormItem
                name="password"
                required
                label={t("WORD_PASSWORD")}
                min={4}
              />
              <SelectCountry
                label={t("WORD_COUNTRY")}
                name="idCountry"
                showSearch
                placeholder={t("WORD_SELECT_COUNTRY")}
                onChangeItem={(cc) => {
                  if (cc) {
                    form?.setFieldsValue({ phoneCode: cc.codePhone });
                  }
                }}
                required
              />
              <InputPhoneNumFormItem
                name="phone"
                label={t("WORD_PHONE")}
                placeholder={"ex. 129000000"}
                required
              />
              {/* <InputTextFormItem
                name="recaptchaToken"
                required
                hidden
              /> */}
              {/* <InputTextFormItem
                name="userName"
                required
                placeholder={t("WORD_EX_APPLE_INC")}
                label={t("WORD_USERNAME")}
                hidden
              /> */}
              <CheckBoxFormItem
                onChange={handleCheckboxChange}
                name="agree"
                label={
                  <>
                    {t("WORD_TERMS_ACCEPT")}
                    {"  "}
                    <MyLinkA
                      label={t("WORD_TERMS_AND_CONDITIONS")}
                      style={{ fontWeight: 500 }}
                      color="primary"
                      underline
                      href="https://ordersbook.com/terms"
                    />
                  </>
                }
                className="register-checkbox"
              />
            </MySpace>
          </FormApp>
          <div className="registration-btn-wrapper">
            <MyButtonPurple
              fullWidth
              label={t("WORD_SIGN_UP")}
              form={formName}
              disabled={!CaptchaCheck || !agree}
              loading={saving}
            />
          </div>
          <MyCaptcha
            onChange={(token) => {
              setCaptchaCheck(Boolean(token));
            }}
          />
        </MyCard>
      </div>
    </>
  );
};

export default Registration;
