import React, { memo } from "react";
import DashboardSalesCard from "./DashboardSalesCard";
import DashboardTopProductsCard from "./DashboardTopProductsCard";
import MySpace from "../../components/mySpace/MySpace";
import DashboardTopCustomersCard from "./DashboardTopCustomersCard";

const DashboardStatRow = () => {
  return (
    <MySpace align="top" fullWidth className="card-row" style={{ gap: 10 }}>
      <div style={{ height: "100%" }}>
        <DashboardSalesCard />
      </div>
      <div style={{ height: "100%", maxHeight: 340 }}>
        <DashboardTopProductsCard />
      </div>
      <div style={{ height: "100%", maxHeight: 340 }}>
        <DashboardTopCustomersCard />
      </div>
    </MySpace>
  );
};

export default memo(DashboardStatRow);
