import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MyLink from "../../components/myLink/MyLink";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import useWindowSize from "../../utils/useWindowSize";
import DashboardSVG from "../../icons/DashboardSVG";
import { Menu } from "antd";

const BlockedSideBarMenu = ({ onCloseToggle }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  const [selectedMenuKey, setselectedMenuKey] = useState(location.pathname);

  const sideMenuColapsed = useSelector((state) => {
    return state.sideMenuColapsed;
  });

  const lang = useSelector((state) => {
    return state.lang;
  });

  useEffect(() => {
    setselectedMenuKey(location.pathname);
  }, [location.pathname]);

  let svgSize = sideMenuColapsed ? 20 : 25;

  const items = [
    {
      key: `/${lang}/dashboard`,
      className: "side-menu-item",
      label: (
        <MyLink path="/dashboard" state={{}}>
          {t("WORD_DASHBOARD")}
        </MyLink>
      ),
      icon: <DashboardSVG size={svgSize} />,
    },
    {
      type: "divider",
    },
  ];

  return (
    <Menu
      // theme={isDark ? "dark" : "light"}
      className="main-menu"
      // style={{ padding: sideMenuColapsed ? "8px 24px" : "8px 15px" }}
      defaultSelectedKeys={["1"]}
      // mode={isMobile ? "inline" : "vertical"}
      items={items}
      selectedKeys={[selectedMenuKey]}
      onSelect={(menu) => {
        isMobile && onCloseToggle && onCloseToggle();
        setselectedMenuKey(menu?.key);
      }}
    />
  );
};

export default BlockedSideBarMenu;
