import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelectFormItem from "../mySelectFormItem/MySelectFormItem";
import BranchActions from "../../actions/BranchActions";

const SelectBranchStatus = ({
  label,
  style,
  onChange,
  value,
  withAll,
  ...otherProps
}) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    let data = [
      { label: t("WORD_PENDING"), value: BranchActions.STATUS.PENDING },
      { label: t("WORD_ACTIVE"), value: BranchActions.STATUS.ACTIVE },
      {
        label: t("WORD_BLOCKED"),
        value: BranchActions.STATUS.BLOCKED,
      },
    ];
    if (withAll) data.unshift({ label: t("WORD_ALL"), value: "" });
    setlist(data);
  }, [t, withAll]);

  return (
    <MySelectFormItem
      label={label}
      options={list}
      style={style}
      onChange={onChange}
      value={value}
      {...otherProps}
    />
  );
};

export default SelectBranchStatus;
