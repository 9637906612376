import React, { useContext, useEffect, useRef, useState } from "react";
import MyDivider from "../../components/myDivider/MyDivider";
import { useTranslation } from "react-i18next";
import { useForm } from "antd/es/form/Form";
import InputNumberFormItem from "../../components/myInputForm/InputNumberFormItem";
import SelectTypeOfTaxAccount from "../../components/selects/SelectTypeOfTaxAccount";
import SelectExceedingCustomerDebtLimit from "../../components/selects/SelectExceedingCustomerDebtLimit";
import { UserContext } from "../../layouts/settingsLayout/SettingsLayout";
import ClientSaveActions from "../../actions/ClientSaveActions";
import UtilNotify from "../../utils/UtilNotify";
import SelectLanguage from "../../components/selects/SelectLanguage";
import SelectCurrency from "../../components/selects/SelectCurrency";
import MySwitchFormItem from "../../components/mySwitchFormItem/MySwitchFormItem";
import { useNavigate } from "react-router-dom";
import ProductSettings from "./ProductSettings";
import UserDeleteSelfModal from "../users/userDeleteSelf/UserDeleteSelfModal";
import MyButtonDelete from "../../components/myButton/MyButtonDelete";
import MySpace from "../../components/mySpace/MySpace";
import MyHelmet from "../../components/helmet/MyHelmet";
import MyCard from "../../components/myCard/MyCard";
import MyPageHeader from "../../components/myPageHeader/MyPageHeader";
import FormApp from "../../components/formApp/FormApp";
import MyButtonSuccess from "../../components/myButton/MyButtonSuccess";

const formName = "general_form";

const GeneralSettings = () => {
  const { t, i18n } = useTranslation();
  const [form] = useForm();
  const [formUnit] = useForm();
  const refModal = useRef();
  const navigate = useNavigate();

  const { userData, changeUserSettings } = useContext(UserContext);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    form?.setFieldsValue(userData);
  }, [userData, form]);

  const onFinish = async (values) => {
    setSaving(true);
    let result = await ClientSaveActions.saveClientOptions(values);
    if (result.success) {
      formUnit.submit();
      changeUserSettings(values);
      i18n.changeLanguage(values.language);
      navigate(`/${values.language}/dashboard`, { replace: true });
      UtilNotify.notifySuccess(t("WORD_SETTINGS_SAVED"));
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    setSaving(false);
  };

  return (
    <>
      <MyHelmet title={t("WORD_GENERAL_SETTINGS")} />
      <MyCard>
        <div style={{ marginBottom: 20 }}>
          <MyPageHeader title={t("WORD_GENERAL_SETTINGS")} />
        </div>
        <MyDivider />
        <FormApp form={form} name={formName} onFinish={onFinish}>
          <MySpace size="middle" direction="vertical" fullWidth>
            <InputNumberFormItem
              name="orderDueDayCount"
              required
              label={t("WORD_ORDER_DUE_DAY_COUNT")}
              tooltipTitle={t("WORD_DEFAULT_DUE_DATE_CREATE")}
            />
            <MySwitchFormItem
              name="useOpenOrder"
              className="settings-switch"
              required
              label={t("WORD_USE_OPEN_ORDER")}
              tooltipTitle={t("WORD_STILL_CHANGE_AFTER_SAVE")}
            />
            <MyDivider smaller />
            <MySwitchFormItem
              name="changeProductPrice"
              className="settings-switch"
              required
              label={t("WORD_CHANGE_PRODUCT_PRICE")}
              tooltipTitle={t("WORD_ALLOW_MANAGER_TO_CHANGE")}
            />
            <MyDivider smaller />
            <MySwitchFormItem
              name="customerCreate"
              className="settings-switch"
              required
              label={t("WORD_CUSTOMER_CREATE")}
              tooltipTitle={t("WORD_ALLOW_MANAGER_CREATE_NEW")}
            />
            <MyDivider smaller />
            <SelectTypeOfTaxAccount
              name="typeOfTaxAccount"
              label={t("WORD_TYPE_OF_TAX_ACCOUNT")}
              required
            />
            <InputNumberFormItem
              name="vatRate"
              label={t("WORD_VAT_RATE")}
              suffix="%"
            />
            <MySwitchFormItem
              name="downloadOpenOrder"
              label={t("WORD_DOWNLOAD_OPEN_ORDERS")}
              className="settings-switch"
              required
            />
            <MyDivider smaller />
            <MySwitchFormItem
              name="countQuantity"
              label={t("WORD_COUNT_QUANTITY")}
              className="settings-switch"
              required
            />
            <MyDivider smaller />
            <MySwitchFormItem
              name="allowBackorders"
              label={t("WORD_ALLOW_BACKORDERS")}
              className="settings-switch"
              tooltipTitle={t("WORD_ALLOW_TO_CREATE_BACKORDER")}
              required
            />
            <MyDivider smaller />
            <SelectExceedingCustomerDebtLimit
              label={t("WORD_EXCEEDING_CUSTOMER_DEBT_LIMIT")}
              tooltipTitle={t("WORD_IN_CASE_OF_EXCEEDING")}
              name="allowOrderDebetLimit"
              required
            />
            <SelectLanguage
              name="language"
              label={t("WORD_SELECT_LANGUAGE")}
              tooltipTitle={t("WORD_INTERFACE_LANGUAGE")}
              required
            />
            <SelectCurrency
              name="currency"
              label={t("WORD_CURRENCY")}
              tooltipTitle={t("WORD_CURRENCY_TO_USE")}
              required
            />
          </MySpace>
        </FormApp>
        <ProductSettings form={formUnit} />
        <MySpace
          align="center"
          style={{ marginTop: 30 }}
          spaceBetween
          fullWidth
        >
          <MyButtonSuccess
            label={t("WORD_SAVE_CHANGES")}
            form={formName}
            loading={saving}
          />
          {process.env.NODE_ENV === "development" ? (
            <MyButtonDelete
              label={t("WORD_DELETE_PROFILE")}
              onConfirm={() => {
                refModal?.current?.viewDelete();
              }}
            />
          ) : null}
        </MySpace>
      </MyCard>
      <UserDeleteSelfModal ref={refModal} />
    </>
  );
};

export default GeneralSettings;
