import { fetchFromUrlPOSTAsync } from "../utils/UrlHelper";

const URL_LIST = "regions/list";

const fs = {
  fillList: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_LIST, urlParams);
    return result;
  },
  getList: async (idCountry) => {
    const result = await fetchFromUrlPOSTAsync(URL_LIST + "/" + idCountry);
    return result;
  },
};

const loc = {
  fillListOptions: async (idCountry) => {
    const result = await RegionActions.getList(idCountry);
    if (result.success) {
      result.data = result.data.map((it) => {
        return {
          label: it.name,
          value: it.idRegion,
          ...it,
        };
      });
    }
    return result;
  },
};

const RegionActions = Object.assign(fs, loc);

export default RegionActions;
