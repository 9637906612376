import React from "react";
import { Card } from "antd";

const MyCard = ({
  children,
  className,
  style,
  fullHeight,
  onClick,
  onDoubleClick,
  withoutRadius,
  borderRadius,
  loading,
  withoutShadow,
}) => {
  return (
    <Card
      style={{
        height: fullHeight ? "100%" : "",
        borderRadius: withoutRadius ? 0 : borderRadius || 14,
        boxShadow: withoutShadow
          ? "none"
          : "0 1px 2px 0 rgba(0, 0, 0, 0.03),0 1px 6px -1px rgba(0, 0, 0, 0.02),0 2px 4px 0 rgba(0, 0, 0, 0.02)",
        ...style,
      }}
      className={`my-card ${className || ""} `}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      variant="borderless"
      loading={loading}
    >
      {loading ? null : children}
    </Card>
  );
};

export default MyCard;
