import React from "react";
import AutoComplete from "react-google-autocomplete";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const googleApisKey = "AIzaSyC728MmeRZPD2ncjwezyY7x5ZjIPfgURRA";

const SelectAddressSuggestionGoogle = ({
  label,
  className,
  onSelect,
  placeholder,
  hidden,
  name,
  required,
  maxLength,
  minLength,
  onClear,
}) => {
  const mapKeyGoogleSuggestion = useSelector((state) => {
    return (
      state.currentUser?.mapKeyGoogleSuggestion ||
      // process.env.REACT_APP_YANDEX_MAP_APIKEY
      googleApisKey
    );
  });

  const { t } = useTranslation();

  return (
    <div>
      <Form.Item
        label={label}
        name={name}
        hasFeedback={false}
        rules={[
          {
            required: required,
            message: `${t("WORD_ERROR_PLEASE_INPUT")} ${placeholder || label}!`,
          },
          {
            max: maxLength,
            message: `${
              placeholder || label
            } must be maximum ${maxLength} characters.`,
          },
          {
            min: minLength,
            message: `${
              placeholder || label
            } must be minimum ${minLength} characters.`,
          },
        ]}
        hidden={hidden}
        className={className || ""}
      >
        <AutoComplete
          apiKey={mapKeyGoogleSuggestion}
          style={{
            // border: "none",
            height: 42,
            borderRadius: 12,
          }}
          allowclear="true"
          className="google-autocomplete"
          results={10}
          options={{
            fields: ["address_components", "geometry", "name"],
            types: ["geocode", "establishment"],
            componentRestrictions: { country: "am" },
          }}
          onPlaceSelected={(place) => {
            if (
              place &&
              place.geometry &&
              place.geometry.location &&
              place.name &&
              place.address_components &&
              place.address_components.length >= 3
            ) {
              onSelect &&
                onSelect(
                  `${place.address_components[0].long_name} ${place.address_components[1].long_name} ${place.address_components[2].long_name}`,
                  place.geometry.location.lat(),
                  place.geometry.location.lng()
                );
            }
          }}
          onChange={(e) => {
            if (e.target.value === "") {
              onClear && onClear();
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        />
      </Form.Item>
    </div>
  );
};

export default SelectAddressSuggestionGoogle;
