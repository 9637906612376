import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AnalyticsActions from "../../../actions/AnalyticsActions";
import BarChart from "./BarChart";
import UtilNotify from "../../../utils/UtilNotify";
import MyCard from "../../../components/myCard/MyCard";
import { Flex } from "antd";
import MySpace from "../../../components/mySpace/MySpace";
import MyTextAnalyticsStatsCard from "../../../components/myText/MyTextAnalyticsStatsCard";

const PaymentBarChartSection = () => {
  const { t } = useTranslation();

  const [listData, setListData] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    const fill = async () => {
      setloading(true);
      const result = await AnalyticsActions.saleReturnPaymentByMonthList();
      if (result.success) {
        setListData(result.data);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setloading(false);
    };
    fill();
  }, []);

  return (
    <MyCard loading={loading}>
      <MySpace
        direction="vertical"
        fullWidth
        className="analytics-space-chart-parent"
        size="middle"
      >
        <MyTextAnalyticsStatsCard bold>
          {t("WORD_LAST_12_MONTHS")}
        </MyTextAnalyticsStatsCard>
        <Flex gap={10}>
          <div className="statsCard__amountHolder--amount--label label-wrapper">
            <div className="value-name value-name-color3"></div>
            {t("WORD_SALES")}
          </div>
          <div className="statsCard__amountHolder--amount--label label-wrapper">
            <div className=" value-name value-name-color1"></div>
            {t("WORD_ORDERS")}
          </div>
          <div className="statsCard__amountHolder--amount--label label-wrapper">
            <div className="value-name value-name-color2"></div>
            {t("WORD_RETURNS")}
          </div>
        </Flex>
        <BarChart
          labels={listData.map((it) => {
            return t(it.monthName);
          })}
          dataSets={[
            {
              label: t("SALES"),
              data: listData.map((it) => {
                return it.saleCount - it.returnCount;
              }),
              backgroundColor: "#99C910",
              borderColor: "#99C910",
              borderRadius: "50",
            },
            {
              label: t("ORDERS"),
              data: listData.map((it) => {
                return it.paymentCount;
              }),
              backgroundColor: "#5861f7",
              borderColor: "#5861f7",
              borderRadius: "50",
            },
            {
              label: t("RETURNS"),
              data: listData.map((it) => {
                return it.paymentCount;
              }),
              backgroundColor: "#ff9e34",
              borderColor: "#ff9e34",
              borderRadius: "50",
            },
          ]}
        />
      </MySpace>
    </MyCard>
  );
};

export default PaymentBarChartSection;
