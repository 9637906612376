import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import MyModal from "../../../components/myModal/MyModal";
import { useSelector } from "react-redux";
import MyDivider from "../../../components/myDivider/MyDivider";
import FormApp from "../../../components/formApp/FormApp";
import RadioMonthlyYearlySelect from "./RadioMonthlyYearlySelect";
import { useForm } from "antd/es/form/Form";
import MyTextSecondary from "../../../components/myText/MyTextSecondary";
import RadioVerticalPaymentMethod from "./RadioVerticalPaymentMethod";
import MySpace from "../../../components/mySpace/MySpace";
import UtilDate from "../../../utils/UtilDate";
import { Flex, Form } from "antd";
import InputTextFormItem from "../../../components/myInputForm/InputTextFormItem";
import DateFormLabel from "./DateFormLabel";
import LogoSmallSVG from "../../../icons/LogoSmallSVG";
import InvoiceActions from "../../../actions/InvoiceActions";
import UtilNotify from "../../../utils/UtilNotify";
import ServiceActions from "../../../actions/ServiceActions";
import MyButtonSuccess from "../../../components/myButton/MyButtonSuccess";

const formName = "formPay";

const SubmitButton = ({ form, currrencySymbol }) => {
  const { t } = useTranslation();

  const totalAmount = Form.useWatch("totalAmount", form);

  const [submittable, setSubmittable] = useState(false);

  const values = Form.useWatch([], form);

  useEffect(() => {
    if (form) {
      form
        .validateFields({
          validateOnly: true,
        })
        .then(() => {
          setSubmittable(true);
        })
        .catch(() => {
          setSubmittable(false);
        });
    }
  }, [form, values]);

  return (
    <MyButtonSuccess
      key="ok"
      onClick={() => form.submit()}
      disabled={!submittable}
      label={`${t("WORD_SUBMIT")} ${
        totalAmount ? totalAmount + "" + currrencySymbol : ""
      }`}
      block
    />
  );
};

const ChangePackageModalPay = forwardRef((props, ref) => {
  const refModal = useRef();
  const { t } = useTranslation();
  const [form] = useForm();

  const monthYearSelect = Form.useWatch("monthlyYearlySelect", form);

  const [pack, setpack] = useState({});
  const [saving, setsaving] = useState(false);

  useImperativeHandle(ref, () => ({
    viewPay: async (pay) => {
      if (!pay) {
        const result = await ServiceActions.getClientService();
        if (result.success) {
          setpack(result.data);
        } else {
          UtilNotify.notifyErrorServer(result.errMsg);
        }
      } else {
        setpack(pay);
      }
      refModal.current.open();
    },
  }));

  const companyName = useSelector((state) => {
    return state.currentUser?.companyName;
  });
  const paidUntilDate = useSelector((state) => {
    return state.currentUser.paidUntilDate;
  });

  const onFinish = async (values) => {
    setsaving(true);
    values.invoiceType = InvoiceActions.TYPE.AUTO;
    values.dateFrom = UtilDate.getDate(values.dateFrom);
    values.dateTo = UtilDate.getDate(values.dateTo);
    const result = await InvoiceActions.create(values);
    if (result.success) {
      if (result.data.formUrl) {
        window.location.replace(result.data.formUrl);
      }
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    setsaving(false);
  };

  useEffect(() => {
    let dateFrom = UtilDate.getDateToday();
    if (paidUntilDate) {
      const dayDif = UtilDate.getDiffDaysCountBetween(
        UtilDate.getDateToday(),
        UtilDate.getDate(paidUntilDate)
      );
      if (dayDif < 20) {
        dateFrom = UtilDate.getDate(paidUntilDate);
      }
    }
    const dateTo =
      monthYearSelect === 12
        ? UtilDate.getDateOneYearFromToday(dateFrom)
        : UtilDate.getDateOneMonthFromToday(dateFrom);
    const price =
      monthYearSelect === 12 ? pack?.priceYearly : pack?.priceMontly;
    const totalAmount = price * monthYearSelect;
    form.setFieldsValue({
      idService: pack?.idService,
      dateFrom: dateFrom,
      dateTo: dateTo,
      dateFromRender: UtilDate.formatDate(dateFrom),
      dateToRender: UtilDate.formatDate(dateTo),
      price,
      totalAmount: totalAmount,
    });
  }, [form, monthYearSelect, pack, paidUntilDate]);

  return (
    <MyModal
      ref={refModal}
      className="pay-modal"
      title={
        <div className="package-wrapper-logo">
          <div className="package-modal-logo-wrapper">
            <LogoSmallSVG />
          </div>
        </div>
      }
      width={300}
      saving={saving}
      hideCancel={true}
      footer={
        <div className="confirm-modal-btn-wrapper">
          <SubmitButton form={form} currrencySymbol={pack?.currrencySymbol} />
        </div>
      }
    >
      <MySpace direction="vertical" fullWidth style={{ marginTop: 10 }}>
        <div style={{ textAlign: "center" }}>
          <MyTextSecondary size={18} bold className="modal-company-name">
            {t("WORD_ORDERSBOOK")}
          </MyTextSecondary>
        </div>
        <div style={{ textAlign: "center" }}>
          <MyTextSecondary size={15} bold className="modal-second-company-name">
            {companyName}
          </MyTextSecondary>
        </div>
        <Flex align="center" justify="center" style={{ minHeight: 200 }}>
          <FormApp
            form={form}
            name={formName}
            onFinish={onFinish}
            loading={saving}
            className="pay-form-wrapper"
            initialValues={{
              totalAmount: 0,
              // monthlyYearlySelect: 1,
            }}
          >
            <div className="pay-form">
              <MySpace direction="vertical" fullWidth size="middle">
                <MyTextSecondary className="payment-type-text">
                  {t("WORD_CHOOSE_PAYMENT_TYPE")}
                </MyTextSecondary>
                <div>
                  <RadioMonthlyYearlySelect
                    name="monthlyYearlySelect"
                    required
                  />
                  <MyDivider style={{ color: "#CACFD3" }} />
                  <RadioVerticalPaymentMethod
                    name="paymentMethod"
                    required
                    placeholder="Payment method"
                  />
                </div>
              </MySpace>
            </div>
            {monthYearSelect ? (
              // <Flex>
              <MySpace
                fullWidth
                align="center"
                style={{ justifyContent: "center" }}
              >
                <MySpace>
                  <DateFormLabel name="dateFromRender" />
                  <span>-</span>
                  <DateFormLabel name="dateToRender" />
                </MySpace>
                {/* <MySpace>
                    <div className="info-margin">|</div>
                    <InfoTooltip
                      placement="right"
                      label={t("WORD_PACKAGE_MODAL_INFO")}
                      trigger="click"
                    />
                  </MySpace> */}
              </MySpace>
            ) : // </Flex>
            null}
            <InputTextFormItem name="dateFrom" hidden />
            <InputTextFormItem name="dateTo" hidden />
            <InputTextFormItem name="totalAmount" hidden />
            <InputTextFormItem name="idService" hidden />
            <InputTextFormItem name="price" hidden />
          </FormApp>
        </Flex>
      </MySpace>
    </MyModal>
  );
});

export default ChangePackageModalPay;
