import React, { useCallback, useEffect, useMemo, useState } from "react";
import MyPageHeader from "../../../components/myPageHeader/MyPageHeader";
import { useTranslation } from "react-i18next";
import MySpace from "../../../components/mySpace/MySpace";
import UtilDate from "../../../utils/UtilDate";
import VisitsActions from "../../../actions/VisitsActions";
import { useSelector } from "react-redux";
import UtilNotify from "../../../utils/UtilNotify";
import ApiStorage from "../../../redux/ApiStorage";
import MyTable from "../../../components/myTable/MyTable";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import MyHelmet from "../../../components/helmet/MyHelmet";
import useNavigateApi from "../../../appRoute/useNavigateApi";
import FormApp from "../../../components/formApp/FormApp";
import { useForm } from "antd/es/form/Form";
import SelectCustomer from "../../../components/selects/SelectCustomer";
import SelectManager from "../../../components/selects/SelectManager";
import MyButtonText from "../../../components/myButton/MyButtonText";
import { Flex } from "antd";
import MyButtonTableAction from "../../../components/myButton/MyButtonTableAction";

const formName = "formVisitFilter";

const VisitList = () => {
  const { t } = useTranslation();
  const navigateApi = useNavigateApi();
  const [form] = useForm();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setpagination] = useState({ page: 0, perPage: 50 });
  const [totalRows, settotalRows] = useState(0);
  const [sort, setsort] = useState("");

  const visitPageFilters = useSelector((state) => {
    return state.visitPageFilters;
  });

  const onFinish = useCallback(
    (fil) => {
      ApiStorage.setVisitPageFilters(Object.assign({}, visitPageFilters, fil));
      setpage(0);
    },
    [visitPageFilters]
  );

  const setpage = (page) => {
    setpagination((ov) => ({ page: page, perPage: ov.perPage }));
  };

  const setFilterClear = () => {
    form.resetFields();
    ApiStorage.setVisitPageFilters({});
    setpage(0);
  };

  const refreshListDb = useCallback(async () => {
    setLoading(true);
    const fetchParams = {
      page: pagination.page,
      perPage: pagination.perPage,
      sort: sort,
      q: visitPageFilters?.searchName || "",
      filter: { ...visitPageFilters },
    };
    const result = await VisitsActions.fillPagination(fetchParams);

    if (result.success) {
      setData(result.data);
      settotalRows(result.totalRows);
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    setLoading(false);
  }, [visitPageFilters, pagination, sort]);

  useEffect(() => {
    refreshListDb();
  }, [refreshListDb]);

  useEffect(() => {
    if (visitPageFilters) {
      form?.setFieldsValue(visitPageFilters);
    }
  }, [visitPageFilters, form]);

  const columns = useMemo(() => {
    let col = [
      {
        title: t("WORD_DISTRIBUTOR"),
        dataIndex: "nameUser",
        key: "nameUser",
        width: 160,
      },
      {
        title: t("WORD_CUSTOMER"),
        dataIndex: "nameCustomer",
        key: "nameCustomer",
        // width: 200,
      },
      {
        title: t("WORD_VISIT_DATE"),
        dataIndex: "visitDate",
        key: "visitDate",
        width: 150,
        sorter: true,
        render: (value) => {
          return <div>{UtilDate.formatDateTime(value)}</div>;
        },
      },
      {
        title: t("WORD_ACTIONS"),
        dataIndex: "actions",
        width: 120,
        key: "actions",
        render: (_, { idManagerVisit }) => (
          <MySpace>
            <MyButtonTableAction
              title={t("WORD_VIEW")}
              icon={faEye}
              onClick={() => navigateApi(`/visit/view/${idManagerVisit}`)}
            />
          </MySpace>
        ),
      },
    ];
    return col;
  }, [t, navigateApi]);

  const onChangeTable = (_, __, sorter) => {
    let so = "";
    if (sorter.order) {
      so = `${sorter.columnKey} ${sorter.order === "ascend" ? "ASC" : "DESC"}`;
    }
    setsort(so);
  };

  return (
    <>
      <MyHelmet title={t("WORD_VISITS")} />
      <Flex vertical gap={10}>
        <Flex vertical gap="small">
          <MyPageHeader inPage title={t("WORD_VISITS")} />
          <FormApp
            onFinish={onFinish}
            name={formName}
            form={form}
            style={{ marginLeft: 8 }}
          >
            <MySpace>
              <SelectCustomer
                placeholder={t("WORD_CUSTOMER")}
                onChange={() => form.submit()}
                name="idCustomer"
                width={200}
                // hasValue={Object.keys(visitPageFilters)[0]}
              />
              <SelectManager
                placeholder={t("WORD_DISTRIBUTOR")}
                onChange={() => form.submit()}
                name="idManager"
                width={200}
              />
              <MyButtonText
                label={t("WORD_RESET_FILTERS")}
                onClick={setFilterClear}
              />
            </MySpace>
          </FormApp>
        </Flex>
        <MyTable
          rowKey="idManagerVisit"
          columns={columns}
          dataSource={data}
          onChange={onChangeTable}
          loading={loading}
          total={totalRows}
          onChangePage={(e, ee) => {
            setpagination({ page: e, perPage: ee });
          }}
          page={pagination.page}
          pageSize={pagination.perPage}
        />
      </Flex>
    </>
  );
};

export default VisitList;
