import React, { useEffect, useRef, useState } from "react";
import MySpace from "../../../components/mySpace/MySpace";
import MyCard from "../../../components/myCard/MyCard";
import MyPageHeader from "../../../components/myPageHeader/MyPageHeader";
import { useTranslation } from "react-i18next";
import PrintSVG from "../../../icons/PrintSVG";
import ActivityRightCardRow from "../../../components/activityRightCardRow/ActivityRightCardRow";
import MyDivider from "../../../components/myDivider/MyDivider";
import ClockTimeBlueSVG from "../../../icons/ClockTimeBlueSVG";
import CalenderOrangeSVG from "../../../icons/CalenderOrangeSVG";
import MyTextSecondary from "../../../components/myText/MyTextSecondary";
import MyText from "../../../components/myText/MyText";
import { Flex } from "antd";
import DateDisplayWithIconLabel from "../../../components/dateDisplayWithIconLabel/DateDisplayWithIconLabel";
import AddressView from "../../../components/addressView/AddressView";
import { useParams } from "react-router-dom";
import VisitsActions from "../../../actions/VisitsActions";
import UtilNotify from "../../../utils/UtilNotify";
import UtilDate from "../../../utils/UtilDate";
import LoadingIconApp from "../../../components/loadingIconApp/LoadingIconApp";
import { Placemark } from "react-yandex-maps";
import VisitViewRightCardStats from "./VisitViewRightCardStats";
import { useReactToPrint } from "react-to-print";
import VisitViewPrint from "./VisitViewPrint";
import MyHelmet from "../../../components/helmet/MyHelmet";
import MyMapYandex from "../../../components/myMapYandex/MyMapYandex";
import useNavigateApi from "../../../appRoute/useNavigateApi";
import { useFormatCurrency } from "../../../utils/useFormatWithCurrency";
import MyProgress from "../../../components/progress/MyProgress";
import MyTag from "../../../components/tag/MyTag";

const VisitView = () => {
  const { t } = useTranslation();
  const navigateApi = useNavigateApi();
  const mapRef = useRef(null);
  const contentRef = useRef();
  const { formatDoubleWithCurrency } = useFormatCurrency();
  const { _idManagerVisit } = useParams();

  const [loading, setloading] = useState(false);
  const [data, setData] = useState([]);

  const reactToPrintFn = useReactToPrint({ contentRef });

  useEffect(() => {
    const fill = async () => {
      setloading(true);
      const resultGetOne = await VisitsActions.getOne(_idManagerVisit);
      if (resultGetOne.success) {
        setData(resultGetOne.data);
      } else {
        UtilNotify.notifyErrorServer(resultGetOne.errMsg);
      }
      setloading(false);
    };
    fill();
  }, [_idManagerVisit]);

  const type = VisitsActions.getTypeLabel(data?.type);

  return (
    <>
      <MyHelmet title={`${t("WORD_VISITS")} ${t("WORD_VIEW")}`} />
      <MySpace direction="vertical" size={"middle"} fullWidth>
        <Flex align="top" gap="middle">
          <MyCard style={{ width: "70%" }}>
            {loading ? (
              <LoadingIconApp />
            ) : (
              <div>
                <MyPageHeader
                  title={`${t("WORD_ID")}
                  : #${data?.idManagerVisit}`}
                  extraRight={
                    <div
                      className="print-btn"
                      style={{ cursor: "pointer" }}
                      onClick={reactToPrintFn}
                    >
                      <PrintSVG />
                    </div>
                  }
                  onBack={() => navigateApi(`/visit/list`)}
                  style={{ marginBottom: 20 }}
                />
                <MySpace size="large" fullWidth align="flex-start">
                  <MyMapYandex ref={mapRef} width={500}>
                    <Placemark
                      geometry={[data?.mapLat, data?.mapLng]}
                      options={{
                        iconColor: "green",
                        // boundsAutoApply: true,
                      }}
                    />
                    <Placemark
                      geometry={[data?.lat, data?.lan]}
                      options={{
                        iconColor: data?.typeObject?.color,
                      }}
                    />
                  </MyMapYandex>
                  <MySpace direction="vertical">
                    <MySpace fullWidth align="top" style={{ marginTop: 5 }}>
                      <MyTextSecondary secondary>
                        {t("WORD_TYPE")}:
                      </MyTextSecondary>
                      <MyTag label={t(type.label)} color={type.color} />
                    </MySpace>
                    <ActivityRightCardRow
                      secondary
                      title={t("WORD_VISIT_DATE")}
                      data={UtilDate.formatDateTime(data?.visitDate)}
                      noSpace
                    />
                    <ActivityRightCardRow
                      secondary
                      title={t("WORD_SALES_MANAGER")}
                      data={data?.nameManager}
                      noSpace
                    />
                    <ActivityRightCardRow
                      secondary
                      title={t("WORD_CUSTOMER")}
                      data={data?.nameCustomer}
                      noSpace
                    />
                    <ActivityRightCardRow
                      secondary
                      title={t("WORD_DESCRIPTION")}
                      data={data?.note}
                      noSpace
                    />
                  </MySpace>
                </MySpace>
              </div>
            )}
          </MyCard>
          <MyCard style={{ width: "28%" }}>
            <MySpace direction="vertical" fullWidth>
              <ActivityRightCardRow
                data={data?.nameCustomer}
                noSpace
                secondary
              />
              <ActivityRightCardRow
                title={t("WORD_TIN")}
                data={data?.tax}
                noSpace
                secondary
              />
              <MyDivider />
              <MySpace fullWidth spaceBetween>
                <DateDisplayWithIconLabel
                  icon={<ClockTimeBlueSVG />}
                  title={t("WORD_LAST_ORDER")}
                  data={"01.03.22 ???"}
                />
                <DateDisplayWithIconLabel
                  icon={<CalenderOrangeSVG />}
                  title={t("WORD_CONTRACT_DATE")}
                  data={UtilDate.formatDate(data?.contractDate)}
                />
              </MySpace>
              <MySpace fullWidth spaceBetween style={{ marginTop: 5 }}>
                <MyTextSecondary color="#a7a6b9">
                  {t("WORD_DEBIT_LIMIT")}
                </MyTextSecondary>
                <MyText>
                  {formatDoubleWithCurrency(data?.debitLimit)} /
                  <span> {formatDoubleWithCurrency(data?.debitBalance)}</span>
                </MyText>
              </MySpace>
              <MyProgress percent={0} showInfo={false} />
              {data?.deliveryLocation ? (
                <AddressView
                  title={t("WORD_DELIVERY_LOCATION")}
                  data={data?.deliveryLocation}
                />
              ) : null}
              {data?.legalAddress ? (
                <AddressView
                  title={t("WORD_LEGAL_ADDRESS")}
                  data={data?.legalAddress}
                />
              ) : null}
              <VisitViewRightCardStats />
              <ActivityRightCardRow title={t("WORD_NAME")} secondary />
              <ActivityRightCardRow
                title={t("WORD_BANK_NAME")}
                secondary
                data={data?.bankName}
              />
              <ActivityRightCardRow
                title={t("WORD_BANK_NUMBER")}
                secondary
                data={data?.bankNumber}
              />
              <ActivityRightCardRow
                title={t("WORD_CONTRACT_DATE")}
                secondary
                data={UtilDate.formatDate(data?.contractDate)}
              />
              <ActivityRightCardRow
                title={t("WORD_CONTRACT_NUMBER")}
                secondary
                data={data?.contractNumber}
              />
              <ActivityRightCardRow
                title={t("WORD_DIRECTOR")}
                secondary
                data={data?.director}
              />
              <ActivityRightCardRow
                title={t("WORD_CATEGORY")}
                secondary
                data={data?.category}
              />
              <ActivityRightCardRow
                title={t("WORD_DESCRIPTION")}
                secondary
                data={data?.description}
              />
            </MySpace>
          </MyCard>
        </Flex>
      </MySpace>
      <div style={{ display: "none" }}>
        <VisitViewPrint ref={contentRef} data={data} type={type} />
      </div>
    </>
  );
};

export default VisitView;
