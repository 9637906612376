import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelectFormItem from "../mySelectFormItem/MySelectFormItem";
import OrdersActions from "../../actions/OrdersActions";

const SelectOrderStatus = ({ withAll, ...otherProps }) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    let data = [
      { label: t("WORD_CLOSED"), value: OrdersActions.STATUS.CLOSED },
      { label: t("WORD_PENDING"), value: OrdersActions.STATUS.OPENED },
    ];
    if (withAll) data.unshift({ label: t("WORD_ALL"), value: "" });
    setlist(data);
  }, [t, withAll]);

  return <MySelectFormItem {...otherProps} options={list} />;
};

export default SelectOrderStatus;
