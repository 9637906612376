import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "antd/es/form/Form";
import FormApp from "../../../components/formApp/FormApp";
import SelectManager from "../../../components/selects/SelectManager";
import UtilNotify from "../../../utils/UtilNotify";
import OrdersActions from "../../../actions/OrdersActions";
import MyModalForm from "../../../components/myModal/MyModalForm";

const formName = "formOrderEdit";

const OrderEditModal = forwardRef(
  ({ loading, icon, isPayment, onChange }, ref) => {
    const { t } = useTranslation();
    const [form] = useForm();
    const refModal = useRef();

    const [data, setData] = useState({});
    const [saving, setSaving] = useState(false);

    useImperativeHandle(ref, () => ({
      viewOrder: (ord) => {
        if (form) {
          setData(ord);
          form?.setFieldsValue({ idManager: ord?.idManager });
        }
        refModal.current.open();
      },
    }));

    const onFinish = async (values) => {
      setSaving(true);
      const result = await OrdersActions.update(values, data.idOrder);
      if (result.success) {
        refModal.current.close();
        UtilNotify.notifySuccess(
          isPayment
            ? t("WORD_PAYMENT_UPDATED_SUCCESSFULLY")
            : t("WORD_ORDER_UPDATED_SUCCESSFULLY")
        );
        onChange && onChange(values);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setSaving(false);
    };

    return (
      <MyModalForm
        ref={refModal}
        loading={loading}
        icon={icon}
        title={isPayment ? t("WORD_EDIT_PAYMENT") : t("WORD_ORDER_EDIT")}
        labelOk={t("WORD_SAVE_CHANGES")}
        saving={saving}
        formName={formName}
        success
      >
        <FormApp onFinish={onFinish} name={formName} form={form}>
          <SelectManager
            placeholder={t("WORD_SELECT_DISTRIBUTOR")}
            label={t("WORD_SELECT_DISTRIBUTOR")}
            name="idManager"
            required
          />
        </FormApp>
      </MyModalForm>
    );
  }
);

export default OrderEditModal;
