import React, { memo } from "react";
import MyIcon from "./MyIcon";

const SVGIcon = ({ size }) => {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.94097 4.21858C3.32058 4.9973 3 6.21332 3 7.93212V15.7321C3 17.3737 3.48187 18.5855 4.28463 19.3865C5.08759 20.1877 6.30297 20.6691 7.95 20.6691H15.732C17.374 20.6691 18.586 20.188 19.3869 19.387C20.1879 18.5861 20.669 17.3741 20.669 15.7321L20.669 15.7279L20.697 10.7671V10.765C20.6967 9.00374 20.3717 7.85571 19.7662 7.14648C19.1837 6.4642 18.2105 6.04012 16.536 6.04012L13.705 6.04012C12.7515 6.03879 11.8541 5.58997 11.2808 4.82812L10.3676 3.61391L10.3664 3.61229C10.0788 3.22771 9.62574 3.00094 9.14242 3.00012H7.143C5.50556 3.00012 4.53296 3.47552 3.94097 4.21858ZM2.76778 3.28391C3.72754 2.07923 5.20144 1.50012 7.143 1.50012H9.14404C10.0961 1.50145 10.9946 1.94828 11.5669 2.71298L11.5676 2.71396L12.4792 3.92612C12.7698 4.31215 13.2242 4.53931 13.7066 4.54012H16.536C18.4485 4.54012 19.9308 5.02905 20.907 6.17252C21.86 7.28874 22.197 8.87856 22.197 10.7671L22.197 10.7714L22.169 15.7321V15.7344C22.1686 17.6694 21.5952 19.3001 20.4476 20.4477C19.2995 21.5957 17.668 22.1691 15.732 22.1691H7.95C6.01003 22.1691 4.37541 21.5961 3.22512 20.4483C2.07463 19.3003 1.5 17.6685 1.5 15.7321V7.93212C1.5 6.07793 1.83642 4.45294 2.76778 3.28391ZM7.75 13.2C7.75 12.7858 8.08579 12.45 8.5 12.45H11.25V9.70001C11.25 9.2858 11.5858 8.95001 12 8.95001C12.4142 8.95001 12.75 9.2858 12.75 9.70001V12.45H15.5C15.9142 12.45 16.25 12.7858 16.25 13.2C16.25 13.6142 15.9142 13.95 15.5 13.95H12.75V16.7C12.75 17.1142 12.4142 17.45 12 17.45C11.5858 17.45 11.25 17.1142 11.25 16.7V13.95H8.5C8.08579 13.95 7.75 13.6142 7.75 13.2Z"
        fill="#130F26"
      />
    </svg>
  );
};

const FolderCreateSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(FolderCreateSVG);
