import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import MyModal from "../../components/myModal/MyModal";
import { useTranslation } from "react-i18next";
import UtilNotify from "../../utils/UtilNotify";
import NotificationActions from "../../actions/NotificationActions";
import UtilObject from "../../utils/UtilObject";
import UtilDate from "../../utils/UtilDate";
import ActivityRightCardRow from "../../components/activityRightCardRow/ActivityRightCardRow";

const NotificationViewModal = forwardRef(({ onChange }, ref) => {
  const refModal = useRef();
  const { t } = useTranslation();

  const [notification, setNotification] = useState({});

  useImperativeHandle(ref, () => ({
    viewNotification: (noti) => {
      setNotification(noti);
      refModal.current.open();
    },
  }));

  useEffect(() => {
    const setReaded = async () => {
      if (
        !UtilObject.isEmpty(notification) &&
        notification.notifRead === NotificationActions.STATUS.NOTREADED
      ) {
        const result = await NotificationActions.save(notification?.idNotif, {
          notifRead: NotificationActions.STATUS.READED,
        });
        if (result.success) {
          onChange && onChange(notification?.idNotif);
        } else {
          UtilNotify.notifyErrorServer(result.errMsg);
        }
      }
    };
    setReaded();
  }, [notification, onChange]);

  return (
    <MyModal ref={refModal} title={t("WORD_NOTIFICATION")} hideFooter>
      <ActivityRightCardRow
        noSpace
        title={t("WORD_CREATE_DATE")}
        bold
        data={UtilDate.formatDateTime(notification.notifDate)}
      />
      <ActivityRightCardRow
        noSpace
        title={t("WORD_MESSAGE")}
        bold
        data={notification.notifText}
      />
    </MyModal>
  );
});

export default NotificationViewModal;
