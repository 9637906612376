import React from "react";
import MyButton from "./MyButton";
import "./MyButton.scss";

const MyButtonGray = ({
  className,
  success,
  disabled,
  nextToFilter,
  danger,
  style,
  icon,
  ...otherProps
}) => {
  return (
    <MyButton
      {...otherProps}
      size="large"
      icon={icon}
      className={"action-gray"}
      style={{ cursor: disabled ? "not-allowed" : "pointer", ...style }}
      type="primary"
      danger={danger}
    />
  );
};

export default MyButtonGray;
