import React, { forwardRef } from "react";
import OrderViewPrint from "./OrderViewPrint";
import OrderViewPrintThermal from "./OrderViewPrintThermal";
import "./OrderPrint.scss";

const OrderViewPrintPage = forwardRef(({ orderData, ...otherProps }, ref) => {
  return (
    <div ref={ref}>
      <OrderViewPrint orderData={orderData} {...otherProps} />
      <OrderViewPrintThermal orderData={orderData} />
    </div>
  );
});

export default OrderViewPrintPage;
