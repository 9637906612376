import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MyLink from "../../components/myLink/MyLink";
import { useTranslation } from "react-i18next";
import DashboardSVG from "../../icons/DashboardSVG";
import ShoppingCartSVG from "../../icons/ShoppingCartSVG";
import WalletSVG from "../../icons/WalletSVG";
import GpsSVG from "../../icons/GpsSVG";
import ProductSVG from "../../icons/ProductSVG";
import AdminSVG from "../../icons/AdminSVG";
import DebtSVG from "../../icons/DebtSVG";
import DevicesSVG from "../../icons/DevicesSVG";
import BranchesSVG from "../../icons/BranchesSVG";
import { Badge, Menu } from "antd";
import { useLocation } from "react-router-dom";
import useWindowSize from "../../utils/useWindowSize";
import PartnersSVG from "../../icons/PartnersSVG";
import ApiStorage from "../../redux/ApiStorage";
import OrdersActions from "../../actions/OrdersActions";
import VisitSVG from "../../icons/VisitSVG";
import AnalyticsWhiteSVG from "../../icons/AnalyticsWhiteSVG";
import UtilDate from "../../utils/UtilDate";
import "./SideBarMenu.scss";

const SideBarMenu = ({ onCloseToggle }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();

  const [selectedMenuKey, setselectedMenuKey] = useState(location.pathname);

  const sideMenuColapsed = useSelector((state) => {
    return state.sideMenuColapsed;
  });

  const badgeCountOrder = useSelector((state) => {
    return state.badgeCountOrder;
  });

  const badgeCountPayment = useSelector((state) => {
    return state.badgeCountPayment;
  });

  const lang = useSelector((state) => {
    return state.lang;
  });

  useEffect(() => {
    setselectedMenuKey(location.pathname);
  }, [location.pathname]);

  let svgSize = sideMenuColapsed ? 20 : 26;

  const items = [
    {
      key: `/${lang}/dashboard`,
      className: "side-menu-item",
      label: (
        <MyLink path="/dashboard" state={{}}>
          {t("WORD_DASHBOARD")}
        </MyLink>
      ),
      icon: <DashboardSVG size={svgSize} />,
    },
    {
      type: "group",
    },
    {
      key: `/${lang}/order/list`,
      className: "side-menu-item",
      label: (
        <MyLink
          path="/order/list"
          state={{}}
          onClick={() => {
            ApiStorage.setOrderPageFilters({});
            if (badgeCountOrder) {
              OrdersActions.clearBadgeCountOrder();
            }
          }}
        >
          {t("WORD_ORDERS")}
        </MyLink>
      ),
      icon: (
        <Badge
          count={badgeCountOrder}
          offset={[1, 5]}
          size="small"
          style={{ boxShadow: "none", paddingTop: 1 }}
        >
          <ShoppingCartSVG size={svgSize} />
        </Badge>
      ),
    },
    {
      key: `/${lang}/payment/list`,
      className: "side-menu-item",
      label: (
        <MyLink
          path="/payment/list"
          state={{}}
          onClick={() => {
            ApiStorage.setPaymentPageFilters({});
            if (badgeCountPayment) {
              OrdersActions.clearBadgeCountPayment();
            }
          }}
        >
          {t("WORD_PAYMENTS")}
        </MyLink>
      ),
      icon: (
        <Badge
          count={badgeCountPayment}
          offset={[1, 5]}
          size="small"
          style={{ boxShadow: "none", paddingTop: 1 }}
        >
          <WalletSVG size={svgSize} />
        </Badge>
      ),
    },
    {
      key: `/${lang}/visit/list`,
      className: "side-menu-item",
      label: (
        <MyLink
          path="/visit/list"
          state={{}}
          onClick={() => {
            ApiStorage.setVisitPageFilters({});
          }}
        >
          {t("WORD_VISITS")}
        </MyLink>
      ),
      icon: <VisitSVG size={svgSize} />,
    },
    {
      type: "group",
    },
    {
      key: `/${lang}/product/list`,
      className: "side-menu-item",
      label: (
        <MyLink path="/product/list" state={{}}>
          {t("WORD_PRODUCTS")}
        </MyLink>
      ),
      icon: <ProductSVG size={svgSize} fill="white" />,
    },
    {
      key: `/${lang}/customer/list`,
      className: "side-menu-item",
      label: (
        <MyLink
          path="/customer/list"
          state={{}}
          onClick={() => {
            ApiStorage.setCustomerPageFilters({});
          }}
        >
          {t("WORD_CUSTOMERS")}
        </MyLink>
      ),
      icon: <PartnersSVG size={svgSize} />,
    },
    {
      key: `/${lang}/debit/list`,
      className: "side-menu-item",
      label: (
        <MyLink
          path="/debit/list"
          state={{}}
          onClick={() => {
            ApiStorage.setDebitPageFilters({});
          }}
        >
          {t("WORD_DEBTS")}
        </MyLink>
      ),
      icon: <DebtSVG size={svgSize} />,
    },
    {
      key: `/${lang}/analytics/orders`,
      className: "side-menu-item",
      label: (
        <MyLink
          path="/analytics/orders"
          state={{}}
          onClick={() => {
            ApiStorage.setAnalyticsFilters({
              date: "THIS_MONTH",
              dateFrom: UtilDate.getThisMonthStart(),
              dateTo: UtilDate.getThisMonthEnd(),
            });
          }}
        >
          {t("WORD_ANALYTICS")}
        </MyLink>
      ),
      icon: <AnalyticsWhiteSVG size={svgSize} />,
    },
    {
      key: `/${lang}/gps`,
      className: "side-menu-item",
      label: (
        <MyLink path="/gps" state={{}}>
          {t("WORD_GPS")}
        </MyLink>
      ),
      icon: <GpsSVG size={svgSize} />,
    },
    {
      key: `/${lang}/user/list`,
      className: "side-menu-item",
      label: (
        <MyLink
          path="/user/list"
          state={{}}
          onClick={() => {
            ApiStorage.setUserPageFilters({});
          }}
        >
          {t("WORD_SALES_TEAM")}
        </MyLink>
      ),
      icon: <AdminSVG size={svgSize} />,
    },
    {
      key: `/${lang}/device/list`,
      className: "side-menu-item",
      label: (
        <MyLink
          path="/device/list"
          state={{}}
          onClick={() => {
            ApiStorage.setDevicePageFilters({});
          }}
        >
          {t("WORD_DEVICES")}
        </MyLink>
      ),
      icon: <DevicesSVG size={svgSize} />,
    },
    {
      key: `/${lang}/branch/list`,
      className: "side-menu-item",
      label: (
        <MyLink
          path="/branch/list"
          state={{}}
          onClick={() => {
            ApiStorage.setBranchPageFilters({});
          }}
        >
          {t("WORD_BRANCHES")}
        </MyLink>
      ),
      icon: <BranchesSVG size={svgSize} />,
    },
  ];

  return (
    <Menu
      // theme={"light"}
      className="main-menu no-print"
      // style={{ padding: sideMenuColapsed ? "8px 24px" : "8px 15px" }}
      defaultSelectedKeys={["1"]}
      // mode={isMobile ? "inline" : "vertical"}
      items={items}
      selectedKeys={[selectedMenuKey]}
      onSelect={(menu) => {
        isMobile && onCloseToggle && onCloseToggle();
        setselectedMenuKey(menu?.key);
      }}
    />
  );
};

export default SideBarMenu;
