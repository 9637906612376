import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MyPageHeader from "../../components/myPageHeader/MyPageHeader";
import MySpace from "../../components/mySpace/MySpace";
import { useTranslation } from "react-i18next";
import UtilDate from "../../utils/UtilDate";
import UtilNotify from "../../utils/UtilNotify";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import NotificationActions from "../../actions/NotificationActions";
import NotificationViewModal from "./NotificationViewModal";
import MyTable from "../../components/myTable/MyTable";
import SelectNotifStatus from "../../components/selects/SelectNotifStatus";
import { useSelector } from "react-redux";
import ApiStorage from "../../redux/ApiStorage";
import MyHelmet from "../../components/helmet/MyHelmet";
import useWindowSize from "../../utils/useWindowSize";
import HeaderMobile from "../../components/headerForMobile/HeaderMobile";
import SearchInput from "../../components/myInputForm/SearchInput";
import MyButtonText from "../../components/myButton/MyButtonText";
import MyButtonTableAction from "../../components/myButton/MyButtonTableAction";
import MyTag from "../../components/tag/MyTag";

const NotificationSettings = () => {
  const { t } = useTranslation();
  const refModal = useRef();
  const { isMobile } = useWindowSize();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setpage] = useState(0);
  const [totalRows, settotalRows] = useState(0);
  const [search, setSearch] = useState("");
  const [filterStatus, setfilterStatus] = useState("");

  const fill = useCallback(async () => {
    setLoading(true);
    const fetchParams = {
      page: page,
      perPage: 50,
      search: search,
      filter: { notifRead: filterStatus },
    };
    const result = await NotificationActions.fillPagination(fetchParams);
    if (result.success) {
      setData(result.data);
      settotalRows(result.totalRows);
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    setLoading(false);
  }, [page, search, filterStatus]);

  const notificationCount = useSelector((state) => {
    return state.currentUser?.notificationCount;
  });

  useEffect(() => {
    fill();
  }, [fill]);

  const columns = useMemo(() => {
    let col = [
      {
        title: t("WORD_MESSAGE"),
        dataIndex: "notifText",
        key: "notifText",
        // width: 100,
        sorter: true,
      },
      {
        title: t("WORD_CREATE_DATE"),
        dataIndex: "notifDate",
        key: "notifDate",
        width: 200,
        // sorter: true,
        render: (value) => {
          return <div>{UtilDate.formatDateTime(value)}</div>;
        },
      },
      {
        title: t("WORD_STATUS"),
        dataIndex: "notifRead",
        key: "notifRead",
        width: 20,
        render: (value) => {
          const status = NotificationActions.getNotifStatusLabel(value);
          return <MyTag label={t(status.label)} color={status.color} />;
        },
      },
      {
        title: t("WORD_ACTIONS"),
        dataIndex: "actions",
        width: 120,
        key: "actions",
        render: (_, obj) => (
          <MySpace>
            <MyButtonTableAction
              title={t("WORD_VIEW")}
              icon={faEye}
              onClick={() => {
                refModal?.current?.viewNotification(obj);
              }}
            />
          </MySpace>
        ),
      },
    ];
    return col;
  }, [t]);

  return (
    <>
      <MyHelmet title={t("WORD_NOTIFICATION_SETTINGS")} />
      {!isMobile ? (
        <div style={{ marginBottom: 20 }}>
          <MyPageHeader
            inPage
            title={t("WORD_NOTIFICATION_SETTINGS")}
            extraLeft={
              <MySpace>
                <SearchInput
                  onClear={() => {
                    setSearch("");
                    setpage(0);
                  }}
                  onPressEnter={(value) => {
                    setSearch(value);
                    setpage(0);
                  }}
                />
                <SelectNotifStatus
                  width={150}
                  placeholder={t("WORD_SELECT_STATUS")}
                  onChange={setfilterStatus}
                />
                <MyButtonText
                  label={t("WORD_RESET_FILTERS")}
                  onClick={() => {
                    setfilterStatus("");
                  }}
                />
              </MySpace>
            }
          />
        </div>
      ) : (
        <>
          <HeaderMobile>
            <MyPageHeader title={t("WORD_PAYMENTS")} />
            <SelectNotifStatus
              width={150}
              withAll
              placeholder={t("WORD_SELECT_STATUS")}
              onChange={setfilterStatus}
            />
          </HeaderMobile>
          <SearchInput
            onClear={() => {
              setSearch("");
              setpage(0);
            }}
            onPressEnter={(value) => {
              setSearch(value);
              setpage(0);
            }}
          />
        </>
      )}
      <MyTable
        rowKey="idNotif"
        // height={"70vh"}
        columns={columns}
        dataSource={data}
        // onChange={setFilter}
        loading={loading}
        dataLength={totalRows}
        noFooter
      />
      <NotificationViewModal
        ref={refModal}
        onChange={(idNotif) => {
          setData((prevData) =>
            prevData.map((not) => {
              if (not.idNotif === idNotif) {
                not.notifRead = NotificationActions.STATUS.READED;
              }
              return not;
            })
          );
          const newCount = notificationCount - 1;
          ApiStorage.setCurrentUser(
            Object.assign({}, ApiStorage.getCurrentUser(), {
              notificationCount: newCount,
            })
          );
        }}
      />
    </>
  );
};

export default NotificationSettings;
