import React from "react";
import MyCard from "../../../components/myCard/MyCard";
import { useTranslation } from "react-i18next";
import MyButtonMain from "../../../components/myButton/MyButtonMain";
import MySpace from "../../../components/mySpace/MySpace";
import MyDivider from "../../../components/myDivider/MyDivider";
import MyTextSecondary from "../../../components/myText/MyTextSecondary";
import "./Package.scss";

const ChangePackageCard = ({
  packageName,
  packagePrice,
  packagePerMonth,
  packageDeviceCount,
  packageBranchCount,
  packageDataStorage,
  packageGPSTracking,
  pricing,
  isPopular,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <MyCard className="package-card">
      <MySpace
        direction="vertical"
        fullWidth
        style={{ justifyContent: "space-between" }}
      >
        <div>
          {isPopular ? (
            <div className="popular-tag-wrapper">
              <div className="popular-tag">{t("WORD_POPULAR")}</div>
            </div>
          ) : null}
          <h4
            style={{ marginTop: isPopular ? 5 : 35 }}
            className="package-name"
          >
            {packageName}
          </h4>
          <MyTextSecondary className="package-price" textWrap="nowrap">
            {packagePrice}
          </MyTextSecondary>
        </div>
        <div>
          <p className="package-per-month">{t(packagePerMonth)}</p>
        </div>
        <MyDivider />
        <MySpace fullWidth spaceBetween>
          <MyTextSecondary className="package-text">
            {t("WORD_MOBILE_DEVICES")}
          </MyTextSecondary>
          <MyTextSecondary className="package-text" bold>
            {t("WORD_UP_TO") + " " + packageDeviceCount}
          </MyTextSecondary>
        </MySpace>
        <MySpace fullWidth spaceBetween>
          <MyTextSecondary className="package-text">
            {t("WORD_BRANCHES")}
          </MyTextSecondary>
          <MyTextSecondary className="package-text" bold>
            {packageBranchCount}
          </MyTextSecondary>
        </MySpace>
        <MySpace fullWidth spaceBetween>
          <MyTextSecondary className="package-text">
            {t("WORD_DATA_STORAGE")}
          </MyTextSecondary>
          <MyTextSecondary className="package-text" bold>
            {packageDataStorage}
          </MyTextSecondary>
        </MySpace>
        <MySpace fullWidth spaceBetween>
          <MyTextSecondary className="package-text">
            {t("WORD_GPS_TRACKING")}
          </MyTextSecondary>
          <MyTextSecondary className="package-text" bold>
            {packageGPSTracking ? t("included") : "-"}
          </MyTextSecondary>
        </MySpace>
        <MyButtonMain
          className="package-btn"
          label={pricing ? t("WORD_FREE_TRIAL") : t("WORD_BUY_NOW")}
          onClick={onClick}
        />
      </MySpace>
    </MyCard>
  );
};

export default ChangePackageCard;
