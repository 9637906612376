import React, { forwardRef } from "react";
import MyPageHeader from "../../../components/myPageHeader/MyPageHeader";
import { useTranslation } from "react-i18next";
import MySpace from "../../../components/mySpace/MySpace";
import MyTextSecondary from "../../../components/myText/MyTextSecondary";
import ActivityRightCardRow from "../../../components/activityRightCardRow/ActivityRightCardRow";
import UtilDate from "../../../utils/UtilDate";
import MyTag from "../../../components/tag/MyTag";

const VisitViewPrint = forwardRef(({ data, type }, ref) => {
  const { t } = useTranslation();

  return (
    <div ref={ref} style={{ padding: 20 }}>
      <MyPageHeader
        title={`${t("WORD_ID")}
                  : #${data?.idManagerVisit}`}
        style={{ marginBottom: 20 }}
      />
      <MySpace direction="vertical" fullWidth>
        <MySpace>
          <MyTextSecondary secondary>{t("WORD_TYPE")}:</MyTextSecondary>
          <MyTag label={type.label} color={type.color} />
        </MySpace>
        <ActivityRightCardRow
          secondary
          title={t("WORD_VISIT_DATE")}
          data={UtilDate.formatDateTime(data?.visitDate)}
          noSpace
        />
        <ActivityRightCardRow
          secondary
          title={t("WORD_SALES_MANAGER")}
          data={data?.nameManager}
          noSpace
        />
        <ActivityRightCardRow
          secondary
          title={t("WORD_CUSTOMER")}
          data={data?.nameCustomer}
          noSpace
        />
        <ActivityRightCardRow
          secondary
          title={t("WORD_DESCRIPTION")}
          data={data?.note}
          noSpace
        />
      </MySpace>
    </div>
  );
});

export default VisitViewPrint;
