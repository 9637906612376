import React from "react";
import { useTranslation } from "react-i18next";
import PricingLeftSVG from "../../icons/PricingLeftSVG";
import LoadingIconPage from "../../components/loadingIconApp/LoadingIconPage";
import useNavigateApi from "../../appRoute/useNavigateApi";
import useWindowSize from "../../utils/useWindowSize";
import PricingRightSVG from "../../icons/PricingRightSVG";
import ChangePackageCard from "../settings/package/ChangePackageCard";
import ChangePackageCardEnterprise from "../settings/package/ChangePackageCardEnterprise";
import "./Pricing.scss";

const PricingCardsSection = ({ loading, list }) => {
  const { t } = useTranslation();
  const navigateApi = useNavigateApi();
  const { isMobile } = useWindowSize();

  if (loading) {
    return <LoadingIconPage />;
  }

  return (
    <>
      <div className="pricing-header-section">
        <h1 className="pricing-header">
          {t("WORD_FLEXIBLE")}
          <strong> {t("WORD_PLANS")} </strong>
        </h1>
        <div>
          <p className="pricing-subtitle">{t("WORD_SIGN_UP_PARAGRAPH")}</p>
        </div>
      </div>
      <div
        className="pricing-card-wrapper-package-outside"
        style={{ marginTop: isMobile ? 0 : 60 }}
      >
        {isMobile ? null : (
          <div className="left-image">
            <PricingLeftSVG />
          </div>
        )}
        {isMobile ? null : (
          <div className="right-image">
            <PricingRightSVG />
          </div>
        )}
        <div className="package-card-wrapper-outside">
          {list?.map((oo, index) => {
            return (
              <ChangePackageCard
                key={oo.idService}
                packageName={t(oo.serviceName)}
                packagePrice={`${oo.priceMontly}${oo.currrencySymbol}`}
                packagePerMonth={`${t("per month or")} ${oo.priceYearly}${
                  oo.currrencySymbol
                } ${t("billed annually")}`}
                packageDeviceCount={oo.deviceCount}
                packageBranchCount={oo.adminCount}
                packageDataStorage={oo.storageSize}
                packageGPSTracking={oo.tracking}
                isPopular={index === 1 ? true : false}
                pricing
                onClick={() => {
                  navigateApi(`/registration`);
                }}
              />
            );
          })}
          <ChangePackageCardEnterprise isPopular />
        </div>
      </div>
    </>
  );
};

export default PricingCardsSection;
