import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelectFormItem from "../mySelectFormItem/MySelectFormItem";

const SelectOrderExported = ({ withAll, ...otherProps }) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    let data = [
      { label: t("WORD_EXPORTED"), value: 1 },
      { label: t("WORD_NOT_EXPORTED"), value: 0 },
    ];
    if (withAll) data.unshift({ label: t("WORD_ALL"), value: "" });
    setlist(data);
  }, [t, withAll]);

  return <MySelectFormItem {...otherProps} options={list} />;
};

export default SelectOrderExported;
