import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelectFormItem from "../../components/mySelectFormItem/MySelectFormItem";
import ExportTemplatesActions from "../../actions/ExportTemplatesActions";

const SelectViewFor = ({ ...otherProps }) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    let data = [
      { label: "Order Screen", value: ExportTemplatesActions.VIEWIN.ORDERS },
      {
        label: "Payment Screen",
        value: ExportTemplatesActions.VIEWIN.PAYMENTS,
      },
      {
        label: "Customer Screen",
        value: ExportTemplatesActions.VIEWIN.CUSTOMER,
      },
    ];
    setlist(data);
  }, [t]);

  return <MySelectFormItem {...otherProps} options={list} />;
};

export default SelectViewFor;
