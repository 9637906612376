import React, { useCallback, useEffect, useState } from "react";
import SelectSource from "./SelectSource";
import { useForm } from "antd/es/form/Form";
import InputTextFormItem from "../../components/myInputForm/InputTextFormItem";
import TreeTransfer from "./TreeTransfer";
import { Form } from "antd";
import SelectViewFor from "./SelectViewFor";
import SelectFileType from "./SelectFileType";
import UtilNotify from "../../utils/UtilNotify";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ExportTemplatesActions from "../../actions/ExportTemplatesActions";
import MySpace from "../../components/mySpace/MySpace";
import useNavigateApi from "../../appRoute/useNavigateApi";
import EditPageForm from "../../components/editPageForm/EditPageForm";
import MyButtonSecondary from "../../components/myButton/MyButtonSecondary";
import "./ExportTemplates.scss";

const formName = "export_templets_form";

const ExportTemplatesEdit = ({ isNew }) => {
  const [form] = useForm();
  const { t } = useTranslation();
  const { _id } = useParams();
  const navigateApi = useNavigateApi();

  const [saving, setSaving] = useState(false);

  const goBack = useCallback(() => {
    navigateApi(`/settings/exportTemplates/list`);
  }, [navigateApi]);

  const onFinish = async (values) => {
    if (!values.fieldListData.length) {
      UtilNotify.notifyError(t("WORD_TARGET_FIELD_IS_REQUIRED"));
      return;
    }
    setSaving(true);
    let result;
    if (_id) {
      result = await ExportTemplatesActions.update(values, _id);
    } else {
      result = await ExportTemplatesActions.create(values);
    }
    if (result.success) {
      if (isNew) {
        UtilNotify.notifySuccess(t("WORD_EXPORT_TEMPLATE_CREATED"));
      } else {
        UtilNotify.notifySuccess(
          t("WORD_EXPORT_TEMPLATE_UPDATED_SUCCESSFULLY")
        );
      }
      goBack();
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    setSaving(false);
  };

  useEffect(() => {
    const fill = async () => {
      if (_id) {
        const result = await ExportTemplatesActions.getOne(_id);
        if (result.success) {
          form?.setFieldsValue(result.data);
        } else {
          UtilNotify.notifyErrorServer(result.errMsg);
          goBack();
        }
      }
    };
    fill();
  }, [form, _id, goBack]);

  const getTitle = () => {
    return isNew
      ? t("WORD_CREATE_EXPORT_TEMPLATE")
      : t("WORD_EXPORT_TEMPLATES");
  };

  return (
    <div style={{ width: "98%" }}>
      <EditPageForm
        isNew={isNew}
        title={getTitle()}
        form={form}
        formName={formName}
        onFinish={onFinish}
        saving={saving}
        onBack={goBack}
        extraButtons={
          <MyButtonSecondary
            label={t("WORD_DOWNLOAD")}
            loading={saving}
            onClick={async () => {
              await ExportTemplatesActions.exportFile({}, _id);
            }}
          />
        }
      >
        <InputTextFormItem label={t("WORD_TITLE")} name="title" required />
        <SelectViewFor
          name="viewIn"
          label={t("WORD_VIEW_IN")}
          required
          onChange={(val) => {
            if (val === ExportTemplatesActions.VIEWIN.CUSTOMER) {
              form.setFieldsValue({
                source: ExportTemplatesActions.SOURCE.CUSTOMER,
              });
            }
          }}
        />
        <SelectFileType
          name="fileType"
          label={t("WORD_FILE_EXTENSION")}
          required
        />
        <SelectSource
          name="source"
          label={t("WORD_GROUP_BY")}
          onChange={() => form?.setFieldsValue({ fieldListData: [] })}
          required
        />
        {/* <Form.List name="fieldList" hidden={true}>
          {(fields) =>
            fields.map((field) => <React.Fragment key={field.key} />)
          }
        </Form.List> */}
        <Form.List name="fieldListData" hidden={true}>
          {(fields) =>
            fields.map((field) => <React.Fragment key={field.key} />)
          }
        </Form.List>
        <br />
        <MySpace className="tree-parent" fullWidth>
          <TreeTransfer form={form} />
        </MySpace>
      </EditPageForm>
    </div>
  );
};

export default ExportTemplatesEdit;
