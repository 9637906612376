import React, { useEffect, useState } from "react";
import FormApp from "../../components/formApp/FormApp";
import { useTranslation } from "react-i18next";
import UtilNotify from "../../utils/UtilNotify";
import ProductUnitActions from "../../actions/ProductUnitActions";
import LoadingIconApp from "../../components/loadingIconApp/LoadingIconApp";
import MySelectFormMultiItem from "../../components/mySelectFormMultiItem/MySelectFormMultiItem";

const formName = "product_unit_form";

const ProductSettings = ({ form }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [list, setlist] = useState([]);

  useEffect(() => {
    const fill = async () => {
      setLoading(true);
      const result = await ProductUnitActions.productUnitOptions();
      if (result.success) {
        const data = result.data.map((it) => ({
          label: t(it.description),
          value: "" + it.idUnit,
          ...it,
        }));
        setlist(data);
        form.setFieldsValue({
          productUnit: data.filter((oo) => oo.used).map((oo) => oo.value),
        });
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setLoading(false);
    };
    fill();
  }, [form, t]);

  const onFinish = async (values) => {
    const listToSend = list?.filter((it) => {
      return values?.productUnit?.includes(it.value);
    });
    if (!listToSend.length) {
      UtilNotify.notifyError(t("WORD_PLEASE_SELECT_ONE"));
      return;
    }
    const result = await ProductUnitActions.save(listToSend);
    if (!result.success) {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
  };

  return (
    <>
      {loading ? (
        <LoadingIconApp />
      ) : (
        <FormApp
          form={form}
          name={formName}
          onFinish={onFinish}
          style={{ marginTop: 16 }}
        >
          <MySelectFormMultiItem
            options={list}
            label={t("WORD_PRODUCT_UNIT")}
            loading={loading}
            name="productUnit"
            showSearch
          />
        </FormApp>
      )}
    </>
  );
};

export default ProductSettings;
