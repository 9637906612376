import { fetchFromUrlPOSTAsync } from "../utils/UrlHelper";
import UtilDate from "../utils/UtilDate";

const URL_ORDER_BY_PAYMENT_TYPE_LIST =
  "analytics/orders/order_by_payment_type_list";
const URL_ORDER_BY_COUNTRY_LIST =
  "analytics/orders/sale_return_payment_by_country_list";
const URL_ORDER_BY_REGION_LIST =
  "analytics/orders/sale_return_payment_by_region_list";
const URL_ORDER_BY_CITY_LIST =
  "analytics/orders/sale_return_payment_by_city_list";
const URL_ORDER_BY_DISTRICT_LIST =
  "analytics/orders/sale_return_payment_by_district_list";
const URL_ORDER_BY_SALE_RETURN_PAYMENT = "analytics/orders/sale_return_payment";
const URL_ORDER_BY_SALE_RETURN_PAYMENT_BY_MONTH_LIST =
  "analytics/orders/sale_return_payment_by_month_list";
const URL_CUSTOMER_BY_CUSTOMER_COUNT =
  "analytics/customers/customer_count";
const URL_CUSTOMER_BY_ROUTE_COUNT =
  "analytics/customers/route_count";
const URL_CUSTOMER_BY_ROUT_CUSTOMER_COUNT_LIST =
  "analytics/customers/rout_customer_count_list";
const URL_CUSTOMER_BY_COUNTRY_LIST =
  "analytics/customers/customer_count_by_country_list";
const URL_CUSTOMER_BY_REGION_LIST =
  "analytics/customers/customer_count_by_region_list";
const URL_CUSTOMER_BY_CITY_LIST =
  "analytics/customers/customer_count_by_city_list";
const URL_CUSTOMER_BY_DISTRICT_LIST =
  "analytics/customers/customer_count_by_district_list";
const URL_CUSTOMER_BY_MONTH_LIST =
  "analytics/customers/customer_count_by_month_list";

const fs = {
  order_by_payment_type_list: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_ORDER_BY_PAYMENT_TYPE_LIST,
      urlParams
    );
    return result;
  },
  sale_return_payment_by_country_list: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_ORDER_BY_COUNTRY_LIST,
      urlParams
    );
    return result;
  },
  sale_return_payment_by_region_list: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_ORDER_BY_REGION_LIST,
      urlParams
    );
    return result;
  },
  sale_return_payment_by_city_list: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_ORDER_BY_CITY_LIST,
      urlParams
    );
    return result;
  },
  sale_return_payment_by_district_list: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_ORDER_BY_DISTRICT_LIST,
      urlParams
    );
    return result;
  },
  ordersStats: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_ORDER_BY_SALE_RETURN_PAYMENT,
      urlParams
    );
    return result;
  },
  saleReturnPaymentByMonthList: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_ORDER_BY_SALE_RETURN_PAYMENT_BY_MONTH_LIST,
      urlParams
    );
    return result;
  },
  customerCount: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_CUSTOMER_BY_CUSTOMER_COUNT,
      urlParams
    );
    return result;
  },
  routeCount: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_CUSTOMER_BY_ROUTE_COUNT,
      urlParams
    );
    return result;
  },
  routCustomerCountList: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_CUSTOMER_BY_ROUT_CUSTOMER_COUNT_LIST,
      urlParams
    );
    return result;
  },
  customer_count_by_country_list: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_CUSTOMER_BY_COUNTRY_LIST,
      urlParams
    );
    return result;
  },
  customer_count_by_region_list: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_CUSTOMER_BY_REGION_LIST,
      urlParams
    );
    return result;
  },
  customer_count_by_city_list: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_CUSTOMER_BY_CITY_LIST,
      urlParams
    );
    return result;
  },
  customer_count_by_district_list: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_CUSTOMER_BY_DISTRICT_LIST,
      urlParams
    );
    return result;
  },
  customerCountByMonthList: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_CUSTOMER_BY_MONTH_LIST,
      urlParams
    );
    return result;
  },
};

const loc = {
  getRangeOptionsValue: (selectedDateRangeOption) => {
    switch (selectedDateRangeOption) {
      case "YESTERDAY":
        return {
          dateFrom: UtilDate.getDateYesterday(),
          dateTo: UtilDate.getDateYesterday(),
          dateFromP: UtilDate.getSubtractDays(UtilDate.getDateYesterday(), 1),
          dateToP: UtilDate.getSubtractDays(UtilDate.getDateYesterday(), 1),
        };
      case "3DAYS":
        return {
          dateFrom: UtilDate.getDate3DaysAgo(),
          dateTo: UtilDate.getDateYesterday(),
          dateFromP: UtilDate.getSubtractDays(UtilDate.getDateToday(), 6),
          dateToP: UtilDate.getSubtractDays(UtilDate.getDateYesterday(), 3),
        };
      case "WEEK":
        return {
          dateFrom: UtilDate.getLastWeekStart(),
          dateTo: UtilDate.getLastWeekEnd(),
          dateFromP: UtilDate.getSubtractDays(UtilDate.getDateToday(), 14),
          dateToP: UtilDate.getSubtractDays(UtilDate.getDateYesterday(), 7),
        };
      case "THIS_MONTH":
        return {
          dateFrom: UtilDate.getThisMonthStart(),
          dateTo: UtilDate.getThisMonthEnd(),
          dateFromP: UtilDate.getLastMonthStart(),
          dateToP: UtilDate.getLastMonthEnd(),
        };
      case "LAST_MONTH":
        return {
          dateFrom: UtilDate.getLastMonthStart(),
          dateTo: UtilDate.getLastMonthEnd(),
          dateFromP: UtilDate.getLastMonthStart(UtilDate.getLastMonthStart()),
          dateToP: UtilDate.getLastMonthEnd(UtilDate.getLastMonthEnd()),
        };
      case "LAST_YEAR":
        return {
          dateFrom: UtilDate.getLastYearStart(),
          dateTo: UtilDate.getLastYearEnd(),
          dateFromP: UtilDate.getLastMonthStart(UtilDate.getLastYearStart()),
          dateToP: UtilDate.getLastMonthEnd(UtilDate.getLastYearEnd()),
        };
      case "THIS_YEAR":
        return {
          dateFrom: UtilDate.getThisYearStart(),
          dateTo: UtilDate.getThisYearEnd(),
          dateFromP: UtilDate.getLastYearStart(),
          dateToP: UtilDate.getLastYearEnd(),
        };
      default:
        return {
          dateFrom: UtilDate.getDateToday(),
          dateTo: UtilDate.getDateToday(),
          dateFromP: UtilDate.getSubtractDays(UtilDate.getDateToday(), 1),
          dateToP: UtilDate.getSubtractDays(UtilDate.getDateToday(), 1),
        };
    }
  },
};

const AnalyticsActions = Object.assign(fs, loc);

export default AnalyticsActions;
