import React from "react";
import MySpace from "../mySpace/MySpace";
import MyTextSecondary from "../myText/MyTextSecondary";
import MyText from "../myText/MyText";
import colors from "../../Color.scss";

const DateDisplayWithIconLabel = ({
  title,
  data,
  style,
  bold,
  secondStyle,
  icon,
}) => {
  return (
    <MySpace>
      <div>{icon}</div>
      <MySpace direction="vertical" style={{ rowGap: 0, columnGap: 0 }}>
        <MyTextSecondary
          style={style}
          bold={bold}
          color={colors.dateDisplayGray}
        >
          {title}
        </MyTextSecondary>
        <MyText style={secondStyle}>{data}</MyText>
      </MySpace>
    </MySpace>
  );
};

export default DateDisplayWithIconLabel;
