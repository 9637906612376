import axios from "axios";
import React, { useEffect, useState } from "react";
import { AutoComplete, Form } from "antd";
import { useSelector } from "react-redux";
import UtilString from "../../../utils/UtilString";
import { useTranslation } from "react-i18next";
import UtilNotify from "../../../utils/UtilNotify";
import "./SelectAddressSuggestion.scss";

const SelectAddressSuggestionYandex = ({
  label,
  className,
  onSelect,
  placeholder,
  hidden,
  name,
  required,
  maxLength,
  minLength,
  // suffixIcon,
  onClear,
}) => {
  const mapKeyYandexSuggestion = useSelector((state) => {
    return (
      state.currentUser?.mapKeyYandexSuggestion ||
      process.env.REACT_APP_YANDEX_MAP_APIKEY
    );
  });
  const mapKeyYandexApi = useSelector((state) => {
    return (
      state.currentUser?.mapKeyYandexApi ||
      process.env.REACT_APP_YANDEX_MAP_APIKEY
    );
  });

  const { t } = useTranslation();

  // const mapKeyYandex = `5bdc348b-211d-4bc5-a4ce-0c21682ccfcf`;
  // const mapKeyYandex = `5942eb60-edea-424f-bd70-04bbb7d2d80a`;
  // const mapKeyYandex = `836db0a4-bacd-43ef-85be-692f4928a41b`;

  const clientCountryName = useSelector((state) => {
    return state.currentUser?.clientCountryName;
  });

  const [listData, setlistData] = useState([]);
  const [search, setsearch] = useState("");

  useEffect(() => {
    let count = search?.length || 0;
    const getTextForSearch = () => {
      if (search) {
        if (UtilString.checkLanguage(search) === "en") {
          return `${clientCountryName} ${search}`;
        }
      }
      return `${search}`;
    };

    const se = getTextForSearch();
    if (se && count > 3) {
      // if (se) {
      const config = {};
      axios
        .get(
          `https://suggest-maps.yandex.ru/v1/suggest?apikey=${mapKeyYandexSuggestion}&text=${se}&results=10`,
          config
        )
        .then((res) => {
          if (res) {
            const data = res?.data;
            if (data) {
              if (data?.results) {
                setlistData(
                  data?.results
                    ?.filter((it) => it?.subtitle?.text && it?.title?.text)
                    .map((oo) => {
                      const add = `${oo?.subtitle?.text}, ${oo?.title?.text}`;
                      return {
                        label: add,
                        value: add,
                      };
                    }) || []
                );
              }
            }
          }
        })
        .catch((error) => {
          UtilNotify.notifyError(
            "Error: please check your Yandex suggestion KEY"
          );
        });
    }
  }, [search, mapKeyYandexSuggestion, mapKeyYandexApi, clientCountryName]);

  return (
    <div>
      <Form.Item
        label={label}
        name={name}
        hasFeedback={false}
        rules={[
          {
            required: required,
            message: `${t("WORD_ERROR_PLEASE_INPUT")} ${placeholder || label}!`,
          },
          {
            max: maxLength,
            message: `${
              placeholder || label
            } must be maximum ${maxLength} characters.`,
          },
          {
            min: minLength,
            message: `${
              placeholder || label
            } must be minimum ${minLength} characters.`,
          },
        ]}
        hidden={hidden}
        className={className || ""}
      >
        <AutoComplete
          className="yandex-autocomplete"
          onSearch={setsearch}
          placeholder={placeholder}
          options={listData}
          defaultActiveFirstOption={true}
          backfill={true}
          onSelect={onSelect}
          allowClear
          onClear={onClear}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        />
      </Form.Item>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 5,
          marginTop: 50,
        }}
      >
        {suffixIcon}
      </div> */}
    </div>
  );
};

export default SelectAddressSuggestionYandex;
