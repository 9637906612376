import { fetchFromUrlPOSTAsync } from "../utils/UrlHelper";

const URL_LIST = "district/list";

const fs = {
  fillList: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_LIST, urlParams);
    return result;
  },
  getList: async (idCity) => {
    const result = await fetchFromUrlPOSTAsync(URL_LIST + "/" + idCity, []);
    return result;
  },
};

const loc = {
  fillListOptions: async (idCity) => {
    const result = await DistrictActions.getList(idCity);
    if (result.success) {
      result.data = result.data.map((it) => {
        return {
          label: it.name,
          value: it.idDistrict,
          ...it,
        };
      });
    }
    return result;
  },
};

const DistrictActions = Object.assign(fs, loc);

export default DistrictActions;
