import React from "react";
import { Typography } from "antd";

const { Text } = Typography;

const MyText = ({
  children,
  size,
  bold,
  className,
  style,
  onClick,
  strong,
  color,
}) => {
  return (
    <Text
      style={{ fontSize: size, fontWeight: bold ? "bold" : "normal", ...style }}
      strong={strong || false}
      className={className}
      onClick={onClick}
      color={color}
    >
      {children}
    </Text>
  );
};

export default MyText;
