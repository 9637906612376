import React, { useEffect, useRef } from "react";
import { FilterOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { t } from "i18next";
import MyDrawerWithReset from "../../../components/myDrawer/MyDrawerWithReset";
import FormApp from "../../../components/formApp/FormApp";
import MySpace from "../../../components/mySpace/MySpace";
import SelectCustomerMulti from "../../../components/selects/SelectCustomerMulti";
import InputFormDatePicker from "../../../components/inputFormDatePicker/InputFormDatePicker";
import SelectOrderBranch from "../../../components/selects/SelectOrderBranch";
import SelectDateFromTo from "../../../components/selects/SelectDateFromTo";
import { Form } from "antd";
import { useForm } from "antd/es/form/Form";
import ApiStorage from "../../../redux/ApiStorage";
import UtilObject from "../../../utils/UtilObject";
import UtilDate from "../../../utils/UtilDate";

const formName = "formAnalyticsOrdersFilter";

const AnalyticsOrdersFilterDrawer = ({ onChange, onReset }) => {
  const [form] = useForm();
  const refDrawer = useRef();

  const date = Form.useWatch("date", form);

  const filter = useSelector((state) => {
    return state.analyticsFilters;
  });

  useEffect(() => {
    if (!UtilObject.isEmpty(filter)) {
      form?.setFieldsValue({
        ...filter,
        dateFrom: UtilDate.getDate(filter.dateFrom),
        dateTo: UtilDate.getDate(filter.dateTo),
      });
    }
  }, [filter, form]);

  const onFinish = (values) => {
    ApiStorage.setAnalyticsFilters(values);
    onChange && onChange(values);
    refDrawer?.current?.closeDrawer();
  };

  const handleReset = () => {
    form.resetFields();
    form.submit();
    onReset && onReset();
    refDrawer?.current?.closeDrawer();
  };

  const countDefinedValues = (obj) => {
    return Object.values(obj).filter(
      (value) =>
        value !== undefined && (Array.isArray(value) ? value.length > 0 : true)
    ).length;
  };

  const definedCount = countDefinedValues(filter);

  const isCustomDate = date === "CUSTOM";

  return (
    <MyDrawerWithReset
      ref={refDrawer}
      label={t("WORD_FILTER")}
      icon={<FilterOutlined />}
      formName={formName}
      handleReset={handleReset}
      definedCount={definedCount}
    >
      <FormApp
        onFinish={onFinish}
        name={formName}
        form={form}
        initialValues={{
          date: "THIS_MONTH",
          dateFrom: UtilDate.getThisMonthStart(),
          dateTo: UtilDate.getThisMonthEnd(),
        }}
      >
        <MySpace direction="vertical" size="middle" fullWidth>
          <SelectDateFromTo
            withCustom
            name="date"
            label={t("WORD_DATE")}
            onChange={(_, ee) => {
              form.setFieldsValue(ee);
            }}
          />
          <InputFormDatePicker
            placeholder={t("WORD_DATE_FROM")}
            label={t("WORD_DATE_FROM")}
            name="dateFrom"
            disabled={!isCustomDate}
          />
          <InputFormDatePicker
            placeholder={t("WORD_DATE_TO")}
            name="dateTo"
            label={t("WORD_DATE_TO")}
            disabled={!isCustomDate}
          />
          <SelectOrderBranch
            placeholder={t("WORD_SELECT_BRANCH")}
            label={t("WORD_SELECT_BRANCH")}
            name="idBranch"
            fullWidth
            withAll
          />
          <SelectCustomerMulti
            placeholder={t("WORD_SELECT_CUSTOMER")}
            label={t("WORD_SELECT_CUSTOMER")}
            name="idCustomer"
            fullWidth
            withAll
          />
        </MySpace>
      </FormApp>
    </MyDrawerWithReset>
  );
};

export default AnalyticsOrdersFilterDrawer;
