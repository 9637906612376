import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySpace from "../../../components/mySpace/MySpace";
import InputTextFormItem from "../../../components/myInputForm/InputTextFormItem";
import SelectManager from "../../../components/selects/SelectManager";
import SelectPaymentStatus from "../../../components/selects/SelectPaymentStatus";
import InputTextAreaFormItem from "../../../components/myInputForm/InputTextAreaFormItem";
import SelectOrderPaymentType from "../../../components/selects/SelectOrderPaymentType";
import InputFormDatePicker from "../../../components/inputFormDatePicker/InputFormDatePicker";
import paymentActions from "../../../actions/PaymentActions";
import UtilNotify from "../../../utils/UtilNotify";
import { useForm } from "antd/es/form/Form";
import SelectCustomer from "../../../components/selects/SelectCustomer";
import useNavigateApi from "../../../appRoute/useNavigateApi";
import OrdersActions from "../../../actions/OrdersActions";
import { useParams } from "react-router-dom";
import UtilDate from "../../../utils/UtilDate";
import EditPageForm from "../../../components/editPageForm/EditPageForm";

const formName = "formPaymentEdit";

const PaymentEdit = ({ isNew }) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const navigateApi = useNavigateApi();

  const [loading, setloading] = useState(!isNew);
  const [saving, setSaving] = useState(false);

  const { _idOrder } = useParams();

  const handleNavigate = () => {
    navigateApi(`/payment/list`);
  };

  useEffect(() => {
    const fill = async () => {
      setloading(true);
      const resultGetOne = await OrdersActions.getOne(_idOrder);
      if (resultGetOne.success) {
        form?.setFieldsValue(
          Object.assign(resultGetOne.data, {
            createdAt: UtilDate.getDate(resultGetOne.data.createdAt),
          })
        );
      } else {
        UtilNotify.notifyErrorServer(resultGetOne.errMsg);
      }
      setloading(false);
    };
    if (!isNew) {
      fill();
    }
  }, [_idOrder, isNew, form]);

  const onFinish = async (values) => {
    setSaving(true);
    let result;
    if (!isNew) {
      result = await paymentActions.update(values, _idOrder);
    } else {
      result = await paymentActions.create(values);
    }
    if (result.success) {
      UtilNotify.notifySuccess(
        isNew ? t("WORD_PAYMENT_CREATED") : t("WORD_PAYMENT_UPDATED")
      );
      handleNavigate();
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    setSaving(false);
  };

  const getTitle = () => {
    return `${t("WORD_PAYMENT")} ${isNew ? t("WORD_CREATE") : t("WORD_EDIT")}`;
  };

  return (
    <EditPageForm
      form={form}
      formName={formName}
      onFinish={onFinish}
      title={getTitle()}
      saving={saving}
      loading={loading}
      isNew={isNew}
      withCancel
      cancelOnClick={handleNavigate}
      onBack={handleNavigate}
      initialValues={{ createdAt: UtilDate.getDateToday() }}
    >
      <SelectManager
        name="idManager"
        label={t("WORD_DISTRIBUTOR")}
        placeholder={t("WORD_SELECT_DISTRIBUTOR")}
        required
      />
      <SelectCustomer
        name="idCustomer"
        label={t("WORD_CUSTOMER")}
        placeholder={t("WORD_SELECT_CUSTOMER")}
        required
      />
      <MySpace fullWidth className="select-row" align="top">
        <InputTextFormItem
          label={t("WORD_TOTAL_AMOUNT")}
          placeholder={t("WORD_TOTAL_AMOUNT")}
          name="totalAmount"
          required
        />
        <SelectOrderPaymentType
          label={t("WORD_PAYMENT_TYPE")}
          placeholder={t("WORD_SELECT_PAYMENT_TYPE")}
          name="idPaymentType"
          required
        />
      </MySpace>
      <MySpace fullWidth className="select-row" align="top">
        <InputFormDatePicker
          name="createdAt"
          label={t("WORD_CREATED_AT")}
          placeholder={t("WORD_SELECT_CREATED_AT")}
          required
        />
        <SelectPaymentStatus
          label={t("WORD_PAYMENT_STATUS")}
          placeholder={t("WORD_SELECT_PAYMENT_STATUS")}
          name="confirmStatus"
          required
        />
      </MySpace>
      <InputTextAreaFormItem name="note" label={t("WORD_NOTE")} />
    </EditPageForm>
  );
};

export default PaymentEdit;
