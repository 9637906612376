import React, { useEffect, useRef, useState } from "react";
import MySpace from "../../../components/mySpace/MySpace";
import { useTranslation } from "react-i18next";
import { useForm } from "antd/es/form/Form";
import { useParams } from "react-router-dom";
import SelectOrderBranch from "../../../components/selects/SelectOrderBranch";
import InputTextFormItem from "../../../components/myInputForm/InputTextFormItem";
import InputEmailFormItem from "../../../components/myInputForm/InputEmailFormItem";
import MyButtonMain from "../../../components/myButton/MyButtonMain";
import MyButtonDelete from "../../../components/myButton/MyButtonDelete";
import UserActions from "../../../actions/UserActions";
import UtilNotify from "../../../utils/UtilNotify";
import InputPasswordFormItem from "../../../components/myInputForm/InputPasswordFormItem";
import md5 from "md5";
import UserChangePasswordModal from "./UserChangePasswordModal";
import MyTextSecondary from "../../../components/myText/MyTextSecondary";
import useNavigateApi from "../../../appRoute/useNavigateApi";
import { Form } from "antd";
import MyButtonConfirm from "../../../components/myButton/MyButtonConfirm";
import EditPageForm from "../../../components/editPageForm/EditPageForm";
import "../userList/UserList.scss";

const formName = "formUserEdit";

const UserEdit = ({ isNew }) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const navigateApi = useNavigateApi();
  const refModal = useRef();
  const status = Form.useWatch("status", form);

  const [loading, setloading] = useState(false);
  const [saving, setSaving] = useState(false);

  const { _idUser } = useParams();

  const goBack = () => {
    navigateApi(`/user/list`);
  };

  const onFinish = async (values) => {
    setSaving(true);
    let result;
    if (!isNew) {
      result = await UserActions.updateManager(values, _idUser);
    } else {
      values.password = md5(values.password);
      result = await UserActions.createManager(values);
    }
    if (result.success) {
      UtilNotify.notifySuccess(
        isNew ? t("WORD_MANAGER_CREATED") : t("WORD_MANAGER_UPDATED")
      );
      goBack();
    } else {
      UtilNotify.notifyErrorServer(result.errrMsg);
    }
    setSaving(false);
  };

  useEffect(() => {
    const fill = async () => {
      setloading(true);
      const resultGetOne = await UserActions.getOne(_idUser);
      if (resultGetOne.success) {
        form?.setFieldsValue(resultGetOne.data);
      } else {
        UtilNotify.notifyErrorServer(resultGetOne.errMsg);
      }
      setloading(false);
    };
    if (!isNew) {
      fill();
    }
  }, [_idUser, isNew, form]);

  const handleDelete = async () => {
    const result = await UserActions.deleteUserTeam(_idUser);
    if (result.success) {
      UtilNotify.notifySuccess(t("WORD_MANAGER_DELETED_SUCCESSFULLY"));
      goBack();
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
  };

  const handleBlock = async () => {
    const result = await UserActions.updateManager(
      { status: UserActions.STATUS.BLOCKED },
      _idUser
    );
    if (result.success) {
      UtilNotify.notifySuccess(t("WORD_MANAGER_BLOCKED_SUCCESSFULLY"));
      goBack();
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
  };

  const handleUnblock = async () => {
    const result = await UserActions.updateManager(
      { status: UserActions.STATUS.ACTIVE },
      _idUser
    );
    if (result.success) {
      UtilNotify.notifySuccess(t("WORD_MANAGER_UNBLOCKED_SUCCESSFULLY"));
      goBack();
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
  };

  const getTitle = () => {
    return `${t("WORD_DISTRIBUTOR")} ${
      isNew ? t("WORD_CREATE") : t("WORD_EDIT")
    }`;
  };

  return (
    <>
      <EditPageForm
        title={getTitle()}
        form={form}
        formName={formName}
        onFinish={onFinish}
        saving={saving}
        onBack={goBack}
        loading={loading}
        isNew={isNew}
        withCancel
        extraButtons={
          <>
            {isNew ? null : (
              <MySpace>
                {status === UserActions.STATUS.BLOCKED ? (
                  <MyButtonConfirm
                    label={t("WORD_UNBLOCK")}
                    onConfirm={handleUnblock}
                    title={t("WORD_ARE_YOU_SURE_YOU_WANT_TO_UNBLOCK")}
                  />
                ) : (
                  <MyButtonDelete
                    label={t("WORD_BLOCK")}
                    onConfirm={handleBlock}
                    blockBtn
                  />
                )}
                <MyButtonDelete
                  form={formName}
                  label={t("WORD_DELETE")}
                  onConfirm={handleDelete}
                />
                <MyButtonMain
                  label={t("WORD_CHANGE_PASSWORD")}
                  onClick={() => {
                    refModal?.current?.viewChangePassword(_idUser);
                  }}
                  changePassword
                  noForm
                />
              </MySpace>
            )}
          </>
        }
      >
        <MyTextSecondary>{t("WORD_PLEASE_FILL_DISTRUBUTOR")}</MyTextSecondary>
        <SelectOrderBranch
          label={t("WORD_BRANCH")}
          placeholder={t("WORD_SELECT_BRANCH")}
          name="idBranch"
          required
          onLoad={(val) => {
            if (isNew) {
              form.setFieldsValue({ idBranch: val });
            }
          }}
        />
        <InputTextFormItem label={t("WORD_FIRST_NAME")} name="fName" required />
        <InputTextFormItem
          label={t("WORD_USERNAME")}
          name="username"
          required
        />
        {isNew ? (
          <InputPasswordFormItem
            label={t("WORD_PASSWORD")}
            name="password"
            required
          />
        ) : null}
        <InputEmailFormItem
          label={t("WORD_EMAIL")}
          name="email"
          required={false}
        />
        <MySpace fullWidth className="select-row" align="top">
          <InputTextFormItem name="phone" label={t("WORD_PHONE")} />
          <InputTextFormItem name="menagerNumber" label={t("WORD_NUMBER")} />
        </MySpace>
        <InputTextFormItem
          label={t("WORD_DELIVERY_METHOD")}
          name="deliveryMethod"
        />
        <InputTextFormItem
          label={t("WORD_SUPPLY_LOCATION")}
          name="supplyLocation"
        />
        <InputTextFormItem name="status" hidden />
      </EditPageForm>
      <UserChangePasswordModal ref={refModal} />
    </>
  );
};

export default UserEdit;
