import { Form, Input } from "antd";
import React from "react";

const DateFormLabel = ({ name }) => {
  return (
    <Form.Item noStyle name={name}>
      <Input style={{ maxWidth: 110 }} readOnly variant="borderless" />
    </Form.Item>
  );
};

export default DateFormLabel;
