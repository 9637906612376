import React, { memo } from "react";
import MyIcon from "./MyIcon";

export const SVGIcon = (props) => {
  return (
    <svg
      width="160"
      height="285"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 159 284.4"
      // style="enable-background:new 0 0 159 284.4;"
      // xml:space="preserve"
    >
      <g>
        <defs>
          <rect id="SVGID_1_" x="0" width="159" height="284.4" />
        </defs>
        {/* <clipPath id="SVGID_00000088106203887492375840000006862222792177123506_">
          <use xlink:href="#SVGID_1_" style={{ overflow: "hidden" }} />
        </clipPath> */}
        <g>
          <linearGradient
            id="SVGID_00000124133524947448731550000007332146083822511494_"
            gradientUnits="userSpaceOnUse"
            x1="61.936"
            y1="157.3018"
            x2="158.9902"
            y2="157.3018"
            gradientTransform="matrix(1 0 0 -1 0 286)"
          >
            <stop offset="0" style={{ stopColor: "#61D067" }} />
            <stop offset="1" style={{ stopColor: "#5DCFF6" }} />
          </linearGradient>
          <path
            style={{
              opacity: "0.5",
              fill: "url(#SVGID_00000124133524947448731550000007332146083822511494_)",
            }}
            d="
			M150.5,0.4c-8.4-4-16.7,22.6-16.7,22.6c-14.2,18.8-34.1-6.2-32.2,21.9c3.4,49.2-56.6,15.5-28.9,66.8c27.7,51.3-24.4,29-7.2,70.4
			c17.2,41.4-5.1,90.8,20.8,70.5c0,0,22.8-50,46.3-65.5s-14.1-33.8-6.7-54.8c10.7-30.5,36.4-29.3,17.9-62.1
			C125.1,37.5,179,14,150.5,0.4z"
          />
          <g>
            <path
              style={{
                opacity: "0.5",
                fill: "url(#SVGID_00000124133524947448731550000007332146083822511494_)",
              }}
              d="M78.1,233.9c11.2-7.8,24.4-62.9,25.1-95.6c0.4-16.8,5.4-23.8,9.7-29.9c4.2-5.9,7.8-11,5.7-22.7
				c-4-22.3,6.5-32,6.6-32.1l-1.2-1.4c-0.5,0.4-11.5,10.4-7.3,33.8c2,10.9-1.3,15.5-5.4,21.3c-4.3,6.1-9.7,13.6-10.1,31
				c-0.8,35.2-14.3,87.1-24.3,94.2L78.1,233.9z"
            />
          </g>
          <path
            style={{ fill: "#FBE0CE" }}
            d="M54.6,217.8c-1-2.4-2.1-4.9-3.4-7.5c-6.4-13.5-12.1-15.8-21.5-19.5c-2.3-0.9-4.9-1.9-7.8-3.2
			c-13.5-6.1-10.9-15-8.6-22.9c0.3-1.1,0.6-2.2,0.9-3.3c1.2-4.6-1.1-7-4.5-10.5c-3.4-3.2-6.1-6.9-8.2-11c-2.7-5.6-1.3-8.6,0.3-10.2
			c3.4-3.2,11.3-2.7,18.7,1.4c2.3,1.5,4.4,3.3,6.2,5.4c4.7,5,10.6,11.2,25.6,11.2c8.5,0,13.2,1.2,15.6,3.9c2.7,3.1,2.2,7.7,1.5,14.1
			c-0.2,2.2-0.5,4.7-0.7,7.4c-1,16.1,8.5,27.8,8.6,27.9c5.7,6.8,12.7,20.8,9.7,29.5c-1.3,3.6-4.1,5.9-8.4,6.8
			c-1.6,0.3-3.2,0.5-4.7,0.5C63.5,237.9,59.6,229.3,54.6,217.8z M19.7,132.9c-6.6-3.6-13.8-4.4-16.6-1.7c-2.1,2-1.1,5.5,0.1,8
			c2,3.9,4.6,7.5,7.9,10.5c3.6,3.7,6.4,6.6,5,12.2c-0.3,1.1-0.6,2.2-0.9,3.4c-2.3,7.8-4.5,15.3,7.5,20.6c2.8,1.3,5.4,2.3,7.7,3.2
			c9.5,3.7,15.8,6.2,22.5,20.4c1.2,2.6,2.3,5.1,3.4,7.5c5.8,13.2,9.3,21.1,22,18.5c3.6-0.7,6-2.6,7-5.6c2.7-7.6-3.7-21-9.3-27.7
			c-6.4-8.4-9.6-18.7-9-29.2c0.2-2.8,0.4-5.3,0.7-7.5c0.7-5.9,1.1-10.2-1-12.6c-2-2.2-6.4-3.3-14.2-3.3
			c-15.7-0.1-21.9-6.6-26.9-11.8C23.8,136,21.9,134.3,19.7,132.9L19.7,132.9z"
          />
          <g>
            <path
              style={{ fill: "#F5B98F" }}
              d="M76.3,249.9h1.7c0-1.7-0.5-40.6-15.6-52.6c-16.5-13.2-21.4-26.8-23.5-35.9c-2.2-9.4-14.2-13.2-17.6-14.1
				c-0.1,0-0.2,0-0.4,0c-0.1,0-0.2,0.1-0.3,0.1s-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.2-0.1,0.3l0,0c0,0.2,0,0.4,0.1,0.6
				c0.1,0.2,0.3,0.3,0.5,0.4c3.1,0.9,14.3,4.5,16.3,12.9c2.2,9.4,7.2,23.3,24.2,36.9C75.8,210.2,76.3,249.5,76.3,249.9z"
            />
          </g>
          <linearGradient
            id="SVGID_00000080176803961586989760000008887910900982466725_"
            gradientUnits="userSpaceOnUse"
            x1="64.7178"
            y1="244.6461"
            x2="64.7178"
            y2="39.6292"
            gradientTransform="matrix(1 0 0 -1 0 286)"
          >
            <stop offset="0" style={{ stopColor: "#5D4BF4" }} />
            <stop offset="1" style={{ stopColor: "#419BF1" }} />
          </linearGradient>
          <path
            style={{
              opacity: "0.55",
              fill: "url(#SVGID_00000080176803961586989760000008887910900982466725_)",
            }}
            d="
			M39.8,41.8C51.5,37.8,49,60.6,54,77.5c5,16.9,55,7.4,34,49.7c-21,42.3,26.6,14.2,12.4,51.1c-14.2,37-1.2,76.5-16.7,66.4
			s-8.7-56.6-24.8-62.1c-16.1-5.5-18.7-12-2.8-27c15.9-15-41.7-30-27.5-60.3C42.9,65,26.3,46.5,39.8,41.8z"
          />
          <g>
            <path
              style={{
                opacity: "0.55",
                fill: "url(#SVGID_00000080176803961586989760000008887910900982466725_)",
              }}
              d="M77.5,240.5c1.5-6.2,14.1-61.2,2.3-67.9c-10.3-5.9-10.1-28.6-8.5-50.2c1.4-19-23-27.9-24.1-28.3l-0.6,1.8
				c0.2,0.1,24.2,8.8,22.8,26.4c-1.6,22.1-1.8,45.6,9.4,51.9c8.9,5.1,0.7,49.2-3.2,65.8L77.5,240.5z"
            />
          </g>
          <path
            style={{ fill: "#5385F6" }}
            d="M115.7,221L99,284.4H61.6L44.9,221H115.7z"
          />
          <path
            style={{ fill: "#719AF7" }}
            d="M115.7,221c0,0-36.8,6.9-36.8,63.4H61.6L44.9,221H115.7z"
          />
        </g>
      </g>
    </svg>
  );
};

const PricingRightSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(PricingRightSVG);
