import { useForm } from "antd/es/form/Form";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FilterOutlined } from "@ant-design/icons";
import FormApp from "../../components/formApp/FormApp";
import MySpace from "../../components/mySpace/MySpace";
import SelectManager from "../../components/selects/SelectManager";
import InputFormDatePicker from "../../components/inputFormDatePicker/InputFormDatePicker";
import MyDrawerWithReset from "../../components/myDrawer/MyDrawerWithReset";

const formName = "gpsFilterForm";

const GpsFilterDrawer = ({ onChange, onReset, loading, filter }) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const refDrawer = useRef();

  useEffect(() => {
    form?.setFieldsValue(filter);
  }, [form, filter]);

  const onFinish = (values) => {
    onChange && onChange(values);
    refDrawer.current.closeDrawer();
  };

  const handleReset = () => {
    form.resetFields();
    onReset && onReset();
  };

  return (
    <MyDrawerWithReset
      ref={refDrawer}
      label={t("WORD_FILTER")}
      icon={<FilterOutlined />}
      loading={loading}
      formName={formName}
      handleReset={handleReset}
    >
      <FormApp onFinish={onFinish} name={formName} form={form}>
        <MySpace direction="vertical" size="middle" fullWidth>
          <SelectManager
            name="idManager"
            placeholder={t("WORD_SELECT_MANAGER")}
            label={t("WORD_SELECT_MANAGER")}
            required
          />
          <InputFormDatePicker name="createDate" label="Date" required />
        </MySpace>
      </FormApp>
    </MyDrawerWithReset>
  );
};

export default GpsFilterDrawer;
