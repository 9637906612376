import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelectFormItem from "../mySelectFormItem/MySelectFormItem";
import UtilNotify from "../../utils/UtilNotify";
import BusinessTypeActions from "../../actions/BusinessTypeActions";

const SelectBusinessType = ({
  label,
  style,
  onChange,
  value,
  ...otherProps
}) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    const fill = async () => {
      const result = await BusinessTypeActions.fillListOptions();
      if (result.success) {
        setlist(result.data);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    };
    fill();
  }, [t]);

  return (
    <MySelectFormItem
      label={label}
      options={list}
      style={style}
      onChange={onChange}
      value={value}
      {...otherProps}
    />
  );
};

export default SelectBusinessType;
