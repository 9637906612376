import React, { useEffect, useRef, useState } from "react";
import MySpace from "../../../components/mySpace/MySpace";
import MyPageHeader from "../../../components/myPageHeader/MyPageHeader";
import { useTranslation } from "react-i18next";
import MyCard from "../../../components/myCard/MyCard";
import MyDivider from "../../../components/myDivider/MyDivider";
import FormApp from "../../../components/formApp/FormApp";
import { useForm } from "antd/es/form/Form";
import InputTextAreaFormItem from "../../../components/myInputForm/InputTextAreaFormItem";
import InputTextFormItem from "../../../components/myInputForm/InputTextFormItem";
import MyButtonDelete from "../../../components/myButton/MyButtonDelete";
import { useParams } from "react-router-dom";
import InputNumberFormItem from "../../../components/myInputForm/InputNumberFormItem";
import SelectOrderBranch from "../../../components/selects/SelectOrderBranch";
import UtilNotify from "../../../utils/UtilNotify";
import CustomerActions from "../../../actions/CustomerActions";
import { Form } from "antd";
import paymentActions from "../../../actions/PaymentActions";
import SelectCountry from "../../../components/selects/SelectCountry";
import SelectCity from "../../../components/selects/SelectCity";
import SelectRegion from "../../../components/selects/SelectRegion";
import SelectDistrict from "../../../components/selects/SelectDistrict";
import { Placemark } from "react-yandex-maps";
import InputFormDatePicker from "../../../components/inputFormDatePicker/InputFormDatePicker";
import MyTextSecondary from "../../../components/myText/MyTextSecondary";
import MyButtonSecondary from "../../../components/myButton/MyButtonSecondary";
import UtilDate from "../../../utils/UtilDate";
import MyHelmet from "../../../components/helmet/MyHelmet";
import MyMapYandex from "../../../components/myMapYandex/MyMapYandex";
import useNavigateApi from "../../../appRoute/useNavigateApi";
import LoadingIconPage from "../../../components/loadingIconApp/LoadingIconPage";
import SelectAddressSuggestion from "../../../components/selects/selectAddressSuggestion/SelectAddressSuggestion";
import useWindowSize from "../../../utils/useWindowSize";
import MyButtonSuccess from "../../../components/myButton/MyButtonSuccess";
import MySpaceColumn from "../../../components/mySpace/MySpaceColumn";
import "./CustomerEdit.scss";

const formName = "formCustomerEdit";

const CustomerEdit = () => {
  const [loading, setloading] = useState(true);
  const [saving, setSaving] = useState(false);

  const { t } = useTranslation();
  const [form] = useForm();
  const navigateApi = useNavigateApi();
  const mapRef = useRef(null);
  const { isMobile } = useWindowSize();

  const [MyYmaps, setMyYmaps] = useState();

  const { _idCustomer } = useParams();

  const idCountry = Form.useWatch("idCountry", form);
  const idRegion = Form.useWatch("idRegion", form);
  const idCity = Form.useWatch("idCity", form);
  const address = Form.useWatch("address", form);

  const goBack = () => {
    navigateApi(`/customer/list`);
  };

  useEffect(() => {
    const fill = async () => {
      let _discounts = [];
      let _phones = [];
      let _address = [];
      if (_idCustomer) {
        setloading(true);
        const resultGetOne = await CustomerActions.getOne(_idCustomer);
        if (resultGetOne.success) {
          form?.setFieldsValue(
            Object.assign(resultGetOne.data, {
              idCountry:
                resultGetOne.data.idCountry <= 0
                  ? null
                  : resultGetOne.data.idCountry,
              idCity:
                resultGetOne.data.idCity <= 0 ? null : resultGetOne.data.idCity,
              idRegion:
                resultGetOne.data.idRegion <= 0
                  ? null
                  : resultGetOne.data.idRegion,
              idDistrict:
                resultGetOne.data.idDistrict <= 0
                  ? null
                  : resultGetOne.data.idDistrict,
              debitLimit:
                resultGetOne.data.debitLimit <= 0
                  ? null
                  : resultGetOne.data.debitLimit,
              contractDate: resultGetOne.data.contractDate
                ? UtilDate.getDate(resultGetOne.data.contractDate)
                : null,
            })
          );
          _discounts = resultGetOne.data.discounts;
          _phones = resultGetOne.data.phones;
          _address = resultGetOne.data.address;
        } else {
          UtilNotify.notifyErrorServer(resultGetOne.errMsg);
        }
      }
      const listPhones = [
        { type: CustomerActions.PHONE_TYPE.MOBILE },
        { type: CustomerActions.PHONE_TYPE.PHONE },
      ];
      for (const phone of listPhones) {
        const p = _phones?.find((it) => it.type === phone.type);
        if (p) Object.assign(phone, p);
      }
      form?.setFieldsValue({ phones: listPhones });

      const listAddresses = [
        { type: CustomerActions.ADDRESS_TYPE.DELIVERY },
        { type: CustomerActions.ADDRESS_TYPE.BUSSINESS },
      ];
      for (const add of listAddresses) {
        const p = _address?.find((it) => it.type === add.type);
        if (p) Object.assign(add, p);
      }
      form?.setFieldsValue({ address: listAddresses });

      const resultPayments = await paymentActions.paymentTypeList();
      if (resultPayments.success) {
        for (const dis of _discounts) {
          const obj = resultPayments.data.find(
            (oo) => oo.idPaymentType === dis.idPaymentType
          );
          if (obj) {
            Object.assign(obj, { value: dis.value });
          }
        }
        form?.setFieldsValue({
          discounts: resultPayments.data,
        });
      }
      setloading(false);
    };
    fill();
  }, [_idCustomer, form]);

  const onFinish = async (values) => {
    setSaving(true);
    const result = await CustomerActions.saveCustomer(_idCustomer, values);
    if (result.success) {
      if (_idCustomer) {
        UtilNotify.notifySuccess(t("WORD_CUSTOMER_UPDATED"));
      } else {
        UtilNotify.notifySuccess(t("WORD_CUSTOMER_CREATED_SUCCESSFULLY"));
      }
      goBack();
    } else {
      setSaving(false);
      UtilNotify.notifyErrorServer(result.errMsg);
    }
  };

  if (loading) {
    return <LoadingIconPage />;
  }

  const onLoadMap = (myMap) => {
    myMap.load((ymaps) => {
      setMyYmaps(ymaps);
    });
  };

  const handleDragEnd = async (newCoords) => {
    const geocoder = await new MyYmaps.geocode(newCoords);
    const firstGeoObject = geocoder.geoObjects.get(0);
    const addressLine = firstGeoObject.getAddressLine();

    form.setFieldsValue({
      address: address.map((ad) => {
        if (ad.type === CustomerActions.ADDRESS_TYPE.DELIVERY) {
          return Object.assign(ad, {
            mapLat: newCoords[0],
            mapLan: newCoords[1],
            address: addressLine,
          });
        }
        return ad;
      }),
    });
  };

  const handleDelete = async () => {
    const result = await CustomerActions.deleteCustomer(_idCustomer);
    if (result.success) {
      UtilNotify.notifySuccess(t("WORD_CUSTOMER_DELETED_SUCCESSFULLY"));
      goBack();
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
  };

  const getTitle = () => {
    return `${t("WORD_CUSTOMER")} ${
      _idCustomer ? t("WORD_EDIT") : t("WORD_CREATE")
    }`;
  };

  let isNew = !_idCustomer;

  return (
    <>
      <MyHelmet title={getTitle()} />
      <MySpace
        size={"middle"}
        fullWidth
        align="flex-start"
        direction={isMobile ? "vertical" : "horizontal"}
      >
        <MyCard className="customer-edit-card">
          <FormApp
            form={form}
            name={formName}
            loading={loading}
            onFinish={onFinish}
            // initialValues={{ phones: [{ type: CustomerActions.PHONE_TYPE.MOBILE }, { type: CustomerActions.PHONE_TYPE.PHONE }], address: [{}, {}] }}
          >
            <MyPageHeader title={getTitle()} onBack={goBack} />
            <MyDivider />
            <MySpace direction="vertical" fullWidth size="middle">
              <MyTextSecondary>{t("WORD_PLEASE_FILL_INFO")}</MyTextSecondary>
              <SelectOrderBranch
                label={t("WORD_BRANCH")}
                placeholder={t("WORD_SELECT_BRANCH")}
                name="idBranch"
                required
                onLoad={(val) => {
                  form.setFieldsValue({ idBranch: val });
                }}
              />
              <InputTextFormItem label={t("WORD_NAME")} name="name" required />
              <MyDivider />
              <InputTextFormItem
                label={t("WORD_LEGAL_NAME")}
                name="legalName"
              />
              <InputTextFormItem label={t("WORD_TAX")} name="tax" />
              {/* <SelectAddressSuggestion
                key={'index + oo.id'}
                onClear={() => {
                }}
                onSelect={async (name, lat, lan) => {
                  console.warn('name :>> ', name, lat, lan);
                }}
                // name={idd}
                label={t('WORD_ADDRESS')}
              /> */}
              <Form.List name="address">
                {(fields) => (
                  <>
                    {fields.map((field, index) => {
                      let list = form.getFieldValue("address");
                      let type = list[index].type;
                      return (
                        <div key={index} className="margin-bottom">
                          {/* <InputTextFormItem
                            label={
                              type === CustomerActions.ADDRESS_TYPE.DELIVERY
                                ? t("WORD_ADDRESS_DELIVERY")
                                : t("WORD_ADDRESS_BUSSINESS")
                            }
                            name={[field.name, "address"]}
                          /> */}
                          <SelectAddressSuggestion
                            key={index}
                            onClear={() => {}}
                            onSelect={async (name, lat, lan) => {
                              if (index === 0) {
                                form.setFieldsValue({
                                  address: list.map((ad) => {
                                    if (
                                      ad.type ===
                                      CustomerActions.ADDRESS_TYPE.DELIVERY
                                    ) {
                                      return Object.assign(ad, {
                                        mapLat: lat,
                                        mapLan: lan,
                                        address: name,
                                      });
                                    }
                                    return ad;
                                  }),
                                });
                              } else if (index === 1) {
                                form.setFieldsValue({
                                  address: list.map((ad) => {
                                    if (
                                      ad.type ===
                                      CustomerActions.ADDRESS_TYPE.BUSSINESS
                                    ) {
                                      return Object.assign(ad, {
                                        // mapLat: lat,
                                        // mapLan: lan,
                                        address: name,
                                      });
                                    }
                                    return ad;
                                  }),
                                });
                              }
                            }}
                            label={
                              type === CustomerActions.ADDRESS_TYPE.DELIVERY
                                ? t("WORD_ADDRESS_DELIVERY")
                                : t("WORD_ADDRESS_BUSSINESS")
                            }
                            name={[field.name, "address"]}
                          />
                        </div>
                      );
                    })}
                  </>
                )}
              </Form.List>
              <MySpace fullWidth className="select-row">
                <InputFormDatePicker
                  label={t("WORD_CONTRACT_DATE")}
                  name="contractDate"
                />
                <InputNumberFormItem
                  label={t("WORD_CONTRACT_NUMBER")}
                  name="contractNumber"
                  placeholder={0}
                />
              </MySpace>
              <MySpace fullWidth className="select-row">
                <InputTextFormItem
                  label={t("WORD_BANK_NAME")}
                  name="bankName"
                />
                <InputTextFormItem
                  label={t("WORD_BANK_NUMBER")}
                  name="bankNumber"
                />
              </MySpace>
              <Form.List name="phones">
                {(fields) => (
                  <MySpace fullWidth className="select-row" align="top">
                    {fields.map((field, index) => {
                      let list = form.getFieldValue("phones");
                      let type = list[index].type;
                      return (
                        <div key={index} className="margin-bottom">
                          <InputTextFormItem
                            label={
                              type === CustomerActions.PHONE_TYPE.MOBILE
                                ? t("WORD_MOBILE")
                                : t("WORD_PHONE")
                            }
                            name={[field.name, "number"]}
                          />
                        </div>
                      );
                    })}
                  </MySpace>
                )}
              </Form.List>
              <InputTextFormItem label={t("WORD_DIRECTOR")} name="director" />
              <InputTextFormItem label={t("WORD_CATEGORY")} name="category" />
              <InputTextAreaFormItem
                label={t("WORD_DESCRIPTION")}
                name="description"
              />
              <MyDivider />
              <label>{t("WORD_CUSTOMER_DISCOUNT")}</label>
              <Form.List name="discounts">
                {(fields) => (
                  <MySpaceColumn fullWidth>
                    {fields.map((field, index) => {
                      let list = form.getFieldValue("discounts");
                      let name = list[index].name;
                      return (
                        <div key={index} className="margin-bottom">
                          <InputNumberFormItem
                            className="input-discount-customer"
                            name={[field.name, "value"]}
                            prefix={t(name)}
                            suffix="%"
                            placeholder={"0"}
                          />
                        </div>
                      );
                    })}
                  </MySpaceColumn>
                )}
              </Form.List>
              <InputNumberFormItem
                name="debitLimit"
                label={t("WORD_DEBIT_LIMIT")}
              />
              <MySpace fullWidth className="select-row">
                <SelectCountry
                  label={t("WORD_COUNTRY")}
                  name="idCountry"
                  placeholder={t("WORD_SELECT_COUNTRY")}
                  showSearch
                />
                <SelectRegion
                  label={t("WORD_REGION")}
                  placeholder={t("WORD_SELECT_REGION")}
                  name="idRegion"
                  idCountry={idCountry}
                  showSearch
                />
              </MySpace>
              <MySpace fullWidth className="select-row">
                <SelectCity
                  label={t("WORD_CITY")}
                  placeholder={t("WORD_SELECT_CITY")}
                  showSearch
                  name="idCity"
                  idRegion={idRegion}
                />
                <SelectDistrict
                  label={t("WORD_DISTRICT")}
                  placeholder={t("WORD_SELECT_DISTRICT")}
                  showSearch
                  name="idDistrict"
                  idCity={idCity}
                />
              </MySpace>
              <MySpace style={{ marginTop: 30 }}>
                <MyButtonSuccess
                  label={!isNew ? t("WORD_SAVE_CHANGES") : t("WORD_CREATE")}
                  form={formName}
                  loading={saving}
                />
                <MyButtonSecondary
                  onClick={goBack}
                  label={t("WORD_CANCEL")}
                  reset
                />
                {!isNew ? (
                  <MyButtonDelete
                    form={formName}
                    loading={saving}
                    label={t("WORD_DELETE")}
                    onConfirm={handleDelete}
                  />
                ) : null}
              </MySpace>
            </MySpace>
          </FormApp>
        </MyCard>
        <div className="map-wrapper">
          <div className="map-wrapper-inside">
            <MyMapYandex
              ref={mapRef}
              width="100%"
              height="100%"
              onLoadMap={onLoadMap}
              onDoubleClick={handleDragEnd}
            >
              {address?.map((oo, index) => {
                return (
                  <div key={index}>
                    <Placemark
                      geometry={[oo?.mapLat, oo?.mapLan]}
                      options={{
                        iconColor: "green",
                        boundsAutoApply: true,
                        draggable: true,
                      }}
                      onDragEnd={(e) => {
                        const newCoords = e
                          .get("target")
                          .geometry.getCoordinates();
                        handleDragEnd(newCoords);
                      }}
                    />
                  </div>
                );
              })}
            </MyMapYandex>
          </div>
        </div>
      </MySpace>
    </>
  );
};

export default CustomerEdit;
