import React from "react";
import { getBaseURLShowImage } from "../../utils/UrlHelper";
import { Image } from "antd";

const MyImage = ({ icon, alt, width, height, withMargin, preview }) => {
  return (
    <div
      style={{
        marginBottom: withMargin ? 10 : 0,
      }}
    >
      <Image
        src={getBaseURLShowImage(icon)}
        alt={alt || "icon"}
        width={width || 100}
        height={height || 100}
        preview={preview}
      />
    </div>
  );
};

export default MyImage;
