import React, { useEffect } from "react";
import useNavigateApi from "../../appRoute/useNavigateApi";
import { CloseOutlined } from "@ant-design/icons";
import MySpace from "../../components/mySpace/MySpace";
import ApiStorage from "../../redux/ApiStorage";
import { useSelector } from "react-redux";
import MyButtonSuccess from "../../components/myButton/MyButtonSuccess";

const TrialNotifRow = ({ title, btnLabel, path }) => {
  const navigateApi = useNavigateApi();

  const trialClose = useSelector((state) => {
    return state.trialClose;
  });

  useEffect(() => {
    ApiStorage.setTrialClose(false);
  }, []);

  return (
    <>
      {trialClose ? null : (
        <div className="orange-notification-wrapper no-print">
          <div></div>
          <MySpace size="middle">
            <div>{title}</div>
            <MyButtonSuccess
              label={btnLabel}
              onClick={() => navigateApi(path)}
            />
          </MySpace>
          <div
            className="close-notification-wrapper-trial"
            onClick={() => ApiStorage.setTrialClose(true)}
          >
            <CloseOutlined style={{ color: "#fff" }} />
          </div>
        </div>
      )}
    </>
  );
};

export default TrialNotifRow;
