import React, { memo } from "react";
import MyIcon from "./MyIcon";

const SVGIcon = () => {
  return (
    <svg
      width="5"
      height="9"
      viewBox="0 0 5 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2364_103)">
        <path
          d="M4.3362 8.74995C4.25033 8.75099 4.16517 8.73433 4.08603 8.70099C4.00688 8.66766 3.93546 8.61837 3.87621 8.5562L0.24127 4.91998C0.16673 4.85009 0.10697 4.76596 0.0655298 4.67256C0.0240998 4.57916 0.00182003 4.47839 2.00272e-05 4.37623C-0.000819973 4.27364 0.0202601 4.17206 0.0618701 4.07828C0.10347 3.9845 0.16464 3.9007 0.24127 3.83249L3.87621 0.19376C3.93578 0.13201 4.00726 0.0829972 4.08633 0.0496932C4.1654 0.0163891 4.25041 -0.000512829 4.3362 1.18513e-05C4.42423 0.000334547 4.51131 0.0180963 4.59243 0.0522717C4.67355 0.086447 4.74709 0.136358 4.80881 0.199121C4.87052 0.261884 4.91919 0.336255 4.952 0.417936C4.9848 0.499618 5.0011 0.586991 4.99994 0.675007C4.99971 0.767977 4.98115 0.859988 4.94533 0.945781C4.90951 1.03157 4.85714 1.10946 4.7912 1.175L1.56875 4.37748L4.7912 7.57996C4.85714 7.6455 4.90951 7.72339 4.94533 7.80918C4.98115 7.89498 4.99971 7.98699 4.99994 8.07996C5.00044 8.16755 4.98365 8.25439 4.95055 8.33549C4.91744 8.41659 4.86867 8.49037 4.80702 8.5526C4.74537 8.61484 4.67205 8.6643 4.59126 8.69816C4.51047 8.73203 4.4238 8.74962 4.3362 8.74995Z"
          fill="#000"
        />
      </g>
      <defs>
        <clipPath id="clip0_2364_103">
          <rect width="5" height="9" fill="#000" />
        </clipPath>
      </defs>
    </svg>
  );
};

const ArrowLeftSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(ArrowLeftSVG);
