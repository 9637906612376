import React, { useEffect, useState } from "react";
import MyDropDown from "../../../components/myDropDown/MyDropDown";
import { useTranslation } from "react-i18next";
import ExportTemplatesActions from "../../../actions/ExportTemplatesActions";
import UtilNotify from "../../../utils/UtilNotify";
import UtilObject from "../../../utils/UtilObject";
import { DownOutlined } from "@ant-design/icons";
import MyButtonGray from "../../../components/myButton/MyButtonGray";
import MySpace from "../../../components/mySpace/MySpace";
import ExportWhiteSVG from "../../../icons/ExportWhiteSVG";

const OrderExportDropdown = ({ viewIn, ids, onChange }) => {
  const { t } = useTranslation();

  const [items, setitems] = useState([]);

  useEffect(() => {
    const fill = async () => {
      const result = await ExportTemplatesActions.fillList({
        filter: { viewIn },
      });
      if (result.success) {
        setitems(
          result.data.map((oo) => {
            return {
              key: oo.idExportTemplet,
              label: `${oo.title} (${oo.fileType})`,
              onClick: async () => {
                if (ids && ids.length) {
                  const result = await ExportTemplatesActions.exportFile(
                    { ids },
                    oo.idExportTemplet
                  );
                  if (result.errMsg) {
                    UtilNotify.notifyErrorServer(result.errMsg);
                  } else {
                    onChange && onChange();
                  }
                } else {
                  UtilNotify.notifyError(t("WORD_PLEASE_SELECT_ITEMS"));
                }
              },
            };
          })
        );
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    };
    fill();
  }, [ids, viewIn, t, onChange]);

  return (
    <div>
      {!UtilObject.isEmpty(items) ? (
        <MyDropDown items={items} arrow={false}>
          <MyButtonGray
            label={
              <MySpace align="center">
                <div>{t("WORD_EXPORT")}</div>
                <DownOutlined style={{ fontSize: 13 }} />
              </MySpace>
            }
            icon={<ExportWhiteSVG />}
          />
          {/* {!isMobile ? t("WORD_EXPORT") : <ImportOutlined />} */}
        </MyDropDown>
      ) : null}
    </div>
  );
};

export default OrderExportDropdown;
