import React, { useState } from "react";
import MyCard from "../../../components/myCard/MyCard";
import MyText from "../../../components/myText/MyText";
import { Divider } from "antd";
import FormApp from "../../../components/formApp/FormApp";
import MySpace from "../../../components/mySpace/MySpace";
import { useTranslation } from "react-i18next";
import { useForm } from "antd/es/form/Form";
import MyButtonBlue from "../../../components/myButton/MyButtonBlue";
import InputPasswordFormItem from "../../../components/myInputForm/InputPasswordFormItem";
import InputFormPasswordConfirm from "../../../components/myInputForm/InputPasswordConfirmFormItem";
import UserActions from "../../../actions/UserActions";
import { useParams } from "react-router-dom";
import UtilNotify from "../../../utils/UtilNotify";
import useNavigateApi from "../../../appRoute/useNavigateApi";
import UtilString from "../../../utils/UtilString";
import MyCaptcha from "../../../components/myGoogleCaptcha/MyCaptcha";

const formName = "forgot_password_set_form";

const ForgotPasswordSetNewPassword = () => {
  const { t } = useTranslation();
  const [form] = useForm();

  const navigateApi = useNavigateApi();

  const { activationKey } = useParams();

  const [saving, setsaving] = useState(false);
  const [CaptchaCheck, setCaptchaCheck] = useState(false);

  const onFinish = async (values) => {
    setsaving(true);
    values.password = UtilString.md5(values.password);
    const result = await UserActions.forgotPassword_reset(
      values,
      activationKey
    );
    if (result.success) {
      navigateApi("/login");
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
      setsaving(false);
    }
  };

  return (
    <div className="outside-card-wrapper">
      <MyCard className="login-card">
        <div className="register-text-wrapper">
          <h5 className="card-header-forgot">{t("WORD_PASSWORD_RECOVERY")}</h5>
          <MyText className="card-header-text">
            {t("WORD_SET_A_NEW_PASSWORD")}
          </MyText>
        </div>
        <Divider />
        <FormApp form={form} name={formName} onFinish={onFinish}>
          <MySpace direction="vertical" fullWidth>
            <InputPasswordFormItem
              label={t("WORD_NEW_PASSWORD")}
              name="password"
            />
            <InputFormPasswordConfirm label={t("WORD_REPEAT_PASSOWRD")} />
            <div className="registration-btn-wrapper">
              <MyButtonBlue
                label={t("WORD_SAVE_CHANGES")}
                form={formName}
                style={{ width: 150, height: 44, marginTop: 10 }}
                loading={saving}
                disabled={!CaptchaCheck}
                changePassword
              />
            </div>
          </MySpace>
          <Divider dashed />
        </FormApp>
        <MyCaptcha
          onChange={() => {
            setCaptchaCheck(true);
          }}
        />
      </MyCard>
    </div>
  );
};

export default ForgotPasswordSetNewPassword;
