import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import MySpace from "../../../components/mySpace/MySpace";
import MyPageHeader from "../../../components/myPageHeader/MyPageHeader";
import CustomerActions from "../../../actions/CustomerActions";
import UtilNotify from "../../../utils/UtilNotify";
import UtilDate from "../../../utils/UtilDate";
import MyTable from "../../../components/myTable/MyTable";
import { faEye, faPen } from "@fortawesome/free-solid-svg-icons";
import { faStackOverflow } from "@fortawesome/free-brands-svg-icons";
import CustomerViewModal from "./CustomerViewModal";
import CustomerStatementModal from "./CustomerStatementModal";
import CustomerImportModal from "./CustomerImportModal";
import MyHelmet from "../../../components/helmet/MyHelmet";
import useNavigateApi from "../../../appRoute/useNavigateApi";
import ImportWhiteSVG from "../../../icons/ImportWhiteSVG";
import ExportTemplatesActions from "../../../actions/ExportTemplatesActions";
import OrderExportDropdown from "../../orders/orderList/OrderExportDropdown";
import CustomerNewUserPage from "./CustomerNewUserPage.js";
import { useSelector } from "react-redux";
import useWindowSize from "../../../utils/useWindowSize.js";
import MyButtonPlus from "../../../components/myButton/MyButtonSuccess.js";
import ApiStorage from "../../../redux/ApiStorage.js";
import { useForm } from "antd/es/form/Form.js";
import FormApp from "../../../components/formApp/FormApp.js";
import InputFormRangeDatePicker from "../../../components/inputFormRangeDatePicker/InputFormRangeDatePicker.js";
import MyButtonText from "../../../components/myButton/MyButtonText.js";
import MyButtonSuccess from "../../../components/myButton/MyButtonSuccess.js";
import SearchInput from "../../../components/myInputForm/SearchInput.js";
import { Flex } from "antd";
import MyButtonTableAction from "../../../components/myButton/MyButtonTableAction.js";
import MyButtonGray from "../../../components/myButton/MyButtonGray.js";
import HeaderMobile from "../../../components/headerForMobile/HeaderMobile.js";

const formName = "formCustomerFilter";

const CustomerList = () => {
  const { t } = useTranslation();
  const refModal = useRef();
  const refModalSecond = useRef();
  const refModalImport = useRef();
  const navigateApi = useNavigateApi();
  const { isMobile } = useWindowSize();
  const [form] = useForm();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setpagination] = useState({ page: 0, perPage: 50 });
  const [totalRows, settotalRows] = useState(0);
  const [search, setSearch] = useState("");
  const [sort, setsort] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const currentUserSteps = useSelector((state) => {
    return state.currentUser?.stepsDone;
  });

  const customerPageFilters = useSelector((state) => {
    return state.customerPageFilters;
  });

  const onFinish = useCallback(
    (fil) => {
      let updatedFilters = { ...fil };

      if (fil.date && fil.date.length === 2) {
        const [dateFrom, dateTo] = fil.date;
        updatedFilters.dateFrom = dateFrom.format("YYYY-MM-DD");
        updatedFilters.dateTo = dateTo.format("YYYY-MM-DD");
        delete updatedFilters.date;
      }

      ApiStorage.setCustomerPageFilters({
        ...customerPageFilters,
        ...updatedFilters,
      });

      setpage(0);
    },
    [customerPageFilters]
  );

  const setpage = (page) => {
    setpagination((ov) => ({ page: page, perPage: ov.perPage }));
  };

  const setFilterClear = () => {
    form.resetFields();
    ApiStorage.setCustomerPageFilters({});
    setpage(0);
  };

  const refreshListDb = useCallback(async () => {
    setLoading(true);
    const fetchParams = {
      page: pagination.page,
      perPage: pagination.perPage,
      sort: sort,
      search: search,
      filter: { ...customerPageFilters },
    };
    const result = await CustomerActions.fillPagination(fetchParams);
    if (result.success) {
      setData(result.data);
      settotalRows(result.totalRows);
      setSelectedRowKeys([]);
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    setLoading(false);
  }, [pagination, sort, search, customerPageFilters]);

  useEffect(() => {
    refreshListDb();
  }, [refreshListDb]);

  useEffect(() => {
    if (customerPageFilters) {
      form?.setFieldsValue(customerPageFilters);
    }
  }, [customerPageFilters, form]);

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = useMemo(() => {
    let col = [
      {
        title: t("WORD_NAME"),
        dataIndex: "name",
        key: "name",
        // width: 100,
        sorter: true,
      },
      {
        title: t("WORD_DIRECTOR"),
        dataIndex: "director",
        key: "director",
        width: 200,
        sorter: true,
      },
      {
        title: t("WORD_TAX"),
        dataIndex: "tax",
        key: "tax",
        width: 80,
        sorter: true,
      },
      {
        title: t("WORD_MODIFIED_DATE"),
        dataIndex: "updatedAt",
        key: "updatedAt",
        width: 140,
        sorter: true,
        render: (value) => {
          return <div>{UtilDate.formatDateTime(value)}</div>;
        },
      },
      {
        title: t("WORD_ACTIONS"),
        dataIndex: "actions",
        width: 100,
        key: "actions",
        render: (_, { idCustomer }) => (
          <MySpace>
            <MyButtonTableAction
              title={t("WORD_EDIT")}
              icon={faPen}
              onClick={() => navigateApi(`/customer/edit/${idCustomer}`)}
            />
            <MyButtonTableAction
              title={t("WORD_VIEW")}
              icon={faEye}
              onClick={() => {
                refModal?.current?.viewCustomer(idCustomer);
              }}
            />
            <MyButtonTableAction
              title={t("WORD_STATEMENT")}
              icon={faStackOverflow}
              onClick={() => {
                navigateApi(`/customer/statement/${idCustomer}`);
              }}
            />
          </MySpace>
        ),
      },
    ];
    return col;
  }, [t, navigateApi]);

  const onChangeTable = (_, __, sorter) => {
    let so = "";
    if (sorter.order) {
      so = `${sorter.columnKey} ${sorter.order === "ascend" ? "ASC" : "DESC"}`;
    }
    // setfilter((ov) => Object.assign({}, ov, { sort: so }));
    setsort(so);
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      <MyHelmet title={t("WORD_CUSTOMERS")} />
      <Flex vertical gap={10}>
        {!isMobile ? (
          <Flex vertical gap="small">
            <MyPageHeader
              inPage
              extraLeft={
                loading ? null : (
                  <div>
                    {hasSelected
                      ? `${t("WORD_SELECTED")} ${selectedRowKeys.length} ${selectedRowKeys.length > 1
                        ? t("WORD_ITEMS")
                        : t("WORD_ITEM")
                      }`
                      : ""}
                  </div>
                )
              }
              extraRight={
                <MySpace>
                  <OrderExportDropdown
                    viewIn={ExportTemplatesActions.VIEWIN.CUSTOMER}
                    ids={selectedRowKeys}
                  />
                  <MyButtonGray
                    label={t("WORD_IMPORT")}
                    icon={<ImportWhiteSVG />}
                    onClick={() => {
                      refModalImport?.current?.viewCustomer();
                    }}
                  />
                  <MyButtonSuccess
                    withIcon
                    label={t("WORD_ADD")}
                    onClick={() => navigateApi(`/customer/create`)}
                  />
                </MySpace>
              }
              title={t("WORD_CUSTOMERS")}
            />
            <MySpace style={{ marginLeft: 8 }}>
              <SearchInput
                placeholder={t("WORD_SEARCH")}
                onClear={() => {
                  setSearch("");
                  setpage(0);
                }}
                onPressEnter={(value) => {
                  setSearch(value);
                  setpage(0);
                }}
              />
              <FormApp onFinish={onFinish} name={formName} form={form}>
                <MySpace>
                  <InputFormRangeDatePicker
                    name="date"
                    onChange={() => form.submit()}
                    allowClear={false}
                  />
                  <MyButtonText
                    label={t("WORD_RESET_FILTERS")}
                    onClick={setFilterClear}
                  />
                </MySpace>
              </FormApp>
            </MySpace>
          </Flex>
        ) : (
          <div>
            <HeaderMobile>
              <MyPageHeader title={t("WORD_CUSTOMERS")} />
              <MySpace>
                <OrderExportDropdown
                  viewIn={ExportTemplatesActions.VIEWIN.CUSTOMER}
                  ids={selectedRowKeys}
                />
                <MyButtonTableAction
                  nextToFilter
                  antdIcon
                  title={t("WORD_IMPORT")}
                  icon={<ImportWhiteSVG />}
                  onClick={() => {
                    refModalImport?.current?.viewCustomer();
                  }}
                />
                <MyButtonPlus onClick={() => navigateApi(`/customer/create`)} />
              </MySpace>
            </HeaderMobile>
            <SearchInput
              placeholder={t("WORD_SEARCH")}
              onClear={() => {
                setSearch("");
                setpage(0);
              }}
              onPressEnter={(value) => {
                setSearch(value);
                setpage(0);
              }}
            />
          </div>
        )}
        {!currentUserSteps && !loading && data.length === 0 ? (
          <CustomerNewUserPage />
        ) : (
          <MyTable
            rowKey="idCustomer"
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            onChange={onChangeTable}
            loading={loading}
            total={totalRows}
            onChangePage={(e, ee) => {
              setpagination({ page: e, perPage: ee });
            }}
            page={pagination.page}
            pageSize={pagination.perPage}
          />
        )}
      </Flex>
      <CustomerViewModal ref={refModal} />
      <CustomerStatementModal ref={refModalSecond} />
      <CustomerImportModal ref={refModalImport} />
    </>
  );
};

export default CustomerList;
