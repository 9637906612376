import React, { useEffect, useRef } from "react";
import MyCard from "../../components/myCard/MyCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import MyTextTitle from "../../components/myText/MyTextTitle";
import MyTextSecondary from "../../components/myText/MyTextSecondary";
import MySpace from "../../components/mySpace/MySpace";
import MyButtonSecondary from "../../components/myButton/MyButtonSecondary";
import useNavigateApi from "../../appRoute/useNavigateApi";
import { useSelector } from "react-redux";
import ChangePackageModalPay from "../../pages/settings/package/ChangePackageModalPay";
import MyButtonSuccess from "../../components/myButton/MyButtonSuccess";

const PayIdramFail = () => {
  const { t, i18n } = useTranslation();
  const navigateApi = useNavigateApi();
  const refModal = useRef();

  const lang = useSelector((state) => {
    return state.currentUser?.lang;
  });

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  const errMsg = "";

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <MyCard
          style={{
            paddingTop: 30,
            paddingBottom: 30,
            paddingRight: 0,
            paddingLeft: 0,
            border: "1px solid #ebeff6",
            minWidth: "30%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 10,
            }}
          >
            <FontAwesomeIcon icon={faCircleXmark} color="red" size="5x" />
            <MySpace direction="vertical" align="center" style={{ gap: 1 }}>
              <MyTextTitle size={35}>
                {t("WORD_YOUR_PAYMENT_FAILED")}
              </MyTextTitle>
              <MyTextSecondary size={16}>{errMsg}</MyTextSecondary>
            </MySpace>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 10,
                marginTop: 10,
              }}
            >
              <MyButtonSuccess
                success
                label={t("WORD_TRY_AGAIN")}
                style={{ height: 50, borderRadius: 16 }}
                // onClick={() => {
                //   refModal?.current?.viewPay(idService);
                // }}
              />
              <MyButtonSecondary
                label={t("WORD_CANCEL")}
                style={{ height: 50, borderRadius: 16 }}
                onClick={() => navigateApi(`/home`)}
              />
            </div>
          </div>
        </MyCard>
      </div>
      <ChangePackageModalPay ref={refModal} />
    </>
  );
};

export default PayIdramFail;
