import { fetchFromUrlPOSTAsync } from "../utils/UrlHelper";
import ApiStorage from "../redux/ApiStorage";
import ProductSVG from "../icons/ProductSVG";
import FolderCreateSVG from "../icons/FolderCreateSVG";

const URL_FILL_PAGINATION = "products/list";
const URL_FILL_TREE_FOLDER = "products/list/treeFolders";
const URL_PRODUCT_FOLDER_CREATE = "products/folder/create";
const URL_PRODUCT_CREATE = "products/create";
const URL_GET_ONE = "products/getOne";
const URL_PRODUCT_FOLDER_UPDATE = "products/folder/update";
const URL_PRODUCT_UPDATE = "products/update";
const URL_PRODUCT_CHANGEORDERING = "products/changeOrdering";
const URL_DELETE = "products/delete";
const URL_DELETE_FOLDER = "products/delete/folder";
const URL_MOVE_MULTIPLE = "products/move/multiple";

const fs = {
  fillPagination: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_FILL_PAGINATION, urlParams);
    return result;
  },
  getAllForTree: async () => {
    const result = await fetchFromUrlPOSTAsync(URL_FILL_TREE_FOLDER, []);
    return result;
  },
  getOne: async (idProduct) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_GET_ONE + "/" + idProduct,
      []
    );
    return result;
  },
  updateFolder: async (values, idProduct) => {
    let result = await fetchFromUrlPOSTAsync(
      URL_PRODUCT_FOLDER_UPDATE + "/" + idProduct,
      {
        saveData: values,
      }
    );
    return result;
  },
  updateProduct: async (values, idProduct) => {
    let result = await fetchFromUrlPOSTAsync(
      URL_PRODUCT_UPDATE + "/" + idProduct,
      {
        saveData: values,
      }
    );
    return result;
  },
  changeOrdering: async (values) => {
    let result = await fetchFromUrlPOSTAsync(URL_PRODUCT_CHANGEORDERING, {
      saveData: values,
    });
    return result;
  },
  createProduct: async (values) => {
    let result = await fetchFromUrlPOSTAsync(URL_PRODUCT_CREATE, {
      saveData: values,
    });
    if (result.success) {
      ApiStorage.setStepDetailsDone({ done_product: 1 });
      // const currentUser = ApiStorage.getCurrentUser();
      // if (!currentUser?.stepsDone) {
      //   ApiStorage.setCurrentUser(
      //     Object.assign({}, currentUser, {
      //       stepsDoneDetails: {
      //         ...currentUser?.stepsDoneDetails,
      //         done_product: 1,
      //       },
      //     })
      //   );
      // }
    }
    return result;
  },
  createFolder: async (values) => {
    let result = await fetchFromUrlPOSTAsync(URL_PRODUCT_FOLDER_CREATE, {
      saveData: values,
    });
    return result;
  },
  moveMultiple: async (values) => {
    let result = await fetchFromUrlPOSTAsync(URL_MOVE_MULTIPLE, {
      saveData: values,
    });
    return result;
  },
  delete: async (idProduct) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_DELETE + "/" + idProduct,
      []
    );
    return result;
  },
  deleteFolder: async (idProduct) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_DELETE_FOLDER + "/" + idProduct,
      []
    );
    return result;
  },
};

const loc = {
  TYPE: { PRODUCT: 0, FOLDER: 1 },
  getType: (type) => {
    const size = 20;
    switch (type) {
      case 0:
        return {
          icon: <ProductSVG fill="black" size={size} />,
          color: "",
          label: "Product",
          value: 0,
        };
      case 1:
        return {
          icon: <FolderCreateSVG size={size} />,
          color: "",
          label: "Folder",
          value: 1,
        };

      default:
        break;
    }
  },
};

const ProductActions = Object.assign(fs, loc);

export default ProductActions;
