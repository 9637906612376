import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "antd/es/form/Form";
import InputPasswordFormItem from "../../components/myInputForm/InputPasswordFormItem";
import InputPasswordConfirmFormItem from "../../components/myInputForm/InputPasswordConfirmFormItem";
import UtilNotify from "../../utils/UtilNotify";
import UserActions from "../../actions/UserActions";
import md5 from "md5";
import EditPageForm from "../../components/editPageForm/EditPageForm";

const formName = "password_form";

const PasswordSettings = () => {
  const { t } = useTranslation();
  const [form] = useForm();

  const [saving, setSaving] = useState(false);

  const onFinish = async (values) => {
    setSaving(true);
    values.password = md5(values.nPassword);
    let result = await UserActions.saveSettings({
      password: md5(values.nPassword),
    });
    setSaving(false);
    if (result.success) {
      UtilNotify.notifySuccess(t("WORD_SETTINGS_SAVED"));
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
  };

  return (
    <EditPageForm
      title={t("WORD_CHANGE_PASSWORD")}
      form={form}
      formName={formName}
      onFinish={onFinish}
      saving={saving}
    >
      <InputPasswordFormItem
        label={t("WORD_CURRENT_PASSWORD")}
        name="password"
        required
      />
      <InputPasswordFormItem
        label={t("WORD_NEW_PASSWORD")}
        name="nPassword"
        required
      />
      <InputPasswordConfirmFormItem
        label={t("WORD_REPEAT_PASSWORD")}
        name="confirmPassword"
        passwordToConfirm="nPassword"
        required
      />
    </EditPageForm>
  );
};

export default PasswordSettings;
