import React, { useCallback, useEffect, useMemo, useState } from "react";
import MySpace from "../../../components/mySpace/MySpace";
import MyPageHeader from "../../../components/myPageHeader/MyPageHeader";
import ApiStorage from "../../../redux/ApiStorage";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import MyTable from "../../../components/myTable/MyTable";
import ProductQuantityActions from "../../../actions/ProductQuantityActions";
import UtilNotify from "../../../utils/UtilNotify";
import UtilDate from "../../../utils/UtilDate";
import useNavigateApi from "../../../appRoute/useNavigateApi";
import MyHelmet from "../../../components/helmet/MyHelmet";
import { useForm } from "antd/es/form/Form";
import { Flex } from "antd";
import FormApp from "../../../components/formApp/FormApp";
import SelectProductQuantityType from "../../../components/selects/SelectProductQuantityType";
import MyButtonText from "../../../components/myButton/MyButtonText";
import InputFormRangeDatePicker from "../../../components/inputFormRangeDatePicker/InputFormRangeDatePicker";

const formName = "formUserQuantitiesFilter";

const UserQuantities = () => {
  const { t } = useTranslation();
  const navigateApi = useNavigateApi();
  const location = useLocation();
  const { _idUser } = useParams();
  const [form] = useForm();

  const { username } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [totalRows, settotalRows] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pagination, setpagination] = useState({ page: 0, perPage: 50 });
  const [data, setData] = useState([]);

  const userQuantitiesFilters = useSelector((state) => {
    return state.userQuantitiesFilters;
  });

  const setFilter = useCallback(
    (fil) => {
      ApiStorage.setUserQuantitiesFilters(
        Object.assign({}, userQuantitiesFilters, fil)
      );
      setpage(0);
    },
    [userQuantitiesFilters]
  );

  const setpage = (page) => {
    setpagination((ov) => ({ page: page, perPage: ov.perPage }));
  };

  const setFilterClear = () => {
    form.resetFields();
    ApiStorage.setUserQuantitiesFilters({});
    setpage(0);
  };

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    const fill = async () => {
      setLoading(true);
      const fetchParams = {
        page: pagination.page,
        perPage: pagination.perPage,
        filter: { ...userQuantitiesFilters, idUser: _idUser },
      };
      const result = await ProductQuantityActions.fillPagination(fetchParams);
      if (result.success) {
        setData(result.data);
        settotalRows(result.totalRows);
        // settotals(result.totals);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setLoading(false);
    };

    fill();
  }, [userQuantitiesFilters, _idUser, pagination]);

  const columns = useMemo(() => {
    let col = [
      {
        title: t("WORD_PRODUCT_NAME"),
        dataIndex: "productName",
        key: "productName",
        //    width: "160px",
      },
      {
        title: t("WORD_QUANTITY"),
        dataIndex: "quantity",
        key: "quantity",
        width: 200,
        sorter: true,
      },
      {
        title: t("WORD_TYPE"),
        dataIndex: "type",
        key: "type",
        width: 160,
        sorter: true,
        render: (value) => {
          let type = ProductQuantityActions.getType(value);
          return <div>{t(type.label)}</div>;
        },
      },
      {
        title: t("WORD_USERNAME"),
        dataIndex: "nameUser",
        key: "nameUser",
        width: 200,
      },
      {
        title: t("WORD_CREATE_DATE"),
        dataIndex: "createDate",
        key: "createDate",
        width: 200,
        render: (value) => <div>{UtilDate.formatDateTime(value)}</div>,
      },
    ];
    return col;
  }, [t]);

  const onFinish = useCallback(
    (fil) => {
      let updatedFilters = { ...fil };

      if (fil.date && fil.date.length === 2) {
        const [dateFrom, dateTo] = fil.date;
        updatedFilters.dateFrom = dateFrom.format("YYYY-MM-DD");
        updatedFilters.dateTo = dateTo.format("YYYY-MM-DD");
        delete updatedFilters.date;
      }

      ApiStorage.setUserQuantitiesFilters({
        ...userQuantitiesFilters,
        ...updatedFilters,
      });

      setpage(0);
    },
    [userQuantitiesFilters]
  );

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      <MyHelmet title={t("WORD_MANAGER_QUANTITIES")} />
      <Flex vertical gap={10}>
        <MyPageHeader
          onBack={() => navigateApi(`/user/list`)}
          extraLeft={
            loading ? null : (
              <div>
                {hasSelected
                  ? `${t("WORD_SELECTED")} ${selectedRowKeys.length} ${
                      selectedRowKeys.length > 1
                        ? t("WORD_ITEMS")
                        : t("WORD_ITEM")
                    }`
                  : ""}
              </div>
            )
          }
          // extraRight={
          //   <MySpace>
          //     <UserQuantitiesFilterDrawer
          //       onChange={setFilter}
          //       onReset={setFilterClear}
          //       filter={userQuantitiesFilters}
          //     />
          //   </MySpace>
          // }
          title={`${username} quantities`}
        />
        <Flex gap="small">
          <FormApp
            onFinish={onFinish}
            name={formName}
            form={form}
            style={{ marginLeft: 8 }}
          >
            <MySpace>
              <SelectProductQuantityType
                placeholder={t("WORD_SELECT_TYPE")}
                name="type"
                onChange={() => form.submit()}
              />
              <InputFormRangeDatePicker
                name="date"
                onChange={() => form.submit()}
                allowClear={false}
              />
            </MySpace>
          </FormApp>
          <MyButtonText
            label={t("WORD_RESET_FILTERS")}
            onClick={setFilterClear}
          />
        </Flex>
        <MyTable
          rowKey="idProductQuantity"
          rowSelection={rowSelection}
          height={"75vh"}
          columns={columns}
          dataSource={data}
          onChange={setFilter}
          loading={loading}
          onChangePage={(e, ee) => {
            setpagination({ page: e, perPage: ee });
          }}
          total={totalRows}
          page={pagination.page}
          pageSize={pagination.perPage}
        />
      </Flex>
    </>
  );
};

export default UserQuantities;
