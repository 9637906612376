import React, { memo } from "react";
import MyIcon from "./MyIcon";

export const SVGIcon = ({ style, size }) => {
  return (
    <svg
      width={size || 48}
      height={size || 48}
      viewBox="0 0 78 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ style }}
    >
      <circle opacity="0.2" cx="39" cy="39" r="39" fill="#8247FF"></circle>
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.849 53.3129C28.849 51.9129 29.9824 50.7795 31.3824 50.7795C32.7657 50.7795 33.899 51.9129 33.899 53.3129C33.899 54.6962 32.7657 55.8295 31.3824 55.8295C29.9824 55.8295 28.849 54.6962 28.849 53.3129ZM47.599 53.3129C47.599 51.9129 48.7324 50.7795 50.1324 50.7795C51.5157 50.7795 52.649 51.9129 52.649 53.3129C52.649 54.6962 51.5157 55.8295 50.1324 55.8295C48.7324 55.8295 47.599 54.6962 47.599 53.3129Z"
        fill="#8247FF"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.65 29.5796C53.6667 29.5796 54.3333 29.9296 55 30.6963C55.6667 31.4629 55.7833 32.5629 55.6333 33.5613L54.05 44.4946C53.75 46.5963 51.95 48.1446 49.8333 48.1446H31.65C29.4333 48.1446 27.6 46.4446 27.4167 44.2463L25.8833 26.0779L23.3667 25.6446C22.7 25.5279 22.2333 24.8779 22.35 24.2113C22.4667 23.5279 23.1167 23.0779 23.8 23.1779L27.775 23.7779C28.3417 23.8796 28.7583 24.3446 28.8083 24.9113L29.125 28.6446C29.175 29.1796 29.6083 29.5796 30.1417 29.5796H52.65ZM42.5493 38.246H47.166C47.866 38.246 48.416 37.6793 48.416 36.996C48.416 36.296 47.866 35.746 47.166 35.746H42.5493C41.8493 35.746 41.2993 36.296 41.2993 36.996C41.2993 37.6793 41.8493 38.246 42.5493 38.246Z"
        fill="#8247FF"
      ></path>
    </svg>
  );
};

const ShoppingCartPurpleCircle = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(ShoppingCartPurpleCircle);
