import React, { memo } from "react";
import MyIcon from "./MyIcon";

const SVGIcon = ({ size }) => {
  return (
    <svg
      width={size || 48}
      height={size || 48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="24" fill="#FF5C00" fillOpacity="0.2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 29.8438V20.708H35V29.917C35 33.6839 32.5851 35.9999 28.7212 35.9999H19.2667C15.4391 35.9999 13 33.6359 13 29.8438ZM19.0619 26.8921C18.5064 26.9173 18.0476 26.4841 18.0234 25.9333C18.0234 25.3813 18.4581 24.9253 19.0136 24.9001C19.5569 24.9001 20.0037 25.3213 20.0158 25.8601C20.0399 26.4133 19.6052 26.8693 19.0619 26.8921ZM24.0228 26.8921C23.4674 26.9173 23.0085 26.4841 22.9844 25.9333C22.9844 25.3813 23.4191 24.9253 23.9745 24.9001C24.5179 24.9001 24.9646 25.3213 24.9767 25.8601C25.0008 26.4133 24.5662 26.8693 24.0228 26.8921ZM28.9497 31.3073C28.3943 31.2953 27.9475 30.8393 27.9475 30.2873C27.9354 29.7353 28.3822 29.2805 28.9376 29.2685H28.9497C29.5172 29.2685 29.976 29.7245 29.976 30.2873C29.976 30.8513 29.5172 31.3073 28.9497 31.3073ZM22.9844 30.288C23.0085 30.8401 23.4674 31.2733 24.0228 31.2481C24.5662 31.2253 25.0008 30.7692 24.9767 30.2172C24.9646 29.6772 24.5179 29.256 23.9745 29.256C23.4191 29.2812 22.9844 29.736 22.9844 30.288ZM18.0098 30.288C18.0339 30.8401 18.4928 31.2733 19.0482 31.2481C19.5915 31.2253 20.0262 30.7692 20.0021 30.2172C19.99 29.6772 19.5432 29.256 18.9999 29.256C18.4445 29.2812 18.0098 29.736 18.0098 30.288ZM27.9609 25.9206C27.9609 25.3685 28.3956 24.9245 28.9511 24.9125C29.4944 24.9125 29.9291 25.3433 29.9533 25.8726C29.9653 26.4246 29.5306 26.8806 28.9873 26.8914C28.4318 26.9034 27.973 26.4834 27.9609 25.9326V25.9206Z"
        fill="#FF5C00"
      />
      <path
        opacity="0.4"
        d="M13.0059 20.7086C13.0216 20.0042 13.0819 18.6062 13.1954 18.1562C13.775 15.6253 15.7432 14.0173 18.5566 13.7881H29.4479C32.2371 14.0293 34.2295 15.6481 34.809 18.1562C34.9213 18.5942 34.9817 20.003 34.9974 20.7086H13.0059Z"
        fill="#FF5C00"
      />
      <path
        d="M19.4844 17.5081C20.0157 17.5081 20.4142 17.1133 20.4142 16.5841V12.9252C20.4142 12.396 20.0157 12 19.4844 12C18.9532 12 18.5547 12.396 18.5547 12.9252V16.5841C18.5547 17.1133 18.9532 17.5081 19.4844 17.5081Z"
        fill="#FF5C00"
      />
      <path
        d="M28.5157 17.5081C29.0349 17.5081 29.4454 17.1133 29.4454 16.5841V12.9252C29.4454 12.396 29.0349 12 28.5157 12C27.9844 12 27.5859 12.396 27.5859 12.9252V16.5841C27.5859 17.1133 27.9844 17.5081 28.5157 17.5081Z"
        fill="#FF5C00"
      />
    </svg>
  );
};

const TotalDebtsSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(TotalDebtsSVG);
