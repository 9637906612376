import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelectFormItem from "../mySelectFormItem/MySelectFormItem";

const SelectDebitLimit = ({
  label,
  style,
  onChange,
  value,
  withAll,
  ...otherProps
}) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    let data = [
      { label: t("WORD_DEBIT_LIMIT"), value: 1 },
      { label: t("WORD_ZERO_LIMIT"), value: 0 },
    ];
    if (withAll) data.unshift({ label: t("WORD_ALL"), value: "" });
    setlist(data);
  }, [t, withAll]);

  return (
    <MySelectFormItem
      label={label}
      options={list}
      style={style}
      onChange={onChange}
      value={value}
      {...otherProps}
    />
  );
};

export default SelectDebitLimit;
