import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelectFormItem from "../mySelectFormItem/MySelectFormItem";
import UserActions from "../../actions/UserActions";

const SelectManagerStatus = ({ withAll, ...otherProps }) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    let data = [
      { label: t("WORD_ACTIVE"), value: UserActions.STATUS.ACTIVE },
      { label: t("WORD_BLOCKED"), value: UserActions.STATUS.BLOCKED },
    ];
    if (withAll) data.unshift({ label: t("WORD_ALL"), value: "" });
    setlist(data);
  }, [t, withAll]);

  return (
    <MySelectFormItem
      {...otherProps}
      options={list}
      // variant="borderless"
      // className="filter-select-default"
    />
  );
};

export default SelectManagerStatus;
