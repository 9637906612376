import { UploadOutlined } from "@ant-design/icons";
import { Upload, message, Form } from "antd";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { getBaseURL } from "../../utils/UrlHelper";
import ApiStorage from "../../redux/ApiStorage";
import UtilNotify from "../../utils/UtilNotify";
import axios from "axios";
import { useTranslation } from "react-i18next";
import MyButtonSecondary from "../myButton/MyButtonSecondary";
// import UtilObject from "../../utils/UtilObject";

// const URL_UPLOAD_FILE = "file/upload/file";
const maxFileSize = 7;

const UploadFilesManuelStart = forwardRef(
  (
    {
      onChange,
      label,
      name,
      required,
      placeholder,
      onLoadingChange,
      hidden,
      maxCount,
      multiple,
      fileTypes,
      className,
    },
    ref
  ) => {
    const { t } = useTranslation();

    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);

    // const handleUpload = (values) => {
    //     const finalUrl = `${getBaseURL()}${url || URL_UPLOAD_FILE}`;
    //     const formData = new FormData();
    //     if (fileList.length === 1) {
    //         formData.append('file', fileList[0]);
    //         // formData.append(name, fileList[0]);
    //     } else {
    //         fileList.forEach((file) => {
    //             formData.append('files[]', file);
    //         });
    //     }
    //     const objToSend = {};
    //     if (values) {
    //         Object.keys(values).forEach(key => {
    //             if (key !== name) {
    //                 const value = values[key];
    //                 objToSend[key] = value;
    //             }
    //         });
    //         if (!UtilObject.isEmpty(objToSend)) {
    //             // formData.append("saveData", JSON.stringify(objToSend));
    //             formData.append("saveData", objToSend);
    //         }
    //     }
    //     setUploading(true);
    //     // You can use any AJAX library you like
    //     fetch(finalUrl, {
    //         method: 'POST',
    //         body: formData,
    //         headers: {
    //             authorization: `Bearer ${ApiStorage.getAccessToken()}`,
    //         },
    //     })
    //         .then((res) => res.json())
    //         .then((result) => {
    //             if (result.success) {
    //                 setFileList([]);
    //                 // message.success('upload successfully.');
    //                 onChange && onChange(result);
    //                 UtilNotify.notifySuccess('upload successfully.')
    //             } else {
    //                 UtilNotify.notifyErrorServer(result.errMsg)
    //             }

    //         })
    //         .catch(() => {
    //             message.error('upload failed.');
    //         })
    //         .finally(() => {
    //             setUploading(false);
    //         });
    // };

    useEffect(() => {
      onLoadingChange && onLoadingChange(uploading);
    }, [uploading, onLoadingChange]);

    const handleUpload = (url) => {
      const finalUrl = `${getBaseURL()}${url}`;
      const formData = new FormData();
      if (fileList.length === 1) {
        formData.append("file", fileList[0]);
        // formData.append(name, fileList[0]);
      } else {
        fileList.forEach((file) => {
          formData.append("files[]", file);
        });
      }

      let deviceKey = ApiStorage.getDeviceKey();
      formData.append("deviceKey", deviceKey);
      formData.append("device", "web");

      // if (values) {
      //   Object.keys(values).forEach((key) => {
      //     if (key !== name) {
      //       formData.append(key, values[key]);
      //     }
      //   });
      // }
      setUploading(true);
      axios
        .post(finalUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${ApiStorage.getAccessToken()}`,
          },
        })
        .then((response) => {
          const result = response.data;
          if (result.success) {
            setFileList([]);
            onChange && onChange(result);
            UtilNotify.notifySuccess(t("WORD_UPLOADED_SUCCESSFULLY"));
          } else {
            UtilNotify.notifyErrorServer(result.errMsg);
          }
        })
        .catch((error) => {
          console.error("error :>> ", error);
          message.error("upload failed.");
        })
        .finally(() => {
          setUploading(false);
        });
    };

    useImperativeHandle(ref, () => ({
      startUpload: handleUpload,
    }));

    const beforeUpload = (file) => {
      let fileTypesList = Array.isArray(fileTypes) ? fileTypes : [fileTypes];
      const fileSize = file.size / 1024 / 1024;
      const isLt2M = fileSize < maxFileSize;
      if (!isLt2M) {
        message.error(`File size must be less than ${maxFileSize}MB!`);
        return false;
      }
      if (!fileTypesList.some((v) => file.name.includes(v))) {
        message.error(`File type should be ${fileTypes}`);
        return false;
      }
      setFileList([...fileList, file]);
      return false;
    };

    // const handleOnChange = (info) => {
    //     if (info.file.status !== 'uploading') {
    //     }
    //     if (info.file.status === 'done') {
    //         if (info?.file?.response) {
    //             const result = info.file.response;
    //             if (result.success) {
    //                 message.success(`${info.file.name} file uploaded successfully`);
    //                 onChange && onChange(result.data.path);
    //             } else {
    //                 message.error(result.errMsg);
    //             }
    //         }
    //     } else if (info.file.status === 'error') {
    //         message.error(`${info.file.name} file upload failed.`);
    //     }
    // }

    return (
      <Form.Item
        label={label}
        name={name}
        placeholder={placeholder || label}
        hidden={hidden}
        extra={`${maxFileSize} MB / File`}
        rules={[
          {
            required: required && !hidden,
            message: `${label || placeholder} ${t("WORD_IS_REQUIRED")}`,
          },
        ]}
        // valuePropName="file"
        // getValueFromEvent={(e) => {
        //     return (e?.fileList || [])
        //         .filter((fi) => {
        //             return fi.status === 'done' && fi.response.data.path;
        //         })
        //         .map((ffi) => {
        //             return ffi.response.data.path;
        //         });
        // }}
      >
        <Upload
          className={className || "file-uploader"}
          // action={`${getBaseURL()}${URL_UPLOAD_FILE}`}
          // headers={{
          //     authorization: `Bearer ${ApiStorage.getAccessToken()}`,
          // }}
          fileList={fileList}
          // name={'file'}
          multiple={multiple || false}
          maxCount={maxCount || 1}
          // data={() => ({ device: 'web', deviceKey: ApiStorage.getDeviceKey() })}
          // onChange={handleOnChange}
          beforeUpload={beforeUpload}
          onRemove={(file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
          }}
        >
          <MyButtonSecondary
            label={t("WORD_CLICK_TO_UPLOAD_FILE")}
            disabled={uploading}
            icon={<UploadOutlined />}
          />
        </Upload>
      </Form.Item>
    );
  }
);

export default UploadFilesManuelStart;
