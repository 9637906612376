import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import NewUserPage from "../../../components/newUserPage/NewUserPage";

const ProductNewUserPage = () => {
  const { t } = useTranslation();

  return (
    <NewUserPage
      title={t("WORD_PRODUCTS")}
      text={t("WORD_PRODUCT_INTRO_TEXT")}
      icon={<FontAwesomeIcon icon={faTags} color="#c2cada" size="10x" />}
    />
  );
};

export default ProductNewUserPage;
