import React, { memo } from "react";
import MyIcon from "./MyIcon";

export const SVGIcon = ({ size }) => {
  return (
    <svg
      width={size || 41}
      height={size || 41}
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M40.8457 20.2139C40.8457 31.2599 31.8917 40.2139 20.8457 40.2139C9.7997 40.2139 0.845703 31.2599 0.845703 20.2139C0.845703 9.16993 9.7997 0.213928 20.8457 0.213928C31.8917 0.213928 40.8457 9.16993 40.8457 20.2139Z"
        fill="#268AFF"
      ></path>
      <path
        d="M28.5651 13.6239L25.3251 23.8639C25.2051 24.2839 24.8651 24.6239 24.4451 24.7459L14.2451 27.9439C13.5651 28.1659 12.9051 27.5039 13.1251 26.8239L16.3251 16.5639C16.4451 16.1439 16.7851 15.8239 17.2051 15.6839L27.4451 12.4839C28.1451 12.2639 28.7851 12.9239 28.5651 13.6239Z"
        fill="#268AFF"
      ></path>
    </svg>
  );
};

const DistanceBlueCircleSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(DistanceBlueCircleSVG);
