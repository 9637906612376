import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Result } from "antd";
import { useTranslation } from "react-i18next";
import LoadingIconApp from "../components/loadingIconApp/LoadingIconApp";
import MyButtonBlue from "../components/myButton/MyButtonBlue";
import UserActions from "../actions/UserActions";
import "../pages/users/login/Login.scss";

const LoginMagic = () => {
  const { _token } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [finshedError, setfinshedError] = useState(false);

  useEffect(() => {
    const login = async () => {
      if (_token) {
        const result = await UserActions.loginMagic(_token);
        if (result.success) {
          navigate(`/${result.data.lang}/home`);
        } else {
          setfinshedError(true);
        }
      }
    };
    login();
  }, [_token, navigate]);

  if (finshedError) {
    return (
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={
          <MyButtonBlue
            onClick={() => navigate("login")}
            label={t("WORD_BACK_TO_LOGIN")}
          />
        }
      />
    );
  }

  return (
    <div className="registration-status-pending-wrapper">
      <LoadingIconApp style={{ fontSize: 40 }} />
    </div>
  );
};

export default LoginMagic;
