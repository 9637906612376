import React, { useEffect, useState } from "react";
import MyTable from "../../../../components/myTable/MyTable";
import { useTranslation } from "react-i18next";
import MySpace from "../../../../components/mySpace/MySpace";
import { faEye, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RouteActions from "../../../../actions/RouteActions";
import UtilNotify from "../../../../utils/UtilNotify";
import { useParams } from "react-router-dom";
import CustomerManagerActions from "../../../../actions/CustomerManagerActions";
import MyButtonTableActionConfirm from "../../../../components/myButton/MyButtonTableActionConfirm";
import MyButtonSecondary from "../../../../components/myButton/MyButtonSecondary";
import SelectTableAction from "../../../../components/selects/SelectTableAction";
import MyTooltip from "../../../../components/myTooltip/MyTooltip";
import useNavigateApi from "../../../../appRoute/useNavigateApi";
import MyButtonTableActionDelete from "../../../../components/myButton/MyButtonTableActionDelete";
import SearchInput from "../../../../components/myInputForm/SearchInput";
import MyButtonTableAction from "../../../../components/myButton/MyButtonTableAction";
import "./UserRouteCustomers.scss";

const UserListCustomerTable = ({ selectedRowKeys, setSelectedRowKeys }) => {
  const { _idManager, _idRoute } = useParams();
  const { t } = useTranslation();
  const navigateApi = useNavigateApi();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setsearch] = useState("");
  const [selectedAction, setselectedAction] = useState("");
  const [pagination, setpagination] = useState({ page: 0, perPage: 50 });
  const [totalRows, settotalRows] = useState(0);

  useEffect(() => {
    const fill = async () => {
      setLoading(true);
      let result;
      setSelectedRowKeys([]);
      const fetchParams = {
        page: pagination.page,
        perPage: pagination.perPage,
        filter: {},
        search: search?.trim(),
      };
      if (_idRoute) {
        fetchParams.filter = { idRoute: _idRoute };
        result = await RouteActions.getCustomers(fetchParams);
      } else {
        result = await CustomerManagerActions.getList(_idManager, fetchParams);
      }
      if (result.success) {
        setData(result.data);
        settotalRows(result.totalRows);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setLoading(false);
    };
    fill();
  }, [search, _idRoute, _idManager, pagination, setSelectedRowKeys]);

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleRemoveFromRoute = async (idRouteCustomers) => {
    if (idRouteCustomers && idRouteCustomers.length > 0) {
      const result = await RouteActions.removeCustomers(
        _idRoute,
        idRouteCustomers
      );
      if (result.success) {
        setData((prevData) =>
          prevData.filter((item) => !idRouteCustomers.includes(item.idCustomer))
        );
        UtilNotify.notifySuccess(t("WORD_REMOVED_SUCCESSFULLY"));
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setSelectedRowKeys([]);
    }
  };

  const handleRemoveFromManager = async (idCustomers) => {
    if (idCustomers && idCustomers.length > 0) {
      const result = await CustomerManagerActions.removeCustomers(
        _idManager,
        idCustomers
      );
      if (result.success) {
        setData((prevData) =>
          prevData.filter((item) => !idCustomers.includes(item.idCustomer))
        );
        UtilNotify.notifySuccess(t("WORD_REMOVED_SUCCESSFULLY"));
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    }
  };

  const columns = [
    {
      title: t("WORD_CUSTOMER"),
      dataIndex: "nameCustomer",
      key: "nameCustomer",
    },
    {
      title: t("WORD_ACTIONS"),
      dataIndex: "actions",
      width: 200,
      key: "actions",
      render: (_, { idRouteCustomer, idCustomer, nameCustomer }) => (
        <MySpace>
          <MyTooltip title={t("WORD_REMOVE")}>
            <MyButtonTableActionDelete
              icon={
                <FontAwesomeIcon icon={faTrashCan} color="#fff" size="xs" />
              }
              title={t("WORD_ARE_YOU_SURE_YOU_WANT_TO_REMOVE")}
              okText={t("WORD_REMOVE")}
              onConfirm={() => {
                _idRoute
                  ? handleRemoveFromRoute([idRouteCustomer])
                  : handleRemoveFromManager([idCustomer]);
              }}
            />
          </MyTooltip>
          {_idRoute ? null : (
            <MyButtonTableAction
              title={t("WORD_DETAILS")}
              icon={faEye}
              onClick={() => {
                navigateApi(
                  `/user/route/customer/details/${_idManager}/${idCustomer}`,
                  {
                    state: { username: nameCustomer },
                  }
                );
              }}
            />
          )}
        </MySpace>
      ),
    },
  ];

  return (
    <>
      <MySpace fullWidth align="top">
        <SearchInput
          onClear={() => {
            setsearch("");
          }}
          onPressEnter={(value) => {
            setsearch(value);
          }}
          className="input-search"
        />
        <MyButtonSecondary
          label={t("WORD_ALL_CUSTOMERS")}
          onClick={() => {
            if (_idRoute) {
              navigateApi(`/user/route/${_idManager}/${_idRoute}/add`);
            } else {
              navigateApi(`/user/route/${_idManager}/add`);
            }
          }}
        />
      </MySpace>
      <div className="margin-top">
        <MyTable
          loading={loading}
          rowKey="idCustomer"
          rowSelection={rowSelection}
          columns={columns}
          showHeader={true}
          style={{ width: "100%" }}
          dataSource={data}
          total={totalRows}
          onChangePage={(e, ee) => {
            setpagination({ page: e, perPage: ee });
          }}
          page={pagination.page}
          pageSize={pagination.perPage}
          title={() => (
            <div>
              <MySpace>
                <SelectTableAction
                  style={{ width: 220 }}
                  placeholder={t("WORD_SELECT_ACTION")}
                  onChange={setselectedAction}
                />
                <MyButtonTableActionConfirm
                  className="apply-action-btn"
                  label={t("WORD_APPLY_ACTION")}
                  disabled={
                    selectedAction === "select" || !selectedRowKeys?.length
                  }
                  onConfirm={() => {
                    if (selectedAction === "remove") {
                      _idRoute
                        ? handleRemoveFromRoute(selectedRowKeys)
                        : handleRemoveFromManager(selectedRowKeys);
                    }
                  }}
                />
              </MySpace>
            </div>
          )}
        />
      </div>
    </>
  );
};

export default UserListCustomerTable;
