import React, { memo } from "react";
import MyIcon from "./MyIcon";

export const SVGIcon = ({ style, size = 41 }) => {
  return (
    <svg
      width={size || 101}
      height={size || 98}
      viewBox="0 0 101 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ style }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6872 20.8882C30.1189 23.9257 33.6789 32.4068 30.6386 39.8315C29.637 42.2775 29.0776 44.9667 29.0776 47.8227C29.0776 59.4909 38.5456 68.9499 50.225 68.9499C61.9044 68.9499 71.3724 59.4909 71.3724 47.8227C71.3724 44.9667 70.8131 42.2775 69.8115 39.8315C66.7711 32.4068 70.3311 23.9257 77.7628 20.8882C85.1945 17.8508 93.6838 21.4074 96.7241 28.832C99.1307 34.7091 100.45 41.13 100.45 47.8227C100.45 75.5348 77.9635 97.9999 50.225 97.9999C22.4865 97.9999 0 75.5348 0 47.8227C0 41.13 1.31934 34.7091 3.72592 28.832C6.76623 21.4074 15.2555 17.8508 22.6872 20.8882Z"
        fill="url(#paint0_angular_1_421)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.7628 20.8882C85.1945 17.8508 93.6837 21.4074 96.724 28.832C99.1306 34.7091 100.45 41.13 100.45 47.8227C100.45 75.5348 77.9635 97.9999 50.225 97.9999C38.2537 97.9999 27.2257 93.7974 18.5953 86.8014C12.3601 81.747 11.4068 72.5997 16.466 66.3704C21.5253 60.1411 30.6813 59.1887 36.9165 64.2431C40.5553 67.1928 45.1651 68.9499 50.225 68.9499C61.9043 68.9499 71.3723 59.4909 71.3723 47.8227C71.3723 44.9667 70.813 42.2775 69.8114 39.8315C66.7711 32.4068 70.331 23.9257 77.7628 20.8882Z"
        fill="url(#paint1_angular_1_421)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.7628 20.8882C85.1945 17.8508 93.6837 21.4074 96.724 28.832C99.1306 34.7091 100.45 41.13 100.45 47.8227C100.45 63.5673 93.1745 77.6252 81.8546 86.8014C75.6194 91.8558 66.4634 90.9034 61.4042 84.6741C56.3449 78.4448 57.2982 69.2976 63.5335 64.2431C68.341 60.346 71.3723 54.4435 71.3723 47.8227C71.3723 44.9667 70.813 42.2775 69.8114 39.8315C66.7711 32.4068 70.331 23.9257 77.7628 20.8882Z"
        fill="url(#paint2_linear_1_421)"
      />
      <ellipse
        cx="50.225"
        cy="14.525"
        rx="14.5388"
        ry="14.525"
        fill="#ED7C4C"
      />
      <defs>
        <radialGradient
          id="paint0_angular_1_421"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(50.225 50.1772) rotate(90) scale(47.8227 48.9033)"
        >
          <stop offset="0.192753" stopColor="#5BC02E" />
          <stop offset="0.392916" stopColor="#B4F100" />
        </radialGradient>
        <radialGradient
          id="paint1_angular_1_421"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(51.5467 48.8568) rotate(83.86) scale(49.4267 51.7635)"
        >
          <stop offset="0.178737" stopColor="#00E386" />
          <stop offset="0.904913" stopColor="#00A1D5" />
        </radialGradient>
        <linearGradient
          id="paint2_linear_1_421"
          x1="82.8374"
          y1="1.59679"
          x2="69.3379"
          y2="78.6175"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.170771" stopColor="#877CFF" />
          <stop offset="1" stopColor="#4EC9FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const LogoSmallSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(LogoSmallSVG);
