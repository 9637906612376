import React from "react";
import { Select } from "antd";

const MySelect = ({
  options,
  onChange,
  className,
  value,
  allowClear,
  style,
  fullWidth,
  width,
  placeholder,
  defaultValue,
}) => {
  return (
    <Select
      onChange={onChange}
      placeholder={placeholder}
      allowClear={allowClear}
      className={`app-select ${className || ""}`}
      options={options}
      defaultValue={defaultValue}
      value={value}
      style={{ width: fullWidth ? "100%" : width, ...style }}
    ></Select>
  );
};

export default MySelect;
