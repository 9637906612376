import i18n from "i18next";
// import Backend from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ApiStorage from "./redux/ApiStorage";
import translationEN from "./translations/en.json";
import translationAR from "./translations/ar.json";
import translationHY from "./translations/hy.json";
import translationRU from "./translations/ru.json";
import ClientSaveActions from "./actions/ClientSaveActions";

const defLanguage = "hy";

i18n
  .use(initReactI18next)
  // .use(Backend)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      ar: {
        translation: translationAR,
      },
      hy: {
        translation: translationHY,
      },
      ru: {
        translation: translationRU,
      },
    },
    fallbackLng: defLanguage,
    supportedLngs: ["en", "ar", "hy", "ru"],
    debug: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    load: "unspecific",
    react: {
      useSuspense: true,
    },
    // detection: {
    //   order: ['htmlTag', "path", 'localStorage', 'navigator'],
    //   cache: ["path"],
    //   lookupFromPathIndex: 0,
    //   checkWhitelist: true,
    // },
    detection: {
      order: ["path", "htmlTag", "navigator", "localStorage"],
      // cache: ["path"],
      lookupFromPathIndex: 0,
      checkWhitelist: true,
    },
    // detection: {
    //   order: ['querystring', 'path', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag'],
    //   caches: ['cookie'],
    //   lookupQuerystring: 'lang',
    // },
  });

i18n.on("languageChanged", async (lang) => {
  if (i18n.language === lang) return;
  const html = document.querySelector("html");
  html && html.setAttribute("lang", lang);
  ApiStorage.setLanguage(lang);
  const us = { ...ApiStorage.getCurrentUser(), language: lang, lang: lang };
  ApiStorage.setCurrentUser(us);
  ClientSaveActions.saveClientOptions({
    language: lang,
  });
});

export default i18n;
