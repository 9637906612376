import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelectFormItem from "../mySelectFormItem/MySelectFormItem";

const SelectInvoiceType = ({
  label,
  style,
  onChange,
  value,
  ...otherProps
}) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    let data = [
      { label: 1, value: 1 },
      { label: 2, value: 2 },
      { label: 3, value: 3 },
    ];
    setlist(data);
  }, [t]);

  return (
    <MySelectFormItem
      label={label}
      options={list}
      style={style}
      onChange={onChange}
      value={value}
      {...otherProps}
    />
  );
};

export default SelectInvoiceType;
