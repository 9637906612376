import { CheckOutlined, HourglassOutlined } from "@ant-design/icons";
import { fetchFromUrlPOSTAsync } from "../utils/UrlHelper";

const URL_FILL_PAGINATION = "orders/list";
const URL_GET_ONE = "orders/getOne";
const URL_CONFIRM_PAYMENT = "orders/confirmPayment";
const URL_CLEAR_BADGE_COUNT_ORDER = "orders/clearBadgeCount/order";
const URL_CLEAR_BADGE_COUNT_PAYMENT = "orders/clearBadgeCount/payment";
const URL_UPDATE = "orders/update";
const URL_DELETE = "orders/delete";
const URL_DELETE_MULTI = "orders/deleteMulti";
const URL_CHANGE_TO_NOT_EXPORTED = "orders/changeNotExported";

const fs = {
  fillPagination: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_FILL_PAGINATION, urlParams);
    return result;
  },
  getOne: async (idOrder) => {
    const result = await fetchFromUrlPOSTAsync(URL_GET_ONE + "/" + idOrder, []);
    return result;
  },
  delete: async (idOrder) => {
    const result = await fetchFromUrlPOSTAsync(URL_DELETE + "/" + idOrder, []);
    return result;
  },
  deleteMulti: async (values) => {
    const result = await fetchFromUrlPOSTAsync(URL_DELETE_MULTI, {
      saveData: values,
    });
    return result;
  },
  confirmPayment: async (idOrder) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_CONFIRM_PAYMENT + "/" + idOrder,
      []
    );
    return result;
  },
  clearBadgeCountOrder: async () => {
    const result = await fetchFromUrlPOSTAsync(URL_CLEAR_BADGE_COUNT_ORDER, []);
    return result;
  },
  clearBadgeCountPayment: async () => {
    const result = await fetchFromUrlPOSTAsync(
      URL_CLEAR_BADGE_COUNT_PAYMENT,
      []
    );
    return result;
  },
  update: async (values, idOrder) => {
    let result = await fetchFromUrlPOSTAsync(URL_UPDATE + "/" + idOrder, {
      saveData: values,
    });
    return result;
  },
  changeToNotExported: async (idOrder) => {
    let result = await fetchFromUrlPOSTAsync(
      URL_CHANGE_TO_NOT_EXPORTED + "/" + idOrder,
      {}
    );
    return result;
  },
};

const loc = {
  STATUS: { CLOSED: 0, OPENED: 1 },
  TYPE: { DEFAULT: 1, RETURN: 2, PAYMENT: 3 },
  EXPORT_TYPE: {
    NOTEXPORTED: 0,
    EXPORTED: 1,
  },
  PAYMENT_STATUS: {
    NOTCONFIRMED: 0,
    CONFIRMED: 1,
  },
  DISTANCE_STATUS: {
    UNDEFINED: 0,
    YES: 1,
    NO: 2,
  },
  getOrderStatusLabel: (status) => {
    switch (status) {
      case 1:
        return { label: "Pending", color: "#ff8947" };
      default:
        return { label: "Closed", color: "#92cf5c" };
    }
  },
  getPaymentStatusLabel: (status) => {
    switch (status) {
      case 1:
        return { label: "Confirmed", icon: <CheckOutlined /> };
      default:
        return { label: "Not confirmed", icon: <HourglassOutlined /> };
    }
  },
  getPaymentExportLabel: (status) => {
    switch (status) {
      case 1:
        return { label: "Export" };
      default:
        return { label: "Not exported" };
    }
  },
  getOrderTypeLabel: (type) => {
    switch (type) {
      case 1:
        return { label: "Order", color: "#8247ff" };
      case 2:
        return { label: "Return", color: "#f4c563" };
      case 3:
        return { label: "Payment", color: "#9fcc6b" };
      default:
        return "";
    }
  },
  getDistanceStatusLabel: (status) => {
    switch (status) {
      case 1:
        return {
          label: "The deal was registered locally.",
          color: "#92cf5c",
          value: 1,
        };
      case 2:
        return {
          label: "The deal was registered from elsewhere.",
          color: "#fcc44d",
          value: 2,
        };
      default:
        return { label: "Address not found.", color: "#a7b4d3", value: 0 };
    }
  },
};

const OrdersActions = Object.assign(fs, loc);

export default OrdersActions;
