import React, { memo } from "react";
import MyIcon from "./MyIcon";

export const SVGIcon = ({ size, fill }) => {
  return (
    <svg
      width={size || 25}
      height={size || 25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M12.499 22.9147C11.698 22.9147 10.898 22.6105 10.2865 22.0032L9.52611 21.2428C9.23132 20.9491 8.83653 20.7866 8.41778 20.7855H7.34798C5.61882 20.7855 4.21153 19.3782 4.21153 17.6491V16.5782C4.21048 16.1605 4.04798 15.7657 3.75319 15.4688L3.00528 14.722C1.78028 13.5043 1.77507 11.5136 2.99382 10.2876L3.75423 9.52613C4.04798 9.23134 4.21048 8.83655 4.21153 8.4178V7.34905C4.21153 5.61884 5.61882 4.21155 7.34798 4.21155H8.41882C8.83653 4.21155 9.23028 4.04905 9.52715 3.75218L10.2761 3.0053C11.4938 1.7803 13.4834 1.77405 14.7105 2.99384L15.4709 3.75426C15.7667 4.04905 16.1605 4.21155 16.5782 4.21155H17.649C19.3782 4.21155 20.7855 5.61884 20.7855 7.34905V8.41884C20.7865 8.83655 20.949 9.23134 21.2438 9.52822L21.9917 10.2761C22.5844 10.8657 22.9126 11.6511 22.9157 12.4897C22.9178 13.323 22.5969 14.1074 22.0126 14.7001C22.0022 14.7105 21.9928 14.722 21.9824 14.7313L21.2428 15.4709C20.949 15.7657 20.7865 16.1605 20.7855 16.5793V17.6491C20.7855 19.3782 19.3782 20.7855 17.649 20.7855H16.5782C16.1605 20.7866 15.7657 20.9491 15.4699 21.2438L14.7209 21.9918C14.1105 22.6063 13.3042 22.9147 12.499 22.9147"
        fill={fill || "#767676"}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4612 10.4696C10.2893 10.6415 10.0664 10.7342 9.81954 10.7342C9.58829 10.7342 9.36121 10.6394 9.17891 10.4686C9.006 10.2967 8.90808 10.0613 8.90808 9.82277C8.90808 9.59569 9.00391 9.36235 9.17266 9.18214C9.26537 9.08839 9.37579 9.01756 9.48621 8.98006C9.80079 8.83631 10.2185 8.92069 10.4664 9.1811C10.555 9.26964 10.6227 9.36652 10.6675 9.46756C10.7164 9.57589 10.7414 9.69881 10.7414 9.82277C10.7414 10.0707 10.6425 10.3009 10.4612 10.4696ZM15.8234 9.17683C15.4682 8.82267 14.8901 8.82267 14.5349 9.17683L9.18069 14.531C8.82548 14.8862 8.82548 15.4643 9.18069 15.8206C9.3536 15.9925 9.58173 16.0872 9.82548 16.0872C10.0692 16.0872 10.2974 15.9925 10.4692 15.8206L15.8234 10.4664C16.1786 10.1102 16.1786 9.53308 15.8234 9.17683ZM15.5268 14.3374C15.1893 14.1957 14.7893 14.2728 14.5227 14.5394C14.4675 14.604 14.3893 14.704 14.3362 14.8217C14.28 14.9488 14.2727 15.0853 14.2727 15.1769C14.2727 15.2686 14.28 15.404 14.3362 15.5311C14.3883 15.6478 14.4508 15.7426 14.5331 15.8249C14.7216 15.9999 14.9404 16.0884 15.1841 16.0884C15.4154 16.0884 15.6425 15.9946 15.8289 15.8207C15.9945 15.6551 16.0852 15.4259 16.0852 15.1769C16.0852 14.9269 15.9945 14.6988 15.8279 14.5321C15.7362 14.4415 15.6258 14.3707 15.5268 14.3374Z"
        fill={fill || "#767676"}
      ></path>
    </svg>
  );
};

const SalesSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(SalesSVG);
