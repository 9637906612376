import React from "react";
import { Button } from "antd";
import MySpace from "../mySpace/MySpace";
import "./MyButton.scss";

const MyButton = ({
  loading,
  label,
  icon,
  onClick,
  className,
  style,
  danger,
  type,
  htmlType,
  form,
  ghost,
  fullWidth,
  disabled,
  hidden,
  block,
  size,
}) => {
  return (
    <Button
      className={`my-button ${className || ""}`}
      loading={loading}
      onClick={onClick}
      style={{ width: fullWidth ? "100%" : "", ...style }}
      danger={danger}
      type={type}
      htmlType={htmlType}
      block={block}
      form={form}
      ghost={ghost}
      disabled={disabled}
      hidden={hidden}
      size={size}
    >
      <MySpace>
        {Boolean(icon) && <div className="my-button-icon">{icon}</div>}
        {Boolean(label) && <span style={{ fontWeight: 600 }}>{label}</span>}
      </MySpace>
    </Button>
  );
};

export default MyButton;
