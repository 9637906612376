import React from "react";
import MyCard from "../../components/myCard/MyCard";
import MyTextAnalyticsStatsCard from "../../components/myText/MyTextAnalyticsStatsCard";
import MyDonutChart from "../../components/myDonutChart/MyDonutChart";
import { Flex } from "antd";
import { useSelector } from "react-redux";
import UtilNumber from "../../utils/UtilNumber";

const AnalyticsVsSection = ({ loading, title, chartData = [] }) => {
  const currency = useSelector((state) => {
    return state.currentUser?.currency;
  });

  return (
    <MyCard loading={loading} style={{ width: "100%" }}>
      <Flex vertical gap={30}>
        <MyTextAnalyticsStatsCard bold>{title}</MyTextAnalyticsStatsCard>
        <Flex align="center" justify="space-between">
          <Flex vertical gap={5}>
            {chartData?.map((ob, index) => {
              return (
                <Flex gap={5} key={index} vertical align="start">
                  <div className="statsCard__amountHolder--amount--label label-wrapper">
                    <div className={`value-name ${ob.className}`}></div>
                    {ob.label}
                  </div>
                  <Flex>
                    <strong
                      style={{ marginRight: 3 }}
                    >{`${currency}${UtilNumber.formatWithK(ob.value)}`}</strong>
                    <span
                      style={{ fontSize: 10, marginTop: 5 }}
                    >{`(${UtilNumber.formatPercentages(ob.per)}%)`}</span>
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
          <MyDonutChart
            data={chartData}
            size={150}
            labels={chartData?.map((oo) => oo.label)}
          />
        </Flex>
      </Flex>
    </MyCard>
  );
};

export default AnalyticsVsSection;
