import React from "react";
import MyButtonMain from "./MyButtonMain";

const MyButtonPurple = ({ ...otherProps }) => {
  return (
    <MyButtonMain {...otherProps} type="primary" className="my-btn-purple" />
  );
};

export default MyButtonPurple;
