import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArcaPaymentApi from "../actions/ArcaPaymentApi";
import LoadingIconPage from "../components/loadingIconApp/LoadingIconPage";

const ArcaPayApiCheck = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const orderId = searchParams.get("orderId");

  const navigate = useNavigate();

  useEffect(() => {
    const check = async () => {
      if (orderId) {
        const resultCheck = await ArcaPaymentApi.checkOrderStatus(orderId);
        console.log('resultCheck :>> ', resultCheck);
        if (resultCheck.success) {
          if (resultCheck.data.paymentAmountInfo.paymentState === "DECLINED") {
            //   navigateApi(`/payment/idramfail`);
            navigate(`/payment/fail`);
          } else {
            //   navigateApi(`/payment/idramsuccess`);
            navigate(`/payment/success`);
          }
        }
      }
    };
    check();
  }, [orderId, navigate]);

  return <LoadingIconPage />;
};

export default ArcaPayApiCheck;
