import React from "react";
import MySegmentedVertical from "../../components/mySegmented/MySegmentedVertical";
import MyTextTitle from "../../components/myText/MyTextTitle";
import { useTranslation } from "react-i18next";

const PricingSegmentedSection = ({ list, onChange, selectedPlan }) => {
  const { t } = useTranslation();

  const options = [
    ...list.map((item) => ({
      label: (
        <div
          style={{
            textAlign: "start",
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
        >
          <MyTextTitle size={16}>{item.serviceName}</MyTextTitle>
          <div>
            {item.deviceCount} {t("WORD_MOBILE_DEVICES")}
          </div>
          <div>
            {item.storageSize} {t("WORD_DATA_STORAGE")}
          </div>
        </div>
      ),
      value: item.serviceName,
    })),
    {
      label: (
        <div className="pricing-segment-div">
          <MyTextTitle size={16}>{t("WORD_ENTERPRISE")}</MyTextTitle>
          <div>{t("WORD_CUSTOM_MOBILE_DEVICES")}</div>
          <div>{t("WORD_CUSTOM_DATA_STORAGE")}</div>
        </div>
      ),
      value: "custom",
    },
  ];

  return (
    <div className="pricing-segmented-section">
      <MySegmentedVertical
        options={options}
        onChange={onChange}
        value={selectedPlan}
      />
    </div>
  );
};

export default PricingSegmentedSection;
