import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MyPageHeader from "../../../components/myPageHeader/MyPageHeader";
import { useTranslation } from "react-i18next";
import MySpace from "../../../components/mySpace/MySpace";
import ShoppingCartPurpleCircleSVG from "../../../icons/ShoppingCartPurpleCircleSVG";
import RefundsYellowCircleSVG from "../../../icons/RefundsYellowCircleSVG";
import LocationSVG from "../../../icons/GpsSVG";
import OrderExportDropdown from "./OrderExportDropdown";
import { useSelector } from "react-redux";
import ApiStorage from "../../../redux/ApiStorage";
import OrdersActions from "../../../actions/OrdersActions";
import UtilNotify from "../../../utils/UtilNotify";
import UtilDate from "../../../utils/UtilDate";
import StatisticCard from "../../../components/statisticCard/StatisticCard";
import MyTable from "../../../components/myTable/MyTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import ExportTemplatesActions from "../../../actions/ExportTemplatesActions";
import MyHelmet from "../../../components/helmet/MyHelmet";
import OrderEditModal from "../orderEdit/OrderEditModal";
import MyTooltip from "../../../components/myTooltip/MyTooltip";
import MyButtonTableActionDelete from "../../../components/myButton/MyButtonTableActionDelete";
import { useFormatCurrency } from "../../../utils/useFormatWithCurrency";
import { Flex } from "antd";
import FormApp from "../../../components/formApp/FormApp";
import SelectOrderType from "../../../components/selects/SelectOrderType";
import SelectOrderStatus from "../../../components/selects/SelectOrderStatus";
import { useForm } from "antd/es/form/Form";
import MyButtonText from "../../../components/myButton/MyButtonText";
import OrderListFilterDrawerFewer from "./OrderListFilterDrawerFewer";
import MyButtonTableActionWithLink from "../../../components/myButton/MyButtonTableActionWithLink";
import MyButtonTableAction from "../../../components/myButton/MyButtonTableAction";
import UtilObject from "../../../utils/UtilObject";
import MyTag from "../../../components/tag/MyTag";
import SelectManagerMulti from "../../../components/selects/SelectManagerMulti";
import "./OrderList.scss";

const formName = "formOrderFilter";

const OrderList = () => {
  const { t } = useTranslation();
  const { formatDoubleWithCurrency } = useFormatCurrency();
  const [form] = useForm();
  const refModal = useRef();

  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, settotalRows] = useState(0);
  const [totals, settotals] = useState({});
  const [pagination, setpagination] = useState({ page: 0, perPage: 50 });
  const [sort, setsort] = useState("");

  const orderPageFilters = useSelector((state) => {
    return state.orderPageFilters;
  });

  const use1c = useSelector((state) => {
    return state.currentUser?.is1c;
  });

  // const setFilter = useCallback(
  //   (fil) => {
  //     ApiStorage.setOrderPageFilters(Object.assign({}, orderPageFilters, fil));
  //     setpage(0);
  //   },
  //   [orderPageFilters]
  // );

  useEffect(() => {
    if (form) {
      form.setFieldsValue(orderPageFilters);
      if (
        !UtilObject.isEmpty(orderPageFilters) &&
        orderPageFilters?.date &&
        orderPageFilters?.date[0] &&
        orderPageFilters?.date[1]
      ) {
        form.setFieldsValue({
          date: [
            orderPageFilters?.date[0]
              ? UtilDate.getDate(orderPageFilters?.date[0])
              : null,
            orderPageFilters?.date[1]
              ? UtilDate.getDate(orderPageFilters?.date[1])
              : null,
          ],
        });
      }
    }
  }, [form, orderPageFilters]);

  const setpage = (page) => {
    setpagination((ov) => ({ page: page, perPage: ov.perPage }));
  };

  const setFilterClear = () => {
    ApiStorage.setOrderPageFilters({});
    form.resetFields();
    setpage(0);
  };

  const refreshListDb = useCallback(async () => {
    setLoading(true);
    const fetchParams = {
      page: pagination.page,
      perPage: pagination.perPage,
      sort: sort,
      q: orderPageFilters?.searchName || "",
      filter: { ...orderPageFilters },
    };
    if (!fetchParams?.filter?.type) {
      fetchParams.filter.type = [
        OrdersActions.TYPE.DEFAULT,
        OrdersActions.TYPE.RETURN,
      ];
    }
    const result = await OrdersActions.fillPagination(fetchParams);
    if (result.success) {
      setData(result.data);
      settotalRows(result.totalRows);
      settotals(result.totals);
      setSelectedRowKeys([]);
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    setLoading(false);
  }, [orderPageFilters, pagination, sort]);

  useEffect(() => {
    refreshListDb();
  }, [refreshListDb]);

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // const handleRowClick = (record, e) => {
  //   if (e.target.closest(".ant-popconfirm") || e.target.closest("button")) {
  //     return;
  //   }
  //   const selectedRowKey = record.idOrder;
  //   const newSelectedRowKeys = selectedRowKeys.includes(selectedRowKey)
  //     ? selectedRowKeys.filter((key) => key !== selectedRowKey)
  //     : [...selectedRowKeys, selectedRowKey];

  //   onSelectChange(newSelectedRowKeys, []);
  // };

  const handleDeleteOne = useCallback(
    async (idOrder) => {
      if (idOrder) {
        const result = await OrdersActions.delete(idOrder);
        if (result.success) {
          await refreshListDb();
          UtilNotify.notifySuccess(t("WORD_REMOVED_SUCCESSFULLY"));
        } else {
          UtilNotify.notifyErrorServer(result.errMsg);
        }
      }
    },
    [t, refreshListDb]
  );

  // const handleDeleteMulti = useCallback(
  //   async (idOrders) => {
  //     if (idOrders && Array.isArray(idOrders)) {
  //       const result = await OrdersActions.deleteMulti(idOrders);
  //         if (result.success) {
  //           setData((prevData) =>
  //             prevData.filter((item) => !idOrders.includes(item.idOrder))
  //           );
  //           setSelectedRowKeys([]);
  //           UtilNotify.notifySuccess(t("WORD_REMOVED_SUCCESSFULLY"));
  //         } else {
  //           UtilNotify.notifyErrorServer(result.errMsg);
  //         }

  //     }
  //   },
  //   [ t]
  // );

  const onChangeTable = (_, __, sorter) => {
    let so = "";
    if (sorter.order) {
      so = `${sorter.columnKey} ${sorter.order === "ascend" ? "ASC" : "DESC"}`;
    }
    setsort(so);
  };

  const onFinish = (values) => {
    const { date } = values;
    if (date) {
      values.dateFrom = date[0];
      values.dateTo = date[1];
    }
    // const obj = Object.assign({}, orderPageFilters, values);
    ApiStorage.setOrderPageFilters({ ...values });
    setpage(0);
  };

  const hasSelected = selectedRowKeys.length > 0;

  const handleEditOrderChange = (ord) => {
    refreshListDb();
  };

  const columns = useMemo(() => {
    let col = [
      {
        title: " ",
        dataIndex: "distanceStatus",
        width: 40,
        key: "distanceStatus",
        render: (value) => {
          const type = OrdersActions.getDistanceStatusLabel(value);
          return (
            <MyTooltip title={t(type.label)} placement="rightBottom">
              <LocationSVG fill={type.color} size={22} />
            </MyTooltip>
          );
        },
      },
      {
        title: t("WORD_ORDER_ID"),
        dataIndex: "idOrder",
        key: "idOrder",
        width: 40,
      },
      {
        title: t("WORD_BRANCH"),
        dataIndex: "nameBranch",
        key: "nameBranch",
        width: 50,
      },
      {
        title: t("WORD_DISTRIBUTOR"),
        dataIndex: "nameManager",
        key: "nameManager",
        width: 100,
      },
      {
        title: t("WORD_CUSTOMER"),
        dataIndex: "nameCustomer",
        key: "nameCustomer",
        width: 100,
      },
      {
        title: t("WORD_TOTAL_AMOUNT"),
        dataIndex: "totalAmount",
        key: "totalAmount",
        width: 80,
        align: "right",
        render: (value) => {
          return formatDoubleWithCurrency(value);
        },
      },
      {
        title: t("WORD_TYPE"),
        dataIndex: "type",
        key: "type",
        width: 20,
        render: (value) => {
          const type = OrdersActions.getOrderTypeLabel(value);
          return <MyTag label={t(type.label)} color={type.color} />;
        },
      },
      {
        title: t("WORD_STATUS"),
        dataIndex: "status",
        key: "status",
        width: 20,
        render: (value) => {
          const status = OrdersActions.getOrderStatusLabel(value);
          return <MyTag label={t(status.label)} color={status.color} />;
        },
      },
      {
        title: t("WORD_PAYMENT_TYPE"),
        dataIndex: "namePaymentType",
        key: "namePaymenttype",
        width: 80,
        render: (value) => {
          return <div>{value}</div>;
        },
      },
      {
        title: t("WORD_CREATE_DATE"),
        dataIndex: "createdAt",
        key: "createdAt",
        width: 80,
        sorter: true,
        render: (value) => {
          return <div>{UtilDate.formatDateTime(value)}</div>;
        },
      },
      {
        title: t("WORD_SYNC_DATE"),
        dataIndex: "syncDate",
        key: "syncDate",
        width: 80,
        sorter: true,
        render: (value) => {
          return <div>{value ? UtilDate.formatDateTime(value) : ""}</div>;
        },
      },
      {
        title: t("WORD_ACTIONS"),
        dataIndex: "",
        key: "x",
        width: 100,
        // fixed: "right",
        render: (_, obj) => (
          <MySpace>
            <MyButtonTableActionWithLink
              path={`/order/view/${obj.idOrder}`}
              title={t("WORD_VIEW")}
              icon={faEye}
            />
            <MyTooltip title={t("WORD_DELETE")}>
              <MyButtonTableActionDelete
                onConfirm={() => {
                  handleDeleteOne(obj.idOrder);
                }}
                icon={
                  <FontAwesomeIcon icon={faTrashCan} color="#fff" size="xs" />
                }
              />
            </MyTooltip>
            {use1c ? null : (
              <MyButtonTableAction
                title={t("WORD_EDIT")}
                onClick={() => {
                  refModal?.current?.viewOrder(obj);
                }}
                icon={faPen}
              />
            )}
          </MySpace>
        ),
      },
    ];
    return col;
  }, [t, handleDeleteOne, use1c, formatDoubleWithCurrency]);

  return (
    <>
      <MyHelmet title={t("WORD_ORDERS")} />
      <Flex vertical gap={10}>
        <MyPageHeader
          inPage
          extraLeft={
            loading ? null : (
              <div>
                {hasSelected
                  ? `${t("WORD_SELECTED")} ${selectedRowKeys.length} ${
                      selectedRowKeys.length > 1
                        ? t("WORD_ITEMS")
                        : t("WORD_ITEM")
                    }`
                  : ""}
              </div>
            )
          }
          extraRight={
            <MySpace>
              <OrderExportDropdown
                viewIn={ExportTemplatesActions.VIEWIN.ORDERS}
                ids={selectedRowKeys}
                onChange={refreshListDb}
              />
              {/* <OrderListFilterDrawer
                onChange={setFilter}
                onReset={setFilterClear}
                filter={orderPageFilters}
              /> */}
            </MySpace>
          }
          title={t("WORD_ORDERS")}
        />
        <Flex gap="small">
          <FormApp
            onFinish={onFinish}
            name={formName}
            form={form}
            style={{ marginLeft: 8 }}
            // initialValues={orderPageFilters}
          >
            <MySpace>
              <SelectOrderType
                placeholder={t("WORD_SELECT_TYPE")}
                name="type"
                onChange={() => form.submit()}
              />
              <SelectOrderStatus
                placeholder={t("WORD_SELECT_STATUS")}
                name="status"
                onChange={() => form.submit()}
              />
              <SelectManagerMulti
                placeholder={t("WORD_SELECT_DISTRIBUTOR")}
                name="idManager"
                style={{ minWidth: 170 }}
              />
              <OrderListFilterDrawerFewer
                onReset={setFilterClear}
                loading={loading}
                formName={formName}
              />
            </MySpace>
          </FormApp>
          <MyButtonText
            label={t("WORD_RESET_FILTERS")}
            onClick={setFilterClear}
          />
        </Flex>
        <MySpace className="stat-card-row" style={{ marginLeft: 8 }}>
          <StatisticCard
            title={t("WORD_ORDERS_TOTAL")}
            loading={loading}
            amount={Math.round(totals?.totalAmountOrder)}
            icon={<ShoppingCartPurpleCircleSVG />}
            secondTitle={t("WORD_IN")}
            count={totals?.totalCountOrder}
          />
          <StatisticCard
            loading={loading}
            title={t("WORD_REFUNDS_TOTAL")}
            amount={Math.round(totals?.totalAmountReturn)}
            icon={<RefundsYellowCircleSVG />}
            secondTitle={t("WORD_IN")}
            count={totals?.totalCountReturn}
          />
        </MySpace>
        <MyTable
          rowKey="idOrder"
          rowSelection={rowSelection}
          onChange={onChangeTable}
          columns={columns}
          dataSource={data}
          loading={loading}
          total={totalRows}
          onChangePage={(e, ee) => {
            setpagination({ page: e, perPage: ee });
          }}
          page={pagination.page}
          pageSize={pagination.perPage}
          rowClassName={(record) => {
            if (!record.exportedAdmin) return "not-exported";
          }}
          scroll={{ x: 1700 }}
          // onRow={(record) => ({
          //   onClick: (e) => handleRowClick(record, e),
          // })}
          // multiSelect={
          //   <div
          //     style={{
          //       padding: 10,
          //       position: "absolute",
          //       bottom: 2,
          //       left: 155,
          //     }}
          //   >
          //     <MySpace>
          //       <SelectTableAction
          //         style={{ width: 220 }}
          //         placeholder={t("WORD_SELECT_ACTION")}
          //         onChange={setselectedAction}
          //       />
          //       <MyButtonTableActionConfirm
          //         className="apply-action-btn"
          //         label={t("WORD_APPLY_ACTION")}
          //         disabled={
          //           selectedAction === "select" || !selectedRowKeys.length
          //         }
          //         onConfirm={() => {
          //           if (selectedAction === "delete") {
          //             handleDeleteMulti(selectedRowKeys);
          //           }
          //         }}
          //       />
          //     </MySpace>
          //   </div>
          // }
        />
      </Flex>
      <OrderEditModal ref={refModal} onChange={handleEditOrderChange} />
    </>
  );
};

export default OrderList;
