import dayjs from "dayjs";
import i18n from "../i18n";

export const myMoment = (date, format) => {
  let mm;
  try {
    if (format) {
      mm = dayjs(date, format);
    } else {
      mm = dayjs(date);
    }
  } catch (error) {
    mm = dayjs();
  }
  return mm;
};

const UtilDate = {
  convertDate: (dat) => {
    return myMoment(dat);
  },
  formatDateTime: (dat) => {
    if (dat) {
      try {
        return myMoment(dat).format("DD/MM/YYYY HH:mm");
      } catch (error) {
        return "---";
      }
    }
    return "---";
  },
  formatMonthYear: (dat) => {
    if (dat) {
      try {
        return myMoment(dat).format("MMM YYYY");
      } catch (error) {
        return "---";
      }
    }
    return "---";
  },
  formatDate: (dat) => {
    if (dat) {
      try {
        return myMoment(dat).format("DD/MM/YYYY");
      } catch (error) {
        return "---";
      }
    }
    return "---";
  },
  getDateToday: () => {
    return myMoment();
  },
  getDateOneMonthFromToday: (dat) => {
    return myMoment(dat).add(1, "months");
  },
  getDateOneYearFromToday: (dat) => {
    return myMoment(dat).add(1, "years");
  },
  getThisMonth: () => {
    return myMoment().month();
  },
  getThisYear: () => {
    return myMoment().year();
  },
  getDate: (dat) => {
    return myMoment(dat);
  },
  getDateYesterday: (today) => {
    return myMoment(today).subtract(1, "day");
  },
  getDate3DaysAgo: (today) => {
    return myMoment(today).subtract(3, "day");
  },
  getDateTomorrow: (today) => {
    return myMoment(today).add(1, "day");
  },
  getDateNextMonth: (today) => {
    return myMoment(today).add(1, "month");
  },
  getDatePrevMonth: (today) => {
    return myMoment(today).subtract(1, "month");
  },
  getDateDayCount: (dayCount = 1) => {
    return myMoment().add(dayCount, "day").day();
  },
  getDiffDaysCount: (dat) => {
    const d1 = myMoment().startOf("day");
    const d2 = myMoment(dat).startOf("day");
    return d1.diff(d2, "day");
  },
  getDiffDaysCountActive: (dat) => {
    return myMoment(dat).diff(myMoment(), "day");
  },
  getDiffDaysCountBetween: (dat1, dat2) => {
    return dat1.diff(dat2, "day");
  },
  getDaysInMonth: (date) => {
    return myMoment(date).daysInMonth();
  },
  getDaysLeftInMonth: (date) => {
    return myMoment(date).daysInMonth() - myMoment(date).get("date");
  },
  getStartOfMonthByMonth: (month) => {
    return myMoment()
      .set("month", month)
      .startOf("month")
      .format("DD/MM/YYYY 00:00:00");
  },
  getStartOfMonth: (month, year) => {
    return myMoment()
      .set("month", month)
      .set("year", year)
      .startOf("month")
      .format("DD/MM/YYYY 00:00:00");
  },
  getStartOfMonthDate: (month, year) => {
    return myMoment()
      .set("month", month)
      .set("year", year)
      .set("hours", 0)
      .startOf("month");
  },
  getEndOfMonth: (month, year) => {
    return myMoment()
      .set("month", month)
      .set("year", year)
      .endOf("month")
      .format("DD/MM/YYYY 00:00:00");
  },
  getEndOfMonthDate: (month, year) => {
    return myMoment().set("month", month).set("year", year).endOf("month");
  },
  getDateDayCountFromDate: (today, dayCount = 1) => {
    return myMoment(today).add(dayCount, "day");
  },
  getLastWeekStart: (today) => {
    return myMoment(today).subtract(1, "weeks").startOf("week");
  },
  getLastWeekEnd: (today) => {
    return myMoment(today).subtract(1, "weeks").endOf("week");
  },
  getThisWeekStart: (today) => {
    return myMoment(today).startOf("week");
  },
  getThisWeekEnd: (today) => {
    return myMoment(today).endOf("week");
  },
  getThisMonthStart: (today) => {
    return myMoment(today).startOf("month");
  },
  getThisMonthEnd: (today) => {
    return myMoment(today).endOf("month");
  },
  getLastMonthStart: (today) => {
    return myMoment(today).subtract(1, "months").startOf("month");
  },
  getLastMonthEnd: (today) => {
    return myMoment(today).subtract(1, "months").endOf("month");
  },
  getThisYearStart: (today) => {
    return myMoment(today).startOf("year");
  },
  getThisYearEnd: (today) => {
    return myMoment(today).endOf("year");
  },
  getLastYearStart: () => {
    return myMoment().subtract(1, "year").startOf("year");
  },
  getLastYearEnd: () => {
    return myMoment().subtract(1, "year").endOf("year");
  },

  getThisYearStart2021: () => {
    return myMoment(myMoment("20.07.2021", "DD.MM.YYYY").toDate()).startOf(
      "year"
    );
  },
  getThisYearRange: () => {
    return [UtilDate.getThisYearStart2021(), UtilDate.getThisYearEnd()];
  },
  isTimeBetween: (serverTime, startTime, endTime) => {
    let start = myMoment(startTime, "H:mm");
    let end = myMoment(endTime, "H:mm");
    let server = myMoment(serverTime, "H:mm");
    if (end < start) {
      return (
        server >= (start && server <= myMoment("23:59:59", "h:mm:ss")) ||
        (server >= myMoment("0:00:00", "h:mm:ss") && server < end)
      );
    }
    return server >= start && server < end;
  },
  isBefore3AM: () => {
    return UtilDate.isTimeBetween(UtilDate.getDateToday(), "00:00:01", "03:00");
  },
  getMonthLongName: (month) => {
    let mom = myMoment();
    mom = mom.month(month);
    return mom.format("MMMM");
  },
  getMonthName: (value, isFull) => {
    if (!value) {
      value = myMoment();
    }
    if (isFull) {
      return value.format("MMMM");
    }
    return value.format("MMM");
  },
  getMonth: (value) => {
    if (!value) {
      value = myMoment();
    }
    return "" + value.month();
  },

  getDayOfMonth: (value) => {
    if (!value) {
      value = myMoment();
    }
    return myMoment(value).date();
  },
  getDayCountOfMonthByDate: (date) => {
    if (!date) {
      date = myMoment();
    }
    return date.daysInMonth();
  },
  getLastMonthWithSameDay: (value) => {
    if (!value) {
      value = myMoment();
    }
    return UtilDate.getEndDateToday(value).subtract(1, "months");
  },
  getEndDateToday: (date) => {
    if (!date) {
      date = myMoment();
    }
    return myMoment(date).endOf("day");
  },
  getMonthFullNameFormatWithoutYear: (date, isMonthFull) => {
    if (!date) {
      date = myMoment();
    }
    if (isMonthFull) {
      return myMoment(date).format("MMMM D");
    } else {
      return myMoment(date).format("MMM D");
    }
  },
  getWeekDayNameShort: (date) => {
    if (!date) {
      date = myMoment();
    }
    return myMoment(date).format("DD ddd");
  },
  getMonthFullNameFormat: (date) => {
    if (!date) {
      date = myMoment();
    }
    return date.format("MMM D, YYYY");
  },
  formatAsDaysAgo: (dd) => {
    const date = myMoment(dd);
    const now = dayjs();
    const diff = now.diff(date, "second");

    const seconds = Math.abs(Math.floor(diff));
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (seconds < 60) {
      return `${seconds} ${i18n.t("WORD_SECOND")}${
        seconds === 1 ? "" : i18n.t("WORD_S")
      } ${i18n.t("WORD_AGO")}`;
    } else if (minutes < 60) {
      return `${minutes} ${i18n.t("WORD_MINUTE")}${
        minutes === 1 ? "" : i18n.t("WORD_S")
      } ${i18n.t("WORD_AGO")}`;
    } else if (hours < 24) {
      return `${hours} ${i18n.t("WORD_HOUR")}${
        hours === 1 ? "" : i18n.t("WORD_S")
      } ${i18n.t("WORD_AGO")}`;
    } else if (days < 30) {
      return `${days} ${i18n.t("WORD_DAY")}${
        days === 1 ? "" : i18n.t("WORD_S")
      } ${i18n.t("WORD_AGO")}`;
    } else if (months < 12) {
      return `${months} ${i18n.t("WORD_MONTH")}${
        months === 1 ? "" : i18n.t("WORD_S")
      } ${i18n.t("WORD_AGO")}`;
    } else if (years === 1) {
      return i18n.t("WORD_ONE_YEAR_AGO");
    } else if (years > 1) {
      return `${years} ${i18n.t("WORD_YEARS_AGO")}`;
    } else {
      return date.format("MMMM D, YYYY");
    }
  },
  isSameOrAfter: (d1, d2) => {
    const date1 = dayjs(d1).startOf("day");
    const date2 = dayjs(d2).startOf("day");
    return date1.isSame(date2) || date1.isAfter(date2);
  },
  getSubtractDays: (today, dayCount) => {
    return myMoment(today).subtract(dayCount, "days");
  },
};

export default UtilDate;
