import React from "react";
import { ConfigProvider } from "antd";
import themeLightConfig from "./theme/themeLightConfig";
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import AppRoute from "./appRoute/AppRoute";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import UserActions from "./actions/UserActions";
import AppRouteBlocked from "./appRoute/AppRouteBlocked";
import "antd/dist/reset.css";
import "./App.scss";

export const history = createBrowserHistory({ basename: "/en" });

function App() {
  const currentUserStatusClient = useSelector((state) => {
    return state.currentUser?.statusClient;
  });

  return (
    <ConfigProvider theme={themeLightConfig}>
      <BrowserRouter
        history={history}
        future={{
          v7_relativeSplatPath: true,
          v7_startTransition: true,
        }}
      >
        {currentUserStatusClient !== UserActions.STATUS.BLOCKED ? (
          <AppRoute />
        ) : (
          <AppRouteBlocked />
        )}
        <ToastContainer />
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
