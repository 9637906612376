import React from "react";
import MyCard from "../../../components/myCard/MyCard";
import MyText from "../../../components/myText/MyText";
import { useTranslation } from "react-i18next";
import MyLink from "../../../components/myLink/MyLink";

const ForgotPasswordRecovery = () => {
  const { t } = useTranslation();

  return (
    <div className="outside-card-wrapper" style={{ textAlign: "center" }}>
      <MyCard withoutShadow>
        <h5 className="card-header-forgot">{t("WORD_PASSWORD_RECOVERY")}</h5>
        <div className="registration-finish-text-wrapper">
          <MyText className="registration-finish-text-second">
            {t("WORD_PASSOWRD_RECOVERY_PAGE_TEXT")}
          </MyText>
        </div>
        <MyLink bold path="/login" state={{ force: true }}>
          {t("WORD_BACK_TO_MAIN_PAGE")}
        </MyLink>
      </MyCard>
    </div>
  );
};

export default ForgotPasswordRecovery;
