import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
} from "chart.js";
import useWindowSize from "../../utils/useWindowSize";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

const DashboardSalesChart = ({ dataChart }) => {
  const { width } = useWindowSize();

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  // const labels = dataChart?.map((item) => item.month_name).reverse();
  // const salesData = dataChart?.map((item) => item.total_sales).reverse();
  const labels = dataChart?.map((item) => item.month_name);
  const salesData = dataChart?.map((item) => item.total_sales);

  const data = {
    labels,
    datasets: [
      {
        label: "Total Sales",
        data: salesData,
        backgroundColor: ["#8CD421"],
        barThickness: 25,
      },
    ],
  };

  return (
    <Bar
      options={options}
      width={width <= 1700 ? 200 : undefined}
      data={data}
    />
  );
};

export default DashboardSalesChart;
