import React from "react";
import { Form, DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import UtilDate from "../../utils/UtilDate";

const { RangePicker } = DatePicker;

const InputFormRangeDatePicker = ({
  label,
  name = "DateValue",
  required = false,
  placeholder = "Select Date",
  hidden,
  style,
  readOnly = false,
  allowClear = true,
  onChange,
  hasFeedback = false,
  format = "DD/MM/YYYY",
}) => {
  const { t } = useTranslation();

  const rangePresets = [
    {
      label: "Today",
      value: [UtilDate.getDateToday(), UtilDate.getDateToday()],
    },
    {
      label: "This Month",
      value: [UtilDate.getThisMonthStart(), UtilDate.getThisMonthEnd()],
    },
    {
      label: "Last 30 Days",
      value: [
        UtilDate.getSubtractDays(UtilDate.getDateToday(), 30),
        UtilDate.getDateToday(),
      ],
    },
    {
      label: "Last Month",
      value: [UtilDate.getLastMonthStart(), UtilDate.getLastMonthEnd()],
    },
  ];

  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        {
          required: required && !hidden,
          message: `${label || placeholder} ${t("WORD_IS_REQUIRED")}`,
        },
      ]}
      layout="vertical"
      hidden={hidden}
      hasFeedback={hasFeedback}
      style={style}
      className="input-range-picker"
    >
      <RangePicker
        format={format}
        // showNow={true}
        inputReadOnly={readOnly}
        allowClear={!readOnly || allowClear}
        className="my-date-picker"
        allowEmpty={[true, true]}
        placeholder={["From", "To"]}
        presets={rangePresets}
        onChange={(e) => {
          if (!readOnly) {
            const localDateFrom = e?.[0]
              ? e[0].startOf("day").format("YYYY-MM-DD")
              : null;
            const localDateTo = e?.[1]
              ? e[1].startOf("day").format("YYYY-MM-DD")
              : null;
            onChange && onChange(e, localDateFrom, localDateTo);
          }
        }}
      />
    </Form.Item>
  );
};

export default InputFormRangeDatePicker;
