import { Form, Radio } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import MySpace from "../../../components/mySpace/MySpace";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCardAlt } from "@fortawesome/free-regular-svg-icons";
import InvoiceActions from "../../../actions/InvoiceActions";
import MyText from "../../../components/myText/MyText";
import IdramSVG from "../../../icons/IdramSVG";
import MyDivider from "../../../components/myDivider/MyDivider";

const RadioVerticalPaymentMethod = ({
  value,
  onChange,
  name,
  label,
  hidden,
  className,
  required,
  placeholder,
}) => {
  const { t } = useTranslation();

  const RadioLabel = ({ faIcon, label, icon, value }) => {
    return (
      <MySpace fullWidth spaceBetween align="center">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {faIcon ? (
            <FontAwesomeIcon
              icon={faIcon}
              style={{ marginRight: 10, fontSize: 20 }}
              color="#A09F9F"
            />
          ) : (
            icon
          )}
          <MyText bold>{label}</MyText>
        </div>
        <Radio value={value} />
      </MySpace>
    );
  };

  const items = [
    {
      faIcon: faCreditCardAlt,
      label: t("WORD_CREDIT_CARD"),
      value: InvoiceActions.METHOD.ARCA,
    },
    {
      icon: <IdramSVG style={{ marginRight: 14 }} />,
      label: t("WORD_IDRAM"),
      value: InvoiceActions.METHOD.IDRAM,
    },
  ];

  return (
    <Form.Item
      name={name}
      label={label}
      hasFeedback={false}
      hidden={hidden}
      rules={[
        {
          required: required,
          message: `${t("WORD_ERROR_PLEASE_INPUT")} ${placeholder || label}!`,
        },
      ]}
      className={`app-select-radio ${className || ""}`}
    >
      <Radio.Group onChange={onChange} value={value}>
        {items.map((it, index) => {
          return (
            <div key={index}>
              <RadioLabel
                faIcon={it.faIcon}
                icon={it.icon}
                label={it.label}
                value={it.value}
              />
              {index + 1 !== items.length ? <MyDivider /> : null}
            </div>
          );
        })}
      </Radio.Group>
    </Form.Item>
  );
};

export default RadioVerticalPaymentMethod;
