import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import MyCard from "../../../components/myCard/MyCard";
import UtilNotify from "../../../utils/UtilNotify";
import AnalyticsActions from "../../../actions/AnalyticsActions";
import BasicChart from "../BasicChart";
import MySpace from "../../../components/mySpace/MySpace";
import MyTextAnalyticsStatsCard from "../../../components/myText/MyTextAnalyticsStatsCard";
import { Flex } from "antd";

const AnalyticsCustomerBasicChartSection = (props) => {
  const { t } = useTranslation();

  const chartRef = useRef(null);

  const [listData, setlistData] = useState([]);
  const [loading, setloading] = useState(false);
  const [chartData, setchartData] = useState({ datasets: [], labels: [] });

  useEffect(() => {
    const fill = async () => {
      setloading(true);
      const result = await AnalyticsActions.customerCountByMonthList();
      if (result.success) {
        setlistData(result.data);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setloading(false);
    };
    fill();
  }, []);

  useEffect(() => {
    const chartData2 = () => {
      const safeListData = listData || [];

      const gradientOne = () => {
        const chart = chartRef.current;
        const ctxCanvas = chart?.ctx;
        if (!ctxCanvas) return "rgba(103, 65, 250, 0.3)"; // Fallback color
        const gradient = ctxCanvas.createLinearGradient(
          0,
          0,
          0,
          ctxCanvas?.height || 200
        );
        gradient.addColorStop(0, "rgba(103, 65, 250, 0.3)");
        gradient.addColorStop(1, "rgba(1, 168, 241, 0.05)");

        return gradient;
      };

      const result = {
        labels: safeListData?.map((it) => {
          return t(it.monthName);
        }),
        datasets: [
          {
            id: 1,
            label: t("WORD_CUSTOMERS"),
            data: safeListData?.map((it) => {
              return it.count;
            }),
            fill: true,
            backgroundColor: gradientOne(),
            borderColor: "#5861f7",
            tension: 0.3,
          },
        ],
      };
      return result;
    };
    setchartData(chartData2());
  }, [listData, chartRef, t]);

  return (
    <MyCard loading={loading}>
      <MySpace
        direction="vertical"
        fullWidth
        className="analytics-space-chart-parent"
        size="middle"
      >
        <MyTextAnalyticsStatsCard bold>
          {t("WORD_LAST_12_MONTHS")}
        </MyTextAnalyticsStatsCard>
        <Flex gap={10}>
          <div className="statsCard__amountHolder--amount--label label-wrapper">
            <div className="value-name value-name-color1"></div>
            {t("WORD_CUSTOMERS")}
          </div>
        </Flex>
        <BasicChart ref={chartRef} data={chartData} />
      </MySpace>
    </MyCard>
  );
};

export default AnalyticsCustomerBasicChartSection;
