import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import MyModal from "../../../components/myModal/MyModal";
import ActivityRightCardRow from "../../../components/activityRightCardRow/ActivityRightCardRow";
import UtilDate from "../../../utils/UtilDate";

const DeviceViewModal = forwardRef((props, ref) => {
  const refModal = useRef();
  const { t } = useTranslation();
  const [device, setDevice] = useState({});

  useImperativeHandle(ref, () => ({
    viewDevice: (devi) => {
      setDevice(devi);
      refModal.current.open();
    },
  }));

  return (
    <MyModal ref={refModal} title={t("WORD_DEVICE_DETAILS")} hideFooter>
      <ActivityRightCardRow
        noSpace
        title={t("WORD_MAC_ADDRESS")}
        bold
        data={device.macAddress}
      />
      <ActivityRightCardRow
        noSpace
        title={t("WORD_CREATE_DATE")}
        bold
        data={UtilDate.formatDateTime(device.createDate)}
      />
      <ActivityRightCardRow
        noSpace
        title={t("WORD_DEVICE_NAME")}
        bold
        data={device.deviceName}
      />
      <ActivityRightCardRow
        noSpace
        title={t("WORD_SERIAL_NUMBER")}
        bold
        data={device.serialNumber}
      />
      <ActivityRightCardRow
        noSpace
        title={t("WORD_ADDITIONAL")}
        bold
        data={device.additional}
      />
    </MyModal>
  );
});

export default DeviceViewModal;
