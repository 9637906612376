import React from "react";
import MyTooltip from "../myTooltip/MyTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MyButton from "./MyButton";
import "./MyButton.scss";

const MyButtonTableAction = ({
  title,
  onClick,
  icon,
  size,
  antdIcon,
  ...otherProps
}) => {
  return (
    <MyTooltip title={title}>
      <MyButton
        {...otherProps}
        className="my-button-action action-gray"
        type="primary"
        onClick={onClick}
        icon={
          antdIcon ? (
            icon
          ) : (
            <FontAwesomeIcon icon={icon} color="#fff" size={size || "xs"} />
          )
        }
      />
    </MyTooltip>
  );
};

export default MyButtonTableAction;
