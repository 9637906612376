import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UtilNotify from "../../utils/UtilNotify";
import CustomerActions from "../../actions/CustomerActions";
import MySelectFormMultiItem from "../mySelectFormMultiItem/MySelectFormMultiItem";

const SelectCustomerMulti = ({ withAll, ...otherProps }) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    const fill = async () => {
      const fetchParams = {
        page: 0,
        perPage: 10000,
        search: "",
        filter: {},
      };
      const result = await CustomerActions.fillPagination(fetchParams);
      if (result.success) {
        let data = result.data.map((it) => ({
          label: it.name,
          value: "" + it.idCustomer,
          ...it,
        }));
        // if (withAll) data.unshift({ label: t("WORD_ALL"), value: "" });
        setlist(data);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    };
    fill();
  }, [t, withAll]);

  // const handleChange = () => {
  //   setpage(page + 1);
  //   setPerpage(perPage + 10);
  // };

  return (
    <MySelectFormMultiItem
      {...otherProps}
      showSearch
      options={list}
      // onSearch={setSearch}
    />
  );
};

export default SelectCustomerMulti;
