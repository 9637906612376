import React, { useEffect, useMemo, useState } from "react";
import MyCard from "../../components/myCard/MyCard";
import MySpace from "../../components/mySpace/MySpace";
import MyTextAnalyticsStatsCard from "../../components/myText/MyTextAnalyticsStatsCard";
import { useTranslation } from "react-i18next";
import MyTable from "../../components/myTable/MyTable";
import { useSelector } from "react-redux";
import AnalyticsActions from "../../actions/AnalyticsActions";
import UtilNotify from "../../utils/UtilNotify";
import UtilNumber from "../../utils/UtilNumber";
import { Flex } from "antd";

const AnalyticsBySortSection = ({ title, filter }) => {
  const { t } = useTranslation();

  const currency = useSelector((state) => {
    return state.currentUser?.currency;
  });

  const [data, setdata] = useState([]);

  let colorRed = "#e24747";
  let colorGreen = "#059857";

  useEffect(() => {
    const fill = async () => {
      const params = {
        filter: filter,
      };
      const result = await AnalyticsActions.order_by_payment_type_list(params);
      if (result.success) {
        setdata(result.data);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    };
    fill();
  }, [filter]);

  const columns = useMemo(() => {
    let col = [
      {
        title: t("WORD_PAYMENT_METHOD"),
        dataIndex: "name",
        key: "name",
      },
      {
        title: t("WORD_ORDER"),
        dataIndex: "count",
        key: "count",
        align: "center",
        width: 250,
      },
      {
        title: t("WORD_AMOUNT"),
        dataIndex: "totalAmount",
        key: "totalAmount",
        align: "center",
        width: 250,
        render: (value, row) => {
          let percentageValue = UtilNumber.calcTwoNumbersPercentage(
            row?.p?.totalAmount,
            value
          );
          let isNegative = String(percentageValue)?.startsWith("-");
          return (
            <Flex vertical align="center">
              <strong>{`${currency}${UtilNumber.formatWithK(value)}`}</strong>
              <Flex>
                <div
                  style={{
                    color:
                      parseInt(percentageValue) === 0
                        ? "unset"
                        : isNegative
                        ? colorRed
                        : colorGreen,
                  }}
                >
                  {`${percentageValue}% (${currency}${UtilNumber.formatWithK(
                    row?.p?.totalAmount
                  )})`}
                </div>
              </Flex>
            </Flex>
          );
        },
      },
    ];
    return col;
  }, [t, currency, colorRed, colorGreen]);

  return (
    <MyCard>
      <MySpace direction="vertical" fullWidth>
        <MyTextAnalyticsStatsCard bold>{title}</MyTextAnalyticsStatsCard>
        <MyTable
          columns={columns}
          dataSource={data}
          className="analytics-table"
          rowKey={(it) => it.idPaymentType}
          noFooter
        />
      </MySpace>
    </MyCard>
  );
};

export default AnalyticsBySortSection;
