import React, { useEffect, useRef, useState } from "react";
import MySpace from "../../../components/mySpace/MySpace";
import { useTranslation } from "react-i18next";
import MyDivider from "../../../components/myDivider/MyDivider";
import { useForm } from "antd/es/form/Form";
import InputTextAreaFormItem from "../../../components/myInputForm/InputTextAreaFormItem";
import InputTextFormItem from "../../../components/myInputForm/InputTextFormItem";
import { useParams } from "react-router-dom";
import InputEmailFormItem from "../../../components/myInputForm/InputEmailFormItem";
import InputNumberFormItem from "../../../components/myInputForm/InputNumberFormItem";
import BranchActions from "../../../actions/BranchActions";
import UtilNotify from "../../../utils/UtilNotify";
import SelectInvoiceType from "../../../components/selects/SelectInvoiceType";
import MyTextSecondary from "../../../components/myText/MyTextSecondary";
import useNavigateApi from "../../../appRoute/useNavigateApi";
import { Form } from "antd";
import EditPageForm from "../../../components/editPageForm/EditPageForm";
import MyButtonConfirm from "../../../components/myButton/MyButtonConfirm";
import MyButtonDelete from "../../../components/myButton/MyButtonDelete";
import BranchChangePasswordModal from "./BranchChangePasswordModal";

const formName = "formBranchEdit";

const BranchEdit = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const navigateApi = useNavigateApi();
  const refModal = useRef();
  const status = Form.useWatch("status", form);

  const [loading, setloading] = useState(true);
  const [saving, setSaving] = useState(false);

  const { _idBranch } = useParams();

  const goBack = () => {
    navigateApi(`/branch/list`);
  };

  const onFinish = async (values) => {
    setSaving(true);
    const result = await BranchActions.updateBranch(values, _idBranch);
    if (result.success) {
      UtilNotify.notifySuccess(t("WORD_BRANCH_UPDATED_SUCCESSFULLY"));
      goBack();
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    setSaving(false);
  };

  useEffect(() => {
    const fill = async () => {
      setloading(true);
      const resultGetOne = await BranchActions.getOne(_idBranch);
      if (resultGetOne.success) {
        form?.setFieldsValue(resultGetOne.data);
      } else {
        UtilNotify.notifyErrorServer(resultGetOne.errMsg);
      }
      setloading(false);
    };
    fill();
  }, [_idBranch, form]);

  const handleStatusBlock = async () => {
    let values = { status: 0 };
    const result = await BranchActions.updateStatus(values, _idBranch);
    if (result.success) {
      UtilNotify.notifySuccess(t("WORD_BRANCH_BLOCKED_SUCCESSFULLY"));
      goBack();
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
  };

  const handleStatusUnblock = async () => {
    let values = { status: 1 };
    const result = await BranchActions.updateStatus(values, _idBranch);
    if (result.success) {
      UtilNotify.notifySuccess(t("WORD_BRANCH_UNBLOCKED_SUCCESSFULLY"));
      goBack();
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
  };

  const getTitle = () => {
    return `${t("WORD_BRANCH")} ${
      _idBranch ? t("WORD_EDIT") : t("WORD_CREATE")
    }`;
  };

  return (
    <>
      <EditPageForm
        title={getTitle()}
        form={form}
        formName={formName}
        onFinish={onFinish}
        saving={saving}
        onBack={goBack}
        loading={loading}
        withCancel
        extraButtons={
          <>
            {status === BranchActions.STATUS.BLOCKED ? (
              <MyButtonConfirm
                label={t("WORD_UNBLOCK")}
                onConfirm={handleStatusUnblock}
                title={t("WORD_ARE_YOU_SURE_YOU_WANT_TO_UNBLOCK")}
              />
            ) : (
              <MyButtonDelete
                blockBtn
                onConfirm={handleStatusBlock}
                label={t("WORD_BLOCK")}
              />
            )}
          </>
        }
      >
        <MyTextSecondary>{t("WORD_PLEASE_FILL_BRANCH")}</MyTextSecondary>
        <InputTextFormItem label={t("WORD_NAME")} name="fName" required />
        <InputEmailFormItem label={t("WORD_EMAIL")} name="email" required />
        <InputTextFormItem
          label={t("WORD_USERNAME")}
          name="username"
          required
        />
        <MySpace fullWidth className="select-row">
          <InputNumberFormItem
            label={t("WORD_DEVICE_COUNT")}
            name="deviceCount"
          />
          <SelectInvoiceType
            label={t("WORD_INVOICE_TYPE")}
            name="invoiceType"
            placeholder={t("WORD_INVOICE_TYPE")}
          />
        </MySpace>
        <MySpace fullWidth className="select-row" align="top">
          <InputTextFormItem withMinMax name="phone" label={t("WORD_PHONE")} />
          <InputTextFormItem
            withMinMax
            name="number"
            label={t("WORD_NUMBER")}
          />
        </MySpace>
        <MyDivider />
        <InputTextFormItem label={t("WORD_LEGAL_NAME")} name="legalName" />
        <MySpace fullWidth className="select-row">
          <InputTextFormItem label={t("WORD_TIN")} name="tin1" />
          <InputNumberFormItem
            name="vatRate"
            label={t("WORD_VAT_RATE")}
            suffix="%"
            readOnly
          />
        </MySpace>
        <InputTextFormItem
          label={t("WORD_LEGAL_ADDRESS")}
          name="legalAddress"
        />
        <InputTextFormItem
          label={t("WORD_SUPPLY_LOCATION")}
          name="supplylocation"
        />
        <MySpace fullWidth className="select-row">
          <InputTextFormItem label={t("WORD_BANK_NAME")} name="bankName" />
          <InputTextFormItem label={t("WORD_BANK_NUMBER")} name="bankAddress" />
        </MySpace>
        <InputTextAreaFormItem
          label={t("WORD_DESCRIPTION")}
          name="description"
        />
        <InputTextFormItem name="status" hidden />
      </EditPageForm>
      <BranchChangePasswordModal ref={refModal} />
    </>
  );
};

export default BranchEdit;
