import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { useTranslation } from "react-i18next";
import MyModal from "../../../components/myModal/MyModal";
import FormApp from "../../../components/formApp/FormApp";
import { useForm } from "antd/es/form/Form";
import MySpace from "../../../components/mySpace/MySpace";
import SelectOrderBranch from "../../../components/selects/SelectOrderBranch";
import UploadFilesManuelModal from "../../../components/uploads/UploadFilesManualModal";

const formName = "formProductUpload";

const ProductImportModal = forwardRef((props, ref) => {
  const refModal = useRef();
  const { t } = useTranslation();
  const [form] = useForm();
  const inputRef = useRef();

  useImperativeHandle(ref, () => ({
    viewProduct: () => {
      refModal.current.open();
    },
  }));

  const onFinish = (values) => {
    inputRef.current.startUpload(`products/uploadfileXlsx/${values.idBranch}`);
  };

  return (
    <MyModal
      ref={refModal}
      title={t("WORD_IMPORT_PRODUCTS_FROM_FILE")}
      labelOk={t("WORD_UPLOAD")}
      width={630}
      onOk={() => form.submit()}
      success
    >
      <FormApp form={form} name={formName} onFinish={onFinish}>
        <MySpace direction="vertical" fullWidth size="middle">
          <div className="upload-select-row">
            <SelectOrderBranch
              name="idBranch"
              placeholder={t("WORD_SELECT_BRANCH")}
              required
              onLoad={(val) => {
                form.setFieldsValue({ idBranch: val });
              }}
            />
            <UploadFilesManuelModal
              ref={inputRef}
              placeholder={t("WORD_FILE")}
              className="file-xml-upload"
              name="productfile"
              fileTypes={[".xlsx"]}
              required
              onChange={(e) => {
                // form.resetFields();
                refModal.current.close();
              }}
            />
          </div>
          <MySpace align="top">
            <div>{t("WORD_DOWNLOAD_DESCRIPTION", { FILETYPE: "XLSX" })}</div>
            <div
              className="xml-download-btn"
              onClick={() => {
                const fileUrl =
                  process.env.PUBLIC_URL + "/Templates/product.xlsx";
                fetch(fileUrl)
                  .then((response) => response.blob())
                  .then((blob) => {
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = "product.xlsx";
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    URL.revokeObjectURL(url);
                  })
                  .catch((error) =>
                    console.error("Error downloading file:", error)
                  );
              }}
            >
              {t("WORD_DOWNLOAD")}
            </div>
          </MySpace>
        </MySpace>
      </FormApp>
    </MyModal>
  );
});

export default ProductImportModal;
