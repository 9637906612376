import React from "react";
import { useTranslation } from "react-i18next";
import MyHelmet from "../../../components/helmet/MyHelmet";
import MySpace from "../../../components/mySpace/MySpace";
import MyPageHeader from "../../../components/myPageHeader/MyPageHeader";
import AnalyticsOrdersFilterDrawer from "../orders/AnalyticsOrdersFilterDrawer";
import AnalyticsCustomersCard from "./AnalyticsCustomersCard";
import { useSelector } from "react-redux";
import AnalyticsCustomersRouteCard from "./AnalyticsCustomersRouteCard";
import { Flex } from "antd";
import AnalyticsCustomersRouteListCard from "./AnalyticsCustomersRouteListCard";
import AnalyticsCustomerByPlace from "./AnalyticsCustomerByPlace";
import AnalyticsCustomerBasicChartSection from "./AnalyticsCustomerBasicChartSection";

const AnalyticsCustomers = () => {
  const { t } = useTranslation();

  const filter = useSelector((state) => {
    return state.analyticsFilters;
  });

  return (
    <>
      <MyHelmet title={t("WORD_ANALYTICS_CUSTOMERS")} />
      <Flex vertical gap={16}>
        <MyPageHeader
          inPage
          extraRight={
            <MySpace>
              <AnalyticsOrdersFilterDrawer />
            </MySpace>
          }
          title={t("WORD_ANALYTICS_CUSTOMERS")}
        />
        <Flex gap={16} justify="space-between">
          <AnalyticsCustomersCard filter={filter} />
          <AnalyticsCustomersRouteCard filter={filter} />
        </Flex>
        <AnalyticsCustomerBasicChartSection />
        <Flex gap={16} justify="space-between">
          <AnalyticsCustomersRouteListCard filter={filter} />
          <AnalyticsCustomerByPlace filter={filter} />
        </Flex>
      </Flex>
    </>
  );
};

export default AnalyticsCustomers;
