import { Statistic } from "antd";
import React from "react";

const MyStatistic = ({ value, style, suffix, prefix, className }) => {
  return (
    <Statistic
      prefix={prefix}
      suffix={suffix}
      value={value || 0}
      valueStyle={style}
      className={className}
    />
  );
};

export default MyStatistic;
