import React from "react";
import { useTranslation } from "react-i18next";
import AdminSVG from "../../../icons/AdminSVG";
import NewUserPage from "../../../components/newUserPage/NewUserPage";

const TeamNewUserPage = () => {
  const { t } = useTranslation();

  return (
    <NewUserPage
      title={t("WORD_SALES_TEAM")}
      text={t("WORD_SALES_TEAM_INTRO_TEXT")}
      icon={<AdminSVG size={150} fill={"#c2cada"} />}
    />
  );
};

export default TeamNewUserPage;
