import React, { memo, useState } from "react";
import { Layout, Space } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import useWindowSize from "../../utils/useWindowSize";
import MenuHeader from "./MenuHeader";
import SideBarMenu from "./SideBarMenu";
import MyDrawer from "../../components/myDrawer/MyDrawer";
import MyDivider from "../../components/myDivider/MyDivider";
import "./HeaderNav.scss";

const { Sider } = Layout;

const MainLayoutSideMenu = () => {
  const { isMobile } = useWindowSize();

  const [menuOpen, setmenuOpen] = useState(false);

  return isMobile ? (
    <MyDrawer
      className="ant-drawer-left-menu-mobile"
      closable={false}
      width={"70%"}
      onClose={setmenuOpen}
      open={menuOpen}
      btnClassName="mobile-menu-btn"
      icon={<MenuOutlined />}
      placement="left"
      noExtraBtn
    >
      <MenuHeader />
      <MyDivider style={{ borderColor: "rgba(255, 255, 255, 0.1)" }} />
      <SideBarMenu />
    </MyDrawer>
  ) : (
    <Sider width={260} className="main-sider">
      <Space.Compact direction="vertical" block="true">
        <MenuHeader />
        <MyDivider style={{ borderColor: "rgba(255, 255, 255, 0.1)" }} />
        <SideBarMenu />
      </Space.Compact>
    </Sider>
  );
};

export default memo(MainLayoutSideMenu);
