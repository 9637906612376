import React, { memo } from "react";
import MyIcon from "./MyIcon";

export const SVGIcon = ({ style, size, fill }) => {
  return (
    <svg
      width={size || 48}
      height={size || 48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M18.6987 29.7155C10.7711 29.7155 4 30.9401 4 35.8347C4 40.7332 10.728 42 18.6987 42C26.6263 42 33.3973 40.7754 33.3973 35.8807C33.3973 30.9823 26.6693 29.7155 18.6987 29.7155Z"
        fill={fill || "white"}
      ></path>
      <path
        opacity="0.4"
        d="M18.6987 25.0496C24.0979 25.0496 28.4247 20.8123 28.4247 15.5248C28.4247 10.2373 24.0979 6 18.6987 6C13.3014 6 8.97261 10.2373 8.97261 15.5248C8.97261 20.8123 13.3014 25.0496 18.6987 25.0496Z"
        fill={fill || "white"}
      ></path>
      <path
        opacity="0.4"
        d="M32.3468 15.6975C32.3468 18.3901 31.521 20.9026 30.0729 22.9896C29.9222 23.2043 30.0552 23.4937 30.3175 23.5397C30.6815 23.5991 31.0553 23.6355 31.4369 23.6431C35.2333 23.7409 38.6404 21.3472 39.5817 17.7424C40.977 12.3935 36.8831 7.59085 31.6678 7.59085C31.1022 7.59085 30.5601 7.64834 30.0318 7.75375C29.9594 7.76908 29.8811 7.80358 29.8419 7.86491C29.7911 7.94348 29.8282 8.04505 29.8791 8.11213C31.4466 10.2643 32.3468 12.8841 32.3468 15.6975Z"
        fill={fill || "white"}
      ></path>
      <path
        d="M43.5582 30.3387C42.8635 28.8879 41.1864 27.8933 38.6345 27.4046C37.431 27.1171 34.1707 26.7089 31.1394 26.7664C31.0944 26.7722 31.0689 26.8028 31.065 26.822C31.0592 26.8527 31.0729 26.8986 31.1316 26.9312C32.5327 27.6097 37.9476 30.561 37.2666 36.7857C37.2373 37.0578 37.4584 37.2878 37.7343 37.2495C39.067 37.0636 42.4956 36.3411 43.5582 34.095C44.1473 32.9068 44.1473 31.5269 43.5582 30.3387Z"
        fill={fill || "white"}
      ></path>
    </svg>
  );
};

const AdminSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(AdminSVG);
