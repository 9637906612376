import React, { memo } from "react";
import MyIcon from "./MyIcon";

export const SVGIcon = ({ size, fill }) => {
  return (
    <svg
      className="icon"
      width={size || 49}
      height={size || 49}
      viewBox="0 0 49 49"
      fill={fill || "#fff"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.9686 38.4663C16.6286 38.4663 17.9886 39.8263 17.9886 41.5063C17.9886 43.1663 16.6286 44.5263 14.9686 44.5263C13.2886 44.5263 11.9286 43.1663 11.9286 41.5063C11.9286 39.8263 13.2886 38.4663 14.9686 38.4663ZM37.4686 38.4663C39.1286 38.4663 40.4886 39.8263 40.4886 41.5063C40.4886 43.1663 39.1286 44.5263 37.4686 44.5263C35.7886 44.5263 34.4286 43.1663 34.4286 41.5063C34.4286 39.8263 35.7886 38.4663 37.4686 38.4663Z"></path>
      <path d="M5.66517 5.32887L5.86899 5.34534L10.639 6.06534C11.319 6.18734 11.819 6.74534 11.879 7.42534L12.259 11.9053C12.319 12.5473 12.839 13.0273 13.479 13.0273H40.489C41.709 13.0273 42.509 13.4473 43.309 14.3673C44.109 15.2873 44.249 16.6073 44.069 17.8053L42.169 30.9253C41.809 33.4473 39.649 35.3053 37.109 35.3053H15.289C12.629 35.3053 10.429 33.2653 10.209 30.6273L8.36899 8.82534L5.34899 8.30534C4.54899 8.16534 3.98899 7.38534 4.12899 6.58534C4.26899 5.76534 5.04899 5.22534 5.86899 5.34534L5.66517 5.32887ZM33.909 20.4253H28.369C27.529 20.4253 26.869 21.0853 26.869 21.9253C26.869 22.7453 27.529 23.4253 28.369 23.4253H33.909C34.749 23.4253 35.409 22.7453 35.409 21.9253C35.409 21.0853 34.749 20.4253 33.909 20.4253Z"></path>
    </svg>
  );
};

const ShoppingCartSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(ShoppingCartSVG);
