import React, { forwardRef, useImperativeHandle } from "react";
import { YMaps, Map } from "react-yandex-maps";

const MyMapYandex = forwardRef(
  (
    { width, height, children, onLoadMap, modules, instanceRef, onDoubleClick },
    ref
  ) => {
    useImperativeHandle(
      ref,
      () => ({
        centerMap: () => {
          if (instanceRef && instanceRef.current) {
            instanceRef.current.setBounds(
              instanceRef.current.geoObjects.getBounds()
            );
            instanceRef.current.setZoom(8);
          }
        },
        getGeoObjects: () => {
          return instanceRef?.current?.geoObjects;
        },
      }),
      [instanceRef]
    );

    const handleDoubleClick = (event) => {
      const coords = event.get("coords");
      console.warn("coords :>> ", coords);
      onDoubleClick && onDoubleClick(coords);
      // setPlacemarks([...placemarks, coords]);
    };

    return (
      <YMaps
        enterprise
        query={{
          lang: "en_RU",
          apikey: process.env.REACT_APP_YANDEX_MAP_APIKEY,
        }}
      >
        <Map
          width={width}
          height={height}
          defaultState={{
            center: [40.1772, 44.50349],
            zoom: 9,
          }}
          modules={modules}
          onLoad={onLoadMap}
          instanceRef={instanceRef}
          onDblClick={handleDoubleClick}
        >
          {children}
        </Map>
      </YMaps>
    );
  }
);

export default MyMapYandex;
