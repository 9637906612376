import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import MySpace from "../../../../components/mySpace/MySpace";
import MyPageHeader from "../../../../components/myPageHeader/MyPageHeader";
import MyButtonSecondary from "../../../../components/myButton/MyButtonSecondary";
import { useParams } from "react-router-dom";
import { Menu } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import RouteActions from "../../../../actions/RouteActions";
import UtilNotify from "../../../../utils/UtilNotify";
import UserRouteEditModal from "./UserRouteEditModal";
import UserListGroupClone from "./UserRouteCloneModal";
import { PlusOutlined } from "@ant-design/icons";
import UserActions from "../../../../actions/UserActions";
import MyTooltip from "../../../../components/myTooltip/MyTooltip";
import ImportWhiteSVG from "../../../../icons/ImportWhiteSVG";
import CloneGroupSVG from "../../../../icons/CloneGroupSVG";
import useNavigateApi from "../../../../appRoute/useNavigateApi";
import MyButtonTableActionDelete from "../../../../components/myButton/MyButtonTableActionDelete";
import MyButtonTableAction from "../../../../components/myButton/MyButtonTableAction";
import "../UserList.scss";

const UserRouteList = ({ children }) => {
  const { _idManager, _idRoute } = useParams();

  const { t } = useTranslation();
  const refModal = useRef();
  const refModalSecond = useRef();
  const navigateApi = useNavigateApi();

  // const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [manager, setmanager] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    const getManager = async () => {
      if (_idManager) {
        const resultGetManager = await UserActions.getOne(_idManager);
        if (resultGetManager.success) {
          setmanager(resultGetManager.data);
        } else {
          UtilNotify.notifyErrorServer(resultGetManager.errMsg);
        }
      }
    };
    getManager();
  }, [_idManager]);

  const handleRemove = async () => {
    if (_idRoute) {
      const result = await RouteActions.removeRoute(_idRoute);
      if (result.success) {
        UtilNotify.notifySuccess(t("WORD_REMOVED_SUCCESSFULLY"));
        await fillRoute();
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    }
  };

  const handleEdit = () => {
    if (_idRoute) refModal?.current?.viewGroup(_idRoute);
  };

  const handleExport = async () => {
    if (_idRoute) RouteActions.exportExcel(_idRoute);
  };

  const handleClone = () => {
    if (_idRoute) refModalSecond?.current?.viewClone(_idRoute);
  };

  const fillRoute = useCallback(async () => {
    // setLoading(true);
    const result = await RouteActions.getRoutes(_idManager);
    if (result.success) {
      const items = result.data.map((oo) => {
        const idRoute = `${oo.idRoute}`;
        return {
          key: idRoute,
          label: oo.name,
          onClick: () => {
            navigateApi(`/user/route/${_idManager}/${idRoute}`);
          },
        };
      });
      items.unshift({
        key: "0",
        label: "All",
        onClick: () => {
          navigateApi(`/user/route/${_idManager}`);
        },
      });
      setData(items);
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    // setLoading(false);
  }, [_idManager, navigateApi]);

  useEffect(() => {
    fillRoute();
  }, [fillRoute]);

  const getTitle = () => {
    const tWordCustomers = t("WORD_CUSTOMERS");
    let title = "";
    if (manager?.fName) {
      title += manager.fName;
    }
    if (manager?.lName) {
      title += (title ? " " : "") + manager.lName;
    }
    return title + (title ? " " : "") + tWordCustomers;
  };

  const hasSelected = selectedRowKeys?.length > 0;

  return (
    <>
      <MySpace direction="vertical" size={"middle"} fullWidth>
        <MyPageHeader
          title={getTitle()}
          onBack={() => navigateApi(`/user/list`)}
          extraRight={
            !_idRoute ? null : (
              <MySpace>
                <MyButtonTableAction
                  antdIcon
                  title={t("WORD_EXPORT_GROUP")}
                  icon={<ImportWhiteSVG size={18} />}
                  onClick={handleExport}
                />
                <MyButtonTableAction
                  antdIcon
                  title={t("WORD_CLONE_GROUP")}
                  icon={<CloneGroupSVG size={18} />}
                  onClick={handleClone}
                />
                <MyButtonTableAction
                  title={t("WORD_EDIT_GROUP")}
                  icon={faPen}
                  onClick={handleEdit}
                />
                <MyTooltip title={t("WORD_REMOVE_GROUP")}>
                  <MyButtonTableActionDelete
                    title={t("WORD_ARE_YOU_SURE_YOU_WANT_TO_REMOVE")}
                    okText={t("WORD_REMOVE")}
                    icon={
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        color="#fff"
                        size="xs"
                      />
                    }
                    onConfirm={handleRemove}
                  />
                </MyTooltip>
              </MySpace>
            )
          }
          extraLeft={
            <div>
              {hasSelected
                ? `${t("WORD_SELECTED")} ${selectedRowKeys?.length} ${
                    selectedRowKeys?.length > 1
                      ? t("WORD_ITEMS")
                      : t("WORD_ITEM")
                  }`
                : ""}
            </div>
          }
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            gap: 8,
          }}
        >
          <MySpace direction="vertical">
            <MyPageHeader
              title={t("WORD_GROUP")}
              className="user-side-menu-header"
              extraRight={
                <MyButtonSecondary
                  className="seconday-btn"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    refModal?.current?.viewGroup(0);
                  }}
                />
              }
            />
            <Menu
              theme="light"
              className="user-side-menu"
              mode="vertical"
              items={data}
              selectedKeys={["" + (_idRoute || 0)]}
            />
            <UserRouteEditModal
              ref={refModal}
              idManager={_idManager}
              nameManager={manager?.fName}
              onChange={fillRoute}
            />
            <UserListGroupClone ref={refModalSecond} />
          </MySpace>
          <div style={{ width: "100%" }}>
            {React.isValidElement(children)
              ? React.cloneElement(children, {
                  selectedRowKeys,
                  setSelectedRowKeys,
                })
              : children}
          </div>
        </div>
      </MySpace>
    </>
  );
};

export default UserRouteList;
