import React from "react";
import { useTranslation } from "react-i18next";
import MyTextAnalyticsStatsCard from "../../../components/myText/MyTextAnalyticsStatsCard";
import MyCard from "../../../components/myCard/MyCard";
import MySpace from "../../../components/mySpace/MySpace";
import MyStatistic from "../../../components/myStatistic/MyStatistic";
import UtilNumber from "../../../utils/UtilNumber";

const AnalyticsCustomersCardBlock = ({ loading, title, icon, value }) => {
  const { t } = useTranslation();

  return (
    <MyCard loading={loading} style={{ width: "50%", minHeight: 130 }}>
      <MySpace fullWidth>
        {icon}
        <MyTextAnalyticsStatsCard bold>{title}</MyTextAnalyticsStatsCard>
      </MySpace>
      <div>
        <MyTextAnalyticsStatsCard size={12}>
          {t("WORD_QUANTITY")}
        </MyTextAnalyticsStatsCard>
        <MyStatistic
          style={{ fontWeight: "bold" }}
          value={UtilNumber.formatDouble(value)}
          className="card-stat"
        />
      </div>
    </MyCard>
  );
};

export default AnalyticsCustomersCardBlock;
