import React from "react";
import MyButtonMain from "./MyButtonMain";

const MyButtonBlue = ({ ...otherProps }) => {
  return (
    <MyButtonMain {...otherProps} type="primary" className="my-btn-blue" />
  );
};

export default MyButtonBlue;
