import { fetchFromUrlPOSTAsync } from "../utils/UrlHelper";

const URL_LIST = "city/list";

const fs = {
  fillList: async (_idRegion) => {
    const result = await fetchFromUrlPOSTAsync(URL_LIST + '/' + _idRegion, []);
    return result;
  },
};

const loc = {
  fillListOptions: async (_idRegion) => {
    const result = await CityActions.fillList(_idRegion);
    if (result.success) {
      result.data = result.data.map((it) => {
        return {
          label: it.name,
          value: it.idCity,
          ...it,
        };
      });
    }
    return result;
  },
};

const CityActions = Object.assign(fs, loc);

export default CityActions;
