import { fetchFromUrlPOSTAsync } from "../utils/UrlHelper";

const URL_FILL_PAGINATION = "visits/list";
const URL_GET_ONE = "visits/getOne";

const fs = {
  fillPagination: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_FILL_PAGINATION, urlParams);
    return result;
  },
  getOne: async (idManagerVisit) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_GET_ONE + "/" + idManagerVisit,
      []
    );
    return result;
  },
};

const loc = {
  TYPE: {
    DEFAULT: 1,
    WITH_ORDER: 2,
  },
  getTypeLabel: (type) => {
    switch (type) {
      case 1:
        return { label: "Visit", color: "#268AFF" };
      case 2:
        return { label: "Pending", color: "#92cf5c" };
      default:
        return { label: "", color: "" };
    }
  },
};

const VisitsActions = Object.assign(fs, loc);

export default VisitsActions;
