import React, { createContext, useEffect, useState } from "react";
import { Layout } from "antd";
import SettingsMenu from "./SettingsMenu";
import UserActions from "../../actions/UserActions";
import { useSelector } from "react-redux";
import UtilNotify from "../../utils/UtilNotify";
import LoadingIconApp from "../../components/loadingIconApp/LoadingIconApp";
import useWindowSize from "../../utils/useWindowSize";
import MyDrawer from "../../components/myDrawer/MyDrawer";
import { SettingOutlined } from "@ant-design/icons";

const { Content } = Layout;

export const UserContext = createContext();

const SettingsLayout = ({ children, fullPage }) => {
  const { isMobile } = useWindowSize();

  const [userData, setUserData] = useState({});
  const [loading, setloading] = useState(false);

  const idUser = useSelector((state) => {
    return state.currentUser?.idUser;
  });

  const userLang = useSelector((state) => {
    return state.currentUser.lang;
  });

  useEffect(() => {
    const fill = async () => {
      setloading(true);
      const resultGetOne = await UserActions.getOne(idUser);
      if (resultGetOne.success) {
        setUserData(resultGetOne.data);
      } else {
        UtilNotify.notifyErrorServer(resultGetOne.errMsg);
      }
      setloading(false);
    };
    fill();
  }, [userLang, idUser]);

  const changeUserSettings = (values) => {
    setUserData((ov) => Object.assign(ov, values));
  };

  return (
    <UserContext.Provider value={{ userData, changeUserSettings }}>
      <Layout
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          // width: fullPage || isMobile ? "100%" : "100%",
          width: "100%",
        }}
      >
        {isMobile ? (
          <div style={{ marginLeft: 10 }}>
            <MyDrawer noExtraBtn icon={<SettingOutlined />}>
              <SettingsMenu />
            </MyDrawer>
          </div>
        ) : (
          <SettingsMenu />
        )}
        <Content
          className="main-content"
          style={{
            maxWidth: fullPage || isMobile ? "100%" : 600,
            padding: 10,
          }}
        >
          {loading ? <LoadingIconApp /> : children}
        </Content>
      </Layout>
    </UserContext.Provider>
  );
};

export default SettingsLayout;
