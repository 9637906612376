import React, { useEffect, useState } from "react";
import MyTable from "../../../../components/myTable/MyTable";
import { useTranslation } from "react-i18next";
import MySpace from "../../../../components/mySpace/MySpace";
import RouteActions from "../../../../actions/RouteActions";
import UtilNotify from "../../../../utils/UtilNotify";
import { useParams } from "react-router-dom";
import CustomerManagerActions from "../../../../actions/CustomerManagerActions";
import MyButtonSecondary from "../../../../components/myButton/MyButtonSecondary";
import useNavigateApi from "../../../../appRoute/useNavigateApi";
import SearchInput from "../../../../components/myInputForm/SearchInput";

const UserListCustomerTableAddSection = () => {
  const { _idManager, _idRoute } = useParams();
  const { t } = useTranslation();
  const navigateApi = useNavigateApi();

  const [data, setData] = useState([]);
  const [totalRows, settotalRows] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [search, setsearch] = useState("");
  const [saving, setsaving] = useState(false);
  const [pagination, setpagination] = useState(
    _idRoute ? { page: 0, perPage: 50 } : { page: 0, perPage: 10000 }
  );

  useEffect(() => {
    const fill = async () => {
      setLoading(true);
      let result;
      const fetchParams = {
        page: pagination.page,
        perPage: pagination.perPage,
        filter: {},
        search: search?.trim(),
      };
      if (_idRoute) {
        fetchParams.filter = { idRoute: _idRoute };
        result = await RouteActions.getListCustomerCanAdd(
          _idManager,
          fetchParams
        );
      } else {
        result = await CustomerManagerActions.getListCustomerCanAdd(
          _idManager,
          fetchParams
        );
      }
      if (result.success) {
        setData(result.data);
        settotalRows(result.totalRows);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setLoading(false);
    };
    fill();
  }, [search, _idRoute, _idManager, pagination]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleAddCustomers = async () => {
    setsaving(true);
    if (_idRoute) {
      const result = await RouteActions.addCustomers(_idRoute, selectedRowKeys);
      if (result.success) {
        navigateApi(`/user/route/${_idManager}/${_idRoute}`);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    } else {
      const result = await CustomerManagerActions.addCustomers(
        _idManager,
        selectedRowKeys
      );
      if (result.success) {
        navigateApi(`/user/route/${_idManager}`);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    }
    setsaving(false);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: t("WORD_CUSTOMER"),
      dataIndex: "nameCustomer",
      key: "nameCustomer",
      render: (value) => <strong>{value}</strong>,
    },
  ];

  return (
    <>
      <MySpace fullWidth>
        <SearchInput
          onClear={() => {
            setsearch("");
          }}
          onPressEnter={(value) => {
            setsearch(value);
          }}
          className="input-search"
        />
        <MyButtonSecondary
          label={t("WORD_ALL_SELECTED_TO_GROUP")}
          onClick={handleAddCustomers}
          disabled={!selectedRowKeys?.length}
          loading={saving}
        />
      </MySpace>
      <div className="user-list-customer-table-wrapper">
        <MyTable
          loading={loading}
          rowKey="idCustomer"
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          total={totalRows}
          onChangePage={(e, ee) => {
            setpagination({ page: e, perPage: ee });
          }}
          page={pagination.page}
          pageSize={pagination.perPage}
        />
      </div>
    </>
  );
};

export default UserListCustomerTableAddSection;
