import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import MyModalForm from "../../../components/myModal/MyModalForm";
import MySpace from "../../../components/mySpace/MySpace";
import SelectManager from "../../../components/selects/SelectManager";
import SelectCustomer from "../../../components/selects/SelectCustomer";
import InputTextFormItem from "../../../components/myInputForm/InputTextFormItem";
import SelectOrderPaymentType from "../../../components/selects/SelectOrderPaymentType";
import InputFormDatePicker from "../../../components/inputFormDatePicker/InputFormDatePicker";
import SelectPaymentStatus from "../../../components/selects/SelectPaymentStatus";
import InputTextAreaFormItem from "../../../components/myInputForm/InputTextAreaFormItem";
import FormApp from "../../../components/formApp/FormApp";
import { useForm } from "antd/es/form/Form";
import OrdersActions from "../../../actions/OrdersActions";
import UtilDate from "../../../utils/UtilDate";
import UtilNotify from "../../../utils/UtilNotify";
import paymentActions from "../../../actions/PaymentActions";

const formName = "formPaymentEditModal";

const PaymentEditModal = forwardRef(({ onChange }, ref) => {
  const refModal = useRef();
  const { t } = useTranslation();
  const [form] = useForm();

  const [loading, setloading] = useState(false);
  const [idOrder, setIDOrder] = useState("");

  useImperativeHandle(ref, () => ({
    viewPaymentEdit: (id) => {
      setIDOrder(id);
      refModal.current.open();
    },
  }));

  useEffect(() => {
    const fill = async () => {
      if (idOrder) {
        setloading(true);
        const resultGetOne = await OrdersActions.getOne(idOrder);
        if (resultGetOne.success) {
          form.setFieldsValue(
            Object.assign(resultGetOne.data, {
              createdAt: UtilDate.getDate(resultGetOne.data.createdAt),
            })
          );
        } else {
          UtilNotify.notifyErrorServer(resultGetOne.errMsg);
        }
        setloading(false);
      }
    };
    fill();
  }, [idOrder, form]);

  const onFinish = async (values) => {
    let result;
    if (idOrder) {
      result = await paymentActions.update(values, idOrder);
    } else {
      result = await paymentActions.create(values);
    }
    if (result.success) {
      UtilNotify.notifySuccess(
        !idOrder ? t("WORD_PAYMENT_CREATED") : t("WORD_PAYMENT_UPDATED")
      );
      onChange && onChange();
      refModal.current.close();
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
  };

  return (
    <MyModalForm
      ref={refModal}
      title={!idOrder ? t("WORD_CREATE_PAYMENT") : t("WORD_EDIT_PAYMENT")}
      width={500}
      okText={!idOrder ? t("WORD_CREATE_PAYMENT") : null}
      formName={formName}
    >
      <FormApp
        form={form}
        name={formName}
        onFinish={onFinish}
        loading={loading}
      >
        <MySpace direction="vertical" fullWidth size="middle">
          <SelectManager
            name="idManager"
            label={t("WORD_DISTRIBUTOR")}
            placeholder={t("WORD_SELECT_DISTRIBUTOR")}
            required
          />
          <SelectCustomer
            name="idCustomer"
            label={t("WORD_CUSTOMER")}
            placeholder={t("WORD_SELECT_CUSTOMER")}
            required
          />
          <MySpace fullWidth className="select-row" align="top">
            <InputTextFormItem
              label={t("WORD_TOTAL_AMOUNT")}
              placeholder={t("WORD_TOTAL_AMOUNT")}
              name="totalAmount"
              required
            />
            <SelectOrderPaymentType
              label={t("WORD_PAYMENT_TYPE")}
              placeholder={t("WORD_SELECT_PAYMENT_TYPE")}
              name="idPaymentType"
              required
            />
          </MySpace>
          <MySpace fullWidth className="select-row" align="top">
            <InputFormDatePicker
              name="createdAt"
              label={t("WORD_CREATED_AT")}
              placeholder={t("WORD_SELECT_CREATED_AT")}
              required
            />
            <SelectPaymentStatus
              label={t("WORD_PAYMENT_STATUS")}
              placeholder={t("WORD_SELECT_PAYMENT_STATUS")}
              name="confirmStatus"
              required
            />
          </MySpace>
          <InputTextAreaFormItem name="note" label={t("WORD_NOTE")} />
        </MySpace>
      </FormApp>
    </MyModalForm>
  );
});

export default PaymentEditModal;
