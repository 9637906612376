import React, { memo } from "react";
import MyIcon from "./MyIcon";

export const SVGIcon = ({ style, size }) => {
  return (
    <svg
      width={size || 48}
      height={size || 48}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <ellipse
        cx="6.90538"
        cy="30.9424"
        rx="4.90538"
        ry="5.05807"
        fill="white"
        fillOpacity="0.5"
      ></ellipse>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.4744 35.9894C26.0106 35.9894 27.4151 35.008 28.4921 33.3857C29.4588 35.0389 30.6154 36.0005 31.8582 36.0005C35.2502 36.0005 38 28.837 38 20.0005C38 11.1639 35.2502 4.00049 31.8582 4.00049C29.1423 4.00049 26.8382 8.59259 26.0275 14.9588C25.5314 14.7339 25.0109 14.6142 24.4744 14.6142C21.9775 14.6142 19.8286 17.207 18.8683 20.9297C17.906 20.0855 16.7551 19.5945 15.5186 19.5945C13.0871 19.5945 10.9868 21.4929 10 24.2428C12.9586 25.414 15.0439 28.2352 15.0439 31.5294C15.0439 33.0451 14.6023 34.4608 13.8374 35.6616C14.3716 35.8654 14.9355 35.9746 15.5186 35.9746C17.4109 35.9746 19.1026 34.8247 20.225 33.0185C21.3283 34.8587 22.8255 35.9894 24.4744 35.9894Z"
        fill="white"
      ></path>
    </svg>
  );
};

const AnalyticsWhiteSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(AnalyticsWhiteSVG);
