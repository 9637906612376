import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MySpace from "../../../components/mySpace/MySpace";
import MyPageHeader from "../../../components/myPageHeader/MyPageHeader";
import { useTranslation } from "react-i18next";
import DeviceActions from "../../../actions/DeviceActions";
import UtilNotify from "../../../utils/UtilNotify";
import UserActions from "../../../actions/UserActions";
import { useSelector } from "react-redux";
import ApiStorage from "../../../redux/ApiStorage";
import MyTable from "../../../components/myTable/MyTable";
import { faEye, faPen } from "@fortawesome/free-solid-svg-icons";
import DeviceViewModal from "../deviceView/DeviceViewModal";
import NotificationActions from "../../../actions/NotificationActions";
import MyHelmet from "../../../components/helmet/MyHelmet";
import useNavigateApi from "../../../appRoute/useNavigateApi";
import DeviceNewUserPage from "./DeviceNewUserPage";
import { useForm } from "antd/es/form/Form";
import FormApp from "../../../components/formApp/FormApp";
import SelectOrderBranch from "../../../components/selects/SelectOrderBranch";
import SelectManagerStatus from "../../../components/selects/SelectManagerStatus";
import MyButtonText from "../../../components/myButton/MyButtonText";
import SearchInput from "../../../components/myInputForm/SearchInput";
import MyButtonTableAction from "../../../components/myButton/MyButtonTableAction";
import { Flex } from "antd";
import MyTag from "../../../components/tag/MyTag";

const formName = "formDeviceFilter";

const DeviceList = () => {
  const { t } = useTranslation();
  const refModal = useRef();
  const navigateApi = useNavigateApi();
  const [form] = useForm();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setpagination] = useState({ page: 0, perPage: 50 });
  const [totalRows, settotalRows] = useState(0);
  const [search, setSearch] = useState("");
  const [sort, setsort] = useState("");

  const devicePageFilters = useSelector((state) => {
    return state.devicePageFilters;
  });

  const currentUserSteps = useSelector((state) => {
    return state.currentUser?.stepsDone;
  });

  const currentUserEmail = useSelector((state) => {
    return state.currentUser?.companyEmail;
  });

  const onFinish = useCallback(
    (fil) => {
      ApiStorage.setDevicePageFilters(
        Object.assign({}, devicePageFilters, fil)
      );
      setpage(0);
    },
    [devicePageFilters]
  );

  const setpage = (page) => {
    setpagination((ov) => ({ page: page, perPage: ov.perPage }));
  };

  const setFilterClear = () => {
    form.resetFields();
    ApiStorage.setDevicePageFilters({});
    setpage(0);
  };

  useEffect(() => {
    const fill = async () => {
      setLoading(true);
      const fetchParams = {
        page: pagination.page,
        perPage: pagination.perPage,
        sort: sort,
        q: devicePageFilters?.searchName || "",
        filter: { ...devicePageFilters },
        search: search,
      };
      const result = await DeviceActions.fillPagination(fetchParams);
      if (result.success) {
        if (pagination.page !== 0) {
          setData((ov) => [...ov, ...(result.data ?? [])]);
        } else {
          setData(result.data);
        }
        settotalRows(result.totalRows);
        // settotals(result.totals);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setLoading(false);
    };

    fill();
  }, [pagination, search, devicePageFilters, sort]);

  useEffect(() => {
    if (devicePageFilters) {
      form?.setFieldsValue(devicePageFilters);
    }
  }, [devicePageFilters, form]);

  const columns = useMemo(() => {
    let col = [
      {
        title: t("WORD_DEVICE_NAME"),
        dataIndex: "deviceName",
        key: "deviceName",
        width: 160,
        sorter: true,
      },
      {
        title: t("WORD_BRANCH"),
        dataIndex: "nameBranch",
        key: "nameBranch",
        width: 200,
      },
      {
        title: t("WORD_MAC_ADDRESS"),
        dataIndex: "macAddress",
        key: "macAddress",
        sorter: true,
      },
      {
        title: t("WORD_STATUS"),
        dataIndex: "status",
        key: "status",
        width: 20,
        render: (value) => {
          const status = UserActions.getStatusLabel(value);
          return <MyTag label={t(status.label)} color={status.color} />;
        },
      },
      {
        title: t("WORD_ACTIONS"),
        dataIndex: "actions",
        width: 120,
        key: "actions",
        render: (_, obj) => (
          <MySpace>
            <MyButtonTableAction
              title={t("WORD_EDIT")}
              icon={faPen}
              onClick={() => navigateApi(`/device/edit/${obj.idDevice}`)}
            />
            <MyButtonTableAction
              title={t("WORD_VIEW")}
              icon={faEye}
              onClick={() => {
                refModal?.current?.viewDevice(obj);
              }}
            />
          </MySpace>
        ),
      },
    ];
    return col;
  }, [t, navigateApi]);

  const onChangeTable = (_, __, sorter) => {
    let so = "";
    if (sorter.order) {
      so = `${sorter.columnKey} ${sorter.order === "ascend" ? "ASC" : "DESC"}`;
    }
    setsort(so);
  };

  return (
    <>
      <MyHelmet title={t("WORD_DEVICES")} />
      <Flex vertical gap={10}>
        <MyPageHeader inPage title={t("WORD_DEVICES")} />
        <MySpace style={{ marginLeft: 8 }}>
          <SearchInput
            onClear={() => {
              setSearch("");
              setpage(0);
            }}
            onPressEnter={(value) => {
              setSearch(value);
              setpage(0);
            }}
          />
          <FormApp onFinish={onFinish} name={formName} form={form}>
            <MySpace>
              <SelectOrderBranch
                name="idBranch"
                placeholder={t("WORD_BRANCH")}
                onChange={() => form.submit()}
                width={140}
              />
              <SelectManagerStatus
                name="status"
                placeholder={t("WORD_STATUS")}
                onChange={() => form.submit()}
                width={160}
              />
              <MyButtonText
                label={t("WORD_RESET_FILTERS")}
                onClick={setFilterClear}
              />
            </MySpace>
          </FormApp>
        </MySpace>
        {!currentUserSteps && !loading && data.length === 0 ? (
          <DeviceNewUserPage email={currentUserEmail} />
        ) : (
          <>
            <MyTable
              rowKey="idDevice"
              // rowSelection={rowSelection}
              columns={columns}
              dataSource={data}
              onChange={onChangeTable}
              loading={loading}
              total={totalRows}
              onChangePage={(e, ee) => {
                setpagination({ page: e, perPage: ee });
              }}
              page={pagination.page}
              pageSize={pagination.perPage}
              hideOnSinglePage={false}
            />
            <DeviceViewModal
              ref={refModal}
              onChange={(idNotif) => {
                setData(
                  data.map((not) => {
                    if (not.idNotif === idNotif) {
                      not.notifRead = NotificationActions.STATUS.READED;
                    }
                    return not;
                  })
                );
              }}
            />
          </>
        )}
      </Flex>
    </>
  );
};

export default DeviceList;
