import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import AnalyticsActions from "../../../actions/AnalyticsActions";
import UtilNotify from "../../../utils/UtilNotify";
import UtilNumber from "../../../utils/UtilNumber";
import MyTable from "../../../components/myTable/MyTable";

const AnalyticsCustomerByPlaceTable = ({
  selectedRegion,
  filter,
  className,
}) => {
  const { t } = useTranslation();

  const [loading, setloading] = useState(true);

  const [data, setdata] = useState([]);

  useEffect(() => {
    const fill = async () => {
      setloading(true);
      const params = {
        filter: filter,
      };
      let result;
      if (selectedRegion === "COUNTRY") {
        result = await AnalyticsActions.customer_count_by_country_list(params);
      } else if (selectedRegion === "REGION") {
        result = await AnalyticsActions.customer_count_by_region_list(params);
      } else if (selectedRegion === "CITY") {
        result = await AnalyticsActions.customer_count_by_city_list(params);
      } else if (selectedRegion === "DISTRICT") {
        result = await AnalyticsActions.customer_count_by_district_list(params);
      }
      if (result?.success) {
        setdata(result?.data);
      } else {
        UtilNotify.notifyErrorServer(result?.errMsg);
      }
      setloading(false);
    };
    fill();
  }, [filter, selectedRegion]);

  const columns = useMemo(() => {
    return [
      {
        title: t("WORD_NAME"),
        dataIndex: "name",
        key: "name",
      },
      {
        title: t("WORD_CUSTOMERS"),
        dataIndex: "count",
        key: "count",
        align: "center",
        width: 100,
        render: (value) => {
          return <strong>{UtilNumber.formatDouble(value)}</strong>;
        },
      },
    ];
  }, [t]);

  return (
    <MyTable
      columns={columns}
      dataSource={data}
      rowKey={(record) => JSON.stringify(record)}
      noFooter
      loading={loading}
      className={className}
    />
  );
};

export default AnalyticsCustomerByPlaceTable;
