import React, { memo, useRef } from "react";
import MyDropDown from "../../components/myDropDown/MyDropDown";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import UserActions from "../../actions/UserActions";
import LogoSmallSVG from "../../icons/LogoSmallSVG";
import MySpace from "../../components/mySpace/MySpace";
import ApiStorage from "../../redux/ApiStorage";
import { Badge } from "antd";
import useNavigateApi from "../../appRoute/useNavigateApi";
import NotificationViewModal from "../../pages/settings/NotificationViewModal";
import useWindowSize from "../../utils/useWindowSize";
import MySpaceRow from "../../components/mySpace/MySpaceRow";
import { useNavigate } from "react-router-dom";
import "./MenuHeader.scss";

const MenuHeader = () => {
  const { t } = useTranslation();
  const navigateApi = useNavigateApi();
  const navigate = useNavigate();
  const refModal = useRef();
  const { isMobile } = useWindowSize();

  const notificationCount = useSelector((state) => {
    return state.currentUser?.notificationCount;
  });

  const companyName = useSelector((state) => {
    return state.currentUser?.companyName;
  });

  // const name = useSelector((state) => {
  //   return state.currentUser?.name;
  // });

  const sideMenuColapsed = useSelector((state) => {
    return state.sideMenuColapsed;
  });

  const items = [
    {
      label: t("WORD_COMPANY_SETTINGS"),
      key: "csettings",
      onClick: () => {
        navigateApi(`/settings/company`);
      },
    },
    {
      label: t("WORD_GENERAL_SETTINGS"),
      key: "gsettings",
      onClick: () => {
        navigateApi(`/settings/general`);
      },
    },
    {
      type: "divider",
    },
    {
      label: notificationCount ? (
        <MySpaceRow fullWidth spaceBetween>
          <div>{t("WORD_NOTIFICATIONS")}</div>
          <Badge status="error" />
        </MySpaceRow>
      ) : (
        t("WORD_NOTIFICATIONS")
      ),
      key: "notif",
      onClick: () => {
        navigateApi(`/settings/notification`);
      },
    },
    // {
    //   icon: <UserOutlined />,
    //   label: t("WORD_PROFILE"),
    //   key: "accsettings",
    //   onClick: () => {
    //     navigateApi(`/settings/account`);
    //   },
    // },
    {
      label: t("WORD_LOGOUT"),
      key: `logout`,
      onClick: async () => {
        await UserActions.logOut();
        ApiStorage.clearUser();
        navigate(`/login`);
      },
    },
  ];

  return (
    <div>
      <MyDropDown
        className="menu-dropdown"
        items={items}
        arrow={!sideMenuColapsed}
        placement="bottom"
      >
        {sideMenuColapsed && !isMobile ? (
          <Badge count={notificationCount}>
            <LogoSmallSVG size={30} />
          </Badge>
        ) : (
          <MySpace
            fullWidth
            size="middle"
            align="center"
            style={{ maxWidth: 210 }}
          >
            <Badge count={notificationCount} style={{ boxShadow: "unset" }}>
              <LogoSmallSVG />
            </Badge>
            {/* <MySpace direction="vertical" fullWidth> */}
            <div className="menu-header-title">{companyName}</div>
            {/* <div className="menu-header-text">{name}</div> */}
            {/* </MySpace> */}
          </MySpace>
        )}
      </MyDropDown>
      <NotificationViewModal ref={refModal} />
    </div>
  );
};

export default memo(MenuHeader);
