import React, { useRef } from "react";
import MySpace from "../../../components/mySpace/MySpace";
import SelectOrderBranch from "../../../components/selects/SelectOrderBranch";
import { FilterOutlined } from "@ant-design/icons";
import SelectOrderExported from "../../../components/selects/SelectOrderExported";
import { useTranslation } from "react-i18next";
import MyDrawerWithReset from "../../../components/myDrawer/MyDrawerWithReset";
import SelectManagerMulti from "../../../components/selects/SelectManagerMulti";
import SelectCustomerMulti from "../../../components/selects/SelectCustomerMulti";
import InputFormRangeDatePicker from "../../../components/inputFormRangeDatePicker/InputFormRangeDatePicker";

const PaymentListFilterDrawerFewer = ({ onReset, loading, formName }) => {
  const { t } = useTranslation();
  const refDrawer = useRef();

  const handleReset = () => {
    onReset && onReset();
    refDrawer?.current?.closeDrawer();
  };

  return (
    <MyDrawerWithReset
      ref={refDrawer}
      label={t("WORD_MORE_FILTERS")}
      icon={<FilterOutlined />}
      loading={loading}
      formName={formName}
      definedCount={1}
      handleReset={handleReset}
    >
      <MySpace direction="vertical" size="middle" fullWidth>
        <InputFormRangeDatePicker
          label={t("WORD_DATE")}
          name="date"
          allowClear={false}
        />
        <SelectOrderBranch
          placeholder={t("WORD_SELECT_BRANCH")}
          label={t("WORD_SELECT_BRANCH")}
          name="idBranch"
        />
        <SelectManagerMulti
          placeholder={t("WORD_SELECT_DISTRIBUTOR")}
          label={t("WORD_SELECT_DISTRIBUTOR")}
          name="idManager"
        />
        <SelectCustomerMulti
          placeholder={t("WORD_SELECT_CUSTOMER")}
          label={t("WORD_SELECT_CUSTOMER")}
          name="idCustomer"
        />
        <SelectOrderExported
          placeholder={t("WORD_SELECT_EXPORT")}
          label={t("WORD_SELECT_EXPORT")}
          name="exportedAdmin"
        />
      </MySpace>
    </MyDrawerWithReset>
  );
};

export default PaymentListFilterDrawerFewer;
