import React, { memo } from "react";
import MyIcon from "./MyIcon";

export const SVGIcon = ({ style, size }) => {
  return (
    <svg
      width={size || 48}
      height={size || 48}
      viewBox="0 0 37 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <circle cx="18" cy="31" r="5" fill="white"></circle>
      <path
        opacity="0.4"
        d="M26.4894 0.329346H9.72739C3.64739 0.329346 0.107391 3.88935 0.107391 9.98935V30.6493C0.107391 36.8493 3.64739 40.3293 9.72739 40.3293H26.4894C32.6674 40.3293 36.1074 36.8493 36.1074 30.6493V9.98935C36.1074 3.88935 32.6674 0.329346 26.4894 0.329346Z"
        fill="white"
      ></path>
    </svg>
  );
};

const DevicesSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(DevicesSVG);
