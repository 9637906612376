import React from "react";
import MyButton from "./MyButton";
import useWindowSize from "../../utils/useWindowSize";
import { PlusOutlined } from "@ant-design/icons";
import "./MyButton.scss";

const MyButtonSuccess = ({ label, withIcon, noForm, ...otherProps }) => {
  const { isMobile } = useWindowSize();

  return (
    <MyButton
      {...otherProps}
      label={isMobile ? "" : label}
      icon={withIcon ? <PlusOutlined /> : null}
      type="primary"
      htmlType={noForm ? "button" : "submit"}
      className="my-button-main-success"
      size="large"
    />
  );
};

export default MyButtonSuccess;
