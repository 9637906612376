import React, { useEffect, useState } from "react";
import MyCard from "../../../../components/myCard/MyCard";
import MyPageHeader from "../../../../components/myPageHeader/MyPageHeader";
import { useTranslation } from "react-i18next";
import FormApp from "../../../../components/formApp/FormApp";
import { Form } from "antd";
import InputNumberFormItem from "../../../../components/myInputForm/InputNumberFormItem";
import MyDivider from "../../../../components/myDivider/MyDivider";
import { useLocation, useParams } from "react-router-dom";
import CustomerManagerActions from "../../../../actions/CustomerManagerActions";
import UtilNotify from "../../../../utils/UtilNotify";
import useNavigateApi from "../../../../appRoute/useNavigateApi";
import MyHelmet from "../../../../components/helmet/MyHelmet";
import MyButtonSuccess from "../../../../components/myButton/MyButtonSuccess";

const formName = "formEditOpeningBalance";

const UserListCustomerDetails = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigateApi = useNavigateApi();
  const location = useLocation();
  const { username } = location.state || {};

  const { _idManager, _idCustomer } = useParams();

  const [saving, setsaving] = useState(false);

  useEffect(() => {
    const fill = async () => {
      const result = await CustomerManagerActions.getCustomerOpeningBalance(
        _idManager,
        _idCustomer
      );
      if (result.success) {
        form?.setFieldsValue(result.data);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    };
    fill();
  }, [_idManager, _idCustomer, form]);

  const goBack = () => {
    navigateApi(`/user/route/${_idManager}`);
  };

  const onFinish = async (values) => {
    setsaving(true);
    const result = await CustomerManagerActions.saveCustomerOpeningBalance(
      values
    );
    if (result.success) {
      goBack();
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    setsaving(false);
  };

  return (
    <>
      <MyHelmet title={t("WORD_CUSTOMER_DETAILS")} />
      <MyCard>
        <MyPageHeader
          title={`${t("WORD_OPENING_BALANCE")} - ${username}`}
          onBack={goBack}
        />
        <MyDivider />
        <FormApp
          form={form}
          name={formName}
          onFinish={onFinish}
          initialValues={{ idCustomer: _idCustomer, idManager: _idManager }}
        >
          <InputNumberFormItem
            required
            name="openingBalance"
            label={t("WORD_OPENING_BALANCE")}
          />
          <InputNumberFormItem required name="idManager" hidden />
          <InputNumberFormItem required name="idCustomer" hidden />
          <MyButtonSuccess
            label={t("WORD_SAVE_CHANGES")}
            form={formName}
            style={{ height: 44, marginTop: 20 }}
            loading={saving}
          />
        </FormApp>
      </MyCard>
    </>
  );
};

export default UserListCustomerDetails;
