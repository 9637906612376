import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MyPageHeader from "../../../components/myPageHeader/MyPageHeader";
import { useTranslation } from "react-i18next";
import MySpace from "../../../components/mySpace/MySpace";
import LocationSVG from "../../../icons/GpsSVG";
import { useSelector } from "react-redux";
import ApiStorage from "../../../redux/ApiStorage";
import OrdersActions from "../../../actions/OrdersActions";
import UtilNotify from "../../../utils/UtilNotify";
import UtilDate from "../../../utils/UtilDate";
import OrderExportDropdown from "../../orders/orderList/OrderExportDropdown";
import StatisticCard from "../../../components/statisticCard/StatisticCard";
import PaymentListFilterDrawer from "./PaymentListFilterDrawer";
import WalletSVG from "../../../icons/WalletSVG";
import MyTable from "../../../components/myTable/MyTable";
import {
  faCheck,
  faEye,
  faPen,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MyHelmet from "../../../components/helmet/MyHelmet";
import ExportTemplatesActions from "../../../actions/ExportTemplatesActions";
import MyTooltip from "../../../components/myTooltip/MyTooltip";
import useWindowSize from "../../../utils/useWindowSize";
import MyButtonTableActionDelete from "../../../components/myButton/MyButtonTableActionDelete";
import MyButtonTableActionConfirmPayment from "../../../components/myButton/MyButtonTableActionConfirmPayment";
import PaymentEditModal from "../paymentEdit/PaymentEditModal";
import { useFormatCurrency } from "../../../utils/useFormatWithCurrency";
import MyButtonSuccess from "../../../components/myButton/MyButtonSuccess";
import FormApp from "../../../components/formApp/FormApp";
import SelectPaymentStatus from "../../../components/selects/SelectPaymentStatus";
import SelectOrderPaymentType from "../../../components/selects/SelectOrderPaymentType";
import { Flex } from "antd";
import PaymentListFilterDrawerFewer from "./PaymentListFilterDrawerFewer";
import { useForm } from "antd/es/form/Form";
import MyButtonText from "../../../components/myButton/MyButtonText";
import MyButtonTableAction from "../../../components/myButton/MyButtonTableAction";
import MyButtonTableActionWithLink from "../../../components/myButton/MyButtonTableActionWithLink";
import MyTag from "../../../components/tag/MyTag";
import HeaderMobile from "../../../components/headerForMobile/HeaderMobile";

const formName = "formPaymentFilter";

const PaymentList = () => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const { formatDoubleWithCurrency } = useFormatCurrency();
  const [form] = useForm();
  const refModal = useRef();

  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, settotalRows] = useState(0);
  const [totals, settotals] = useState({});
  const [pagination, setpagination] = useState({ page: 0, perPage: 50 });
  const [sort, setsort] = useState("");

  const paymentPageFilters = useSelector((state) => {
    return state.paymentPageFilters;
  });

  const is1c = useSelector((state) => {
    return state.currentUser?.is1c;
  });

  const setFilter = useCallback(
    (fil) => {
      ApiStorage.setPaymentPageFilters(
        Object.assign({}, paymentPageFilters, fil)
      );
      setpage(0);
    },
    [paymentPageFilters]
  );

  const setpage = (page) => {
    setpagination((ov) => ({ page: page, perPage: ov.perPage }));
  };

  const setFilterClear = () => {
    form.resetFields();
    ApiStorage.setPaymentPageFilters({});
    setpage(0);
  };

  const fillFS = useCallback(async () => {
    setLoading(true);
    const fetchParams = {
      page: pagination.page,
      perPage: pagination.perPage,
      sort: sort,
      q: paymentPageFilters?.searchName || "",
      filter: { ...paymentPageFilters },
    };
    if (!fetchParams?.filter?.type) {
      fetchParams.filter.type = [OrdersActions.TYPE.PAYMENT];
    }
    const result = await OrdersActions.fillPagination(fetchParams);
    if (result.success) {
      setData(result.data);
      settotalRows(result.totalRows);
      settotals(result.totals);
      setSelectedRowKeys([]);
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    setLoading(false);
  }, [paymentPageFilters, pagination, sort]);

  useEffect(() => {
    fillFS();
  }, [fillFS]);

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleConfirmPayment = useCallback(
    async (idOrder) => {
      if (idOrder) {
        const result = await OrdersActions.confirmPayment(idOrder);
        if (result.success) {
          UtilNotify.notifySuccess(t("WORD_PAYMENT_CONFIRMED_SUCCESSFULLY"));
          setData((data) =>
            data.map((pay) => {
              if (pay.idOrder === idOrder) {
                pay.confirmStatus = OrdersActions.PAYMENT_STATUS.CONFIRMED;
              }
              return pay;
            })
          );
        } else {
          UtilNotify.notifyErrorServer(result.errMsg);
        }
      }
    },
    [t]
  );

  const handleDeleteOne = useCallback(
    async (idOrder) => {
      if (idOrder) {
        const result = await OrdersActions.delete(idOrder);
        if (result.success) {
          await fillFS();
          UtilNotify.notifySuccess(t("WORD_REMOVED_SUCCESSFULLY"));
        } else {
          UtilNotify.notifyErrorServer(result.errMsg);
        }
      }
    },
    [t, fillFS]
  );

  const columns = useMemo(() => {
    let col = [
      {
        title: " ",
        dataIndex: "distanceStatus",
        width: 40,
        key: "distanceStatus",
        align: "center",
        render: (value) => {
          const type = OrdersActions.getDistanceStatusLabel(value);
          return (
            <MyTooltip title={t(type.label)} placement="rightBottom">
              <LocationSVG fill={type.color} size={22} />
            </MyTooltip>
          );
        },
      },
      {
        title: t("WORD_PAYMENT_ID"),
        dataIndex: "idOrder",
        key: "idOrder",
        width: 40,
      },
      {
        title: t("WORD_BRANCH"),
        dataIndex: "nameBranch",
        key: "nameBranch",
        width: 50,
      },
      {
        title: t("WORD_DISTRIBUTOR"),
        dataIndex: "nameManager",
        key: "nameManager",
        width: 100,
      },
      {
        title: t("WORD_CUSTOMER"),
        dataIndex: "nameCustomer",
        key: "nameCustomer",
        width: 100,
      },
      {
        title: t("WORD_TOTAL_AMOUNT"),
        dataIndex: "totalAmount",
        key: "totalAmount",
        width: 80,
        align: "right",
        render: (value) => {
          return formatDoubleWithCurrency(value);
        },
      },
      {
        title: t("WORD_TYPE"),
        dataIndex: "type",
        key: "type",
        width: 20,
        render: (value) => {
          const type = OrdersActions.getOrderTypeLabel(value);
          return <MyTag label={t(type.label)} color={type.color} />;
        },
      },
      {
        title: t("WORD_PAYMENT_TYPE"),
        dataIndex: "namePaymentType",
        key: "namePaymenttype",
        width: 80,
        render: (value) => {
          return <div>{value}</div>;
        },
      },
      {
        title: t("WORD_CREATE_DATE"),
        dataIndex: "createdAt",
        key: "createdAt",
        width: 80,
        sorter: true,
        render: (value) => {
          return <div>{UtilDate.formatDateTime(value)}</div>;
        },
      },
      {
        title: t("WORD_SYNC_DATE"),
        dataIndex: "syncDate",
        key: "syncDate",
        width: 80,
        sorter: true,
        render: (value) => {
          return <div>{value ? UtilDate.formatDateTime(value) : ""}</div>;
        },
      },
      {
        title: t("WORD_UPDATED_DATE"),
        dataIndex: "updatedAt",
        key: "updatedAt",
        width: 80,
        sorter: true,
        render: (value) => {
          return <div>{value ? UtilDate.formatDateTime(value) : ""}</div>;
        },
      },
      {
        title: t("WORD_ACTIONS"),
        dataIndex: "",
        key: "x",
        width: 100,
        render: (_, { idOrder, confirmStatus }) => (
          <MySpace>
            <MyButtonTableActionWithLink
              title={t("WORD_VIEW")}
              icon={faEye}
              path={`/payment/view/${idOrder}`}
            />
            <MyTooltip title={t("WORD_DELETE")}>
              <MyButtonTableActionDelete
                disabled={is1c}
                icon={
                  <FontAwesomeIcon icon={faTrashCan} color="#fff" size="xs" />
                }
                onConfirm={() => {
                  handleDeleteOne(idOrder);
                }}
              />
            </MyTooltip>
            <MyTooltip
              title={confirmStatus ? t("WORD_CONFIRMED") : t("WORD_CONFIRM")}
            >
              <MyButtonTableActionConfirmPayment
                icon={<FontAwesomeIcon icon={faCheck} color="#fff" size="xs" />}
                okText={t("WORD_CONFIRM")}
                cancelText={t("WORD_CANCEL")}
                onConfirm={() => handleConfirmPayment(idOrder)}
                success
                disabled={
                  confirmStatus === OrdersActions.PAYMENT_STATUS.CONFIRMED
                }
                className={!confirmStatus ? "" : "confirmed-btn"}
              />
            </MyTooltip>
            {!is1c &&
              confirmStatus === OrdersActions.PAYMENT_STATUS.NOTCONFIRMED ? (
              <MyButtonTableAction
                title={t("WORD_EDIT")}
                icon={faPen}
                onClick={() => {
                  refModal?.current?.viewPaymentEdit(idOrder);
                }}
              />
            ) : null}
          </MySpace>
        ),
      },
    ];
    return col;
  }, [
    t,
    handleConfirmPayment,
    is1c,
    handleDeleteOne,
    formatDoubleWithCurrency,
  ]);

  const onChangeTable = (_, __, sorter) => {
    let so = "";
    if (sorter.order) {
      so = `${sorter.columnKey} ${sorter.order === "ascend" ? "ASC" : "DESC"}`;
    }
    setsort(so);
  };

  const onFinish = useCallback(
    (values) => {
      const { date } = values;
      if (date) {
        values.dateFrom = date[0];
        values.dateTo = date[1];
      }
      const obj = Object.assign({}, paymentPageFilters, values);
      ApiStorage.setPaymentPageFilters(obj);
      setpage(0);
    },
    [paymentPageFilters]
  );

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      <MyHelmet title={t("WORD_PAYMENTS")} />
      <Flex vertical gap={10}>
        {!isMobile ? (
          <Flex vertical gap="small">
            <MyPageHeader
              inPage
              extraLeft={
                loading ? null : (
                  <div>
                    {hasSelected
                      ? `${t("WORD_SELECTED")} ${selectedRowKeys.length} ${selectedRowKeys.length > 1
                        ? t("WORD_ITEMS")
                        : t("WORD_ITEM")
                      }`
                      : ""}
                  </div>
                )
              }
              extraRight={
                <MySpace>
                  <OrderExportDropdown
                    viewIn={ExportTemplatesActions.VIEWIN.PAYMENTS}
                    ids={selectedRowKeys}
                  />
                  {/* <PaymentListFilterDrawer
                    onChange={setFilter}
                    onReset={setFilterClear}
                    filter={paymentPageFilters}
                  /> */}
                  <MyButtonSuccess
                    label={t("WORD_ADD")}
                    withIcon
                    // onClick={() => navigateApi(`/payment/create`)}
                    onClick={() => {
                      refModal?.current?.viewPaymentEdit();
                    }}
                  />
                </MySpace>
              }
              title={t("WORD_PAYMENTS")}
            />
            <Flex gap="small">
              <FormApp
                onFinish={onFinish}
                name={formName}
                form={form}
                style={{ marginLeft: 8 }}
              >
                <MySpace>
                  <SelectPaymentStatus
                    placeholder={t("WORD_SELECT_STATUS")}
                    name="confirmStatus"
                    onChange={() => form.submit()}
                  />
                  <SelectOrderPaymentType
                    placeholder={t("WORD_SELECT_PAYMENT_TYPE")}
                    name="idPaymentType"
                    onChange={() => form.submit()}
                  />
                  <PaymentListFilterDrawerFewer
                    onReset={setFilterClear}
                    loading={loading}
                    formName={formName}
                  />
                </MySpace>
              </FormApp>
              <MyButtonText
                label={t("WORD_RESET_FILTERS")}
                onClick={setFilterClear}
              />
            </Flex>
          </Flex>
        ) : (
          <HeaderMobile>
            <MyPageHeader title={t("WORD_PAYMENTS")} />
            <MySpace>
              <OrderExportDropdown
                viewIn={ExportTemplatesActions.VIEWIN.PAYMENTS}
              />
              <PaymentListFilterDrawer
                onChange={setFilter}
                onReset={setFilterClear}
                filter={paymentPageFilters}
              />
              <MyButtonSuccess
                withIcon
                onClick={() => {
                  refModal?.current?.viewPaymentEdit();
                }}
              />
            </MySpace>
          </HeaderMobile>
        )}
        <MySpace style={{ marginLeft: 8 }}>
          <StatisticCard
            title={t("WORD_PAYMENTS_TOTAL")}
            loading={loading}
            amount={totals?.totalAmountPayment}
            icon={
              <div className="wallet-icon-circle">
                <WalletSVG fill="#92cf5c" />
              </div>
            }
            secondTitle={t("WORD_IN")}
            count={totals?.totalCountPayment}
          />
        </MySpace>
        <MyTable
          rowKey="idOrder"
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          onChange={onChangeTable}
          loading={loading}
          total={totalRows}
          onChangePage={(e, ee) => {
            setpagination({ page: e, perPage: ee });
          }}
          page={pagination.page}
          pageSize={pagination.perPage}
          rowClassName={(record) => {
            if (!record.exportedAdmin) return "not-exported";
          }}
          scroll={{ x: 1740 }}
        />
      </Flex>
      <PaymentEditModal ref={refModal} onChange={fillFS} />
    </>
  );
};

export default PaymentList;
