import i18n from "i18next";
import { fetchFromUrlPOSTAsync } from "../utils/UrlHelper";

const URL_LIST = "businessType/list";

const fs = {
  fillList: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_LIST, urlParams);
    return result;
  },
};

const loc = {
  fillListOptions: async () => {
    const result = await BusinessTypeActions.fillList();
    if (result.success) {
      result.data = result.data.map((it) => {
        return {
          label: i18n.t(it.businessName),
          value: it.businessId,
          ...it,
        };
      });
    }
    return result;
  },
};

const BusinessTypeActions = Object.assign(fs, loc);

export default BusinessTypeActions;
