import { Modal } from "antd";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import MyText from "../myText/MyText";
import MySpace from "../mySpace/MySpace";
import MyButtonSecondary from "../myButton/MyButtonSecondary";
import MyButtonMain from "../myButton/MyButtonMain";

const MyModal = forwardRef(
  (
    {
      trigerText,
      children,
      width = 450,
      saving,
      onOk,
      destroyOnClose = true,
      title,
      onClose,
      forceRender = false,
      getContainer = false,
      disableOk,
      onVisibleChange,
      labelOk,
      loading,
      hideFooter,
      success,
      footer,
      className,
      height,
    },
    ref
  ) => {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    const showModal = () => {
      setOpen(true);
      onVisibleChange && onVisibleChange(true);
    };

    const handleCancel = () => {
      setOpen(false);
      onVisibleChange && onVisibleChange(false);
    };

    useImperativeHandle(ref, () => ({
      open: () => {
        showModal();
      },
      close: () => {
        handleCancel();
      },
    }));

    useEffect(() => {
      if (!open) {
        onClose && onClose();
      }
    }, [open, onClose]);

    return (
      <>
        <div className="app-modal-trigger-button" onClick={showModal}>
          {trigerText}
        </div>
        <Modal
          className={`my-modal ${className || ""}`}
          title={<MyText bold>{title}</MyText>}
          open={open}
          confirmLoading={saving}
          onCancel={handleCancel}
          width={width}
          height={height}
          loading={loading}
          destroyOnClose={destroyOnClose}
          getContainer={getContainer}
          forceRender={forceRender}
          centered
          footer={
            footer || (
              <MySpace fullWidth spaceBetween>
                {hideFooter ? null : (
                  <MySpace align="start" style={{ marginTop: 4 }}>
                    <MyButtonMain
                      key="ok"
                      onClick={onOk}
                      success={success}
                      label={labelOk || t("WORD_OK")}
                      disabled={disableOk}
                      size="large"
                    />
                    <MyButtonSecondary
                      key="cancel"
                      onClick={handleCancel}
                      label={t("WORD_CANCEL")}
                      reset
                    />
                  </MySpace>
                )}
              </MySpace>
            )
          }
        >
          {children}
        </Modal>
      </>
    );
  }
);

export default MyModal;
