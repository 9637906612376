import React from "react";
import { Popconfirm } from "antd";
import { useTranslation } from "react-i18next";
import { QuestionCircleOutlined } from "@ant-design/icons";
import MyButtonGraySmall from "./MyButtonGraySmall";
import "./MyButton.scss";

const MyButtonTableActionDelete = ({
  disabled,
  cancelText,
  icon,
  label,
  onCancel,
  onConfirm,
  title,
  okText,
  loading,
  onClick,
  ...otherProps
}) => {
  const { t } = useTranslation();

  const handleCancel = () => {
    onCancel && onCancel();
  };

  return (
    <Popconfirm
      title={title || t("WORD_ARE_YOU_SURE_YOU_WANT_TO_DELETE")}
      onConfirm={onConfirm}
      onCancel={handleCancel}
      okText={okText || t("WORD_DELETE")}
      cancelText={cancelText || t("WORD_CANCEL")}
      disabled={disabled}
      okButtonProps={{
        color: "danger",
        type: "primary",
        danger: true,
        style: {
          borderRadius: 12,
        },
      }}
      cancelButtonProps={{
        type: "solid",
        style: {
          backgroundColor: "#a7b4d3",
          color: "#fff",
          borderRadius: 12,
        },
      }}
      icon={
        <QuestionCircleOutlined
          style={{
            color: "red",
          }}
        />
      }
    >
      <div>
        <MyButtonGraySmall
          {...otherProps}
          loading={loading}
          label={label}
          icon={icon}
          danger={"danger"}
          onClick={onClick}
          disabled={disabled}
        />
      </div>
    </Popconfirm>
  );
};

export default MyButtonTableActionDelete;
