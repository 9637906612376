import React, { forwardRef, useMemo } from "react";
import MySpace from "../../../components/mySpace/MySpace";
import { useTranslation } from "react-i18next";
import MyDivider from "../../../components/myDivider/MyDivider";
import UtilNumber from "../../../utils/UtilNumber";
import UtilDate from "../../../utils/UtilDate";
import OrdersActions from "../../../actions/OrdersActions";
import MyTable from "../../../components/myTable/MyTable";
import ActivityRightCardRow from "../../../components/activityRightCardRow/ActivityRightCardRow";
import MyTextTitle from "../../../components/myText/MyTextTitle";
import ImagePlaceholderSVG from "../../../icons/ImagePlaceholderSVG";
import MyImage from "../../../components/myImage/MyImage";
import TitleDataRowAlignEnd from "../../../components/titleDataRowAlignEnd/TitleDataRowAlignEnd";
import { useFormatCurrency } from "../../../utils/useFormatWithCurrency";
import "./OrderPrint.scss";

const OrderViewPrint = forwardRef(
  ({ isPayment, orderData, withoutImage, isOrderView }, ref) => {
    const { t } = useTranslation();
    const { formatDoubleWithCurrency } = useFormatCurrency();

    const columns = useMemo(() => {
      let col = [
        {
          title: t("WORD_PRODUCTS"),
          dataIndex: "nameProduct",
          key: "nameProduct",
          //  width: 120,
        },
        {
          title: t("WORD_PRICE"),
          dataIndex: "price",
          key: "price",
          width: 150,
          render: (value) => <div>{formatDoubleWithCurrency(value)}</div>,
        },
        {
          title: t("WORD_QUANTITY"),
          dataIndex: "quantity",
          key: "quantity",
          width: 100,
        },
        {
          title: t("WORD_TOTAL_AMOUNT"),
          dataIndex: "totalPrice",
          key: "totalPrice",
          width: 120,
          render: (value) => <div>{formatDoubleWithCurrency(value)}</div>,
        },
      ];
      if (withoutImage) {
        col.unshift({
          title: t("WORD_ICON"),
          dataIndex: "icon",
          key: "icon",
          align: "center",
          width: 60,
          render: (_, { icon }) =>
            icon ? (
              <MyImage icon={icon} width={25} height={30} />
            ) : (
              <div>
                <ImagePlaceholderSVG size={25} />
              </div>
            ),
        });
      }
      return col;
    }, [t, formatDoubleWithCurrency, withoutImage]);

    let status = OrdersActions.getOrderStatusLabel(orderData?.status);
    let type = OrdersActions.getOrderTypeLabel(orderData?.type);
    // let exported = OrdersActions.getPaymentExportLabel(orderData.exportedAdmin);

    return (
      <div className="normal-print">
        <MySpace direction="vertical" fullWidth style={{ gap: 5 }}>
          <MyTextTitle bold className="view-print-title">
            {isPayment ? t("WORD_PAYMENT_ID") : t("WORD_ORDER_ID")}: #
            {orderData?.idOrder}
          </MyTextTitle>
          <MyDivider smaller />
          <MySpace>
            <div>
              <span className="column-render-title">
                {t("WORD_CUSTOMER")} :{" "}
              </span>
              <span className="card-row-for-print">
                {orderData?.nameCustomer} |{" "}
              </span>
              <span className="column-render-title">
                {t("WORD_DISTRIBUTOR")} :{" "}
              </span>
              <span className="card-row-for-print">
                {orderData?.nameManager} |{" "}
              </span>
              <span className="column-render-title">{t("WORD_BRANCH")} : </span>
              <span className="card-row-for-print">
                {orderData?.nameBranch}
              </span>
            </div>
          </MySpace>
          <MyDivider smaller />
          <MySpace fullWidth spaceBetween align="top" size="large">
            <div></div>
            <MySpace direction="vertical" style={{ gap: 0 }}>
              {isOrderView ? (
                <ActivityRightCardRow
                  title={t("WORD_TYPE")}
                  data={t(type.label)}
                  bold
                />
              ) : null}
              {isOrderView ? (
                <ActivityRightCardRow
                  title={t("WORD_STATUS")}
                  data={t(status.label)}
                  bold
                />
              ) : null}
              <ActivityRightCardRow
                title={t("WORD_CREATE_DATE")}
                data={UtilDate.formatDateTime(orderData?.createdAt)}
                bold
              />
              <ActivityRightCardRow
                title={t("WORD_DELIVERY_DATE")}
                data={UtilDate.formatDateTime(orderData?.dueDate)}
                bold
              />
              <ActivityRightCardRow
                title={t("WORD_PAYMENT_TYPE")}
                data={orderData?.namePaymenttype}
                bold
              />
              {isOrderView && orderData.tax ? (
                <ActivityRightCardRow
                  title={t("WORD_TAX")}
                  data={orderData?.tax}
                  bold
                />
              ) : null}
              {orderData?.note ? (
                <ActivityRightCardRow
                  title={t("WORD_NOTE")}
                  data={orderData?.note}
                  bold
                />
              ) : null}
            </MySpace>
          </MySpace>
          <MyDivider smaller />
          {isPayment ? null : (
            <MyTable
              rowKey="idOrderItem"
              columns={columns}
              dataSource={orderData?.orderItems}
              className="order-view-table-print"
              noFooter
            />
          )}
          <MySpace fullWidth spaceBetween>
            <div></div>
            <MySpace
              direction="vertical"
              fullWidth
              align="top"
              style={{ gap: 0 }}
            >
              <TitleDataRowAlignEnd
                title={t("WORD_TOTAL")}
                data={
                  <div>{formatDoubleWithCurrency(orderData?.totalSub)}</div>
                }
              />
              <TitleDataRowAlignEnd
                title={t("WORD_DISCOUNT")}
                data={`${UtilNumber.formatDouble(orderData?.discountPer)}%`}
              />
              <TitleDataRowAlignEnd
                title={t("WORD_TOTAL_AMOUNT")}
                data={
                  <div>{formatDoubleWithCurrency(orderData?.totalAmount)}</div>
                }
              />
            </MySpace>
          </MySpace>
        </MySpace>
      </div>
    );
  }
);

export default OrderViewPrint;
