import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelectFormItem from "../mySelectFormItem/MySelectFormItem";
import UtilNotify from "../../utils/UtilNotify";
import CityActions from "../../actions/CityActions";

const SelectCity = ({ idRegion, ...otherProps }) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    const fill = async () => {
      if (idRegion) {
        const result = await CityActions.fillListOptions(idRegion);
        if (result.success) {
          setlist(result.data);
        } else {
          UtilNotify.notifyErrorServer(result.errMsg);
        }
      }
    };
    fill();
  }, [t, idRegion]);

  return (
    <MySelectFormItem
      {...otherProps}
      options={
        idRegion
          ? list
          : [
              {
                value: "warning",
                label: t("WORD_PLEASE_SELECT_A_REGION_FIRST"),
                disabled: true,
              },
            ]
      }
    />
  );
};

export default SelectCity;
