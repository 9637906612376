import React, { useState } from "react";
import ForgotPasswordEmail from "./ForgotPasswordEmail";
import ForgotPasswordRecovery from "./ForgotPasswordRecovery";

const ForgotPasswordPage = () => {
  const [step, setStep] = useState(1);

  return (
    <>
      {step === 1 ? (
        <ForgotPasswordEmail
          onNext={() => {
            setStep(2);
          }}
        />
      ) : (
        <ForgotPasswordRecovery />
      )}
    </>
  );
};

export default ForgotPasswordPage;
