import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "antd/es/form/Form";
import CheckBoxFormItem from "../../components/myCheckbox/CheckBoxFormItem";
import paymentActions from "../../actions/PaymentActions";
import { Form } from "antd";
import UtilNotify from "../../utils/UtilNotify";
import { UserContext } from "../../layouts/settingsLayout/SettingsLayout";
import MySpaceColumn from "../../components/mySpace/MySpaceColumn";
import EditPageForm from "../../components/editPageForm/EditPageForm";

const formName = "payment_form";

const PaymentTypeSettings = () => {
  const { t } = useTranslation();
  const [form] = useForm();

  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);

  const { userData } = useContext(UserContext);

  useEffect(() => {
    const fill = async () => {
      setLoading(true);
      const result = await paymentActions.paymentTypeOptions();
      if (result.success) {
        form?.setFieldsValue({
          ...userData,
          paymentTypes: result.data,
        });
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setLoading(false);
    };
    fill();
  }, [form, userData]);

  const onFinish = async (values) => {
    const list = values.paymentTypes.filter((it) => {
      return it.used;
    });
    if (!list.length) {
      UtilNotify.notifyError(t("WORD_PLEASE_SELECT_ONE"));
      return;
    }
    setSaving(true);
    let result = await paymentActions.save(list);
    setSaving(false);
    if (result.success) {
      UtilNotify.notifySuccess(t("WORD_SETTINGS_SAVED"));
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
  };

  return (
    <EditPageForm
      title={t("WORD_PAYMENT_TYPES")}
      form={form}
      formName={formName}
      onFinish={onFinish}
      saving={saving}
      loading={loading}
    >
      <Form.List name="paymentTypes">
        {(fields) => (
          <MySpaceColumn fullWidth>
            {fields.map((field, index) => {
              let list = form.getFieldValue("paymentTypes");
              let item = list[index];
              return (
                <CheckBoxFormItem
                  name={[field.name, "used"]}
                  key={field.key}
                  label={
                    <span>
                      {t(item.name)}
                      {item.type !== paymentActions.TYPE.OTHER ? (
                        <strong style={{ marginLeft: 4 }}>
                          ({t(paymentActions.getType(item.type).labelShort)})
                        </strong>
                      ) : (
                        ""
                      )}
                    </span>
                  }
                />
              );
            })}
          </MySpaceColumn>
        )}
      </Form.List>
    </EditPageForm>
  );
};

export default PaymentTypeSettings;
