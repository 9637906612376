import React, { memo } from "react";
import MyIcon from "./MyIcon";

const SVGIcon = ({ size }) => {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M16.8843 5.11486H13.9412C13.2081 5.1197 12.512 4.79355 12.0474 4.22751L11.0782 2.88763C10.6214 2.31662 9.92529 1.98894 9.1932 2.00028H7.1126C3.37818 2.00028 2 4.19202 2 7.91885V11.9474C1.99737 12.1978 6.38549 9.30646 11.9412 9.27375C16.2151 9.24859 21.9964 12.1397 21.9969 11.9474V10.7761C22.0147 7.04924 20.6721 5.11486 16.8843 5.11486Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8321 6.54378C21.1521 6.91786 21.3993 7.34817 21.5612 7.81267C21.8798 8.76736 22.0273 9.77061 21.9969 10.7764V16.0294C21.9956 16.4719 21.963 16.9137 21.8991 17.3516C21.7775 18.1243 21.5057 18.8658 21.0989 19.5344C20.9119 19.8574 20.6849 20.1555 20.4231 20.4218C19.2383 21.5092 17.665 22.0752 16.0574 21.9924H7.93061C6.3205 22.0746 4.74462 21.5088 3.55601 20.4218C3.29741 20.155 3.07337 19.8569 2.88915 19.5344C2.48475 18.8663 2.21869 18.1241 2.1067 17.3516C2.03549 16.9144 1.99981 16.4723 2 16.0294V10.7764C1.99983 10.3377 2.02357 9.89927 2.07113 9.46312C2.08114 9.3866 2.09614 9.31133 2.11098 9.23684C2.13573 9.11265 2.16005 8.99063 2.16005 8.86861C2.25031 8.34228 2.41496 7.83141 2.64908 7.35126C3.34261 5.8694 4.76525 5.11516 7.09482 5.11516H16.8754C18.1802 5.01425 19.4753 5.40705 20.5032 6.21546C20.6215 6.31584 20.7316 6.42564 20.8321 6.54378ZM11.9992 9.5C12.4549 9.5 12.8242 9.86911 12.8242 10.3244V12.6748H15.1756C15.6309 12.6748 16 13.0442 16 13.4998C16 13.9554 15.6309 14.3248 15.1756 14.3248H12.8242V16.6756C12.8242 17.1309 12.4549 17.5 11.9992 17.5C11.5436 17.5 11.1742 17.1309 11.1742 16.6756V14.3248H8.82444C8.36912 14.3248 8 13.9554 8 13.4998C8 13.0442 8.36912 12.6748 8.82444 12.6748H11.1742V10.3244C11.1742 9.86911 11.5436 9.5 11.9992 9.5Z"
        fill="white"
      />
    </svg>
  );
};

const FolderCreateWhiteSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(FolderCreateWhiteSVG);
