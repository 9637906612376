import React from "react";
import MyLink from "../../components/myLink/MyLink";
import { useTranslation } from "react-i18next";
import MenuSVG from "../../icons/MenuSVG";
import { Flex } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import MyDropDown from "../../components/myDropDown/MyDropDown";
import langIcon from "../../icons/lang-1.webp";

const OutsideLayoutHeader = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    const newPath = `/${lang}${location.pathname.slice(3)}`;
    navigate(newPath);
  };

  const items = [
    {
      key: "en",
      label: t("WORD_ENGLISH"),
      onClick: () => handleLanguageChange("en"),
    },
    {
      key: "hy",
      label: t("WORD_ARMENIAN"),
      onClick: () => handleLanguageChange("hy"),
    },
    {
      key: "ru",
      label: t("WORD_RUSSIAN"),
      onClick: () => handleLanguageChange("ru"),
    },
    {
      key: "ar",
      label: t("WORD_ARABIC"),
      onClick: () => handleLanguageChange("ar"),
    },
  ];

  return (
    <Flex justify="space-between">
      <MyLink path="/login">
        <MenuSVG />
      </MyLink>
      <MyDropDown arrow="false" items={items} placement="bottomRight">
        <img style={{ width: 44, height: 44 }} src={langIcon} alt="flag" />
      </MyDropDown>
    </Flex>
  );
};

export default OutsideLayoutHeader;
