import { Progress } from "antd";
import React from "react";

const MyProgress = ({ percent, showInfo }) => {
  return (
    <Progress className="my-progress" percent={percent} showInfo={showInfo} />
  );
};

export default MyProgress;
