import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PartnersSVG from "../../../icons/PartnersSVG";
import AnalyticsActions from "../../../actions/AnalyticsActions";
import UtilNotify from "../../../utils/UtilNotify";
import AnalyticsCustomersCardBlock from '../analyticsStatsCard/AnalyticsCustomersCardBlock';

const AnalyticsCustomersCard = ({ filter }) => {

  const { t } = useTranslation();

  const [loading, setloading] = useState(true);
  const [data, setdata] = useState(0);

  useEffect(() => {
    const fill = async () => {
      setloading(true);
      const result = await AnalyticsActions.customerCount({ filter: filter });
      if (result.success) {
        setdata(result.data?.count);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setloading(false);
    }
    fill();
  }, [filter])

  return (
    <AnalyticsCustomersCardBlock
      loading={loading}
      value={data}
      title={t("WORD_CUSTOMERS")}
      icon={<PartnersSVG fill="rgb(118, 118, 118)" size={30} />}
    />
  );
};

export default AnalyticsCustomersCard;
