import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import MyModal from "../../../components/myModal/MyModal";
import ActivityRightCardRow from "../../../components/activityRightCardRow/ActivityRightCardRow";
import UtilDate from "../../../utils/UtilDate";
import MyImage from "../../../components/myImage/MyImage";
import MySpace from "../../../components/mySpace/MySpace";
import ProductActions from "../../../actions/ProductActions";
import UtilNotify from "../../../utils/UtilNotify";
import { useFormatCurrency } from "../../../utils/useFormatWithCurrency";

const ProductViewModal = forwardRef((props, ref) => {
  const refModal = useRef();
  const { t } = useTranslation();
  const { formatDoubleWithCurrency } = useFormatCurrency();

  const [product, setProduct] = useState({});

  const fill = useCallback(async (idProduct) => {
    if (idProduct) {
      const result = await ProductActions.getOne(idProduct);
      if (result.success) {
        setProduct(result.data);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
    }
  }, []);

  useImperativeHandle(ref, () => ({
    viewProduct: async (idP) => {
      refModal.current.open();
      await fill(idP);
    },
  }));

  return (
    <MyModal ref={refModal} title={t("WORD_PRODUCT_DETAILS")} hideFooter>
      {product?.icon ? <MyImage icon={product?.icon} withMargin /> : null}
      <MySpace direction="vertical" style={{ gap: 5 }}>
        <ActivityRightCardRow
          noSpace
          bold
          data={product?.name}
          style={{ display: "block", marginBottom: 5 }}
        />
        <ActivityRightCardRow
          noSpace
          title={t("WORD_BARCODE")}
          bold
          data={product?.barcode}
          style={{ display: "block" }}
        />
        <ActivityRightCardRow
          noSpace
          title={t("WORD_PRICE")}
          bold
          data={formatDoubleWithCurrency(product?.price)}
          style={{ display: "block" }}
        />
        <ActivityRightCardRow
          noSpace
          title={t("WORD_MODIFIED_DATE")}
          bold
          data={UtilDate.formatDateTime(product?.updateAt)}
          style={{ display: "block" }}
        />
        <ActivityRightCardRow
          noSpace
          title={t("WORD_DESCRIPTION")}
          bold
          data={product?.description}
          style={{ display: "block" }}
        />
      </MySpace>
    </MyModal>
  );
});

export default ProductViewModal;
