import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useNavigateApi from "../../appRoute/useNavigateApi";
import { useSelector } from "react-redux";

const AnalyticsMenu = () => {
  const navigateApi = useNavigateApi();
  const location = useLocation();
  const { t } = useTranslation();

  const [selectedMenuKey, setselectedMenuKey] = useState(location.pathname);

  const lang = useSelector((state) => {
    return state.lang;
  });

  useEffect(() => {
    setselectedMenuKey(location.pathname);
  }, [location.pathname]);

  const items = [
    {
      label: t("WORD_ORDERS"),
      key: `/${lang}/analytics/orders`,
      onClick: () => {
        navigateApi(`/analytics/orders`);
      },
    },
    {
      label: t("WORD_PAYMENTS"),
      key: `/${lang}/analytics/payments`,
      onClick: () => {
        navigateApi(`/analytics/payments`);
      },
    },
    {
      label: t("WORD_CUSTOMERS"),
      key: `/${lang}/analytics/customers`,
      onClick: () => {
        navigateApi(`/analytics/customers`);
      },
    },
  ];

  return (
    <Menu
      className="second-side-menu"
      mode="vertical"
      defaultSelectedKeys={["1"]}
      items={items}
      selectedKeys={[selectedMenuKey]}
      onSelect={(menu) => {
        setselectedMenuKey(menu?.key);
      }}
    />
  );
};

export default AnalyticsMenu;
