import React, { useCallback, useEffect, useMemo, useState } from "react";
import MySpace from "../../components/mySpace/MySpace";
import MyPageHeader from "../../components/myPageHeader/MyPageHeader";
import { useTranslation } from "react-i18next";
import MyTable from "../../components/myTable/MyTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrashCan,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import ExportTemplatesActions from "../../actions/ExportTemplatesActions";
import UtilNotify from "../../utils/UtilNotify";
import MyTooltip from "../../components/myTooltip/MyTooltip";
import useNavigateApi from "../../appRoute/useNavigateApi";
import MyHelmet from "../../components/helmet/MyHelmet";
import MyButtonTableActionDelete from "../../components/myButton/MyButtonTableActionDelete";
import MyButtonSuccess from "../../components/myButton/MyButtonSuccess";
import MyButtonTableAction from "../../components/myButton/MyButtonTableAction";
import MyCard from "../../components/myCard/MyCard";
import MyDivider from "../../components/myDivider/MyDivider";

const ExportTemplatesList = () => {
  const { t } = useTranslation();
  const navigateApi = useNavigateApi();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fill = async () => {
      setLoading(true);
      const result = await ExportTemplatesActions.fillList([]);
      if (result.success) {
        setData(result.data);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setLoading(false);
    };
    fill();
  }, []);

  const handleExportDelete = useCallback(
    async (idExportTemplet) => {
      if (idExportTemplet) {
        const result = await ExportTemplatesActions.delete(idExportTemplet);
        if (result.success) {
          setData((prevData) =>
            prevData.filter((item) => item.idExportTemplet !== idExportTemplet)
          );
          UtilNotify.notifySuccess(t("WORD_REMOVED_SUCCESSFULLY"));
        } else {
          UtilNotify.notifyErrorServer(result.errMsg);
        }
      }
    },
    [setData, t]
  );

  const columns = useMemo(() => {
    let col = [
      {
        title: t("WORD_NAME"),
        dataIndex: "title",
        key: "title",
      },
      {
        title: t("WORD_VIEW_IN"),
        dataIndex: "viewIn",
        key: "viewIn",
        width: 150,
      },
      {
        title: t("WORD_FILE_TYPE"),
        dataIndex: "fileType",
        key: "fileType",
        width: 100,
      },
      {
        title: t("WORD_ACTIONS"),
        dataIndex: "actions",
        width: 200,
        key: "actions",
        render: (_, { idExportTemplet, isEditable }) =>
          !isEditable ? null : (
            <MySpace>
              <MyButtonTableAction
                title={t("WORD_EDIT")}
                icon={faPen}
                onClick={() =>
                  navigateApi(
                    `/settings/exportTemplates/edit/${idExportTemplet}`
                  )
                }
              />
              <MyButtonTableAction
                title={t("WORD_DOWNLOAD")}
                icon={faDownload}
                onClick={async () =>
                  await ExportTemplatesActions.exportFile({}, idExportTemplet)
                }
              />
              <MyTooltip title={t("WORD_DELETE")}>
                <MyButtonTableActionDelete
                  onConfirm={() => {
                    handleExportDelete(idExportTemplet);
                  }}
                  danger
                  icon={
                    <FontAwesomeIcon icon={faTrashCan} color="#fff" size="xs" />
                  }
                />
              </MyTooltip>
            </MySpace>
          ),
      },
    ];
    return col;
  }, [t, navigateApi, handleExportDelete]);

  return (
    <>
      <MyHelmet title={t("WORD_EXPORT_TEMPLATES")} />
      <MyCard>
        <MySpace direction="vertical" fullWidth>
          <MyPageHeader
            title={t("WORD_EXPORT_TEMPLATES")}
            extraRight={
              <MyButtonSuccess
                withIcon
                label={t("WORD_ADD_TEMPLATE")}
                onClick={() => navigateApi(`/settings/exportTemplates/create`)}
              />
            }
          />
          <MyDivider />
          <MyTable
            rowKey="idExportTemplet"
            columns={columns}
            dataSource={data}
            loading={loading}
            pagination={false}
            noFooter
          />
        </MySpace>
      </MyCard>
    </>
  );
};

export default ExportTemplatesList;
