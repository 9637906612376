import io from "socket.io-client";
import ApiStorage from "../redux/ApiStorage";
import ClientSaveActions from "../actions/ClientSaveActions";

const socket = io(process.env.REACT_APP_SOCKET_URL, {
  autoConnect: true,
  forceNew: false,
  reconnection: true,
  withCredentials: true,
});

const UtilSocket = {
  startClientChannel: (idClient) => {
    // console.warn('start socket on idClient :>> ', idClient);
    if (idClient) {
      socket.on(idClient, (res) => {
        console.warn("UtilSocket.onClientChannel :>> ", res);
        switch (res.type) {
          case "badgeCountOrderPayment":
            ApiStorage.setBadgeCountOrder(res.data.badgeCountOrder);
            ApiStorage.setBadgeCountPayment(res.data.badgeCountPayment);
            break;
          case "stepDone":
            ApiStorage.setCurrentUser(
              Object.assign(ApiStorage.getCurrentUser(), res.data)
            );
            break;
          default:
            break;
        }
      });
      ClientSaveActions.initSocket();
    }
  },
  closeSocket: () => {
    try {
      // socket.off(ApiStorage.getCurrentUser()?.idClient)
      socket.disconnect();
    } catch (error) {
      console.warn("error :>> ", error);
    }
  },
};

export default UtilSocket;
