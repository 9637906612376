import React, { memo, useEffect, useMemo, useState } from "react";
import MyCard from "../../components/myCard/MyCard";
import MyText from "../../components/myText/MyText";
import { useTranslation } from "react-i18next";
import MyTable from "../../components/myTable/MyTable";
import MyLink from "../../components/myLink/MyLink";
import UtilNumber from "../../utils/UtilNumber";
import { Flex } from "antd";
import StatActions from "../../actions/StatActions";
import UtilNotify from "../../utils/UtilNotify";

const DashboardTopCustomersCard = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fill = async () => {
      setLoading(true);
      const result = await StatActions.dashboardStatsCustomers();
      if (result.success) {
        setData(result.data);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setLoading(false);
    };
    fill();
  }, []);

  const columns = useMemo(() => {
    let col = [
      {
        title: " ",
        dataIndex: "nameCustomer",
        key: "nameCustomer",
        render: (value, { idCustomer }) => (
          <MyLink path={`/customer/edit/${idCustomer}`}>{value}</MyLink>
        ),
      },
      {
        title: t("WORD_TOTAL"),
        dataIndex: "totalAmount",
        key: "totalAmount",
        width: 100,
        align: "right",
        render: (value) => {
          return UtilNumber.formatWithK(value);
        },
      },
      {
        title: t("WORD_ORDER"),
        dataIndex: "orderCount",
        key: "orderCount",
        align: "right",
        width: 70,
      },
    ];
    return col;
  }, [t]);

  return (
    <MyCard fullHeight>
      <Flex justify="space-between" align="center">
        <MyText className="stat-header" size={16}>
          {t("WORD_TOP_CUSTOMERS")}
        </MyText>
        <MyText className="stat-header" size={13}>
          {t("WORD_LAST_30_DAYS")}
        </MyText>
      </Flex>
      <MyTable
        rowKey="idCustomer"
        className="dashboard-table"
        columns={columns}
        dataSource={data}
        loading={loading}
        rowHoverable={false}
        scroll={{ y: 200 }}
        noFooter
      />
    </MyCard>
  );
};

export default memo(DashboardTopCustomersCard);
