import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelectFormItem from "../mySelectFormItem/MySelectFormItem";
import UtilNotify from "../../utils/UtilNotify";
import paymentActions from "../../actions/PaymentActions";

const SelectOrderPaymentType = ({ withAll, ...otherProps }) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    const fill = async () => {
      const result = await paymentActions.fillListOptions();
      if (result.success) {
        setlist(result.data);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }

      if (withAll) result.data.unshift({ label: t("WORD_ALL"), value: "" });
    };
    fill();
  }, [t, withAll]);

  return <MySelectFormItem {...otherProps} options={list} />;
};

export default SelectOrderPaymentType;
