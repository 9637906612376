import { Segmented } from "antd";
import React from "react";
import "./MySegmentedForm.scss";

const MySegmentedVertical = ({ block, options, onChange, disabled, value }) => {
  return (
    <Segmented
      vertical
      block={block}
      options={options}
      onChange={onChange}
      disabled={disabled}
      className="my-segment-vertical"
      value={value}
    />
  );
};

export default MySegmentedVertical;
