import { useForm } from "antd/es/form/Form";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import MyModal from "../../../components/myModal/MyModal";
import FormApp from "../../../components/formApp/FormApp";
import MySpace from "../../../components/mySpace/MySpace";
import InputPasswordFormItem from "../../../components/myInputForm/InputPasswordFormItem";
import MyDivider from "../../../components/myDivider/MyDivider";
import UserActions from "../../../actions/UserActions";
import UtilNotify from "../../../utils/UtilNotify";
import md5 from "md5";

const formName = "formBranchEditPassword";

const UserChangePasswordModal = forwardRef((props, ref) => {
  const refModal = useRef();
  const { t } = useTranslation();
  const [form] = useForm();

  const [idUser, setidUser] = useState({});
  const [saving, setSaving] = useState(false);

  useImperativeHandle(ref, () => ({
    viewChangePassword: (idU) => {
      setidUser(idU);
      refModal.current.open();
    },
  }));

  const onFinish = async (values) => {
    setSaving(true);
    let password = md5(values.password);
    values.password = password;
    const result = await UserActions.updatePassword(values, idUser);
    if (result.success) {
      UtilNotify.notifySuccess(t("WORD_PASSWORD_UPDATED"));
      refModal.current.close();
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
  };

  return (
    <MyModal
      ref={refModal}
      title={t("WORD_CHANGE_PASSWORD")}
      labelOk={t("WORD_SAVE_CHANGES")}
      width={400}
      onOk={() => form.submit()}
      saving={saving}
      success
    >
      <FormApp form={form} name={formName} onFinish={onFinish}>
        <MySpace direction="vertical" fullWidth>
          <InputPasswordFormItem label={t("WORD_PASSWORD")} name="password" />
        </MySpace>
      </FormApp>
      <MyDivider />
    </MyModal>
  );
});

export default UserChangePasswordModal;
