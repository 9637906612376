import axios from "axios";
import { history } from "../App";
import ApiStorage from "../redux/ApiStorage";
import UtilNotify from "./UtilNotify";
import UtilNumber from "./UtilNumber";
import UtilDate from "./UtilDate";

let BASE_URL = process.env.REACT_APP_BASE_NODE_URL;
console.warn("BASE_URL :>> ", BASE_URL);

export const getBaseURL = () => {
  return `${BASE_URL}/api/v4/`;
};

export const getBaseURLShowImage = (src) => {
  const iconSrc = src
    ? src.startsWith("http")
      ? src
      :
      `${process.env.REACT_APP_DO_SPACES_READ_URL}/${src}`
    : "";
  return iconSrc;
};

const adapter = axios.create({
  baseURL: getBaseURL(),
  headers: {
    Accept: "application/json;charset=UTF-8",
    "Content-Type": "application/json",
  },
  validateStatus: false,
  withCredentials: false,
  timeout: 30000,
});

export async function fetchFromUrlPOSTAsync(url, parameters = []) {
  return await fetchFromUrlPOSTAsyncMain(url, parameters, {});
}

export async function fetchFromUrlPOSTAsyncLong(url, parameters = []) {
  return await fetchFromUrlPOSTAsyncMain(url, parameters, { timeout: 600000 });
}

export async function fetchFromUrlPOSTAsyncDownloadFile(
  url,
  parameters = [],
  fileName
) {
  let response = await fetchFromUrlPOSTAsyncMain(url, parameters, {
    responseType: "blob",
  });

  if (response.hasOwnProperty("errMsg")) {
    return response;
  }

  const blob = new Blob([response], { encoding: "UTF-8" });
  // const blob = await response.blob();
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);

  if (!fileName) {
    const nam = UtilDate.getDateToday().format("DD-MM-YYYY_HH-MM-ss");
    if (response.type === "text/plain") {
      fileName = `${nam}.txt`;
    } else if (
      response.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      fileName = `${nam}.xlsx`;
    } else if (response.type === "application/xml") {
      fileName = `${nam}.xml`;
    }
  }
  link.download = fileName;
  link.click();
  return response;
}

export async function fetchFromUrlPOSTAsyncMain(
  url,
  parameters = [],
  adapterOptions
) {
  try {
    let deviceKey = ApiStorage.getDeviceKey();
    if (!deviceKey) {
      ApiStorage.setDeviceKey(UtilNumber.getNewUniqID());
      deviceKey = ApiStorage.getDeviceKey();
    }

    let dataPost = {
      device: "web",
      offset: getTimeZone(),
      deviceKey: deviceKey.toString(),
    };

    if (Array.isArray(parameters)) {
      parameters.forEach((par) => {
        dataPost[par.key] = par.value;
      });
    } else {
      Object.assign(dataPost, parameters);
    }

    let token = ApiStorage.getAccessToken();
    if (token) {
      adapter.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete adapter.defaults.headers.common["Authorization"];
    }
    let result = await adapter.post(url, dataPost, adapterOptions);
    if (result.status === 401) {
      // history.push("/login",{force: true})
      // window.location.reload();
    } else if (result.status === 410) {
      ApiStorage.clearUser();
      history.push({
        pathname: "/en/login",
        replace: true,
        state: { force: true },
      });
      window.location.reload();
    } else if (result.status === 500) {
      UtilNotify.notifyErrorServer(result.statusText);
      return { success: false, errMsg: result.statusText };
    }
    return result.data;
  } catch (error) {
    console.warn("error = ", error);
    UtilNotify.notifyErrorServer(error.message);
    // history.push("/en/page_not_found", { force: true })
    // window.location.reload();
    return { success: false, errMsg: error.message };
  }
}

export async function fetchFromUrlGETAsync(url, parameters = []) {
  try {
    let dataPost = {
      device: "web",
      offset: getTimeZone(),
    };

    parameters.forEach((par) => {
      dataPost[par.key] = par.value;
    });
    let token = ApiStorage.getAccessToken();
    if (token) {
      adapter.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete adapter.defaults.headers.common["Authorization"];
    }
    let result = await adapter.get(url, {
      params: dataPost,
    });
    return result.data;
  } catch (error) {
    console.warn("error = ", error);
    return { success: false, errMsg: error.message };
  }
}

function getTimeZone() {
  let offset = new Date().getTimezoneOffset();
  const result = `+${Math.abs(offset)}`;
  return result;
}

export async function downloadFile(url) {
  // const response = await axios({
  //   url: url,
  //   method: "GET",
  //   // headers: headers,
  //   headers: {
  //     Accept: "application/json;charset=UTF-8",
  //     "Content-Type": "application/xml",
  //   },
  //   responseType: "blob" // important
  // })
  const adapter = axios.create({
    baseURL: process.env.REACT_APP_BASE_NODE_URL,
    headers: {
      Accept: "application/json;charset=UTF-8",
      "Content-Type": "application/json",
    },
    validateStatus: false,
    withCredentials: false,
    timeout: 30000,
  });

  let response = await adapter.get(url, {
    responseType: "blob",
  });

  console.warn("response :>> ", response);
  // const url = window.URL.createObjectURL(new Blob([response.data]));
  // const link = document.createElement("a");
  // link.href = url;
  // link.setAttribute(
  //   "download",
  //   `${this.props.file.name}.${this.props.file.mime}`
  // );
  // document.body.appendChild(link);
  // link.click();

  // // Clean up and remove the link
  // link.parentNode.removeChild(link);
}

export default adapter;
