import React, { useRef } from "react";
import MySpace from "../../../components/mySpace/MySpace";
import SelectOrderPaymentType from "../../../components/selects/SelectOrderPaymentType";
import SelectOrderBranch from "../../../components/selects/SelectOrderBranch";
import { FilterOutlined } from "@ant-design/icons";
import SelectOrderExported from "../../../components/selects/SelectOrderExported";
import { useTranslation } from "react-i18next";
import MyDrawerWithReset from "../../../components/myDrawer/MyDrawerWithReset";
import SelectCustomerMulti from "../../../components/selects/SelectCustomerMulti";
import InputFormRangeDatePicker from "../../../components/inputFormRangeDatePicker/InputFormRangeDatePicker";
import SelectOrderType from "../../../components/selects/SelectOrderType";
import SelectOrderStatus from "../../../components/selects/SelectOrderStatus";

const OrderListFilterDrawerFewer = ({ onReset, loading, formName }) => {
  const { t } = useTranslation();
  const refDrawer = useRef();

  const handleReset = () => {
    onReset && onReset();
    refDrawer?.current?.closeDrawer();
  };

  return (
    <MyDrawerWithReset
      ref={refDrawer}
      label={t("WORD_MORE_FILTERS")}
      icon={<FilterOutlined />}
      loading={loading}
      formName={formName}
      // definedCount={definedCount}
      definedCount={1}
      handleReset={handleReset}
      // forceRender={true}
    >
      <MySpace direction="vertical" size="middle" fullWidth>
        <InputFormRangeDatePicker
          label={t("WORD_DATE")}
          name="date"
          allowClear={false}
        />
        <SelectOrderPaymentType
          placeholder={t("WORD_SELECT_PAYMENT_TYPE")}
          label={t("WORD_SELECT_PAYMENT_TYPE")}
          name="idPaymentType"
        />
        <SelectOrderBranch
          placeholder={t("WORD_SELECT_BRANCH")}
          label={t("WORD_SELECT_BRANCH")}
          name="idBranch"
        />
        <SelectCustomerMulti
          placeholder={t("WORD_SELECT_CUSTOMER")}
          label={t("WORD_SELECT_CUSTOMER")}
          name="idCustomer"
        />
        <SelectOrderExported
          placeholder={t("WORD_SELECT_EXPORT")}
          label={t("WORD_SELECT_EXPORT")}
          name="exportedAdmin"
        />
        <SelectOrderType
          placeholder={t("WORD_SELECT_TYPE")}
          label={t("WORD_SELECT_TYPE")}
          name="type"
        />
        <SelectOrderStatus
          placeholder={t("WORD_SELECT_STATUS")}
          label={t("WORD_SELECT_STATUS")}
          name="status"
        />
      </MySpace>
    </MyDrawerWithReset>
  );
};

export default OrderListFilterDrawerFewer;
