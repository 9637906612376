import React, { useEffect } from "react";
import { Flex, Layout } from "antd";
import MainLayoutSideMenu from "./MainLayoutSideMenu";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import MainLayoutTrialNotif from "./MainLayoutTrialNotif";
import { useParams } from "react-router-dom";
import useWindowSize from "../../utils/useWindowSize";

const { Content } = Layout;

const MainLayout = ({ children, fullPage }) => {
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const { isMobile } = useWindowSize();

  const langRedux = useSelector((state) => {
    return state.lang;
  });

  useEffect(() => {
    if (lang !== langRedux) {
      i18n.changeLanguage(lang);
    }
  }, [lang, langRedux, i18n]);

  return (
    <Layout className="main-layout">
      <MainLayoutSideMenu />
      <Flex
        vertical
        style={{ width: !isMobile ? "calc(100vw - 260px)" : null }}
      >
        <MainLayoutTrialNotif />
        <div className="main-content-wrapper">
          <Content
            className="main-content"
            style={{
              width: fullPage || isMobile ? "100%" : 700,
              // height: !trialClose ? "calc(100% - 30%)" : "100%",
            }}
          >
            {children}
          </Content>
        </div>
      </Flex>
    </Layout>
  );
};

export default MainLayout;
