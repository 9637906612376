import React from "react";
import { useTranslation } from "react-i18next";
import TrialNotifRow from "./TrialNotifRow";

const MainLayoutResubscribeNotif = () => {
  const { t } = useTranslation();

  return (
    <TrialNotifRow
      title={t("WORD_TRIAL_NOTIFICATION_DESCRIPTION")}
      btnLabel={t("WORD_PAY")}
      path={`/settings/package_change`}
    />
  );
};

export default MainLayoutResubscribeNotif;
