import React, { useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const MyCaptcha = ({ onChange }) => {
  const reCaptchaRef = useRef();

  useEffect(() => {
    let isSubscribed = true;
    const check = async () => {
      if (isSubscribed) {
        const token = await reCaptchaRef?.current?.executeAsync();
        // console.log('token :>> ', token);
        if (token) {
          onChange && onChange(token);
        }
      }
    };
    check();
    return () => {
      // ref?.reset();
      isSubscribed = false;
    };
  }, [reCaptchaRef, onChange]);

  // const handleRecaptchaVerify = useCallback(async (token) => {
  //   console.log('reCAPTCHA Token:', token);
  //   if (token) {
  //     onChange && onChange(token);
  //   }
  // }, []);

  // useEffect(() => {
  //   reCaptchaRef.current.executeAsync()
  // }, [reCaptchaRef])

  return (
    <ReCAPTCHA
      ref={reCaptchaRef}
      className="my-captcha"
      sitekey={process.env.REACT_APP_CHAPCHA_KEY}
      onChange={onChange}
      size="invisible"
    />
  );
};

export default MyCaptcha;
