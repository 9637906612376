import React from "react";
import { Layout } from "antd";
import useWindowSize from "../../utils/useWindowSize";
import MyDrawer from "../../components/myDrawer/MyDrawer";
import { SettingOutlined } from "@ant-design/icons";
import AnalyticsMenu from "./AnalyticsMenu";

const { Content } = Layout;

const AnalyticsLayout = ({ children, fullPage }) => {
  const { isMobile } = useWindowSize();

  return (
    <Layout
      style={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        width: fullPage ? "100%" : "60%",
      }}
    >
      {isMobile ? (
        <MyDrawer
          noExtraBtn
          btnClassname="settings-btn"
          icon={<SettingOutlined />}
        >
          <AnalyticsMenu />
        </MyDrawer>
      ) : (
        <AnalyticsMenu />
      )}
      <Content
        className="main-content"
        style={{ width: fullPage ? "100%" : 600, padding: 10 }}
      >
        {children}
      </Content>
    </Layout>
  );
};

export default AnalyticsLayout;
