import React, { memo } from "react";
import MyIcon from "./MyIcon";

const SVGIcon = ({ size, style, ...otherProps }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size || 20}
      height={size || 20}
      viewBox="0 0 68.73 71.512"
      style={style}
      {...otherProps}
    >
      <defs>
        <clipPath id="a">
          <path
            d="M44.184,9.171a2.591,2.591,0,0,1-3.6.982L37.42,8.3a20.678,20.678,0,0,0-21.274,0A20.951,20.951,0,0,0,5.455,26.735v3.6a2.655,2.655,0,0,1-2.618,2.618A2.655,2.655,0,0,1,.218,30.335v-3.6A26,26,0,0,1,13.528,3.607a25.9,25.9,0,0,1,26.619,0l3.164,1.855a3.06,3.06,0,0,1,.873,3.709M38.838,53.027a2.591,2.591,0,0,0-3.6-.982L32.074,53.9a10.691,10.691,0,0,1-16.037-9.273V26.844a10.691,10.691,0,0,1,16.037-9.273l15.382,8.946a10.716,10.716,0,0,1,0,18.546l-3.164,1.855A2.639,2.639,0,0,0,46.911,51.5l3.164-1.855A15.5,15.5,0,0,0,58.039,35.79a15.721,15.721,0,0,0-7.964-13.855L34.692,12.989a15.657,15.657,0,0,0-16.037,0,15.5,15.5,0,0,0-7.964,13.855V44.627a15.982,15.982,0,0,0,24,13.855l3.164-1.855h0A2.733,2.733,0,0,0,38.838,53.027Zm1.2,14.728a25.9,25.9,0,0,1-26.619,0A26.1,26.1,0,0,1,0,44.627v-3.6a2.655,2.655,0,0,1,2.618-2.618,2.655,2.655,0,0,1,2.618,2.618v3.6A20.825,20.825,0,0,0,15.928,63.064a20.678,20.678,0,0,0,21.274,0c5.127-2.946,10.255-5.891,15.382-8.946A20.951,20.951,0,0,0,63.275,35.681h0A20.825,20.825,0,0,0,52.584,17.244L49.42,15.389a2.639,2.639,0,0,1,2.618-4.582h0L55.2,12.662A25.732,25.732,0,0,1,68.73,35.681,26,26,0,0,1,55.42,58.809C50.293,61.755,45.165,64.7,40.038,67.755ZM21.383,30.444A2.584,2.584,0,0,0,24,33.063a2.655,2.655,0,0,0,2.618-2.618h0V27.935a.6.6,0,0,1,.327-.545.7.7,0,0,1,.655,0l6.764,3.927,6.764,3.927a.618.618,0,0,1,0,1.091l-6.764,3.927L27.6,44.19a.7.7,0,0,1-.655,0,.6.6,0,0,1-.327-.545V41.136A2.584,2.584,0,0,0,24,38.517a2.655,2.655,0,0,0-2.618,2.618v3.6a5.291,5.291,0,0,0,7.964,4.582l15.382-8.946a5.011,5.011,0,0,0,2.618-4.582,5.223,5.223,0,0,0-2.618-4.582L29.347,22.262a5.219,5.219,0,0,0-5.346,0,5.011,5.011,0,0,0-2.618,4.582Z"
            transform="translate(0 0.075)"
            fill="#f58222"
          />
        </clipPath>
      </defs>
      <g transform="translate(0 0)">
        <path
          d="M44.184,9.171a2.591,2.591,0,0,1-3.6.982L37.42,8.3a20.678,20.678,0,0,0-21.274,0A20.951,20.951,0,0,0,5.455,26.735v3.6a2.655,2.655,0,0,1-2.618,2.618A2.655,2.655,0,0,1,.218,30.335v-3.6A26,26,0,0,1,13.528,3.607a25.9,25.9,0,0,1,26.619,0l3.164,1.855a3.06,3.06,0,0,1,.873,3.709M38.838,53.027a2.591,2.591,0,0,0-3.6-.982L32.074,53.9a10.691,10.691,0,0,1-16.037-9.273V26.844a10.691,10.691,0,0,1,16.037-9.273l15.382,8.946a10.716,10.716,0,0,1,0,18.546l-3.164,1.855A2.639,2.639,0,0,0,46.911,51.5l3.164-1.855A15.5,15.5,0,0,0,58.039,35.79a15.721,15.721,0,0,0-7.964-13.855L34.692,12.989a15.657,15.657,0,0,0-16.037,0,15.5,15.5,0,0,0-7.964,13.855V44.627a15.982,15.982,0,0,0,24,13.855l3.164-1.855h0A2.733,2.733,0,0,0,38.838,53.027Zm1.2,14.728a25.9,25.9,0,0,1-26.619,0A26.1,26.1,0,0,1,0,44.627v-3.6a2.655,2.655,0,0,1,2.618-2.618,2.655,2.655,0,0,1,2.618,2.618v3.6A20.825,20.825,0,0,0,15.928,63.064a20.678,20.678,0,0,0,21.274,0c5.127-2.946,10.255-5.891,15.382-8.946A20.951,20.951,0,0,0,63.275,35.681h0A20.825,20.825,0,0,0,52.584,17.244L49.42,15.389a2.639,2.639,0,0,1,2.618-4.582h0L55.2,12.662A25.732,25.732,0,0,1,68.73,35.681,26,26,0,0,1,55.42,58.809C50.293,61.755,45.165,64.7,40.038,67.755ZM21.383,30.444A2.584,2.584,0,0,0,24,33.063a2.655,2.655,0,0,0,2.618-2.618h0V27.935a.6.6,0,0,1,.327-.545.7.7,0,0,1,.655,0l6.764,3.927,6.764,3.927a.618.618,0,0,1,0,1.091l-6.764,3.927L27.6,44.19a.7.7,0,0,1-.655,0,.6.6,0,0,1-.327-.545V41.136A2.584,2.584,0,0,0,24,38.517a2.655,2.655,0,0,0-2.618,2.618v3.6a5.291,5.291,0,0,0,7.964,4.582l15.382-8.946a5.011,5.011,0,0,0,2.618-4.582,5.223,5.223,0,0,0-2.618-4.582L29.347,22.262a5.219,5.219,0,0,0-5.346,0,5.011,5.011,0,0,0-2.618,4.582Z"
          transform="translate(0 0.075)"
          fill="#f58222"
        />
        <g clipPath="url(#a)">
          <rect
            width={68.73}
            height={73.748}
            transform="translate(0 -1.118)"
            fill="#f58222"
          />
        </g>
      </g>
    </svg>
  );
};

const IdramSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(IdramSVG);
