import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelectFormItem from "../mySelectFormItem/MySelectFormItem";
import UserActions from "../../actions/UserActions";
import UtilNotify from "../../utils/UtilNotify";

const SelectManager = ({ withAll, ...otherProps }) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    const fill = async () => {
      setloading(true);
      const result = await UserActions.fillPagination({
        filter: { role: UserActions.ROLE.MANAGER },
        page: 0,
        perPage: 1000,
      });
      if (result.success) {
        let data = result.data.map((it) => ({
          label: it.fName,
          value: it.idUser,
          ...it,
        }));
        if (withAll) data.unshift({ label: t("WORD_ALL"), value: "" });
        setlist(data);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setloading(false);
    };
    fill();
  }, [t, withAll]);

  return (
    <MySelectFormItem
      {...otherProps}
      options={list}
      loading={loading}
      showSearch
    />
  );
};

export default memo(SelectManager);
