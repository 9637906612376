import { fetchFromUrlPOSTAsync } from "../utils/UrlHelper";

const URL_LIST = "country/list";
const URL_ONE_BY_COUNTRY_CODE = "country/getOneByCountryCode";

const fs = {
  fillList: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_LIST, urlParams);
    return result;
  },
  getOneByCountryCode: async (countryCode) => {
    const result = await fetchFromUrlPOSTAsync(URL_ONE_BY_COUNTRY_CODE + '/' + countryCode, []);
    return result;
  },
};

const loc = {
  fillListOptions: async () => {
    const result = await CountryActions.fillList();
    if (result.success) {
      result.data = result.data.map((it) => {
        return {
          label: it.countryName,
          value: it.IdCountries,
          ...it,
        };
      });
    }
    return result;
  },
  fillListOptionsCode: async () => {
    const result = await CountryActions.fillList();
    if (result.success) {
      result.data = result.data.map((it) => {
        return {
          label: it.codePhone,
          value: it.codePhone,
          ...it,
        };
      });
    }
    return result;
  },
};

const CountryActions = Object.assign(fs, loc);

export default CountryActions;
