import { fetchFromUrlPOSTAsync } from "../utils/UrlHelper";

const URL_FILL_PAGINATION = "device/list";
const URL_GET_ONE = "device/getOne";
const URL_UPDATE = "device/update";
const URL_DELETE = "device/delete";
const URL_UNBLOCK = "device/unblock";
const URL_BLOCK = "device/block";

const fs = {
  fillPagination: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_FILL_PAGINATION, urlParams);
    return result;
  },
  getOne: async (idDevice) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_GET_ONE + "/" + idDevice,
      []
    );
    return result;
  },
  delete: async (idDevice) => {
    const result = await fetchFromUrlPOSTAsync(URL_DELETE + "/" + idDevice, []);
    return result;
  },
  block: async (idDevice) => {
    const result = await fetchFromUrlPOSTAsync(URL_BLOCK + "/" + idDevice, []);
    return result;
  },
  unblock: async (idDevice) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_UNBLOCK + "/" + idDevice,
      []
    );
    return result;
  },
  update: async (values, idDevice) => {
    let result = await fetchFromUrlPOSTAsync(URL_UPDATE + "/" + idDevice, {
      saveData: values,
    });
    return result;
  },
};

const loc = {
  STATUS: {
    BLOCKED: 0,
    ACTIVE: 1,
  },
};

const DeviceActions = Object.assign(fs, loc);

export default DeviceActions;
