import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AppRoutePrivate from "./AppRoutePrivate";
import BlockedLayout from "../layouts/blockedLayout/BlockedLayout";
import ChangePackagePage from "../pages/settings/package/ChangePackagePage";
import NotificationSettings from "../pages/settings/NotificationSettings";

const AppRouteBlocked = () => {
  return (
    <Routes>
      <Route
        path="/:lang/buyApi"
        replace
        index
        element={
          <AppRoutePrivate>
            <BlockedLayout fullPage>
              <ChangePackagePage />
            </BlockedLayout>
          </AppRoutePrivate>
        }
      />
      <Route
        path="/:lang/notification"
        replace
        element={
          <AppRoutePrivate>
            <BlockedLayout fullPage>
              <NotificationSettings />
            </BlockedLayout>
          </AppRoutePrivate>
        }
      />
      <Route path="*" element={<Navigate to={"/en/buyApi"} />} />
    </Routes>
  );
};

export default AppRouteBlocked;
