import { Button } from "antd";
import React from "react";

const MyButtonText = ({ label, onClick, className }) => {
  return (
    <Button type="link" onClick={onClick} className={className}>
      {label}
    </Button>
  );
};

export default MyButtonText;
