import React from "react";
import { Popconfirm } from "antd";
import { useTranslation } from "react-i18next";
import MyButton from "./MyButton";
import { QuestionCircleOutlined } from "@ant-design/icons";
import colors from "../../Color.scss";

const MyButtonConfirmDelete = ({
  icon,
  label,
  onCancel,
  onConfirm,
  disabled,
  title,
  okText,
  cancelText,
  loading,
  onClick,
  style,
  blockBtn,
}) => {
  const { t } = useTranslation();

  const handleCancel = () => {
    onCancel && onCancel();
  };

  return (
    <Popconfirm
      title={
        title
          ? title
          : blockBtn
          ? t("WORD_ARE_YOU_SURE_YOU_WANT_TO_BLOCK")
          : t("WORD_ARE_YOU_SURE_YOU_WANT_TO_DELETE")
      }
      onConfirm={onConfirm}
      onCancel={handleCancel}
      okText={okText ? okText : blockBtn ? t("WORD_BLOCK") : t("WORD_DELETE")}
      cancelText={cancelText || t("WORD_CANCEL")}
      disabled={disabled}
      okButtonProps={{
        color: "danger",
        type: "primary",
        danger: true,
        style: {
          borderRadius: 12,
        },
      }}
      cancelButtonProps={{
        type: "solid",
        style: {
          backgroundColor: colors.defaultGray,
          color: colors.whiteColor,
          borderRadius: 12,
        },
      }}
      icon={
        <QuestionCircleOutlined
          style={{
            color: "red",
          }}
        />
      }
    >
      <div>
        <MyButton
          type="primary"
          loading={loading}
          label={label}
          icon={icon}
          danger={"danger"}
          onClick={onClick}
          size={"large"}
          style={{
            minWidth: 86,
            ...style,
          }}
        />
      </div>
    </Popconfirm>
  );
};

export default MyButtonConfirmDelete;
