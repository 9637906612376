import React from "react";
import SelectAddressSuggestionYandex from "./SelectAddressSuggestionYandex";
import SelectAddressSuggestionGoogle from "./SelectAddressSuggestionGoogle";
import { useSelector } from "react-redux";
import ClientSaveActions from "../../../actions/ClientSaveActions";

const SelectAddressSuggestion = (props) => {
  const suggestionType = useSelector((state) => {
    return state.currentUser?.mapProviderType;
  });

  return suggestionType === ClientSaveActions.MAPPROVIDERTYPE.GOOGLE ? (
    <SelectAddressSuggestionGoogle {...props} />
  ) : (
    <SelectAddressSuggestionYandex {...props} />
  );
};

export default SelectAddressSuggestion;
