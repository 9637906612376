import axios from 'axios';

const UtilIPInfo = {
  getInfo: async (obj) => {
    try {
      // const response = await axios.get('http://ip-api.com/json/');
      const response = await axios.get('https://ipapi.co/json/');
      if (response?.data) {
        response.data.countryCode = response.data.country_code;
      }
      return response.data;
    } catch (error) {
      console.error('Error fetching country info:', error);
      return null;
    }
  },
};

export default UtilIPInfo;
