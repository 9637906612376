import React from "react";
import "../myLink/MyLink.scss";

const MyLinkA = ({
  label,
  blank = false,
  href,
  className = "",
  underline,
  style,
  bold,
  color,
}) => {
  return (
    <a
      className={`my-link ${color} ${className || ""}`}
      href={href}
      target={blank ? "_blank" : ""}
      rel="noopener noreferrer"
      style={{
        textDecoration: underline ? "underline" : "",
        fontWeight: bold ? 600 : 0,
        ...style,
      }}
    >
      {label}
    </a>
  );
};

export default MyLinkA;
