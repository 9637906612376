import React from "react";
import MySpace from "../mySpace/MySpace";
import MyTextSecondary from "../myText/MyTextSecondary";
import MyText from "../myText/MyText";
import MyTextTitle from "../myText/MyTextTitle";

const TitleDataRowAlignEnd = ({
  title,
  data,
  style,
  bold,
  secondary,
  className,
  secondClassName,
  secondStyle,
  withoutWidth,
  textTitle,
}) => {
  return (
    <MySpace style={{ width: withoutWidth ? null : 160 }} spaceBetween>
      {textTitle ? (
        <MyTextTitle size={13} bold>
          {title}
        </MyTextTitle>
      ) : (
        <MyTextSecondary
          style={style}
          bold={bold}
          color={secondary ? "#a7a6b9" : "#272727"}
          className={className}
        >
          {title}:
        </MyTextSecondary>
      )}
      <MyText className={secondClassName} style={secondStyle}>
        {data}
      </MyText>
    </MySpace>
  );
};

export default TitleDataRowAlignEnd;
