import React, { memo } from "react";
import MyIcon from "./MyIcon";

export const SVGIcon = ({ size, fill }) => {
  return (
    <svg
      width={size || 18}
      height={size || 18}
      viewBox="0 0 49 49"
      fill={fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1706 6.20289C21.5404 3.66389 26.9114 3.70827 31.2402 6.31913C35.5263 8.98317 38.1313 13.7377 38.1072 18.8522C38.0073 23.9332 35.214 28.7093 31.7223 32.4015C29.7071 34.5421 27.4526 36.435 25.0051 38.0414C24.753 38.1872 24.4769 38.2848 24.1904 38.3293C23.9146 38.3176 23.646 38.2361 23.4089 38.0922C19.6722 35.6785 16.3941 32.5974 13.7321 28.9973C11.5046 25.9921 10.2391 22.3614 10.1074 18.5982L10.1174 18.0507C10.2992 13.1386 12.957 8.6512 17.1706 6.20289ZM25.9219 14.3989C24.1455 13.6438 22.0975 14.054 20.734 15.438C19.3706 16.822 18.9607 18.9068 19.6957 20.7189C20.4308 22.531 22.1658 23.713 24.0906 23.713C25.3516 23.7221 26.5638 23.217 27.457 22.3103C28.3503 21.4036 28.8504 20.1706 28.8459 18.8861C28.8526 16.9254 27.6983 15.154 25.9219 14.3989Z"
        fill={fill || "#fff"}
      ></path>
      <path
        opacity="0.4"
        d="M24.1074 44.3293C29.6302 44.3293 34.1074 43.4339 34.1074 42.3293C34.1074 41.2248 29.6302 40.3293 24.1074 40.3293C18.5845 40.3293 14.1074 41.2248 14.1074 42.3293C14.1074 43.4339 18.5845 44.3293 24.1074 44.3293Z"
        fill={fill || "#fff"}
      ></path>
    </svg>
  );
};

const LocationSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(LocationSVG);
