import React, { memo } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

const MyHelmet = ({ title }) => {
  const name = useSelector((state) => {
    return state.currentUser?.companyName;
  });

  return (
    <Helmet>
      <title>{`${name} | Ordersbook - ${title}`}</title>
      <meta
        property="og:description"
        content="Ordersbook is a business automatization app for managing of sales, supply and delivery processes."
      />
    </Helmet>
  );
};

export default memo(MyHelmet);
