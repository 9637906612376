import React, { memo, useCallback } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";

const MyMapGoogle = ({ children, width, height, center, onLoad }, ref) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_APIKEY,
  });

  // const [map, setMap] = React.useState(null)

  const onLoadMap = useCallback(
    function callback(map) {
      // This is just an example of getting and using the map instance!!! don't just blindly copy!
      // const bounds = new window.google.maps.LatLngBounds(center);
      // map.fitBounds(bounds);

      // setMap(map)
      onLoad && onLoad(map);
    },
    [onLoad]
  );

  // const onUnmount = React.useCallback(function callback(map) {
  //   // setMap(null)
  // }, [])

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        width: `${width}px`,
        height: `${height}px`,
      }}
      center={
        center || {
          lat: 40.1772,
          lng: 44.50349,
        }
      }
      zoom={10}
      onLoad={onLoadMap}
      // onUnmount={onUnmount}
    >
      {children}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default memo(MyMapGoogle);
