import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelectFormItem from "../mySelectFormItem/MySelectFormItem";

const SelectLanguage = ({ ...otherProps }) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    let data = [
      { label: t("WORD_ENGLISH"), value: "en" },
      { label: t("WORD_ARMENIAN"), value: "hy" },
      { label: t("WORD_RUSSIAN"), value: "ru" },
      { label: t("WORD_ARABIC"), value: "ar" },
    ];
    setlist(data);
  }, [t]);

  return <MySelectFormItem {...otherProps} options={list} />;
};

export default SelectLanguage;
