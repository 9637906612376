import React from "react";
import MyLink from "../myLink/MyLink";
import MyTooltip from "../myTooltip/MyTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MyButtonGraySmall from "./MyButtonGraySmall";
import "./MyButton.scss";

const MyButtonTableActionWithLink = ({ path, icon, title, ...otherProps }) => {
  return (
    <MyLink _blank path={path}>
      <MyTooltip title={title}>
        <MyButtonGraySmall
          {...otherProps}
          icon={<FontAwesomeIcon icon={icon} color="#fff" size="xs" />}
        />
      </MyTooltip>
    </MyLink>
  );
};

export default MyButtonTableActionWithLink;
