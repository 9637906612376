import React from "react";
import PricingSegmentedSection from "./PricingSegmentedSection";
import PricingSegmentedCard from "./PricingSegmentedCard";

const PricingDetailsSection = ({ list, setSelectedPlan, selectedPlan }) => {
  const onChange = (val) => {
    setSelectedPlan(val);
  };

  return (
    <div className="pricing-parent-div">
      <div className="pricing-inner-div">
        <PricingSegmentedSection
          list={list}
          onChange={onChange}
          selectedPlan={selectedPlan}
        />
        <PricingSegmentedCard selectedPlan={selectedPlan.toLowerCase()} />
      </div>
    </div>
  );
};

export default PricingDetailsSection;
