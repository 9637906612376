import React, { memo } from "react";
import MyIcon from "./MyIcon";

export const SVGIcon = ({ style, size }) => {
  return (
    <svg
      width={size || 23}
      height={size || 23}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3644 11.5004C20.3644 16.3966 16.3959 20.365 11.4998 20.365C6.60368 20.365 2.63522 16.3966 2.63522 11.5004C2.63522 6.60432 6.60368 2.63586 11.4998 2.63586C16.3959 2.63586 20.3644 6.60432 20.3644 11.5004Z"
        stroke="#3D96FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M14.7884 14.3202L11.1755 12.1649V7.5199"
        stroke="#3D96FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
};

const ClockTimeBlueSVG = (props) => {
  return <MyIcon icon={<SVGIcon />} {...props} />;
};

export default memo(ClockTimeBlueSVG);
