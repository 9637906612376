import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelectFormItem from "../mySelectFormItem/MySelectFormItem";
import UtilNotify from "../../utils/UtilNotify";
import DistrictActions from "../../actions/DistrictActions";

const SelectDistrict = ({ idCity, ...otherProps }) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    const fill = async () => {
      if (idCity) {
        const result = await DistrictActions.fillListOptions(idCity);
        if (result.success) {
          setlist(result.data);
        } else {
          UtilNotify.notifyErrorServer(result.errMsg);
        }
      }
    };
    fill();
  }, [t, idCity]);

  return (
    <MySelectFormItem
      {...otherProps}
      options={
        idCity
          ? list
          : [
              {
                value: "warning",
                label: t("WORD_PLEASE_SELECT_CITY_FIRST"),
                disabled: true,
              },
            ]
      }
    />
  );
};

export default SelectDistrict;
