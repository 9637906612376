import React, { useEffect } from "react";
import MyCard from "../../components/myCard/MyCard";
import MyTextTitle from "../../components/myText/MyTextTitle";
import { useTranslation } from "react-i18next";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useNavigateApi from "../../appRoute/useNavigateApi";
import { useSelector } from "react-redux";
import MyButtonSuccess from "../../components/myButton/MyButtonSuccess";

const InvoicePaymentSuccess = () => {
  const { t, i18n } = useTranslation();
  const navigateApi = useNavigateApi();

  const lang = useSelector((state) => {
    return state.currentUser?.lang;
  });

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <MyCard
        style={{
          paddingTop: 30,
          paddingBottom: 30,
          paddingRight: 0,
          paddingLeft: 0,
          border: "1px solid #ebeff6",
          minWidth: "30%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 10,
          }}
        >
          <FontAwesomeIcon icon={faCircleCheck} color="green" size="5x" />
          <MyTextTitle
            size={35}
            style={{
              width: "75%",
              display: "flex",
              flexWrap: "nowrap",
              textAlign: "center",
            }}
          >
            {t("WORD_YOUR_PAYMENT_WAS_SUCCESSFULL")}
          </MyTextTitle>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 10,
              marginTop: 10,
            }}
          >
            <MyButtonSuccess
              label={t("WORD_RETURN")}
              style={{ height: 50, borderRadius: 16, width: 100 }}
              onClick={() => navigateApi(`/home`)}
            />
          </div>
        </div>
      </MyCard>
    </div>
  );
};

export default InvoicePaymentSuccess;
