import colors from "../Color.scss";

const themeLightConfig = {
  colorPrimary: colors.primaryColorLighter,
  colorLink: colors.primaryColorLighter,
  colorText: colors.primaryText,
  token: {
    colorPrimary: colors.primaryColorLighter,
    colorPrimaryText: colors.whiteColor,
    colorInfo: colors.primaryColorLighter,
    colorSuccess: colors.success,
    colorWarning: colors.warning,
    colorError: colors.danger,
    colorTextSecondary: colors.secondaryText,
    colorBorder: colors.borderColor,
    colorBgLayout: colors.bg,
    colorErrorBgHover: colors.dangerActive,
    fontFamily: "Inter,Open Sans, Tahoma, ArianAmu, Arial, sans-serif",
    colorLink: colors.linkColor,
    colorLinkHover: colors.linkHover,
    colorLinkActive: colors.linkHover,
  },
  components: {
    Layout: {
      siderBg: colors.primaryColor,
      bodyBg: colors.bg,
    },
    Menu: {
      itemSelectedBg: "rgba(255,255,255,0.14901960784313725)",
      itemHoverBg: "rgba(255,255,255,0.14901960784313725)",
    },
    Button: {
      defaultBorderColor: colors.primaryColorLighter,
      defaultColor: colors.primaryColorLighter,
      controlHeight: 40,
      defaultGhostColor: colors.primaryColorLighter,
      defaultGhostBorderColor: colors.primaryColorLighter,
      colorPrimary: colors.primaryColorLighter,
      colorLink: colors.primaryColorLighter,
      controlHeightSM: 30,
      paddingInlineSM: 11,
      borderRadiusLG: colors.filterSelectBorderRadius,
      contentFontSizeLG: 14,
      paddingBlockLG: 4,
      paddingInlineLG: 10,
    },
    Input: {
      controlHeight: 40,
      colorBorder: colors.googleAutocompleteGray,
      borderRadius: 12,
      controlHeightLG: 50,
      borderRadiusLG: 9,
      fontSizeLG: 14,
    },
    InputNumber: {
      controlHeight: 40,
      colorBorder: colors.googleAutocompleteGray,
      borderRadius: 12,
      controlHeightLG: 50,
      borderRadiusLG: 9,
      fontSizeLG: 14,
    },
    Checkbox: {
      controlInteractiveSize: 20,
      colorPrimary: "rgb(130,71,255)",
      colorPrimaryBorder: "rgb(130,71,255)",
      colorPrimaryHover: "rgba(129,71,255,0.8)",
    },
    Select: {
      controlHeight: 40,
      colorBorder: colors.googleAutocompleteGray,
      borderRadius: 12,
      controlHeightLG: 50,
      borderRadiusLG: 9,
      fontSizeLG: 14,
    },
    DatePicker: {
      controlHeight: 40,
      colorBorder: colors.googleAutocompleteGray,
      borderRadius: 12,
      controlHeightLG: 50,
      borderRadiusLG: 9,
      fontSizeLG: 14,
    },
    Table: {
      fontSize: 12,
      headerBg: colors.whiteColor,
      headerColor: colors.tableHeaderColor,
      rowHoverBg: colors.diffGrayBlue,
      rowSelectedBg: "rgba(230,244,255,0)",
      footerBg: "rgba(255,255,255,0)",
      // borderRadius: 20,
      // headerBorderRadius: 20,
    },
    TreeSelect: {
      titleHeight: 30,
      indentSize: 10,
    },
    Pagination: {
      fontWeightStrong: 400,
      paddingContentHorizontal: 15,
    },
    Card: {
      padding: 12,
      paddingLG: 16,
      borderRadius: 6,
      boxShadow: "none",
    },
    Modal: {
      borderRadiusSM: 8,
      borderRadiusLG: 16,
      padding: 10,
      paddingLG: 12,
    },
    Divider: {
      colorSplit: colors.whiteGrayColor,
      margin: 5,
      marginLG: 15,
    },
    Dropdown: {
      borderRadiusLG: 6,
      padding: 10,
    },
    Tag: {
      borderRadiusSM: 20,
    },
  },
};

export default themeLightConfig;
