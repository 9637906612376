import React, { useCallback, useEffect, useMemo, useState } from "react";
import MySpace from "../../../components/mySpace/MySpace";
import MyPageHeader from "../../../components/myPageHeader/MyPageHeader";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import MyTable from "../../../components/myTable/MyTable";
import CustomerActions from "../../../actions/CustomerActions";
import UtilNotify from "../../../utils/UtilNotify";
import { useSelector } from "react-redux";
import ApiStorage from "../../../redux/ApiStorage";
import UtilDate from "../../../utils/UtilDate";
import OrdersActions from "../../../actions/OrdersActions";
import { Flex, Table } from "antd";
import useNavigateApi from "../../../appRoute/useNavigateApi";
import { useFormatCurrency } from "../../../utils/useFormatWithCurrency";
import FormApp from "../../../components/formApp/FormApp";
import InputFormRangeDatePicker from "../../../components/inputFormRangeDatePicker/InputFormRangeDatePicker";
import SelectManager from "../../../components/selects/SelectManager";
import { useForm } from "antd/es/form/Form";
import MyButtonText from "../../../components/myButton/MyButtonText";
import MyTag from "../../../components/tag/MyTag";

const formName = "formCustomerStatementFilter";

const CustomerStatement = () => {
  const { t } = useTranslation();
  const navigateApi = useNavigateApi();
  const [form] = useForm();
  const { formatDoubleWithCurrency } = useFormatCurrency();
  const { _idCustomer } = useParams();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setpage] = useState(0);
  const [totalRows, settotalRows] = useState(0);
  const [totals, setTotals] = useState({});

  const perPage = 1000;

  const customerStatementFilters = useSelector((state) => {
    return state.customerStatementFilters;
  });

  // const setFilter = useCallback(
  //   (fil) => {
  //     ApiStorage.setCustomerStatementFilters(
  //       Object.assign({}, customerStatementFilters, fil)
  //     );
  //     setpage(0);
  //   },
  //   [customerStatementFilters]
  // );

  const setFilterClear = () => {
    form.resetFields();
    ApiStorage.setCustomerStatementFilters({});
    setpage(0);
  };

  useEffect(() => {
    const fill = async () => {
      setLoading(true);
      const fetchParams = {
        page: page,
        perPage: perPage,
        filter: { ...customerStatementFilters },
      };
      const result = await CustomerActions.getStatement(
        _idCustomer,
        fetchParams
      );
      if (result.success) {
        // if (page !== 0) {
        //   setData((ov) => [...ov, ...(result.data ?? [])]);
        // } else {
        setData(result.data);
        setTotals(result.totals);
        // }
        settotalRows(result.totalRows);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setLoading(false);
    };

    fill();
  }, [page, _idCustomer, customerStatementFilters]);

  const columns = useMemo(() => {
    let col = [
      {
        title: t("WORD_N"),
        dataIndex: "n",
        key: "n",
        width: 70,
        render: (_, __, index) => <div>{index + 1}</div>,
      },
      {
        title: t("WORD_CREATE_DATE"),
        dataIndex: "createdAt",
        key: "createdAt",
        width: 167,
        render: (value) => <div>{UtilDate.formatDateTime(value)}</div>,
      },
      {
        title: t("WORD_TYPE"),
        dataIndex: "type",
        key: "type",
        width: 100,
        render: (value) => {
          const type = OrdersActions.getOrderTypeLabel(value);
          return <MyTag label={t(type.label)} color={type.color} />;
        },
      },
      {
        title: t("WORD_DISTRIBUTOR"),
        dataIndex: "nameManager",
        key: "nameManager",
        align: "center",
      },
      {
        title: t("WORD_DEBIT"),
        dataIndex: "debitAmount",
        key: "debitAmount",
        width: 170,
        align: "right",
        render: (value) =>
          value === 0 ? "---" : <span>{formatDoubleWithCurrency(value)}</span>,
      },
      {
        title: t("WORD_CREDIT"),
        dataIndex: "creditAmount",
        key: "creditAmount",
        width: 170,
        align: "right",
        render: (value) =>
          value === 0 ? "---" : <span>{formatDoubleWithCurrency(value)}</span>,
      },
    ];
    return col;
  }, [t, formatDoubleWithCurrency]);

  const onFinish = useCallback(
    (fil) => {
      const obj = Object.assign({}, customerStatementFilters, fil);
      ApiStorage.setCustomerStatementFilters(obj);
      setpage(0);
    },
    [customerStatementFilters]
  );

  return (
    <MySpace direction="vertical" fullWidth size={"middle"}>
      <MyPageHeader
        // extraRight={
        //   <MySpace>
        //     <MyButtonTableAction
        //         nextToFilter
        //         antdIcon
        //         title={t("WORD_IMPORT")}
        //         icon={<ImportWhiteSVG />}
        //       />
        //     <CustomerStatementFilterDrawer
        //       onChange={setFilter}
        //       onReset={setFilterClear}
        //     />
        //   </MySpace>
        // }
        onBack={() => navigateApi(`/customer/list`)}
        title={t("WORD_CUSTOMER_STATEMENT")}
      />
      <Flex gap="small">
        <FormApp onFinish={onFinish} name={formName} form={form}>
          <MySpace>
            <SelectManager
              placeholder={t("WORD_SELECT_MANAGER")}
              name="idManager"
              onChange={() => form.submit()}
            />
            <InputFormRangeDatePicker
              name="date"
              onChange={() => form.submit()}
            />
          </MySpace>
        </FormApp>
        <MyButtonText
          label={t("WORD_RESET_FILTERS")}
          onClick={setFilterClear}
        />
      </Flex>
      <MyTable
        rowKey="idOrder"
        columns={columns}
        dataSource={data}
        loading={loading}
        total={totalRows}
        page={page}
        pageSize={perPage}
        className="customer-statement-table"
        noFooter
        summary={() => {
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell index={0} align="right">
                  <strong>{t("WORD_INITIAL_BALANCE")}</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} align="right">
                  <strong>
                    {formatDoubleWithCurrency(totals?.startingBalance)}
                  </strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} align="right">
                  <strong>
                    {formatDoubleWithCurrency(totals?.startingBalance)}
                  </strong>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell index={0} align="right">
                  <strong>{t("WORD_PERIOD_TOTAL")}</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} align="right">
                  <strong>
                    {formatDoubleWithCurrency(totals?.debitAmount)}
                  </strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} align="right">
                  <strong>
                    {formatDoubleWithCurrency(totals?.creditAmount)}
                  </strong>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell index={0} align="right">
                  <strong>{t("WORD_TOTAL_UNTIL")}</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} align="right">
                  <strong>
                    {formatDoubleWithCurrency(totals?.debitAmountFinal)}
                  </strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} align="right">
                  <strong>
                    {formatDoubleWithCurrency(totals?.creditAmountFinal)}
                  </strong>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row
                style={{
                  borderBottomLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
              >
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell index={0} align="right">
                  <strong>{t("WORD_TOTAL_BALANCE")}</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} align="right">
                  <strong>
                    {totals?.balanceAmountFinal > 0
                      ? formatDoubleWithCurrency(totals?.balanceAmountFinal)
                      : formatDoubleWithCurrency(0)}
                  </strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} align="right">
                  <strong>
                    {totals?.balanceAmountFinal < 0
                      ? formatDoubleWithCurrency(totals?.balanceAmountFinal)
                      : formatDoubleWithCurrency(0)}
                  </strong>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </MySpace>
  );
};

export default CustomerStatement;
