import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import MyCard from "../../../components/myCard/MyCard";
import MyPageHeader from "../../../components/myPageHeader/MyPageHeader";
import MyDivider from "../../../components/myDivider/MyDivider";
import MySpace from "../../../components/mySpace/MySpace";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../layouts/settingsLayout/SettingsLayout";
import UtilDate from "../../../utils/UtilDate";
import InvoiceActions from "../../../actions/InvoiceActions";
import UtilNotify from "../../../utils/UtilNotify";
import UtilNumber from "../../../utils/UtilNumber";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import InvoiceViewModal from "./InvoiceViewModal";
import useNavigateApi from "../../../appRoute/useNavigateApi";
import MyHelmet from "../../../components/helmet/MyHelmet";
import { useSelector } from "react-redux";
import useWindowSize from "../../../utils/useWindowSize";
import MyButtonSecondary from "../../../components/myButton/MyButtonSecondary";
import ChangePackageModalPay from "./ChangePackageModalPay";
import MyTable from "../../../components/myTable/MyTable";
import MyButtonSuccess from "../../../components/myButton/MyButtonSuccess";
import MyButtonTableAction from "../../../components/myButton/MyButtonTableAction";
import { Flex } from "antd";
import MyTag from "../../../components/tag/MyTag";

const PackageSettings = () => {
  const { t } = useTranslation();
  const refModal = useRef();
  const refModalPay = useRef();
  const navigateApi = useNavigateApi();
  const { isMobile, height } = useWindowSize();

  const { userData } = useContext(UserContext);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, settotalRows] = useState(0);
  const [pagination, setpagination] = useState({ page: 0, perPage: 50 });

  const activeUntilDate = useSelector((state) => {
    return state.currentUser?.activeUntilDate;
  });

  useEffect(() => {
    const fill = async () => {
      setLoading(true);
      const fetchParams = {
        page: pagination.page,
        perPage: 50,
        filter: {},
      };
      const result = await InvoiceActions.fillPagination(fetchParams);
      if (result.success) {
        const list = [];
        list.push(...result.data);
        setData(list);
        settotalRows(result.totalRows);
      } else {
        UtilNotify.notifyErrorServer(result.errMsg);
      }
      setLoading(false);
    };

    fill();
  }, [pagination]);

  const columns = useMemo(() => {
    let col = [
      {
        title: "#",
        dataIndex: "idInvoice",
        key: "idInvoice",
        width: 25,
        sorter: true,
      },
      {
        title: t("WORD_CREATE_DATE"),
        dataIndex: "createDate",
        key: "createDate",
        width: 200,
        sorter: true,
        render: (value) => {
          return <div>{UtilDate.formatDateTime(value)}</div>;
        },
      },
      {
        title: t("WORD_PERIOD"),
        dataIndex: "invoiceDateFrom",
        key: "invoiceDateFrom",
        width: 180,
        render: (value, { invoiceDateTo }) => {
          return (
            <div>
              {UtilDate.formatDate(value)} /{" "}
              {UtilDate.formatDate(invoiceDateTo)}
            </div>
          );
        },
      },
      {
        title: t("WORD_TOTAL_AMOUNT"),
        dataIndex: "totalAmount",
        key: "totalAmount",
        // width: 200,
        align: "right",
        render: (value, { currrencySymbol }) => {
          return (
            <div>
              {UtilNumber.formatDouble(value)}
              {currrencySymbol}
            </div>
          );
        },
      },
      {
        title: t("WORD_STATUS"),
        dataIndex: "status",
        key: "status",
        width: 20,
        render: (value) => {
          // if (value !== InvoiceActions.STATUS.PAID) return null;
          const status = InvoiceActions.getStatus(value);
          return <MyTag label={t(status.label)} color={status.color} />;
        },
      },
      {
        title: t("WORD_ACTIONS"),
        dataIndex: "actions",
        width: 120,
        key: "actions",
        render: (_, obj) => (
          <MySpace>
            <MyButtonTableAction
              title={t("WORD_VIEW")}
              icon={faEye}
              onClick={() => {
                refModal?.current?.viewPackage(obj);
              }}
            />
          </MySpace>
        ),
      },
    ];
    return col;
  }, [t]);

  const PackageInfoRender = ({ data, label, isNumber }) => {
    return (
      <MySpace>
        <div>{label}:</div>
        <div>{isNumber ? UtilDate.formatDate(data) : data}</div>
      </MySpace>
    );
  };

  const tableHeight = height - 270;

  return (
    <>
      <MyHelmet title={t("WORD_PACKAGE_DETAILS")} />
      <Flex vertical gap={10}>
        <MyCard>
          <MyPageHeader
            title={t("WORD_PACKAGE_DETAILS")}
            extraRight={
              <MySpace>
                <MyButtonSecondary
                  label={t("WORD_CHANGE_PACKAGE")}
                  onClick={() => navigateApi(`/settings/package_change`)}
                />
                <MyButtonSuccess
                  label={t("WORD_PAY")}
                  onClick={() => {
                    refModalPay?.current?.viewPay();
                  }}
                />
              </MySpace>
            }
          />
          <MyDivider />
          <MySpace direction="vertical" style={{ marginBottom: 10 }}>
            <PackageInfoRender
              data={userData?.serviceName}
              label={t("WORD_PACKAGE_NAME")}
            />
            <PackageInfoRender
              data={userData?.deviceCount}
              label={t("WORD_DEVICE_COUNT")}
            />
            <PackageInfoRender
              data={userData?.paidUntilDate || activeUntilDate}
              label={t("WORD_ACTIVE_UNTIL_DATE")}
              isNumber
            />
          </MySpace>
        </MyCard>
        <MyTable
          rowKey="idInvoice"
          height={isMobile ? "100%" : tableHeight}
          columns={columns}
          dataSource={data}
          loading={loading}
          total={totalRows}
          onChangePage={(e, ee) => {
            setpagination({ page: e, perPage: ee });
          }}
          page={pagination.page}
          pageSize={pagination.perPage}
        />
      </Flex>
      <InvoiceViewModal ref={refModal} />
      <ChangePackageModalPay ref={refModalPay} />
    </>
  );
};

export default PackageSettings;
