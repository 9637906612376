import { fetchFromUrlPOSTAsync } from "../utils/UrlHelper";

const URL_FILL_PAGINATION = "invoice/list";
const URL_GET_ONE = "invoice/getOne";
const URL_CREATE = "invoice/create";

const fs = {
  fillPagination: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_FILL_PAGINATION, urlParams);
    return result;
  },
  getOne: async (idInvoice) => {
    const result = await fetchFromUrlPOSTAsync(
      URL_GET_ONE + "/" + idInvoice,
      []
    );
    return result;
  },
  create: async (values) => {
    const result = await fetchFromUrlPOSTAsync(URL_CREATE, {
      saveData: values,
    });
    return result;
  },
};

const loc = {
  STATUS: {
    PENDING: 1,
    DECLINED: 2,
    CONFIRMED: 3,
  },
  TYPE: {
    AUTO: 1,
    CHANGECLIENTSERVICE: 2,
    MANUAL: 3,
  },
  METHOD: {
    INVOICE: 1,
    CREDITCARD: 2,
    PAYPAL: 3,
    ARCA: 4,
    BANKTRANSFER: 5,
    IDRAM: 6,
  },
  getStatus: (status) => {
    switch (status) {
      case 1:
        return { label: "Pending", color: "#FFA500" };
      case 2:
        return { label: "Declined", color: "#FC5252" };
      case 3:
        return { label: "Confirmed", color: "#92CF5C" };
      default:
        return { label: "", color: "" };
    }
  },
};

const InvoiceActions = Object.assign(fs, loc);

export default InvoiceActions;
