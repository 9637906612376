import React, { useCallback, useEffect, useRef, useState } from "react";
import GPSActions from "../../actions/GPSActions";
import UtilNotify from "../../utils/UtilNotify";
import { useTranslation } from "react-i18next";
import GpsFilterDrawer from "./GpsFilterDrawer";
import MyPageHeader from "../../components/myPageHeader/MyPageHeader";
import UtilObject from "../../utils/UtilObject";
import UtilDate from "../../utils/UtilDate";
import MyMapGoogle from "../../components/myMapGoogle/MyMapGoogle";
import { DirectionsRenderer } from "@react-google-maps/api";
import MySpace from "../../components/mySpace/MySpace";
import StatisticCard from "../../components/statisticCard/StatisticCard";
import ShoppingCartPurpleCircleSVG from "../../icons/ShoppingCartPurpleCircleSVG";
import DistanceBlueCircleSVG from "../../icons/DistanceBlueCircleSVG";
import TimeRedSVG from "../../icons/TimeRedSVG";

const GpsGoogle = () => {
  const { t } = useTranslation();

  const mapRef = useRef(null);

  // const [MyYmaps, setMyYmaps] = useState();

  // const [locations, setLocations] = useState([]);
  const [filter, setfilter] = useState({
    idManager: 3380,
    createDate: UtilDate.getDate("2023-07-02"),
  });

  const [directionsResponses, setDirectionsResponses] = useState([]);

  const divideArrayIntoChunks = (arr, chunkSize) => {
    const result = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize + 1));
    }
    return result;
  };

  const drawRoute = useCallback(async (list) => {
    if (list.length < 2) return;
    const directionsService = new window.google.maps.DirectionsService();
    const opt = {
      origin: list[0],
      destination: list[list.length - 1],
      waypoints: list
        .slice(1, -1)
        .map((location) => ({ location, stopover: false })),
      travelMode: window.google.maps.TravelMode.DRIVING,
    };
    const results = await directionsService.route(opt);
    setDirectionsResponses((prev) => [...prev, results]);
  }, []);

  useEffect(() => {
    const fill = async () => {
      if (mapRef && mapRef.current) {
        mapRef.current.geoObjects.removeAll();
      }
      if (!UtilObject.isEmpty(filter)) {
        const fetchParams = {
          filter: filter,
        };
        const result = await GPSActions.fillList(fetchParams);
        if (result.success) {
          const coords = result.data.map((item) => {
            const it = JSON.parse(item.location);
            return { lat: it.Lat, lng: it.Lan };
          });
          // setLocations(coords);
          const ll = divideArrayIntoChunks(coords, 3);
          for (const ar of ll) {
            drawRoute(ar);
          }
        } else {
          UtilNotify.notifyErrorServer(result.errMsg);
        }
      } else {
        // setLocations([])
      }
    };
    fill();
  }, [filter, drawRoute, mapRef]);

  const setFilterClear = () => {
    setfilter({});
  };

  return (
    <div>
      <MyPageHeader
        extraRight={
          <GpsFilterDrawer
            onChange={setfilter}
            onReset={setFilterClear}
            filter={filter}
            forceRender={true}
          />
        }
        title={t("WORD_GPS_LOCATION")}
      />
      <MySpace className="stat-card-row">
        <StatisticCard
          title={t("WORD_ORDERS")}
          amount={0}
          icon={<ShoppingCartPurpleCircleSVG />}
          secondTitle={t("WORD_IN")}
        />
        <StatisticCard
          title={t("WORD_DISTANCE")}
          amount={0}
          icon={<DistanceBlueCircleSVG />}
          secondTitle={t("WORD_IN")}
          withoutCurrency
        />
        <StatisticCard
          title={t("WORD_TIME")}
          amount={0}
          icon={<TimeRedSVG />}
          secondTitle={t("WORD_IN")}
          withoutCurrency
        />
      </MySpace>
      <MyMapGoogle
        // ref={mapRef}
        width={1100}
        height={900}
        // onLoadMap={setMyYmaps}
      >
        {directionsResponses.map((directionsResponse, index) => (
          <DirectionsRenderer
            key={index}
            directions={directionsResponse}
            options={{ suppressMarkers: true }}
          />
        ))}
      </MyMapGoogle>
    </div>
  );
};

export default GpsGoogle;
