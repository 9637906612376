import React from "react";
import MyTextAnalyticsStatsCard from "../../../components/myText/MyTextAnalyticsStatsCard";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import MyCard from "../../../components/myCard/MyCard";
import MySpace from "../../../components/mySpace/MySpace";
import MyStatistic from "../../../components/myStatistic/MyStatistic";
import UtilNumber from "../../../utils/UtilNumber";
import { Flex } from "antd";
import MyTextSecondary from "../../../components/myText/MyTextSecondary";

const AnalyticsStatsCard = ({
  loading,
  title,
  suffix,
  withoutCurrency,
  icon,
  withoutQuantity,
  count,
  total,
  totalP,
}) => {
  const { t } = useTranslation();

  const currency = useSelector((state) => {
    return state.currentUser?.currency;
  });

  let colorRed = "#e24747";
  let colorGreen = "#059857";

  const { percentage, isPlus } = UtilNumber.calcTwoNumbersPercentageIsPlus(
    total,
    totalP
  );

  return (
    <MyCard style={{ width: "33%" }} loading={loading}>
      <MySpace direction="vertical" fullWidth>
        <MySpace fullWidth>
          {icon}
          <MyTextAnalyticsStatsCard
            bold
            style={{
              marginBottom: !withoutQuantity ? 0 : 5,
              display: "block",
            }}
          >
            {title}
          </MyTextAnalyticsStatsCard>
        </MySpace>
        <Flex gap={40}>
          <div>
            <MyTextAnalyticsStatsCard size={12}>
              {t("WORD_AMOUNT")}
            </MyTextAnalyticsStatsCard>
            <MyStatistic
              style={{ fontWeight: "bold" }}
              value={UtilNumber.formatWithK(total)}
              prefix={suffix || !withoutCurrency ? currency : null}
              className="card-stat"
            />
            <MyTextSecondary
              color={
                percentage === "0" ? "#A6AAAC" : isPlus ? colorGreen : colorRed
              }
            >
              {`${percentage}% (${currency}${UtilNumber.formatWithK(total)})`}
            </MyTextSecondary>
          </div>
          {withoutQuantity ? null : (
            <div>
              <MyTextAnalyticsStatsCard size={12}>
                {t("WORD_QUANTITY")}
              </MyTextAnalyticsStatsCard>
              <MyStatistic
                style={{ fontWeight: "bold" }}
                value={UtilNumber.formatDouble(count)}
                className="card-stat"
              />
            </div>
          )}
        </Flex>
      </MySpace>
    </MyCard>
  );
};

export default AnalyticsStatsCard;
