import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "./MyLink.scss";

const MyLink = ({
  path,
  search,
  state,
  _blank = false,
  style,
  className,
  children,
  bold,
  onClick,
  underline,
  color,
}) => {
  const lang = useSelector((state) => {
    return state.lang;
  });

  const pathUrl = `/${lang}${path}`;

  const to = {
    pathname: `${pathUrl}`,
    search: search,
    state: state,
  };

  return (
    <Link
      style={{
        fontWeight: bold ? 600 : 0,
        textDecoration: underline ? "underline" : "",
        color: color,
        ...style,
      }}
      onClick={onClick}
      className={`my-link ${color} ${className || ""}`}
      to={to}
      target={_blank ? "_blank" : ""}
      state={state}
    >
      {children}
    </Link>
  );
};

export default MyLink;
