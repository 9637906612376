import { fetchFromUrlPOSTAsync, fetchFromUrlPOSTAsyncDownloadFile } from "../utils/UrlHelper";

const URL_FILL_PAGINATION = "productQuantity/list";
const URL_PRODUCT_QUANTITY_CREATE = "productQuantity/create";
const URL_PRODUCT_QUANTITY_EXPORT_EXCEL = "productQuantity/exportExcel";

const fs = {
  fillPagination: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsync(URL_FILL_PAGINATION, urlParams);
    return result;
  },
  createProductQuantity: async (values) => {
    let result = await fetchFromUrlPOSTAsync(URL_PRODUCT_QUANTITY_CREATE, {
      saveData: values,
    });
    return result;
  },
  exportExcelQauntity: async (urlParams) => {
    const result = await fetchFromUrlPOSTAsyncDownloadFile(
      URL_PRODUCT_QUANTITY_EXPORT_EXCEL,
      urlParams,
      "Quantity.xlsx"
    );
    return result;
  },
};

const loc = {
  TYPE: { IN: 1, OUT: 2 },
  getType: (type) => {
    switch (type) {
      case 1:
        return {
          label: "In",
          value: 1,
        };
      case 2:
        return {
          label: "Out",
          value: 2,
        };

      default:
        break;
    }
  },
};

const ProductQuantityActions = Object.assign(fs, loc);

export default ProductQuantityActions;
