import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MySelectFormItem from "../mySelectFormItem/MySelectFormItem";
import UtilNotify from "../../utils/UtilNotify";
import RegionActions from "../../actions/RegionActions";

const SelectRegion = ({ idCountry, ...otherProps }) => {
  const { t } = useTranslation();

  const [list, setlist] = useState([]);

  useEffect(() => {
    const fill = async () => {
      if (idCountry) {
        const result = await RegionActions.fillListOptions(idCountry);
        if (result.success) {
          setlist(result.data);
        } else {
          UtilNotify.notifyErrorServer(result.errMsg);
        }
      }
    };
    fill();
  }, [t, idCountry]);

  return (
    <MySelectFormItem
      {...otherProps}
      options={
        idCountry
          ? list
          : [
              {
                value: "warning",
                label: t("WORD_PLEASE_SELECT_A_COUNTRY_FIRST"),
                disabled: true,
              },
            ]
      }
    />
  );
};

export default SelectRegion;
