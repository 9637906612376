import { useForm } from "antd/es/form/Form";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import MyModalForm from "../../../components/myModal/MyModalForm";
import FormApp from "../../../components/formApp/FormApp";
import InputTextFormItem from "../../../components/myInputForm/InputTextFormItem";
import MySpace from "../../../components/mySpace/MySpace";
import MatchingInput from "../../../components/myInputForm/MatchingInput";
import { useSelector } from "react-redux";
import UtilNotify from "../../../utils/UtilNotify";
import ClientSaveActions from "../../../actions/ClientSaveActions";
import ApiStorage from "../../../redux/ApiStorage";
import useNavigateApi from "../../../appRoute/useNavigateApi";
import MyTextSecondary from "../../../components/myText/MyTextSecondary";

const formName = "deleteUser";

const UserDeleteSelfModal = forwardRef(({ loading, icon }, ref) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const refModal = useRef();
  const navigateApi = useNavigateApi();

  const [saving, setSaving] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  useImperativeHandle(ref, () => ({
    viewDelete: () => {
      refModal.current.open();
    },
  }));

  const companyName = useSelector((state) => {
    return state.currentUser.companyName;
  });

  const idClient = useSelector((state) => {
    return state.currentUser.idClient;
  });

  const handleValuesChange = () => {
    const companyName = form.getFieldValue("companyName");
    const enterName = form.getFieldValue("enterName");
    setIsSubmitDisabled(companyName !== enterName);
  };

  const onFinish = async () => {
    setSaving(true);
    const result = await ClientSaveActions.deleteProfile(idClient);
    if (result.success) {
      refModal.current.close();
      ApiStorage.clearUser();
      navigateApi(`/login`);
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    setSaving(false);
  };

  return (
    <MyModalForm
      ref={refModal}
      loading={loading}
      icon={icon}
      title={t("WORD_DELETE_PROFILE")}
      okText={t("WORD_DELETE")}
      saving={saving}
      formName={formName}
      disableOk={isSubmitDisabled}
      success={false}
      fontSize={20}
      danger
    >
      <FormApp
        onFinish={onFinish}
        name={formName}
        form={form}
        initialValues={{ companyName: companyName }}
        onValuesChange={handleValuesChange}
      >
        <MySpace direction="vertical" fullWidth>
          <MyTextSecondary size={16}>
            {t("WORD_DELETING_PROFILE_FIRST_WARNING_TEXT")}{" "}
            {t("WORD_DELETING_PROFILE_SECOND_WARNING_TEXT")}
          </MyTextSecondary>
          <MyTextSecondary size={16}>
            {t("WORD_CONFIRM_YOU_WANT_TO_PERMANETLY_DELETE_THIS_PROFILE")}
          </MyTextSecondary>
          <InputTextFormItem
            name="companyName"
            variant="filled"
            readOnly
            disabled
            style={{ marginTop: 20 }}
          />
          <MatchingInput
            name="enterName"
            matchField="companyName"
            label={t("WORD_ENTER_COMPANY_NAME_YOU_WANT_TO_DELETE")}
            style={{ marginBottom: 10 }}
          />
        </MySpace>
      </FormApp>
    </MyModalForm>
  );
});

export default UserDeleteSelfModal;
