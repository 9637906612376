import { Space } from "antd";
import React from "react";

const MySpace = ({
  children,
  fullWidth,
  direction,
  size = "small",
  style,
  spaceBetween,
  align,
  className,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <Space
      style={{
        width: fullWidth ? "100%" : "",
        justifyContent: spaceBetween ? "space-between" : "",
        ...style,
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      direction={direction}
      size={size}
      align={align}
      className={className || ""}
      onClick={onClick}
    >
      {children}
    </Space>
  );
};

export default MySpace;
