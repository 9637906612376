import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import MyModal from "../../../../components/myModal/MyModal";
import { useTranslation } from "react-i18next";
import FormApp from "../../../../components/formApp/FormApp";
import RouteActions from "../../../../actions/RouteActions";
import UtilNotify from "../../../../utils/UtilNotify";
import MySpace from "../../../../components/mySpace/MySpace";
import { Form } from "antd";
import SelectManager from "../../../../components/selects/SelectManager";
import InputTextFormItem from "../../../../components/myInputForm/InputTextFormItem";
import MyButtonSuccess from "../../../../components/myButton/MyButtonSuccess";

const formName = "formCloneGroup";

const UserRouteCloneModal = forwardRef((props, ref) => {
  const refModal = useRef();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [idRoute, setIdRoute] = useState("");
  const [saving, setSaving] = useState(false);

  useImperativeHandle(ref, () => ({
    viewClone: (route) => {
      setIdRoute(route);
      form?.setFieldsValue(route);
      refModal.current.open();
    },
  }));

  const onFinish = async (values) => {
    setSaving(true);
    values.idRoute = idRoute;
    const result = await RouteActions.clone(values);
    if (result.success) {
      UtilNotify.notifySuccess(t("WORD_CLONED_SUCCESSFULLY"));
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    refModal.current.close();
    setSaving(false);
  };

  return (
    <MyModal ref={refModal} title={t("WORD_CLONE_GROUP")} hideFooter>
      <FormApp form={form} name={formName} onFinish={onFinish}>
        <MySpace direction="vertical" fullWidth>
          <SelectManager
            name="idManager"
            label={t("WORD_SELECT_MANAGER")}
            placeholder={t("WORD_SELECT_MANAGER")}
          />
          <InputTextFormItem name="idRoute" hidden />
          <MyButtonSuccess
            label={t("WORD_SAVE")}
            form={formName}
            style={{ height: 44, marginTop: 20 }}
            loading={saving}
          />
        </MySpace>
      </FormApp>
    </MyModal>
  );
});

export default UserRouteCloneModal;
