import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import MyModal from "../../../components/myModal/MyModal";
import { useTranslation } from "react-i18next";
import FormApp from "../../../components/formApp/FormApp";
import SelectManager from "../../../components/selects/SelectManager";
import { useForm } from "antd/es/form/Form";
import MySpace from "../../../components/mySpace/MySpace";
import SelectDateFromTo from "../../../components/selects/SelectDateFromTo";
import UtilDate from "../../../utils/UtilDate";
import SelectProductQuantityType from "../../../components/selects/SelectProductQuantityType";
import InputNumberFormItem from "../../../components/myInputForm/InputNumberFormItem";
import ProductQuantityActions from "../../../actions/ProductQuantityActions";
import UtilNotify from "../../../utils/UtilNotify";
import InputTextFormItem from "../../../components/myInputForm/InputTextFormItem";
import MyButtonSecondary from "../../../components/myButton/MyButtonSecondary";
import MyButtonSuccess from "../../../components/myButton/MyButtonSuccess";

const formName = "formProductQuantityModal";

const ProductQuantityModal = forwardRef(({ onChange }, ref) => {
  const refModal = useRef();
  const { t } = useTranslation();
  const [form] = useForm();

  const [quantity, setQuantity] = useState({});
  const [saving, setSaving] = useState(false);

  useImperativeHandle(ref, () => ({
    viewQuantity: (cu) => {
      refModal.current.open();
      setQuantity(cu);
      if (form) {
        form?.setFieldsValue({ productName: cu.name });
      }
    },
  }));

  const onFinish = async (values) => {
    values.createDate = UtilDate.getDateToday();
    values.idProduct = quantity.idProduct;
    setSaving(true);
    const result = await ProductQuantityActions.createProductQuantity(values);
    if (result.success) {
      UtilNotify.notifySuccess(t("WORD_PRODUCT_QUANTITY_CREATED"));
      onChange && onChange(values);
      refModal.current.close();
    } else {
      UtilNotify.notifyErrorServer(result.errMsg);
    }
    setSaving(false);
  };

  return (
    <MyModal ref={refModal} title={t("WORD_CREATE_QUANTITY")} hideFooter>
      <FormApp name={formName} onFinish={onFinish} form={form}>
        <MySpace direction="vertical" fullWidth>
          <SelectProductQuantityType
            name="type"
            label={t("WORD_SELECT_TYPE")}
            placeholder={t("WORD_SELECT_TYPE")}
            required
          />
          <InputNumberFormItem
            name="quantity"
            label={t("WORD_QUANTITY")}
            placeholder={t("WORD_QUANTITY")}
            required
          />
          <SelectManager
            name="idManager"
            label={t("WORD_SELECT_MANAGER")}
            placeholder={t("WORD_SELECT_MANAGER")}
            onChangeItem={(it) => {
              if (it.IdUsers) {
                form?.setFieldsValue({ managerName: it.fName });
              }
            }}
          />
          <SelectDateFromTo name="createDate" hidden />
          <MySpace>
            <MyButtonSuccess label={t("WORD_CREATE")} loading={saving} />
            <MyButtonSecondary
              onClick={() => {
                refModal?.current?.close();
              }}
              label={t("WORD_CANCEL")}
              reset
            />
          </MySpace>
        </MySpace>
        <InputTextFormItem name="productName" hidden />
        <InputTextFormItem name="managerName" hidden />
      </FormApp>
    </MyModal>
  );
});

export default ProductQuantityModal;
