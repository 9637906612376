import i18n from "../i18n";
import UtilDate from "./UtilDate";

const numberFormat1 = new Intl.NumberFormat("en-US");

const UtilNumber = {
  formatDouble: (value) => {
    if (isNaN(value)) {
      return "";
    }
    try {
      return numberFormat1.format(value);
    } catch (error) {}
    return value;
  },
  getNewUniqID: () => {
    return (
      Math.floor(Math.random() * (99999999999 - 1111111111)) +
      UtilDate.getDateToday().toDate().getTime()
    );
  },
  isNumber: (val) => {
    let str = String(val);
    str = str.trim();
    if (!str) {
      return false;
    }
    str = str.replace(/^0+/, "") || "0";
    let n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  },
  calcTwoNumbersPercentage: (oldValue, newValue) => {
    if (oldValue === 0) {
      return 0;
    }
    if (!oldValue) {
      oldValue = 1;
    }
    if (!newValue) {
      newValue = 1;
    }
    const percentage = Math.round(
      parseFloat(((newValue - oldValue) / oldValue) * 100)
    );
    const isPlus = UtilNumber.getIsPercentagePositive(percentage);
    return `${isPlus ? "+" : ""}${percentage}`;
  },
  calcTwoNumbersPercentageIsPlus: (oldValue, newValue) => {
    if (!oldValue) {
      return { percentage: "0", isPlus: false };
    }
    if (!oldValue) {
      oldValue = 1;
    }
    if (!newValue) {
      newValue = 1;
    }
    const percentage = Math.round(
      parseFloat(((newValue - oldValue) / oldValue) * 100)
    );
    const isPlus = UtilNumber.getIsPercentagePositive(percentage);

    return {
      percentage: `${isPlus ? "+" : ""}${percentage}`,
      isPlus,
    };
  },
  calculateDiscountedPrice: (originalPrice, discountPercentage) => {
    if (originalPrice === 0) {
      return 0;
    }
    if (!originalPrice) {
      originalPrice = 1;
    }
    if (!discountPercentage) {
      discountPercentage = 0;
    }
    let discountAmount = originalPrice * (discountPercentage / 100);
    let discountedPrice = originalPrice - discountAmount;
    return Math.round(parseFloat(discountedPrice) * 100) / 100;
  },

  calcTwoNumbersPercentageDiff: (num1, num2) => {
    if (!num1) {
      num1 = 1;
    }
    if (!num2) {
      num2 = 1;
    }
    const percentageChange = ((num1 - num2) / Math.abs(num2)) * 100;
    return UtilNumber.formatPercentages(percentageChange);
  },

  formatPercentages: (val) => {
    if (Math.abs(val) >= 1000000) {
      return Math.round(val / 1000000) + "M";
    } else if (Math.abs(val) >= 1000) {
      return Math.round(val / 1000) + "K";
    } else {
      return Math.round(val).toString();
    }
  },

  formatAddPlusAtBegining: (percentage) => {
    let isPositive = UtilNumber.getIsPercentagePositive(percentage);
    if (isPositive === 0) {
      return 0 + "%";
    } else if (isPositive) {
      return "+" + percentage + "%";
    } else {
      return percentage + "%";
    }
  },
  getIsPercentagePositive: (percentage) => {
    if (percentage) {
      if (percentage === 0 || percentage === "0") {
        return 0;
      }
      if (
        percentage > 0 ||
        (typeof percentage === "string" &&
          percentage[0] &&
          percentage[0] !== "-")
      ) {
        return true;
      }
    }
    return false;
  },
  calcLoan: (amount, percentage, months) => {
    if (percentage) {
      percentage = parseFloat(percentage?.toString()?.replaceAll(",", "."));
    }

    const round = (x) => {
      return Math.round(x * 100) / 100;
    };

    let principal = amount;
    let interest = percentage / 100 / 12;
    let payments = months;

    let x = Math.pow(1 + interest, payments);
    let monthly = (principal * x * interest) / (x - 1);

    if (
      !isNaN(monthly) &&
      monthly !== Number.POSITIVE_INFINITY &&
      monthly !== Number.NEGATIVE_INFINITY
    ) {
      return {
        payment: round(monthly),
        total: round(monthly * payments),
        totalInterest: round(monthly * payments - principal),
      };
    } else {
      return { payment: "", total: "", totalInterest: "" };
    }
  },
  formatWithK: (value) => {
    if (!value) {
      return "0";
    }
    try {
      if (value >= 1000000) {
        const formattedValue = (value / 1000 / 1000).toFixed(2);
        return `${formattedValue} ${i18n.t("WORD_MILLION")}`;
      } else if (value >= 1000) {
        const formattedValue = (value / 1000).toFixed(2);
        return `${formattedValue} ${i18n.t("WORD_THOUSAND")}`;
      }
      return value?.toLocaleString();
    } catch (error) {
      console.error(error);
    }
    return value;
  },
};

export default UtilNumber;
